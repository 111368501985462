window.$ = require('jquery');

$(document).ready(function() {
    // Function to display error messages below the field
    function showError($field) {
        var errorMessage = $field.data('parsley-required-message');
        var errorList = $('<ul>').addClass('sfcc-store-locator-error');
        var errorItem = $('<li>').text(errorMessage);
        errorList.append(errorItem);
        $field.after(errorList);
    }

    // Listening to the form's submission
    $('#sfccStoreLocator').submit(function(event) {
        // Prevent the default form submission action
        event.preventDefault();

        // Clear previous errors
        $('.sfcc-store-locator-error').remove();

        // Get zipCode and radius values from the form
        var $zipCode = $('#zipCode');
        var $radius = $('#radius');

        var zipCodeValue = $zipCode.val();
        var radiusValue = $radius.val();

        var hasError = false;

        // Check if zipCode and radius are set and show errors if needed
        if (!zipCodeValue) {
            showError($zipCode);
            hasError = true;
        }
        if (!radiusValue) {
            showError($radius);
            hasError = true;
        }
        if (hasError) return;

        // Construct the endpoint URL with the required parameters
        var endpoint = $(this).attr('action');
        var fullURL = endpoint + '?postalCode=' + zipCodeValue + '&radius=' + radiusValue;

        // Making the AJAX GET request
        $.ajax({
            url: fullURL,
            type: 'GET',
            dataType: 'json',
            success: function(data) {
                var hasResults = data.stores.length > 0;

                if (!hasResults) {
                    $('.store-locator-no-results').show();
                } else {
                    $('.store-locator-no-results').hide();
                }
                var $resultsDiv = $('.b-store-wrapper');
                $resultsDiv.empty();
                // Handle successful API response
                // You can populate the data in '.b-store_locator-content' or handle it differently
                if (data.storesResultsHtml) {
                    $resultsDiv.append(data.storesResultsHtml);
                }
            }
        });
    });
});
