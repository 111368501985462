import { submitFormJson } from 'widgets/toolbox/ajax';
import { getStateID, isStateRestricted } from 'harmony/toolbox/shipToUtils';
import { isMediumViewAndDown } from 'widgets/toolbox/viewtype';

/**
 * @description Quick add to cart widget
 * @param {typeof import('widgets/Widget').default} Widget Base widget for extending
 * @returns {typeof QuickAddToCart} - QuickAddToCart instance
 */

/**
 * @typedef {ReturnType<typeof import('widgets/global/ProcessButton').default>} ProcessButton
 * @typedef {ReturnType<typeof import('widgets/forms/HarmonyQuantityStepper').default>} HarmonyQuantityStepper
 */

export default function (Widget) {
    /**
     * @class QuickAddToCart
     * @classdesc Controls quantity stepper and 'add to cart' button
     * @property {boolean} data-is-disabled handling if component is disabled inside of component, as it has behavior based on viewport
     * @property {string} data-product-id
     * @property {string} data-text-network-error Error message for network issues
     * @property {string} data-show-minicart-on-product-add - Show minicart on product add
     * @property {string} data-accessibility-alerts Alerts configuration
     * @example
     * <div data-widget="quickAddToCart"
        data-product-id="productID"
        data-text-network-error="${Resource.msg('error.alert.network', 'product', null)}"
        data-accessibility-alerts='{
            "addedtocart": "${Resource.msg('alert.addedtocart', 'product', null)}"
        }'
        data-is-disabled="false">
            <div  data-widget="harmonyQuantityStepper"
                ...
            </div>
            <button data-ref="addToCart">
                ...
            </button>
        </div>
     */
    class QuickAddToCart extends Widget {
        prefs() {
            return {
                isDisabled: false,
                showMinicartOnProductAdd: false,
                autoCloseMinicart: false,
                autoCloseTimeout: 5000,
                classHidden: 'h-hidden',
                shipToRestrictedStates: [],
                isHandledRestrictedStates: false,
                ...super.prefs()
            };
        }

        init() {
            super.init();

            // handle shipping restrictions
            this.eventBus().on('shipto.restrictions.updated', 'handleStateRestrictions');
            this.eventBus().on('minicart.removed.product', 'handleRemovedProduct');
            this.handleStateRestrictions();
        }

        /**
         * @param stateID
         * @description Handle update of shipping restrictions
         */
        handleStateRestrictions(stateID) {
            if (!this.prefs().isHandledRestrictedStates) {
                return;
            }

            stateID = getStateID(stateID);
            if (!stateID) {
                return;
            }

            var isRestricted = isStateRestricted(stateID, this.prefs().shipToRestrictedStates);
            var classHidden = this.prefs().classHidden;

            // handle `quickAddToCart` block
            this.ref('self').toggleClass(classHidden, isRestricted);

            // handle disabled button
            var disabledButtons = document.querySelectorAll('[data-id=producttile_disabledbtn_' + this.prefs().productId + ']');

            disabledButtons.forEach(function (disabledBtn) {
                disabledBtn.classList.toggle(classHidden, !isRestricted);
            });
        }

        /**
         * @description post action after product adding. Handling success/error here
         * @param {object} response server response object
         */
        postAddProduct(response) {
            const { autoCloseMinicart, autoCloseTimeout } = this.prefs();
            const cartModel = response.cart;
            let element = document.getElementById('add-to-cart-' + this.prefs().productId);

            if (!response.error && cartModel) {
                const analytics = this.data('analytics');

                if (analytics) {
                    analytics.quantity = Number(this.getQtyValue());
                    this.data('analytics', JSON.stringify(analytics));
                }
                element?.setAttribute('data-add-to-cart-success', 'true');
                const accessibilityAlert = this.prefs().accessibilityAlerts.addedtocart;
                this.showAlertMessage(accessibilityAlert, false);
                if (!isMediumViewAndDown()) {
                    this.showAlertMessage(accessibilityAlert, false);
                }

                // Hide mini cart pop up for CSC Agent
                if (response.isCSCAgent) {
                    cartModel.showMinicart = false;
                } else {
                    cartModel.showMinicart = this.prefs().showMinicartOnProductAdd;
                }
                this.eventBus().emit('product.added.to.cart', cartModel, this);

                if (autoCloseMinicart) {
                    this.eventBus().emit('auto.close.minicart', autoCloseTimeout);
                }

                this.updateAvailabilityComponent();
            } else if (response.error) {
                element?.setAttribute('data-add-to-cart-success', 'false');
                this.showAlertMessage(response.message, true);
            }

            // set low in stock status
            if (response.isNotEnoughATS && response.errorData) {
                // eslint-disable-next-line max-len
                const HarmonyQuantityStepper = /** @type {HarmonyQuantityStepper} */(this.getConstructor('harmonyQuantityStepper'));

                this.eachChild(child => {
                    if (!(child instanceof HarmonyQuantityStepper)) {
                        return;
                    }

                    // set max available value
                    child.ref('field').val(response.errorData.qtyValue);
                });
            }

            // set out of stock status
            if (response.isOutOfStock && response.errorData) {
                // eslint-disable-next-line max-len
                const HarmonyQuantityStepper = /** @type {HarmonyQuantityStepper} */(this.getConstructor('harmonyQuantityStepper'));
                const ProcessButton = /** @type {ProcessButton} */(this.getConstructor('processButton'));

                this.eachChild(child => {
                    if ((child instanceof HarmonyQuantityStepper)) {
                        child.ref('field').val(response.errorData.qtyValue);
                        child.toggleDisable(true);
                    }

                    if ((child instanceof ProcessButton)) {
                        child.ref('self').setText(this.prefs().outOfStockLabel);
                        child.disable();
                    }
                });
            }
        }

        /**
         * @description Get value from input quantity block
         * @returns {number} quantity value for current tile
         */
        getQtyValue() {
            return this.getById('input-quantity', (qtyInput) => {
                return (qtyInput && qtyInput.ref('field') && qtyInput.ref('field').val());
            });
        }

        /**
         * @param {RefElement} button add to cart button itself
         */
        addToCart(button) {
            if (this.prefs().isDisabled) {
                return;
            }

            this.eventBus().emit('addtocart.button.clicked', true);

            // Prevent twice clicks on a button
            if (button.isBusy) {
                return;
            }

            const addToCartBtnPrefs = button.prefs();
            // eslint-disable-next-line max-len
            var wineClubProdList = document.getElementById('wineClubProdList') != null ? document.getElementById('wineClubProdList').value : '';
            const promise = submitFormJson(addToCartBtnPrefs.addToCartUrl, {
                pid: this.currentProductID || addToCartBtnPrefs.pid,
                quantity: this.getQtyValue() || this.selectedQuantity || 1,
                wineClubProdList: wineClubProdList,
                childProducts: this.getChildProducts() || []
            }).then(response => this.postAddProduct(response))
                .catch(() => {
                    //once bundle perfomance issue is fixed, Remove if condition.
                    if(addToCartBtnPrefs.isbundle === undefined && !addToCartBtnPrefs.isbundle){
                        this.showAlertMessage(this.prefs().textNetworkError, true);
                    }
                })
                .finally(() => {
                    this.eventBus().emit('addtocart.button.clicked', false);
                });

            this.eventBus().emit('loader.start', promise);
        }

        /**
         * @description Emits global alert
         * @param {string} message Text for an alert
         * @param {boolean} isError is error flag
         */
        showAlertMessage(message, isError) {
            this.eventBus().emit('alert.show', {
                accessibilityAlert: message,
                errorClass: isError
            });
        }

        /**
         * @description Add to cart trigger click handler
         */
        triggerClick() {
            const ProcessButton = /** @type {ProcessButton} */(this.getConstructor('processButton'));

            this.eachChild(child => {
                if (child instanceof ProcessButton) {
                    this.addToCart(child);
                }
            });
        }

        /**
         * @description Toggle busy of addToCartBtn
         * @param {object} initiator - widget initiator
         * @param {boolean} isBusy - is busy processing flag
         */
        toggleBusy(initiator, isBusy) {
            const ProcessButton = /** @type {ProcessButton} */(this.getConstructor('processButton'));

            this.eachChild(child => {
                if (!(child instanceof ProcessButton)) {
                    return;
                }

                if (isBusy) {
                    child.startProcess();
                } else {
                    child.stopProcess();
                }
            });
        }

        /**
         * @description method definition is obligatory when using in conjunction with a QuantityStepper component
         */
        changeAttribute() {}

        renderAddToCartText(params) {
            this.getById('addToCart', (addToCart) => {
                addToCart.render('template', {
                    isAvailable: params.available,
                    isPreorder: params.availability && params.availability.isPreorder
                }, addToCart.ref('container'));
            });
        }

        /**
         * @description get value from button data attribute
         * @returns {any} childProducts
         */
        getChildProducts() {
            const ProcessButton = /** @type {ProcessButton} */(this.getConstructor('processButton'));
            let childProducts = null;

            this.eachChild(child => {
                if (!(child instanceof ProcessButton)) {
                    return;
                }

                childProducts = JSON.stringify((child && child.config && child.config.childProducts));
            });

            return childProducts;
        }

        /**
         * @description handle removed product
         * @param {string} pid - product id
         */
        handleRemovedProduct(pid) {
            if (pid === this.prefs().productId) {
                this.updateAvailabilityComponent();
            }
        }

        /**
         * @description update availability component
         */
        updateAvailabilityComponent() {
            // eslint-disable-next-line max-len
            const HarmonyQuantityStepper = /** @type {HarmonyQuantityStepper} */(this.getConstructor('harmonyQuantityStepper'));

            this.eachChild(child => {
                if ((child instanceof HarmonyQuantityStepper)) {
                    child.changeValue();
                }
            });
        }
    }

    return QuickAddToCart;
}
