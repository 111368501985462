/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 * @typedef {ReturnType<typeof import('./InputText').default>} InputText
*/

/**
 * @description Base MaskableInput implementation
 * @param {InputText} InputText Base widget for extending
 * @returns {typeof MaskableInput} Input Email class
 */
export default function (InputText) {
    /**
     * @category widgets
     * @subcategory forms
     * @class MaskableInput
     * @augments InputText
     * @classdesc Adds maskable InputMask (customised library) implementation.
     * As a basis was taken [this library](https://github.com/ViniChab/supermask.js).
     * On widget level you must specify 4 event listeners, as described in properties.
     * Widget is not intended to have a separate DOM representation, and should be used as a mixin for another input widgets.
     * @property {string} data-mask - Mask for widget in format `+99(9)99-999-999`, `9-999-9999-999` etc
     * @property {string} data-event-input - Field `input` event handler
     * @property {string} data-event-keypress - Field `keypress` event handler
     * @property {string} data-event-change - Field `change` event handler
     * @property {string} data-event-paste - Field `paste` event handler
     * @returns {typeof MaskableInput} MaskableInput class;
     */
    class MaskableInput extends InputText {
        prefs() {
            return {
                mask: '',
                ...super.prefs()
            };
        }

        /**
         * @description Overloaded `setValue` in order to trigger mask revalidation
         * @param {(string|number|undefined)} [newVal] - set this value to input
         * @param {(boolean|undefined)} [silently] - if set to `true` - input should not be validated against a new value
         * @returns {void}
         */
        setValue(newVal = '', silently = false) {
            super.setValue(newVal, silently);

            const { field, mask } = this.getFieldAndMask();
            if (!this.inputMask || !field) {
                return;
            }
            if (field instanceof HTMLInputElement) {
                this.inputMask.validate(field, mask);
            }
        }

        init() {
            super.init();

            import(/* webpackChunkName: 'inputmask' */'widgets/toolbox/InputMask').then((InputMask) => {
                // eslint-disable-next-line new-cap
                this.inputMask = new InputMask.default();
                this.onDestroy(() => {
                    this.inputMask = undefined;
                });

                const { field, mask } = this.getFieldAndMask();
                if (!field) {
                    return;
                }
                if (field instanceof HTMLInputElement) {
                    this.inputMask.validate(field, mask);
                }
            });
        }

        /**
         * @description Get field and mask for current maskable widget instance
         * @returns {{field: HTMLElement|undefined, mask: string}} Object, containig field and mask and field ref element
         */
        getFieldAndMask() {
            const field = this.ref('field');
            return {
                field: field.get(),
                mask: this.prefs().mask
            };
        }

        /**
         * @description On input logic
         * @param {RefElement} element - target input
         * @param {Event} event - target event
         * @listens dom#input
         * @returns {void}
         */
        onInput(element, event) {
            super.onInput(element, event);

            const { field, mask } = this.getFieldAndMask();
            if (!this.inputMask || !field) {
                return;
            }
            if (field instanceof HTMLInputElement) {
                this.inputMask.onInput(field, mask, event);
            }
        }

        /**
         * @description On keypress logic
         * @param {RefElement} element - target input
         * @param {Event} event - target event
         * @listens dom#keypress
         * @returns {void}
         */
        onKeypress(element, event) {
            const { field, mask } = this.getFieldAndMask();
            if (!this.inputMask || !field) {
                return;
            }
            if (field instanceof HTMLInputElement) {
                this.inputMask.onKeypress(field, mask, event);
            }
        }

        /**
         * @description On change logic
         * @listens dom#change
         * @returns {void}
         */
        onChange() {
            const { field, mask } = this.getFieldAndMask();
            if (!this.inputMask || !field) {
                return;
            }
            if (field instanceof HTMLInputElement) {
                this.inputMask.onChange(field, mask);
            }
        }

        /**
         * @description On paste logic
         * @param {RefElement} element - target input
         * @param {Event} event - target event
         * @listens dom#paste
         * @returns {void}
         */
        onPaste(element, event) {
            const { field, mask } = this.getFieldAndMask();
            if (!this.inputMask || !field) {
                return;
            }
            if (field instanceof HTMLInputElement) {
                this.inputMask.onPaste(field, mask, event);
            }
        }
    }

    return MaskableInput;
}
