window.$ = require('jquery');
$(document).ready(function () {
    const secretKey = 'G7s*9v!Q@lPz&8JdKm#T2bR^X4WpE$hM';

    function createSignature(data, key) {
        let hash = 0;
        const str = data + key;
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            hash = (hash << 5) - hash + char;
            hash |= 0; // Convert to 32bit integer
        }
        return hash.toString(16);
    }

    function obfuscateAndEncodeAmount(amount) {
        const obfuscatedAmount = amount.toString().split('').reverse().join(''); // Simple obfuscation
        const encodedAmount = btoa(obfuscatedAmount); // Base64 encoding using btoa
        const signature = createSignature(encodedAmount, secretKey);
        return `amount=${encodedAmount}&sig=${signature}`;
    }

    function generateSecureLink() {
        const amount = $('#amount').val();
        const baseUrl = $('#hiddenUrlPart').text();
        if (amount) {
            const queryParams = obfuscateAndEncodeAmount(amount);
            const secureLink = `${baseUrl}?${queryParams}`;
            $('#result').html(`<strong>Generated Link:</strong> ${secureLink}`).show();
        } else {
            alert('Please enter an amount.');
        }
    }

    // Bind the click event to a button with id "generateLinkButton"
    $('#generateLinkButton').on('click', generateSecureLink);
});