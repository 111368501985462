import { getStateID, isStateRestricted } from 'harmony/toolbox/shipToUtils';

/**
 * @description ShipToError widget implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof ShipToError} - ShipToStates instance
 */
export default function (Widget) {
    return class ShipToError extends Widget {
        prefs() {
            return {
                shipToRestrictedStates: [],
                ...super.prefs()
            };
        }

        init() {
            super.init();

            // handle shipping restrictions
            this.eventBus().on('shipto.restrictions.updated', 'handleStateRestrictions');
            this.eventBus().on('shipto.restrictions.updated.error.msg', 'handleStateRestrictions');
            this.handleStateRestrictions();
        }

        /**
         * @description Handle shipping restrictions
         */
        handleStateRestrictions(stateID) {
            stateID = getStateID(stateID);
            if (!stateID) {
                return;
            }

            if (isStateRestricted(stateID, this.prefs().shipToRestrictedStates)) {
                this.show();
            } else {
                this.hide();
            }
        }
    };
}
