window.$ = require('jquery');

$(document).ready(function () {
    $('body').on('click', '.title.contactus-generalinfo', function (e) {
        e.preventDefault();
        $(this).parents('.customer-service').toggleClass('active');
        $('.customer-service-content').toggleClass('h-sm-hidden');

        if ($(this).parents('.customer-service').hasClass('active')) {
            $(this).attr('aria-expanded', true);
        } else {
            $(this).attr('aria-expanded', false);
        }
    });
});

window.addEventListener("load", (event) => {
    if(window.location.href.includes('COPlaceOrder-Submit')) {
        window.sessionStorage.setItem("order", window.location.href);
    }
});

window.addEventListener("beforeunload", (event) => {
    if(window.location.href.includes('COPlaceOrder-Submit')) {
        if(window.sessionStorage.getItem("order")) {
            window.sessionStorage.removeItem("order");
            window.setTimeout(function(){
            window.location = document.getElementsByClassName('b-logo')[0].getAttribute('href');
           },1000);
        } else {
            window.sessionStorage.setItem("order", window.location.href);
        }
    }
    window.onbeforeunload = null;
});

