import { debounce } from 'widgets/toolbox/debounce';

const keyCode = Object.freeze({
    END: 35,
    HOME: 36,
    LEFT: 37,
    RIGHT: 39
});

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {ReturnType<typeof import('widgets/global/Button').default>} Button
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 * @typedef {InstanceType<Button>} button
 * @typedef {InstanceType<ReturnType<typeof import('widgets/global/TabPanel').default>>} tabPanel
 */

/**
 * @description Base Tabs implementation
 * @param {Widget} Widget - widget for extending
 * @returns {typeof Tabs} Tabs widget
 */
export default function (Widget) {
    /**
     * @class Tabs
     * @augments Widget
     *
     * @property {string} data-widget - Widget name `tabs`
     * @property {boolean} data-active-first - activate first tab and first tab panel
     * @property {string} data-active-panel - activate tab and tab panel by provided panel id
     * @property {boolean} data-auto-activation - if tabs list should follow accessibility `Tabs with Automatic Activation` feature
     * @property {boolean} data-use-url-anchor - in case if true, each tab change will add a related hash to document location.
     * @classdesc Represents tabbed contents displaying and navigation.
     * Has the next features:
     * 1. Mark selected tab as active
     * 2. Handles keydown by enter\space keyboard button to manipulate the active tab
     * 3. Sets focus to the current tab
     * 4. Adds hash to document location if enabled
     *
     * @example <caption>Example of Tabs widget usage</caption>
     * <div data-widget="tabs">
     *     <ul class="nav nav-tabs nav-fill">
     *         <li class="nav-item">
     *             <a
     *                 data-widget="button"
     *                 data-panel-name="login"
     *                 data-widget-event-click="handleTabClick"
     *                 data-id="button-login"
     *                 data-event-click.prevent="handleClick"
     *                 href="#login" role="tab"
     *             >
     *                 ${Resource.msg('link.header.login.module', 'login', null)}
     *             </a>
     *         </li>
     *         <li class="nav-item">
     *             <a
     *                 data-widget="button"
     *                 data-panel-name="register"
     *                 data-widget-event-click="handleTabClick"
     *                 data-id="button-register"
     *                 data-event-click.prevent="handleClick"
     *                 href="#register" role="tab"
     *             >
     *                 ${Resource.msg('link.header.register.module', 'login', null)}
     *             </a>
     *         </li>
     *     </ul>
     *     <div class="tab-content">
     *         <div id="login" role="tabpanel" data-widget="tabPanel">
     *             ... tab content
     *             <isinclude template="account/components/loginForm" />
     *             <isinclude template="account/components/oauth" />
     *         </div>
     *         <div id="register" role="tabanel" data-widget="tabPanel">
     *             ... tab content
     *             <isinclude template="account/components/registerForm" />
     *         </div>
     *     </div>
     * </div>
     */
    class Tabs extends Widget {
        /**
         * @description Returns Widget configuration object
         * @returns {object} Widget configuration object
         */
        prefs() {
            return {
                classesActive: 'm-active',
                activePanel: '',
                activeFirst: false,
                autoActivation: false,
                useUrlAnchor: false,
                ...super.prefs()
            };
        }

        /**
         * @description Refresh widget handler
         * @returns {void}
         */
        onRefresh() {
            super.onRefresh();
            this.fulfillPanelNames();
        }

        /**
         * @description Widget initialization logic
         * @returns {void}
         */
        init() {
            super.init();
            this.fulfillPanelNames();

            if (this.prefs().activePanel) {
                this.activatePanel(this.prefs().activePanel);
            } else if (this.prefs().useUrlAnchor) {
                this.handleUrlChange();
            } else if (this.prefs().activeFirst && this.panelNames && this.panelNames.length) {
                this.activatePanel(this.panelNames[0]);
            }

            this.state = {};

            this.tablist = this.ref('tablist').get();

            if (this.tablist) {
                this.tabsControlsScrollInit();
            }
        }

        /**
         * @description Init tabs controll scrolling functionality.
         * @returns {void}
         */
        tabsControlsScrollInit() {
            this.tabsControlsScroll = this.tabsControlsHandleScroll.bind(this);
            this.tabsControlsHandleScroll();

            this.state.scrollStart = true;
            this.state.scrollEnd = false;

            this.ev('scroll', this.tabsControlsHandleScroll, this.tablist, true);
            this.ev('resize', debounce(() => {
                this.tabsControlsScrollabilityCheck();
            }, 300), window, true);
        }

        /**
         * @description Scrollability check for tabs control.
         * @returns {boolean} scrollability state
         */
        tabsControlsScrollabilityCheck() {
            this.state.scrollable = this.tablist.offsetWidth < this.tablist.scrollWidth;
            this.tablist.classList.toggle('m-scrollable', this.state.scrollable);

            return this.state.scrollable;
        }

        /**
         * @description Handler for tabs control scrolling.
         * @returns {void}
         */
        tabsControlsHandleScroll() {
            if (!this.tabsControlsScrollabilityCheck()) {
                return;
            }

            this.state.scrollStart = !this.tablist.scrollLeft;
            this.state.scrollEnd = this.tablist.scrollWidth === this.tablist.scrollLeft + this.tablist.offsetWidth;

            this.tablist.classList.toggle('m-scroll_start', this.state.scrollStart);
            this.tablist.classList.toggle('m-scroll_end', this.state.scrollEnd);
        }

        /**
         * @description Registers all available panels by adding their IDs into array.
         * @returns {void}
         */
        fulfillPanelNames() {
            /**
            * @type {string[]}
            */
            this.panelNames = [];
            const Button = /** @type {Button} */(this.getConstructor('button'));

            this.eachChild(child => {
                if (child instanceof Button && this.panelNames) {
                    this.panelNames.push(String(child.config.panelName));
                }
            });
        }

        /**
         * @param {InstanceType<Widget>} clickedButton - Widget, representing customer's tab clicked element
         * @returns {void}
         */
        handleTabClick(clickedButton) {
            this.activatePanel(String(clickedButton.config.panelName));
        }

        /**
         * @param {string} panelName name of panel to activate
         * @param {boolean} saveToPushState - If we need to save hash in history
         * @returns {void}
         */
        activatePanel(panelName, saveToPushState = true) {
            if (this.panelNames) {
                this.panelNames.forEach(id => {
                    const isActive = id === panelName;

                    if (isActive) {
                        this.activePanel = panelName;
                        this.focusedTab = panelName;
                    }

                    const currentTabPanel = this.getById(id, (/** @type {tabPanel} */tabPanel) => {
                        tabPanel[isActive ? 'activate' : 'deactivate']();
                        if (isActive && saveToPushState) {
                            this.handleUrlAnchor(id);
                        }

                        return tabPanel;
                    });

                    if (!currentTabPanel && this.ref(id)) {
                        this.toggleActiveRefPanel(this.ref(id), isActive);
                        if (isActive && saveToPushState) {
                            this.handleUrlAnchor(id);
                        }
                    }

                    this.getById(`button-${id}`, (/** @type {button} */ button) => {
                        if (isActive) {
                            button.activate();
                            button.select();
                        } else {
                            button.deactivate();
                            button.unselect();
                        }
                    });
                });
            }
        }

        /**
         * @description In addition to tab switch, action will be reflected in URL anchor, if enabled.
         * This will further allow to select proper tab, when returning back from browser history.
         * @param {string} tabName tab name to reflect in browser location hash.
         * @returns {void}
         */
        handleUrlAnchor(tabName) {
            if (this.prefs().useUrlAnchor) {
                window.history.pushState({
                    hashChangedOnly: true
                }, document.title, `#${tabName}`);
            }
        }

        /**
         * @description Triggers needed tab activation, when URL changed
         * in case of history push/replace state and using browser's `back` button.
         * @param {boolean} saveToPushState - If we need to save hash in history
         * @returns {void}
         */
        handleUrlChange(saveToPushState = true) {
            if (document.location.hash) {
                const panelToActivate = this.panelNames
                    && this.panelNames.find(panelName => panelName === document.location.hash.replace('#', ''));
                if (panelToActivate) {
                    this.activatePanel(panelToActivate, saveToPushState);
                }
            }
        }

        /**
         * @description Method to toggle active panel, if it is not a separate widget `TabPanel`
         * but an ordinary `ref` element inside `Tabs` widget.
         * @param {refElement} panel target panel name
         * @param {boolean} state is target panel is selected or not
         * @returns {void}
         */
        toggleActiveRefPanel(panel, state) {
            panel.toggleClass(this.prefs().classesActive, state);
        }

        /**
         * @description Gets last focused panel name
         * <br>(focused panel is not necessarily active panel)
         * @returns {string} Focused panel name if founded. Empty string otherwise
         */
        getLastFocusedTab() {
            return this.focusedTab
                ? this.focusedTab
                : this.activePanel || (this.panelNames && this.panelNames[0]) || '';
        }

        /**
         * @description Sets focus to panel with given name
         * <br>Uses `roving focus` accessibility feature
         * <br>https://www.w3.org/TR/wai-aria-practices/#kbd_roving_tabindex
         * @param {string} tab Tab name to set focus to
         * @returns {void}
         */
        setFocusToTab(tab) {
            if (!tab) {
                return;
            }

            this.focusedTab = tab;

            if (this.panelNames) {
                this.panelNames.forEach(id => {
                    const isTargetTab = id === tab;
                    this.getById(`button-${id}`, (/** @type {button} */ button) => {
                        if (isTargetTab) {
                            button.focus().setTabindex();
                        } else {
                            button.unsetTabindex();
                        }
                    });
                });
            }

            if (this.prefs().autoActivation) {
                this.activatePanel(tab);
            }
        }

        /**
         * @description Sets focus to the very first panel
         * @returns {void}
         */
        setFocusToFirstTab() {
            const firstTab = (this.panelNames
                && this.panelNames.length
                && this.panelNames[0])
                || '';
            this.setFocusToTab(firstTab);
        }

        /**
         * @description Sets focus to the very last panel
         * @returns {void}
         */
        setFocusToLastTab() {
            const lastTab = (this.panelNames
                && this.panelNames.length
                && this.panelNames[this.panelNames.length - 1])
                || '';
            this.setFocusToTab(lastTab);
        }

        /**
         * @description Sets focus to previous panel. Loops focus, if first panel reached
         * @returns {void}
         */
        setFocusToPreviousTab() {
            if (this.panelNames && this.panelNames.length) {
                const currentFocusedTab = this.getLastFocusedTab();
                const currentFocusedTabIndex = this.panelNames.indexOf(currentFocusedTab);
                const previousTab = currentFocusedTabIndex === 0
                    ? this.panelNames[this.panelNames.length - 1]
                    : this.panelNames[currentFocusedTabIndex - 1];
                this.setFocusToTab(previousTab);
            }
        }

        /**
         * @description Sets focus to next panel. Loops focus, if last panel reached
         * @returns {void}
         */
        setFocusToNextTab() {
            if (this.panelNames && this.panelNames.length) {
                const currentFocusedTab = this.getLastFocusedTab();
                const currentFocusedTabIndex = this.panelNames.indexOf(currentFocusedTab);
                const nextTab = currentFocusedTabIndex === this.panelNames.length - 1
                    ? this.panelNames[0]
                    : this.panelNames[currentFocusedTabIndex + 1];
                this.setFocusToTab(nextTab);
            }
        }

        /**
         * @description Keydown Event handler
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         * @returns {void}
         */
        handleKeydown(_, event) {
            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.HOME:
                    this.setFocusToFirstTab();
                    preventEventActions = true;
                    break;

                case keyCode.END:
                    this.setFocusToLastTab();
                    preventEventActions = true;
                    break;

                case keyCode.LEFT:
                    this.setFocusToPreviousTab();
                    preventEventActions = true;
                    break;

                case keyCode.RIGHT:
                    this.setFocusToNextTab();
                    preventEventActions = true;
                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    return Tabs;
}
