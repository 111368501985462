import { submitFormJson } from 'widgets/toolbox/ajax';
import { get } from 'widgets/toolbox/util';
import { getHeaderType } from 'harmony/toolbox/shipToUtils';

/**
 * @typedef {ReturnType<typeof import('widgets/forms/AjaxForm').default>} AjaxForm
 * @typedef {ReturnType<typeof import('widgets/forms/InputRadio').default>} InputRadio
 */

/**
 * @description ShipToStates widget implementation
 * @param {AjaxForm} AjaxForm Base widget for extending
 * @returns {typeof ShipToStates} - ShipToStates instance
 */
export default function (AjaxForm) {
    class ShipToStates extends AjaxForm {
        init() {
            super.init();
            this.headerType = this.ref('self').data('headerType') || '';
            this.eventBus().on('shiptostate.submit', 'handleSubmit');
            this.eventBus().on('shipttostate.setstate', 'setInputValue');
            this.handleBrowsingOnlyModal();
        }

        /**
         * Set radio input value
         *
         * @param {string} stateID - state code
         */
        setInputValue(stateID) {
            this.getById('dwfrm_states_stateCode', (inputRadio) => {
                inputRadio.setValue(stateID);
                this.stateID = stateID;
            });
        }

        /**
         * Handle ship to state from submit
         * @returns {boolean} - false to match super implementation
         */
        handleSubmit() {
            // submit form
            const promise = submitFormJson(
                this.getFormUrl(),
                this.getFormFields(),
                'POST',
                true
            )
            // emit event to redraw header value
                .then((res) => {
                    this.handleStateChange(res);
                });

            this.eventBus().emit('loader.start', promise);
            return false;
        }

        /**
         * handle state change
         */
        handleStateChange(response) {
            const stateID = get(response, 'stateID') || this.stateID;
            const basketItems = get(response, 'basket.numItems');

            this.eventBus().emit('shipto.title.redraw');
            this.eventBus().emit('shipto.state.changed', stateID);
            this.eventBus().emit('shipto.restrictions.updated', stateID);

            // refresh basket items if it includes items
            if (basketItems) {
                this.eventBus().emit('cart.items.refresh');
            }

            if (response.basket) {
                this.eventBus().emit('product.added.to.cart', response.basket, this);
            }

            // store internal value
            this.getById('dwfrm_states_stateCode', (inputRadio) => {
                this.stateID = inputRadio.getValue();
            });
            // pushing monetate data for custom variables (state_eligible, state_name)
            if (response.stateJSON) {
                var isStateEligible = response.stateJSON.isStateEligible ? 'yes' : 'no';
                window.monetateData.ship_eligible = isStateEligible;
                window.monetateData.ship_state = response.stateJSON.shipToState;
                // eslint-disable-next-line max-len
                window.monetateQ.push(['setCustomVariables', [{ name: 'ship_eligible', value: monetateData.ship_eligible}, {name: 'ship_state', value: monetateData.ship_state }]]);
            }
        }

        /**
         * handle browsing only dialog open
         */
        handleModalOpen() {
            // emit event to notify that specific dialog was opened
            this.emit('browsingonlymodalopen', this);
        }

        /**
         * Handle radio input select
         *
         * @param {InputRadio} inputRadio - input radio widget
         */
        handleChange(inputRadio) {
            var dataSet = inputRadio.getDataSet();
            var isBrowsingOnly = dataSet.browsingOnly;

            if (isBrowsingOnly) {
                this.eventBus().emit('sidebar.close');
                // display confirmation modal
                var modalTitle = dataSet.modalTitle;
                this.getById('browsingOnlyModal', BrowsingOnlyModal => BrowsingOnlyModal.showModal({ modalTitle }, () => {
                    this.handleModalOpen();
                    BrowsingOnlyModal.afterShowModal();
                }));
            } else {
                // submit form
                this.handleSubmit();
            }
        }

        /**
         * handle browsing only state modal cancel
         */
        handleModalCancel() {
            // emit event that state should be selected
            this.emit('selectstate', this);
            // emit event to notify that specific dialog was closed
            this.emit('browsingonlymodalclose', this);
        }

        /**
         * handle browsing only by auto
         */
        handleBrowsingOnlyModal() {
            if (this.headerType && getHeaderType() !== this.headerType) {
                return;
            }

            const self = this.ref('self');
            const inputRadio = self.data('initialState')
                ? document.querySelector('input[data-id="dwfrm_states_stateCode-' + self.data('initialState') + '"]')
                : null;
            if (inputRadio && self.data('showBrowsingOnlyModal')) {
                submitFormJson(self.data('disableBrowsingOnlyUrl'), {}, 'POST', true).then(() => {
                    const modalTitle = inputRadio.dataset.modalTitle;
                    this.getById('browsingOnlyModal', BrowsingOnlyModal => BrowsingOnlyModal.showModal({ modalTitle },
                        this.handleModalOpen.bind(this)));
                });
            }
        }
    }

    return ShipToStates;
}
