/**
 * @typedef {ReturnType<typeof import('widgets/forms/AjaxForm').default>} AjaxForm
 * @typedef {InstanceType<ReturnType<typeof import('widgets/forms/InputText').default>>} inputText
 */

/**
 * @param {AjaxForm} AjaxForm Base widget for extending
 * @returns {typeof EmailSubscribe} EmailSubscribe widget
 */
export default function (AjaxForm) {
    /**
     * @category widgets
     * @subcategory ajax
     * @class EmailSubscribe
     * @augments AjaxForm
     * @classdesc Serves email subscription form in footer.
     * The main purpose EmailSubscribe widget is to allow send/validate the request to server and handles server response
     * @property {string} data-widget - Widget name `emailSubscribe`
     * @property {string} data-event-submit - Event listener for form submission
     * @example <caption>Example of EmailSubscribe widget usage</caption>
     * <form action="${URLUtils.url('EmailSubscribe-Subscribe')}" method="POST" name="subscribe-form"
     *     data-widget="emailSubscribe"
     *     data-event-submit.prevent="handleSubmit">
     *     <div class="input-group" data-ref="formContent">
     *         ... subscription form content
     *     </div>
     *     <div data-ref="successMessage"></div>
     * </form>
     */
    class EmailSubscribe extends AjaxForm {
        prefs() {
            return {
                signupEmail: 'dwfrm_emailsubscribe_email',
                formContent: 'formContent',
                successBlock: 'successBlock',
                successMessage: 'successMessage',
                agreeToPrivacy: 'agreeToPrivacy',
                accessibilityAnnouncementMsg: '',
                ...super.prefs()
            };
        }

        /**
         * @description Handles email input
         * @param {inputText} el event source element
         * @returns {void}
         */
        onEmailInput(el) {
            const entered = el && el.getValue();
            if (entered && entered.length) {
                this.showAgreeToPrivacy();
            }
        }

        /**
         * @description Show agree to privacy block
         * @returns {void}
         */
        showAgreeToPrivacy() {
            this.ref('agreeToPrivacy').show();
        }

        /**
         * @description Handles server response
         * @emits "alert.show"
         * @param {object} data Server JSON response once form submitted
         * @returns {void}
         */
        onSubmitted(data) {
            if (data.success) {
                this.ref('formContent').hide();
                this.ref('successMessage')
                    .setText(data.msg)
                    .show();
                /**
                 * @description Global event to show alert
                 * @event "alert.show"
                 */
                this.eventBus().emit('alert.show', {
                    accessibilityAlert: this.prefs().accessibilityAnnouncementMsg
                });
            }

            if (!data.success) {
                if (data.fieldErrors) {
                    Object.entries(data.fieldErrors).forEach(([name, errorMsg]) => {
                        this.getById(name, formField => formField.setError(errorMsg));
                    });
                }
                if (data.msg) {
                    this.getById(this.prefs().signupEmail, inputEmail => inputEmail.setError(data.msg));
                }
            }
        }
    }

    return EmailSubscribe;
}
