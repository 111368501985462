/**
 * @typedef {ReturnType<typeof import('widgets/forms/BasicInput').default>} BasicInput
 */

/**
 * @description Harmony BasicInput implementation
 * @param {BasicInput} BasicInput Base widget for extending
 * @returns {typeof HarmonyBasicInput} Harmony Basic Input class
 */
export default function (BasicInput) {
    /**
     * @class HarmonyBasicInput
     * @augments BasicInput
     */
    class HarmonyBasicInput extends BasicInput {
        /**
         * field focus handler
         */
        focus() {
            const basicInput = this.ref('self').get();
            let parentForm = null;

            if (basicInput) {
                parentForm = basicInput.closest('form');
            }

            /**
             * @description field
             * @type {any}
            */
            const field = this.ref('field').get();

            if (field && parentForm && parentForm.dataset.widget) {
                this.eventBus().emit('interacted.field', {
                    formId: parentForm.id || parentForm.dataset.widget,
                    fieldName: field.name,
                    formName: parentForm.name
                });
            }

            super.focus();
        }

        /**
         * helper handle if field need additional validation on blur
         */
        blurAndValidate() {
            this.blur();
            this.validate();
        }

        /**
         * Do validation on input only in case field was validated before (form submit or field validation on blur)
         */
        validateOnInput() {
            if (this.validated) {
                this.validate();
            }
        }

        /**
         * @description Triggers input validation process
         * overloaded to tirgger gtm event
         * @returns {boolean} input validation result
         */
        validate() {
            this.validated = true;

            const valid = super.validate();

            if (!valid) {
                // report gtm data
                this.eventBus().emit('gtm.form.error', {
                    formName: this.formName,
                    errorMessage: this.error
                });
            }

            return valid;
        }
    }

    return HarmonyBasicInput;
}
