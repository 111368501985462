/**
 * @module events
 * @category widgets
 * @subcategory toolbox
 * @description Create and returns an instance of EventEmitter. Refer [eventemitter3 documentation](https://www.npmjs.com/package/eventemitter3).
 */

import { EventEmitter } from 'eventemitter3';
const EventBus = new EventEmitter();
export default EventBus;
