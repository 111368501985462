import { setCurrentToken } from '../toolbox/ajax';

/**
 * @param {typeof import('widgets/Widget').default} Widget - widget for extending
 * @returns {typeof Token} Token widget
 */
export default function (Widget) {
    /**
     * @category widgets
     * @subcategory global
     * @class Token
     * @augments Widget
     * @property {string} data-widget - Widget name `token`
     * @property {string} data-token-name - Name of CSRF token
     * @property {string} data-token-value - Value of CSRF token
     * @classdesc Represents Token component with the next features:
     * 1. If available on page - adds CSRF data in context of ajax requests.
     * 2. Widget container is usually empty and hidden.
     *
     * @example <caption>Example of Token widget usage</caption>
     * <div
     *     data-widget="token"
     *     data-token-name="${require('dw/web/CSRFProtection').getTokenName()}"
     *     data-token-value="${require('dw/web/CSRFProtection').generateToken()}"
     *     hidden="hidden"
     * ></div>
     */
    class Token extends Widget {
        /**
         * @description Widget initialization logic
         * @returns {void}
         */
        init() {
            setCurrentToken(this.config.tokenName, this.config.tokenValue);
        }
    }

    return Token;
}
