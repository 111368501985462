/* eslint-disable indent */
/* eslint-disable sonarjs/no-duplicate-string */
/* sonar-ignore-start */

const [, pdp, , plp, wishlist, cart, checkout, orderconfirmation, account, content] = [
    'home',
    'pdp',
    'clp',
    'plp',
    'wishlist',
    'cart',
    'checkout',
    'orderconfirmation',
    'account',
    'static'
]
// @ts-ignore
.map(ctx => window.contexts.includes(ctx));

import widgetsMgr from 'widgets/widgetsMgr';
import Accordion from 'widgets/global/Accordion';
import AccordionItem from 'widgets/global/AccordionItem';
import Button from 'widgets/global/Button';
import ListAccessibility from 'widgets/global/ListAccessibility';
import MegaMenu from 'widgets/header/MegaMenu';
import SwipeToClose from 'widgets/global/SwipeToClose';
import MenuPanel from 'widgets/header/MenuPanel';
import MenuPanelToggle from 'widgets/header/MenuPanelToggle';
import MenuBarItem from 'widgets/header/MenuBarItem';
import HamburgerMenuItem from 'widgets/header/HamburgerMenuItem';
import DismissBlock from 'widgets/global/DismissBlock';
import Form from 'widgets/forms/Form';
import BasicForm from 'widgets/forms/BasicForm';
import CountrySelector from 'widgets/header/CountrySelector';
import CountrySelectorMenuItem from 'widgets/header/CountrySelectorMenuItem';
import AjaxForm from 'widgets/forms/AjaxForm';
import Token from 'widgets/global/Token';
import BasicInput from 'widgets/forms/BasicInput';
import InputEmail from 'widgets/forms/InputEmail';
import InputPassword from 'widgets/forms/InputPassword';
import InputCheckbox from 'widgets/forms/InputCheckbox';
import InputText from 'widgets/forms/InputText';
import InputTextarea from 'widgets/forms/InputTextarea';
import InputHidden from 'widgets/forms/InputHidden';
import InputTel from 'widgets/forms/InputTel';
import MaskableInput from 'widgets/forms/MaskableInput';
import Tabs from 'widgets/global/Tabs';
import TabPanel from 'widgets/global/TabPanel';
import InputSelect from 'widgets/forms/InputSelect';
import Label from 'widgets/global/Label';
import EmailSubscribe from 'widgets/forms/ajax/EmailSubscribe';
import ConfirmDialog from 'widgets/global/ConfirmDialog';
import Carousel from 'widgets/global/Carousel';
import BackToTop from 'widgets/global/BackToTop';
import Modal from 'widgets/global/Modal';
import InputRadio from 'widgets/forms/InputRadio';
import ConsentTracking from 'widgets/global/ConsentTracking';
import DropDown from 'widgets/global/DropDown';
import EmitBusEvent from 'widgets/global/EmitBusEvent';
import AjaxFormResponseContainer from 'widgets/forms/AjaxFormResponseContainer';
import Combobox from 'widgets/forms/Combobox';
import SearchBox from 'widgets/search/SearchBox';
import FocusHighlighter from 'widgets/global/FocusHighlighter';
import SearchBoxToggle from 'widgets/search/SearchBoxToggle';
import StickyHeader from 'widgets/global/StickyHeader';
import ButtonWithIcon from 'widgets/global/ButtonWithIcon';
import AccessibilityFocusTrapMixin from 'widgets/global/AccessibilityFocusTrapMixin';
import AccessibilityFocusMixin from 'widgets/global/AccessibilityFocusMixin';
import SoftKeyboardToggleMixin from 'widgets/global/SoftKeyboardToggleMixin';
import ProductTile from 'widgets/product/ProductTile';
import AddToWishlistMixin from 'widgets/wishlist/AddToWishlistMixin';
import Disclosure from 'widgets/global/Disclosure';
import GlobalAlerts from 'widgets/global/GlobalAlerts';
import GlobalModal from 'widgets/global/GlobalModal';
import MenuButton from 'widgets/header/MenuButton';

widgetsMgr.addWidgetsList('global.widgets', () => [
    ['token', Token],
    ['label', Label],
    ['carousel', Carousel],
    ['button', Button],
        ['buttonWithIcon', ButtonWithIcon, 'button'],
    ['listAccessibility', ListAccessibility],
        ['refinementsPanel', Modal, 'listAccessibility'],
        ['refinementsPanel', SwipeToClose, 'refinementsPanel'],
        ['megaMenu', MegaMenu, 'listAccessibility'],
        ['menuBarItem', MenuBarItem, 'listAccessibility'],
        ['hamburgerMenuItem', HamburgerMenuItem, 'listAccessibility'],
            ['countrySelectorMenuItem', CountrySelectorMenuItem, 'hamburgerMenuItem'],
                ['countrySelectorMenuItem', CountrySelector, 'countrySelectorMenuItem'],
        ['accordion', Accordion, 'listAccessibility'],
        ['menuButton', MenuButton, 'listAccessibility'],
    ['accordionItem', AccordionItem],
    ['swipeToClose', SwipeToClose],
        ['menuPanel', MenuPanel, 'swipeToClose'],
    ['menuPanelToggle', MenuPanelToggle],
    ['dropDown', DropDown],
    ['dismissBlock', DismissBlock],
    ['countrySelector', CountrySelector, 'menuButton'],
    ['backtotop', BackToTop],
    ['consentTracking', ConsentTracking],
    ['emitBusEvent', EmitBusEvent],
    ['focusHighlighter', FocusHighlighter],
    ['searchBoxToggle', SearchBoxToggle],
    ['stickyHeader', StickyHeader],
    ['basicForm', BasicForm],
        ['form', Form, 'basicForm'],
            ['ajaxform', AjaxForm, 'basicForm'],
                ['emailSubscribe', EmailSubscribe, 'ajaxform'],
    ['basicInput', BasicInput],
        ['inputPassword', InputPassword, 'basicInput'],
        ['inputCheckbox', InputCheckbox, 'basicInput'],
        ['inputSelect', InputSelect, 'basicInput'],
        ['inputText', InputText, 'basicInput'],
            ['inputEmail', InputEmail, 'inputText'],
            ['inputTextarea', InputTextarea, 'inputText'],
            ['inputTel', InputTel, 'inputText'],
                ['inputTel', MaskableInput, 'inputTel'],
        ['inputRadio', InputRadio, 'basicInput'],
        ['inputHidden', InputHidden, 'basicInput'],
    ['modal', Modal],
        ['modal', AccessibilityFocusTrapMixin, 'modal'],
        ['globalModal', GlobalModal, 'modal'],
        ['confirmDialog', ConfirmDialog, 'modal'],
        ['searchBoxToggle', SearchBoxToggle, 'modal'],
    ['combobox', Combobox],
        ['searchBox', SearchBox, 'combobox'],
            ['searchBox', AccessibilityFocusTrapMixin, 'searchBox'],
            ['searchBox', SoftKeyboardToggleMixin, 'searchBox'],
            ['searchBox', Modal, 'searchBox'],
    ['tabs', Tabs],
    ['tabPanel', TabPanel],
    ['productTile', ProductTile],
        ['productTile', AddToWishlistMixin, 'productTile'],
    ['ajaxFormResponseContainer', AjaxFormResponseContainer, 'modal'],
    ['disclosure', Disclosure],
    ['globalAlerts', GlobalAlerts]
]);

if (content) {
    widgetsMgr.asyncListsPromises.push(Promise.all([
        import(/* webpackChunkName: 'content.widgets' */ 'widgets/content/ContactUs')
    ]).then(deps => {
        const [ContactUs] = deps.map(dep => dep.default);

        return {
            listId: 'content.widgets',
            widgetsDefinition: () => [
                ['contactUs', ContactUs]
            ]
        };
    }));
}

if (!checkout) {
    widgetsMgr.asyncListsPromises.push(Promise.all([
        import(/* webpackChunkName: 'minicart.widgets' */ 'widgets/cart/Minicart'),
        import(/* webpackChunkName: 'minicart.widgets' */ 'widgets/cart/MinicartDialog')
    ]).then(deps => {
        const [
            Minicart,
            MinicartDialog
        ] = deps.map(dep => dep.default);

        return {
            listId: 'minicart.widgets',
            widgetsDefinition: () => [
                ['minicart', Minicart],
                // modal
                ['minicartDialog', MinicartDialog, 'modal']
            ]
        };
    }));
}

if (wishlist) {
    widgetsMgr.asyncListsPromises.push(Promise.all([
        import(/* webpackChunkName: 'wishlist.widgets' */ 'widgets/wishlist/WishlistMgr'),
        import(/* webpackChunkName: 'wishlist.widgets' */ 'widgets/wishlist/WishListItem'),
        import(/* webpackChunkName: 'wishlist.widgets' */ 'widgets/product/AddToCartMixin')
    ]).then(deps => {
        const [WishlistMgr, WishListItem, AddToCartMixin] = deps.map(dep => dep.default);

        return {
            listId: 'wishlist.widgets',
            widgetsDefinition: () => [
                ['wishlistMgr', WishlistMgr],
                ['wishListItem', WishListItem],
                    ['wishListItem', AddToCartMixin, 'wishListItem']
            ]
        };
    }));
}

if (pdp || plp || cart || wishlist) {
    widgetsMgr.asyncListsPromises.push(Promise.all([
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductDetail'),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ProductImages'),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/ColorSwatch'),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/product/AddToCartMixin'),
        import(/* webpackChunkName: 'product.widgets' */ 'widgets/global/ProcessButton')
    ]).then(deps => {
        const [
            ProductDetail,
            ProductImages,
            ColorSwatch,
            AddToCartMixin,
            ProcessButton
        ] = deps.map(dep => dep.default);

        return {
            listId: 'product.widgets',
            widgetsDefinition: () => [
                ['productDetail', ProductDetail],
                    ['productDetail', AddToCartMixin, 'productDetail'],
                    ['productDetail', AddToWishlistMixin, 'productDetail'],
                ['productImages', ProductImages],
                    ['productImages', AccessibilityFocusTrapMixin, 'productImages'],
                ['colorSwatch', ColorSwatch],
                // button
                    ['processButton', ProcessButton, 'button']
            ]
        };
    }));
}

if (plp) {
    widgetsMgr.asyncListsPromises.push(Promise.all([
        import(/* webpackChunkName: 'search.widgets' */ 'widgets/search/ProductListingMgr'),
        import(/* webpackChunkName: 'search.widgets' */ 'widgets/search/RefinementsPanel'),
        import(/* webpackChunkName: 'search.widgets' */ 'widgets/search/RefinementsPanelToggle'),
        import(/* webpackChunkName: 'search.widgets' */ 'widgets/search/RefinementMenuItem'),
        import(/* webpackChunkName: 'search.widgets' */ 'widgets/global/Refinement'),
        import(/* webpackChunkName: 'search.widgets' */ 'widgets/search/NoSearchResultsForm')
    ]).then(plpDeps => {
        const [
            ProductListingMgr,
            RefinementsPanel,
            RefinementsPanelToggle,
            RefinementMenuItem,
            Refinement,
            NoSearchResultsForm
        ] = plpDeps.map(dep => dep.default);

        return {
            listId: 'search.widgets',
            widgetsDefinition: () => [
                // listAccessibility
                    ['refinementsPanel', RefinementsPanel, 'refinementsPanel'],
                    ['refinementMenuItem', RefinementMenuItem, 'listAccessibility'],
                // tabs
                    ['productListingMgr', ProductListingMgr, 'tabs'],
                ['refinementsPanelToggle', RefinementsPanelToggle],
                ['refinement', Refinement],
                // basicForm
                    ['noSearchResultsForm', NoSearchResultsForm, 'basicForm']
            ]
        };
    }));
}

if (account) {
    // @ts-ignore
    widgetsMgr.asyncListsPromises.push(Promise.all([
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/account/PaymentsList'),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/account/AddressList'),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/account/OrderHistoryMgr'),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/AddressBookForm'),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/account/PasswordResetForm'),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/AddressForm'),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/ajax/LoginForm'),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/forms/GoogleAutocompleteField'),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/global/SessionExpiration'),
        import(/* webpackChunkName: 'account.widgets' */ 'widgets/order/OrderProductSummary')
    ]).then(deps => {
        const [
            PaymentsList,
            AddressList,
            OrderHistoryMgr,
            AddressBookForm,
            PasswordResetForm,
            AddressForm,
            LoginForm,
            GoogleAutocompleteField,
            SessionExpiration,
            OrderProductSummary
        ] = deps.map(dep => dep.default);

        return {
            listId: 'account.widgets',
            widgetsDefinition: () => [
                ['addressList', AddressList],
                ['paymentsList', PaymentsList],
                ['orderHistoryMgr', OrderHistoryMgr],
                    ['sessionExpiration', SessionExpiration, 'modal'],
                // basicForm / ajaxform
                    ['addressForm', AddressForm, 'ajaxform'],
                    ['addressBookForm', AddressBookForm, 'addressForm'],
                    ['loginForm', LoginForm, 'ajaxform'],
                    ['passwordResetForm', PasswordResetForm, 'ajaxform'],
                // inputText
                ['googleAutocompleteField', GoogleAutocompleteField, 'inputText'],
                ['orderProductSummary', OrderProductSummary]
            ]
        };
    }));
}

if (cart) {
    widgetsMgr.asyncListsPromises.push(Promise.all([
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/cart/CartMgr'),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/forms/CouponForm'),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/cart/PromoCodeDisclosure'),
        import(/* webpackChunkName: 'cart.widgets' */ 'widgets/global/SessionExpiration')
    ]).then(deps => {
        const [
            CartMgr,
            CouponForm,
            PromoCodeDisclosure,
            SessionExpiration
        ] = deps.map(dep => dep.default);

        return {
            listId: 'cart.widgets',
            widgetsDefinition: () => [
                ['cartMgr', CartMgr],
                // modal
                    ['sessionExpiration', SessionExpiration, 'modal'],
                // basicForm / form / ajaxForm
                    ['couponform', CouponForm, 'ajaxform'],
                // disclosure
                    ['promoCodeDisclosure', PromoCodeDisclosure, 'disclosure']
            ]
        };
    }));
}

if (checkout) {
    // @ts-ignore
    widgetsMgr.asyncListsPromises.push(Promise.all([
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/ajax/LoginForm'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/ajax/CheckoutLoginForm'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/account/PasswordResetForm'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/CheckoutMgr'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/AddressCheckoutForm'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/ShippingForm'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/BillingForm'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/PaymentAccordion'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/PaymentAccordionItem'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/PaymentAccordionItemCREDIT_CARD'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/PaymentAccordionItemZERO_PAYMENT'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/PaymentAccordionItemDW_APPLE_PAY'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/PaymentAccordionItemPAYPAL_EXPRESS'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/InputCreditCardNumber'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/checkout/RadioSelector'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/GoogleAutocompleteField'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/forms/AddressForm'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/global/SessionExpiration'),
        import(/* webpackChunkName: 'checkout.widgets' */ 'widgets/content/ContactUs')
    ]).then(deps => {
        const [
            LoginForm,
            CheckoutLoginForm,
            PasswordResetForm,
            CheckoutMgr,
            AddressCheckoutForm,
            ShippingFrom,
            BillingForm,
            PaymentAccordion,
            PaymentAccordionItem,
            PaymentAccordionItemCREDITCARD,
            PaymentAccordionItemZEROPAYMENT,
            PaymentAccordionItemAPPLEPAY,
            PaymentAccordionItemPAYPALEXPRESS,
            InputCreditCardNumber,
            RadioSelector,
            GoogleAutocompleteField,
            AddressForm,
            SessionExpiration,
            ContactUs
        ] = deps.map(dep => dep.default);

        return {
            listId: 'checkout.widgets',
            widgetsDefinition: () => [
                ['checkoutMgr', CheckoutMgr],
                    ['checkoutMgr', AccessibilityFocusMixin, 'checkoutMgr'],
                // modal
                    ['sessionExpiration', SessionExpiration, 'modal'],
                // basicForm / form / ajaxform
                    ['loginForm', LoginForm, 'ajaxform'],
                        ['checkoutLoginForm', CheckoutLoginForm, 'loginForm'],
                    ['passwordResetForm', PasswordResetForm, 'ajaxform'],
                    ['addressForm', AddressForm, 'ajaxform'],
                        ['addressCheckoutForm', AddressCheckoutForm, 'addressForm'],
                            ['addressCheckoutForm', AccessibilityFocusMixin, 'addressCheckoutForm'],
                            ['shippingForm', ShippingFrom, 'addressCheckoutForm'],
                            ['billingForm', BillingForm, 'addressCheckoutForm'],
                // basicInput
                    ['inputCreditCardNumber', InputCreditCardNumber, 'basicInput'],
                    ['googleAutocompleteField', GoogleAutocompleteField, 'inputText'],
                    // inputRadio
                        ['radioSelector', RadioSelector, 'inputRadio'],
                // accordion
                    ['paymentAccordion', PaymentAccordion, 'accordion'],
                // accordionItems
                    ['paymentAccordionItem', PaymentAccordionItem, 'accordionItem'],
                        ['paymentAccordionItemCREDIT_CARD', PaymentAccordionItemCREDITCARD, 'paymentAccordionItem'],
                            ['paymentAccordionItemCREDIT_CARD', AccessibilityFocusMixin, 'paymentAccordionItemCREDIT_CARD'],
                        ['paymentAccordionItemPAYPAL_EXPRESS', PaymentAccordionItemPAYPALEXPRESS, 'paymentAccordionItem'],
                        ['paymentAccordionItemZERO_PAYMENT', PaymentAccordionItemZEROPAYMENT, 'paymentAccordionItem'],
                        ['paymentAccordionItemDW_APPLE_PAY', PaymentAccordionItemAPPLEPAY, 'paymentAccordionItem'],
                ['contactUs', ContactUs]
            ]
        };
    }));
}

if (orderconfirmation) {
    widgetsMgr.asyncListsPromises.push(Promise.all([
        import(/* webpackChunkName: 'orderconfirmation.widgets' */ 'widgets/order/OrderProductSummary')
    ]).then(deps => {
        const [OrderProductSummary] = deps.map(dep => dep.default);

        return {
            listId: 'content.widgets',
            widgetsDefinition: () => [
                ['orderProductSummary', OrderProductSummary]
            ]
        };
    }));
}
/* sonar-ignore-end */
