/**
 * @module widgetsMgr
 * @category widgets
 * @subcategory framework
 */

/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
if ('assetsStaticURL' in window) {
    // @ts-ignore
    // eslint-disable-next-line no-undef, camelcase
    __webpack_public_path__ = window.assetsStaticURL;
}

import './_polyfills';
import { log, getData } from './toolbox/util';
// import eventMgr from './EventMgr';
import { RefElement } from 'widgets/toolbox/RefElement';
import Widget from 'widgets/Widget';
import { init as initViewType, getActiveViewtypeName, ALL_VIEW_TYPES } from 'widgets/toolbox/viewtype';
import eventBus from 'widgets/toolbox/eventBus';

const WIDGET_PROP_NAME = '@@_widget_instance_@@';
const WIDGET_DISPOSABLE_VALUES = '@@_widget_events_disposable_@@';
const WIDGET_DOM_EVENT_PREFIX = 'data-event-';
const WIDGET_DOM_GLOBAL_EVENT_PREFIX = 'data-global-event-';
const DATA_ATTR_PREFIX = 'data-';
const DATA_WIDGET = 'data-widget';
const DATA_WIDGET_VIEWTYPE_RELATED = 'data-widget.';
const WIDGET_EVENT_PREFIX = 'data-widget-event-';
const DATA_INITIALIZED = 'data-initialized';
const DATA_REF = 'data-ref';
/**
 * @description check if there are viewtype modifiers
 * @param {string[]} modifiers to check
 * @returns {boolean} true if any viewtype modifier exist
 */
// @ts-ignore
const isViewtypeModifiers = modifiers => modifiers.some(m => ALL_VIEW_TYPES.includes(m));
/**
 * @description check if HTML Element is widget
 * @param {HTMLElement} el element to check
 * @returns {boolean} true if widget
 */
const isWidget = el => el.hasAttribute(DATA_WIDGET)
    || el.getAttributeNames().some(name => name.startsWith(DATA_WIDGET_VIEWTYPE_RELATED));
/**
 * @description get widget name for current viewtype
 * @param {HTMLElement} el widget element
 * @returns {string|null} widget name if exist
 */
const getViewtypeRelatedWidgetName = el => {
    const activeViewtypeName = getActiveViewtypeName();
    const viewtypeWidgetConfigs = el.getAttributeNames().filter(name => name.startsWith(DATA_WIDGET_VIEWTYPE_RELATED));

    if (viewtypeWidgetConfigs.length) {
        const attrName = viewtypeWidgetConfigs.find(name => name.includes(activeViewtypeName));

        return attrName ? el.getAttribute(attrName) : 'widget';
    } else {
        return null;
    }
};

/**
 * @description check if element has viewtype-related widget definition or widget properties
 * @param {HTMLElement} el element
 * @returns {boolean} widget name if exist
 */
const isViewtypeRelatedWidget = el => {
    if (el.getAttribute(DATA_WIDGET)) {
        return el.getAttributeNames().some(name => name.startsWith(DATA_ATTR_PREFIX) // is data attr
            && !name.startsWith(WIDGET_EVENT_PREFIX) // not widget event
            && !name.startsWith(WIDGET_DOM_EVENT_PREFIX) // not dom event
            && !name.startsWith(WIDGET_DOM_GLOBAL_EVENT_PREFIX) // not gtm event
            && ALL_VIEW_TYPES.some(m => name.includes(m))); // is viewtype related property
    } else {
        return isWidget(el); // is viewtype related widget definition
    }
};

class RootWidget extends Widget { }

if (!document.head.parentElement) {
    throw Error('No document');
}

/**
 * @description get initial widget state from data attributes
 * @param {HTMLElement} domNode element of widget
 * @returns {{[x: string]: object|string|number|boolean|null|undefined}} json-like configuration
 */
function getWidgetConfig(domNode) {
    /**
     * @type {{[x: string]: object|string|number|boolean|null|undefined}}
     */
    const config = {};
    const activeViewtype = getActiveViewtypeName();

    domNode.getAttributeNames().forEach(attrName => {
        if (typeof attrName === 'string'
            && attrName.includes(DATA_ATTR_PREFIX)
            && !attrName.startsWith(WIDGET_DOM_EVENT_PREFIX)
            && !attrName.startsWith(WIDGET_DOM_GLOBAL_EVENT_PREFIX)
            && !attrName.startsWith(DATA_WIDGET)
        ) {
            const [key, ...modifiers] = attrName.replace(DATA_ATTR_PREFIX, '').split('.');
            const camelCaseKey = camelCase(key);
            const isActiveViewtypeModifier = modifiers.includes(activeViewtype);
            const isViewtypeModifier = isViewtypeModifiers(modifiers);
            const isConfigNotExist = typeof config[camelCaseKey] === 'undefined';

            // If modifiers has activeViewtype that we definitely need set it to config, even if we already have value in config (most probable there can be only default value set)
            // If not check if it is default value (not have modifiers) and if value was not previosly set by attribute with modifiers then we will set default value to config
            if (isActiveViewtypeModifier || (!isViewtypeModifier && isConfigNotExist)) {
                // @ts-ignore we may ignore it because attribute existing because provided by method getAttributeNames
                config[camelCaseKey] = getData(domNode.getAttribute(attrName));
            }
        }
    });

    const jsonConfig = domNode.getAttribute('data-json-config');
    if (jsonConfig) {
        try {
            const parsedConfig = JSON.parse(jsonConfig);
            return { ...config, ...parsedConfig };
        } catch (error) {
            throw new Error(`Invalid json config for widget ${domNode} ${error}`);
        }
    }

    return config;
}

const rootWidget = new RootWidget(document.head.parentElement, {});
const widgetsInitMetric = Object.create(null);
let initialized = false;

/**
 * @typedef {[(baseWidget: typeof Widget) => typeof Widget, string|undefined]} widgetConfig
 */

/**
 * @typedef {[string, (baseWidget: typeof Widget) => typeof Widget, string|undefined]} widgetRegistry
 */

/**
 * @category widgets
 * @subcategory framework
 */
class WidgetMgr {
    constructor() {
        /**
         * @description Map of widgets configurations in format: `widgetId: [widgetFunction, dependencyWidgetId]
         * @type {{[key : string] : widgetConfig | undefined}}
         */
        this.widgets = Object.create(null);
        /**
         * @description Map of widgets list generators in format `listId: generatorFunction`
         * Generator function return widgets list
         * @type {{[key : string] : () => [widgetRegistry]}}
         */
        this.widgetsLists = Object.create(null);
        /**
         * @description Array of registered widgets lists IDs
         * @type {string[]}
         */
        this.widgetsListsNameSpaceOrder = [];
        /**
         * @description Map of cached widgets classes in format `widgetId: returnsClassFunction`
         * @type {{[key : string] : typeof Widget|undefined}}
         */
        this.widgetsClassCache = Object.create(null);
        /**
         * @description Map of widgets extending in format `widgetId: ['widgetIdA', 'widgetIdB', ...]`
         * @type {{[key: string] : string[]|undefined}}
         */
        this.hashRegistry = Object.create(null);

        this.timeOfEvaluate = Date.now();
        this.widgets.widget = [() => Widget, ''];
        /**
         * @description Array of list promises
         * @type {Promise[]}
         */
        this.asyncListsPromises = [];
        this.getting = false;

        initViewType();
    }

    /**
     * @description When DOM is ready, the method starts registering widgets,
     * for each assigned widgets list, and executes WidgetMgr.init()
     */
    run() {
        Promise.all(this.asyncListsPromises).then((asyncLists) => {
            asyncLists.forEach(({ listId, widgetsDefinition }) => this.addWidgetsList(listId, widgetsDefinition));

            this.widgetsListsNameSpaceOrder.forEach(this.registerWidgetsList, this);

            this.timeOfRun = Date.now();
            // Init widgets once DOM is ready
            if (document.readyState === 'loading') {
                this.hasDomLoadedFirst = false;
                document.addEventListener('DOMContentLoaded', () => {
                    setTimeout(() => this.init(), 0);
                }, { once: true });
            } else {
                this.hasDomLoadedFirst = true;
                this.init();
            }
        });
    }

    /**
     * @description Returns all registered widgets.
     * @returns {object} all widgets
     */
    getAll() {
        return this.widgets;
    }

    /**
     * @description Returns registered widget by name.
     * @param {string|undefined} name Name of registered widget (used as data-widget="<name>" in DOM)
     * @returns {typeof Widget} return widget class by name
     */
    get(name) {
        if (name) {
            this.getting = true;
            const cachedClass = this.widgetsClassCache[name];

            if (cachedClass) {
                return cachedClass;
            }

            const widgetsConfig = this.widgets[name];

            if (widgetsConfig) {
                const [getWidgetClass, baseWidget] = widgetsConfig;
                const widgetClass = getWidgetClass(this.get(baseWidget));
                this.widgetsClassCache[name] = widgetClass;

                return widgetClass;
            }
        }

        return Widget;
    }

    /**
     * @description add widget by name into registry
     * @param {string} name of widget in registry
     * @param {widgetConfig} widget configuration to set
     * @returns {widgetConfig} widget return set widget config
     */
    set(name, widget) {
        this.widgets[name] = widget;
        return widget;
    }

    /**
     * @description Add widgets list into registry
     * @param {string} nameSpace - name of widgets list
     * @param {() => [widgetRegistry]} cb - function that returns widget list
     */
    addWidgetsList(nameSpace, cb) {
        if (!this.widgetsListsNameSpaceOrder.includes(nameSpace)) {
            this.widgetsListsNameSpaceOrder.push(nameSpace);
        }

        this.widgetsLists[nameSpace] = cb;
    }

    /**
     * @description Register all widgets from widgets list by name space
     * @param {string} nameSpace - widgets list namespace
     * @returns {void}
     */
    registerWidgetsList(nameSpace) {
        if (!PRODUCTION) {
            // eslint-disable-next-line no-console
            console.groupCollapsed(`${nameSpace} widgets registration`);
            // console.profile('Registration widgets');
        }

        this.widgetsLists[nameSpace]().forEach(args => this.register(...args), this);

        if (!PRODUCTION) {
            //  console.profileEnd('Registration widgets');
            // eslint-disable-next-line no-console
            console.groupEnd();
        }
    }

    /**
     * @description set hash to registry by widget name with widget dependency
     * @param {{[key: string]: string[]|undefined}} hashRegistry registry object
     * @param {string} name widget in hashRegistry
     * @param {string} baseWidget name of base widget
     */
    setHash(hashRegistry, name, baseWidget = '') {
        if (!hashRegistry[name]) {
            hashRegistry[name] = [name];
        }

        const currentHash = hashRegistry[name];

        if (baseWidget && currentHash) {
            currentHash.push(baseWidget);
        }
    }

    /**
     * @description get widget hash from registry by name
     * @param {{[key: string]: string[]|undefined}} hashRegistry registry object
     * @param {string} name widget in hashRegistry
     * @returns {string} hash for widget
     */
    getHash(hashRegistry, name) {
        return (hashRegistry[name] || []).reduce((currentWidget, baseWidget) => {
            var hash;
            if (name === baseWidget) {
                hash = baseWidget;
            } else {
                hash = this.getHash(hashRegistry, baseWidget);
            }
            return currentWidget + hash;
        });
    }

    /**
     * @description add widget into registry
     * @param {string} name name of widget (will be used in `data-widget="name"`)
     * @param {(baseWidget: typeof Widget) => typeof Widget} widgetConstructor function mixin that returns class
     * @param {string|undefined} baseWidget base widget to extend
     * @returns {void}
     */
    register(name, widgetConstructor, baseWidget = undefined) {
        if (!PRODUCTION && this.getting) {
            log.warn('register widget after getting');
        }

        const widgetConfig = this.widgets[name];

        if (widgetConfig && baseWidget === name) {
            const [superWidgetConstructor, superBaseWidget] = widgetConfig;
            this.set(name, [base => widgetConstructor(superWidgetConstructor(base)), superBaseWidget]);
        } else {
            this.set(name, [widgetConstructor, baseWidget]);
        }

        this.setHash(this.hashRegistry, name, baseWidget);
    }

    /**
     * @description Returns list of widgets changed during 'viewtype.change' event
     * @returns {Array<widgetRegistry>} widgets that was changed due to viewport change
     */
    getChangedWidgets() {
        /**
         * @type {Array<widgetRegistry>}
         */
        const emptyArray = [];

        const newRegistry = this.widgetsListsNameSpaceOrder
            .map(ns => this.widgetsLists[ns]())
            .reduce((prev, nsList) => [...prev, ...nsList], emptyArray);

        // find extandable widgets

        /*
        * @type {{[key : string] : string[]}}
        */
        const newHash = Object.create(null);

        newRegistry.forEach(([wname, , bname]) => this.setHash(newHash, wname, bname));

        const changedWidgets = newRegistry
            .filter(([wname]) => {
                return this.getHash(newHash, wname) !== this.getHash(this.hashRegistry, wname);
            });

        return changedWidgets;
    }

    /**
     * @description Re-init widgets changed during 'viewtype.change' event and re-assign viewtype related events
     */
    updateMutableComponents() {
        const changedWidgets = this.getChangedWidgets();

        if (!changedWidgets.length) {
            reinitElementByWidgetsNamesList();
            return;
        }

        const widgetsNames = changedWidgets.map(w => w[0]);

        this.getting = false;
        // remove definitions
        changedWidgets.forEach(([name]) => {
            this.hashRegistry[name] = undefined;
            this.widgets[name] = undefined;
            this.widgetsClassCache[name] = undefined;
        });

        // register again
        changedWidgets.forEach(args => this.register(...args));

        reinitElementByWidgetsNamesList(document.head.parentElement, widgetsNames);

        /**
         * @description detach/attach widgets to elements by name
         * @param {HTMLElement|null} el root element for re-initialization widgets
         * @param {string[]} widgetsListNames - widgets list
         * @returns {undefined}
         */
        function reinitElementByWidgetsNamesList(el = document.head.parentElement, widgetsListNames = []) {
            if (!el) {
                return;
            }

            const widgetName = el && el.getAttribute(DATA_WIDGET);

            if ((widgetName && widgetsListNames.includes(widgetName)) || isViewtypeRelatedWidget(el)) {
                detachElement(el);
                attachElements(el);
            } else if (el.children) {
                // recursion

                // disposible not view type events
                // assign view type events
                const attrs = el.getAttributeNames().filter(name => name.startsWith(WIDGET_DOM_EVENT_PREFIX));

                const reloadEvents = attrs.some(attr => {
                    const [, ...modifiers] = attr.replace(WIDGET_DOM_EVENT_PREFIX, '').split('.');

                    // @ts-ignore
                    return modifiers.some(mod => ALL_VIEW_TYPES.includes(mod));
                });

                const parentWidget = el[WIDGET_PROP_NAME] || findParentWidget(el);

                if (reloadEvents) {
                    const disposableValues = el[WIDGET_DISPOSABLE_VALUES];

                    if (disposableValues) {
                        el[WIDGET_DISPOSABLE_VALUES] = disposableValues.filter((dispose) => {
                            // @ts-ignore
                            if (dispose.eventName) {
                                // Dispose only events
                                dispose();
                                return false;
                            }
                            return true;
                        });
                    }

                    attrs.forEach(attachEventToElement(el, parentWidget));
                }

                // assign view type gtm events
                const globalAttrs = el.getAttributeNames().filter(name => name.startsWith(WIDGET_DOM_GLOBAL_EVENT_PREFIX));

                const reloadGtmEvents = globalAttrs.some(attr => {
                    const [, ...modifiers] = attr.replace(WIDGET_DOM_GLOBAL_EVENT_PREFIX, '').split('.');

                    // @ts-ignore
                    return modifiers.some(mod => ALL_VIEW_TYPES.includes(mod));
                });

                if (reloadGtmEvents) {
                    attrs.forEach(attachGlobalEventToElement(el, parentWidget));
                }

                Array.from(el.children).forEach(child => {
                    reinitElementByWidgetsNamesList(/** @type {HTMLElement} */(child), widgetsListNames);
                });
            }
        }
    }

    /**
     * @description Destroy all widgets/events and construct them again. Needed for webpack HMR during change code for any widget
     * @param {HTMLElement|null} el - root element from which start restart of widgets
     */
    restartWidgets(el = document.head.parentElement) {
        if (el) {
            detachElement(el);

            this.getting = false;
            this.hashRegistry = {};
            this.widgets = {};
            this.widgetsClassCache = {};

            this.widgetsListsNameSpaceOrder.forEach(this.registerWidgetsList, this);

            attachElements(el);
        }
    }

    /**
     * @typedef Diff
     * @property {string} name
     * @property {string} action
     * @property {string} value
     * @property {string} oldValue
     * @property {string} newValue
     */

    /**
     * @description destroy widget or refElement, run disposible for events for element
     * @param {HTMLElement} el element for processin attributes
     * @param {Diff} diff diffDom internal object of changes
     * @returns {void}
     */
    removeAttribute(el, diff) {
        if (diff.name === DATA_REF) {
            el[WIDGET_DISPOSABLE_VALUES] = (el[WIDGET_DISPOSABLE_VALUES] || []).filter(disposable => {
                // @ts-ignore
                if (disposable.attrName === 'ref') {
                    disposable();
                    return false;
                }
                return true;
            });
        } else if (DATA_WIDGET === diff.name || diff.name.startsWith(DATA_WIDGET_VIEWTYPE_RELATED)) {
            detachElement(el);
        } else if (diff.name.startsWith(WIDGET_DOM_EVENT_PREFIX)) {
            const name = diff.name.replace(WIDGET_DOM_EVENT_PREFIX, '').split('.')[0];
            const value = diff.value || diff.oldValue;
            el[WIDGET_DISPOSABLE_VALUES] = (el[WIDGET_DISPOSABLE_VALUES] || []).filter(disposable => {
                // @ts-ignore
                if (disposable.eventName === name && disposable.methodToCall === value) {
                    disposable();
                    return false;
                }
                return true;
            });
        } else if (el[WIDGET_PROP_NAME]) {
            handleWidgetPropertyChange(el, diff);
        }
    }

    /**
     * @description Create widget or refElement, assign events for element
     * @param {HTMLElement} el element for processin attributes
     * @param {Diff} diff diffDom internal object of changes
     * @returns {void}
     */
    addAttribute(el, diff) {
        if (diff.name === DATA_REF) {
            const widget = el[WIDGET_PROP_NAME] || findParentWidget(el);
            if (widget && widget.refs) {
                const refEl = new RefElement([el]);
                widget.refs[diff.value || diff.newValue] = refEl;
                disposableForParent(el, widget, refEl, diff.value || diff.newValue);
            }
        } else if (DATA_WIDGET === diff.name || diff.name.startsWith(DATA_WIDGET_VIEWTYPE_RELATED)) {
            attachElements(el);
        } else if (diff.name.startsWith(WIDGET_DOM_EVENT_PREFIX)) {
            attachEventToElement(el, el[WIDGET_PROP_NAME] || findParentWidget(el))(diff.name);
        } else if (diff.name.startsWith(WIDGET_DOM_GLOBAL_EVENT_PREFIX)) {
            attachGlobalEventToElement(el, el[WIDGET_PROP_NAME] || findParentWidget(el))(diff.name);
        } else if (el[WIDGET_PROP_NAME]) {
            const widget = el[WIDGET_PROP_NAME];
            if (widget && diff.name !== DATA_INITIALIZED) {
                widget.config = getWidgetConfig(el);
            }
        }
    }

    /**
     * Attaching widget to DOM elements from parent to child recursively, by Widgets.attachElements(element)
     * Initialize mutation observer
     */
    init() {
        if (!PRODUCTION) {
            this.timeOfInit = Date.now();
            // log.profile('Initialization widgets');
        }

        if (!document.head.parentElement) {
            throw Error('No document');
        }

        const observer = new MutationObserver(mutations => mutations.forEach((mutation) => {
            this.handleMutations(mutation);
        }));

        observer.observe(document.body, {
            attributes: false,
            characterData: false,
            childList: true,
            subtree: true
        });

        attachElements(document.head.parentElement);

        eventBus.on('viewtype.change', () => this.updateMutableComponents());

        if (!PRODUCTION) {
            // @ts-ignore
            const total = Date.now() - window.headInitTime;
            // @ts-ignore
            const timeInitWidgets = Date.now() - this.timeOfInit;
            // @ts-ignore
            const timeToRegisterWidgets = this.timeOfRun - this.timeOfEvaluate;
            // @ts-ignore
            const loadAndScriptingTime = this.timeOfEvaluate - window.headInitTime;

            var waitToDomLoaded = 0;
            // @ts-ignore
            waitToDomLoaded = this.hasDomLoadedFirst ? (window.domReadyTime - this.timeOfInit) : this.timeOfInit - this.timeOfRun;

            // @ts-ignore
            const headToDomReady = (window.domReadyTime || this.timeOfRun) - window.headInitTime;

            log.table({
                headToDomReady: {
                    ms: headToDomReady,
                    percentage: 0
                },
                loadAndScriptingTime: {
                    ms: loadAndScriptingTime,
                    percentage: Math.round((loadAndScriptingTime / total) * 100)
                },
                registerWidgetsTime: {
                    ms: timeToRegisterWidgets,
                    percentage: Math.round((timeToRegisterWidgets / total) * 100)
                },
                waitToDomLoaded: {
                    ms: waitToDomLoaded,
                    percentage: waitToDomLoaded > 0 ? Math.round((waitToDomLoaded / total) * 100) : 0
                },
                initWidgetsTime: {
                    ms: timeInitWidgets,
                    percentage: Math.round((timeInitWidgets / total) * 100)
                },
                total: {
                    ms: total,
                    percentage: Math.round(100)
                }
            });
            if (timeToRegisterWidgets > 50) {
                log.warn('High time of widgets registration');
            }
            if (timeInitWidgets > 50) {
                log.warn('High time of widgets initialization');
            }
            widgetsInitMetric.total = Object.values(widgetsInitMetric).reduce((a, b) => a + b, 0);
            log.groupCollapsed('Widgets initialization time (init method)');
            log.table(widgetsInitMetric);
            log.groupEnd();
            // log.profileEnd('Initialization widgets');
        }

        // CLARIFY: What is magic timeout for 500 ms?
        setTimeout(() => { initialized = true; }, 500);
    }

    /**
     * @description mutations handler for MutationObserver
     * @param {MutationRecord} mutation record of MutationObserver
     * @returns {void}
     */
    handleMutations(mutation) {
        const { addedNodes, removedNodes } = mutation;
        removedNodes.forEach(removedNode => {
            if (removedNode.nodeType === removedNode.ELEMENT_NODE) {
                detachElement(/** @type {HTMLElement} */(removedNode));
            }
        });
        addedNodes.forEach(addedNode => {
            if (addedNode.nodeType === addedNode.ELEMENT_NODE && document.body.contains(addedNode)) {
                attachElements(/** @type {HTMLElement} */(addedNode));
            }
        });
    }
}
const widgetsMgr = new WidgetMgr();

// Matches dashed string for camelizing
var rmsPrefix = /^-ms-/;
var rdashAlpha = /-([a-z])/g;

/**
 * @description handler for Widget.onRefresh() lifecycle hook
 * @param {HTMLElement} el element for processin attributes
 * @param {Diff} diff diffDom internal object of changes
 * @returns {void}
 */
function handleWidgetPropertyChange(el, diff) {
    const widget = el[WIDGET_PROP_NAME];
    if (widget) {
        if (diff.name === DATA_INITIALIZED) {
            if (!widget.isRefreshingWidget) {
                widget.isRefreshingWidget = true;
                widget.onRefresh();
                widget.isRefreshingWidget = false;
            }
            el.setAttribute(DATA_INITIALIZED, '1');
        } else {
            widget.config = getWidgetConfig(el);
        }
    }
}

/**
 * @description Convert dashed to camelCase; used by the css and data modules
 * @param {string} string String to convert
 * @returns {string} Converted string
 */
function camelCase(string) {
    return string.replace(rmsPrefix, 'ms-').replace(rdashAlpha, (_all, letter) => letter.toUpperCase());
}

/**
 * @description Create callback that will attach DOM events to element through `attachEventToWidget` using data-event- attributes
 * @param {HTMLElement} el DOM element
 * @param {Widget} widgetInstance Widget instance
 * @returns {(attr: string) => void} callback
 */
function attachEventToElement(el, widgetInstance) {
    return attr => {
        const [attrName, ...modifiers] = attr.replace(WIDGET_DOM_EVENT_PREFIX, '').split('.');
        const attrValue = el.getAttribute(attr) || '@';

        if (!PRODUCTION && modifiers.length && ALL_VIEW_TYPES.every(m => modifiers.includes(m))) {
            log.error(
                `you shouldn't use ${WIDGET_DOM_EVENT_PREFIX}eventName.${ALL_VIEW_TYPES.join('.')} use it without modifiers`
            );
        }

        if (isViewtypeModifiers(modifiers) && !modifiers.includes(getActiveViewtypeName())) {
            return;
        }

        // @ts-ignore
        if (typeof widgetInstance[attrValue] === 'function') {
            attachEventToWidget(modifiers, widgetInstance, attrName, attrValue, el);
        } else {
            log.error('Widget "' + widgetInstance.constructor.name
                + '" don\'t have method "' + attrValue
                + '". Unable to assign event', { el });
        }
    };
}

/**
 * @description Create callback that will attach global events to element through `attachGlobalEventToWidget` using data-global-event- attributes
 * @param {HTMLElement} el DOM element
 * @param {Widget} widgetInstance Widget instance
 * @returns {(attr: string) => void} callback
 */
function attachGlobalEventToElement(el, widgetInstance) {
    return attr => {
        const [attrName, ...modifiers] = attr.replace(WIDGET_DOM_GLOBAL_EVENT_PREFIX, '').split('.');
        const attrValue = el.getAttribute(attr) || '@';

        if (!PRODUCTION && modifiers.length && ALL_VIEW_TYPES.every(m => modifiers.includes(m))) {
            log.error(
                `you shouldnt use ${WIDGET_DOM_GLOBAL_EVENT_PREFIX}eventName.${ALL_VIEW_TYPES.join('.')} use it without modifiers`
            );
        }

        if (isViewtypeModifiers(modifiers) && !modifiers.includes(getActiveViewtypeName())) {
            return;
        }

        attachGlobalEventToWidget(modifiers, widgetInstance, attrName, attrValue, el);
    };
}

/**
 * @description Attach DOM events to widget instance specified in data-event- attributes
 * @param {string[]} modifiers Event modifiers
 * @param {Widget} widgetInstance Widget for event assignment
 * @param {string} eventName DOM event
 * @param {string} methodToCall widget method to call
 * @param {HTMLElement} el Widget self element to store disposables
 */
function attachEventToWidget(modifiers, widgetInstance, eventName, methodToCall, el) {
    var disposableValues = el[WIDGET_DISPOSABLE_VALUES] || [];

    const prevent = modifiers.includes('prevent');
    const stop = modifiers.includes('stop');
    const once = modifiers.includes('once');
    const self = modifiers.includes('self');

    disposableValues = disposableValues.filter((disposable) => {
        // If such event already exist need to remove it before attaching new event
        // @ts-ignore
        if (disposable.eventName === eventName && disposable.methodToCall === methodToCall && disposable.el === el) {
            disposable();
            return false;
        }
        return true;
    });

    // @ts-ignore
    const disposables = widgetInstance.ev(eventName, function eventHandler(element, event) {
        if (prevent) {
            event.preventDefault();
        }
        if (stop) {
            event.stopPropagation();
        }
        if (once && disposables) {
            disposables.forEach(diposable => diposable());
        }
        if (event.currentTarget !== event.target && self) {
            return;
        }
        const target = Object.values(widgetInstance.refs || {})
            .find((refEl) => Boolean(refEl && refEl instanceof RefElement && refEl.get() === element))
            || new RefElement([element]);

        // @ts-ignore
        widgetInstance[methodToCall].call(this, target, event);
    }, el, modifiers.includes('passive')).map((disposable) => {
        // @ts-ignore
        disposable.methodToCall = methodToCall;
        // @ts-ignore
        disposable.el = el;
        return disposable;
    });

    // register events to remove once removed from DOM
    disposableValues.push(...disposables);
    el[WIDGET_DISPOSABLE_VALUES] = disposableValues;
}

/**
 * @description Attach global events to widget instance specified in data-global-event- attributes
 * @param {string[]} modifiers Event modifiers
 * @param {Widget} widgetInstance Widget for event assignment
 * @param {string} eventName DOM event
 * @param {string} eventToCall gtm event to emit
 * @param {HTMLElement} el Widget self element to store disposables
 */
function attachGlobalEventToWidget(modifiers, widgetInstance, eventName, eventToCall, el) {
    const prevent = modifiers.includes('prevent');
    const stop = modifiers.includes('stop');
    const self = modifiers.includes('self');

    // @ts-ignore
    widgetInstance.ev(eventName, function eventHandler(element, event) {
        if (prevent) {
            event.preventDefault();
        }
        if (stop) {
            event.stopPropagation();
        }

        if (event.currentTarget !== event.target && self) {
            return;
        }

        eventBus.emit(eventToCall, widgetInstance);
    }, el, false);
}

const noop = () => undefined;
const getConstructor = (name) => widgetsMgr.get(name);

/**
 * @description Create widget instance, assign it to parent, assign widget events
 * @param {HTMLElement} domNode element of widget
 * @returns {InstanceType <typeof Widget>} Instance of initialized widget
 */
function initWidget(domNode) {
    /**
     * @type Widget
     */
    var currentWidget;
    const registeredWidgets = widgetsMgr.getAll();

    var instance = /** @type {Widget | undefined} */(domNode[WIDGET_PROP_NAME]);

    var widgetName = domNode.getAttribute(DATA_WIDGET) || getViewtypeRelatedWidgetName(domNode);

    if (!widgetName) {
        throw Error('Empty Widget name');
    }

    if (instance && instance.refs && instance.refs.self) {
        currentWidget = instance;
    } else if (registeredWidgets[widgetName]) {
        currentWidget = new (widgetsMgr.get(widgetName))(domNode, getWidgetConfig(domNode));
    } else {
        // CLARIFY: It is bad idea because wrong naming broke entire JS on page
        throw Error(`Widget "${widgetName}" is not found in registry`);
    }

    domNode[WIDGET_PROP_NAME] = currentWidget;

    if (!instance) {
        const parentWidget = findParentWidget(domNode);
        if (parentWidget.items) {
            linkCurrentToParent(parentWidget, currentWidget);
        }
        if (currentWidget.refs && rootWidget.refs) {
            currentWidget.refs.html = rootWidget.refs.self;
        }

        currentWidget.parentHandler = noop;
        currentWidget.getConstructor = getConstructor;
        prepareWidgetAttributes(domNode, currentWidget, parentWidget);
        // currentWidget.parentHandler = parentWidget.eventHandler.bind(parentWidget);
        return currentWidget;
    }

    return instance;
}

/**
 * @description assign current widget into parent for parent->child structure
 * @param {Widget} parentWidget parent widget instance
 * @param {Widget} currentWidget current widget instance
 * @returns {void}
 */
function linkCurrentToParent(parentWidget, currentWidget) {
    if (parentWidget && currentWidget) {
        if (parentWidget.items) {
            parentWidget.items.push(currentWidget);
            currentWidget.onDestroy(() => {
                if (parentWidget.items) {
                    var idx = parentWidget.items.indexOf(currentWidget);
                    if (idx > -1) {
                        parentWidget.items.splice(idx, 1);
                    }
                }
            });
        }
    }
}

/**
 * @description assign widget events to parent handlers
 * @param {HTMLElement} domNode HTML Element of widget
 * @param {InstanceType <typeof Widget>} currentWidget current widget instance
 * @param {InstanceType <typeof Widget>} parentWidget parent widget instance
 * @returns {void}
 */
function prepareWidgetAttributes(domNode, currentWidget, parentWidget) {
    const forwareToParent = domNode.getAttribute('data-forward-to-parent');

    if (forwareToParent) {
        forwareToParent.split(':').forEach(pair => {
            const [methodName, parrentWidgetMethodName] = pair.split('-');

            currentWidget[methodName] = (...args) => {
                parentWidget[parrentWidgetMethodName || methodName].call(parentWidget, ...args);
            };
        });
    }

    const attrs = domNode.getAttributeNames().filter(name => name.startsWith(WIDGET_EVENT_PREFIX));

    if (attrs && attrs.length) {
        attrs.forEach(attr => {
            const [attrName, ...modifiers] = attr.replace(WIDGET_EVENT_PREFIX, '').split('.');

            if (isViewtypeModifiers(modifiers) && !modifiers.includes(getActiveViewtypeName())) {
                return;
            }

            const attrValue = domNode.getAttribute(attr);
            const prevHandler = currentWidget.parentHandler;
            // @ts-ignore
            if (typeof parentWidget[attrValue] === 'function') {
                currentWidget.parentHandler = (name, ...args) => {
                    prevHandler(name, ...args);
                    // @ts-ignore
                    if (name === attrName && typeof parentWidget[attrValue] === 'function') {
                        // @ts-ignore
                        parentWidget[attrValue].call(parentWidget, ...args);
                    }
                };
            } else {
                log.error(`Widget "${parentWidget.constructor.name}" don't have method "${attrValue}"`);
            }
        });
    }
}

/**
 * @description Recursive widgets creation and events assignment for HTML Element
 * @param {HTMLElement} element top element as entry point to start recursion
 * @returns {void}
 */
function attachElements(element) {
    /**
     * @type {Widget|undefined}
     */
    var widgetInstance;

    if (isWidget(element) && !element[WIDGET_PROP_NAME]) {
        widgetInstance = initWidget(element);
    }

    const ref = element.getAttribute(DATA_REF);
    let parentWidget;

    if (ref) {
        parentWidget = element[WIDGET_PROP_NAME] || findParentWidget(element);

        if (parentWidget.refs) {
            const refEl = new RefElement([element]);
            parentWidget.refs[ref] = refEl;
            // here we will listen for the event of removal parent widget
            disposableForParent(element, parentWidget, refEl, ref);
        }
    }

    parentWidget = parentWidget || element[WIDGET_PROP_NAME] || findParentWidget(element);

    const attrs = element.getAttributeNames().filter(name => name.startsWith(WIDGET_DOM_EVENT_PREFIX));

    if (attrs.length) {
        attrs.forEach(attachEventToElement(element, parentWidget));
    }

    const globalAttrs = element.getAttributeNames().filter(name => name.startsWith(WIDGET_DOM_GLOBAL_EVENT_PREFIX));

    if (globalAttrs.length) {
        globalAttrs.forEach(attachGlobalEventToElement(element, parentWidget));
    }

    var child = element.firstElementChild;

    while (child) {
        attachElements(/** @type {HTMLElement} */(child));
        child = child.nextElementSibling;
    }

    if (widgetInstance) {
        const startTime = Date.now();
        widgetInstance.init();
        element.setAttribute(DATA_INITIALIZED, '1');

        if (!PRODUCTION) {
            var widgetName = element.getAttribute(DATA_WIDGET) || getViewtypeRelatedWidgetName(element);
            if (widgetName && startTime) {
                widgetsInitMetric[widgetName] = (widgetsInitMetric[widgetName] || 0) + (Date.now() - startTime);
            }
        }
    }
}

/**
 * @description Listener for the event of removal parent widget
 * @param {HTMLElement} element Element to listen
 * @param {Widget} parentWidget Parent widget
 * @param {RefElement} refEl linked ref element
 * @param {string} ref RefElement id
 */
function disposableForParent(element, parentWidget, refEl, ref) {
    if (!element[WIDGET_DISPOSABLE_VALUES]) {
        element[WIDGET_DISPOSABLE_VALUES] = [];
    }
    const disposableValues = element[WIDGET_DISPOSABLE_VALUES];
    if (disposableValues) {
        const dispose = () => {
            // remove reference if element is removed from DOM ref points to the same element
            if (parentWidget && parentWidget.refs && refEl === parentWidget.refs[ref]) {
                delete parentWidget.refs[ref];
            }
        };
        // @ts-ignore
        dispose.attrName = 'ref';
        disposableValues.push(dispose);
    }
}

/**
 * @description find parent widget by HTML Element
 * @param {HTMLElement} el entry point for searching
 * @returns {Widget} parent widget
 */
function findParentWidget(el) {
    /**
     * @type {HTMLElement | null}
     */
    var parent = el.parentElement;

    while (parent) {
        const widgetName = parent.getAttribute(DATA_WIDGET) || getViewtypeRelatedWidgetName(parent);

        if (widgetName) {
            break;
        } else {
            parent = parent.parentElement;
        }
    }
    return (parent && parent[WIDGET_PROP_NAME]) || rootWidget;
}

/**
 * @description Destroy widgets, run disposibles for element and elements inside
 * @param {HTMLElement} el top element for recursive procession
 * @returns {void}
 */
function detachElement(el) {
    const disposableValues = el[WIDGET_DISPOSABLE_VALUES];
    if (disposableValues) {
        disposableValues.forEach((dispose) => dispose());
        el[WIDGET_DISPOSABLE_VALUES] = undefined;
    }
    var currentWidget = el[WIDGET_PROP_NAME];
    if (currentWidget) {
        // @ts-ignore
        currentWidget.destroy();
        el[WIDGET_PROP_NAME] = undefined;

        if (!PRODUCTION && !initialized) {
            log.warn('Destroying widget before initialization is complete or right after init complete', el);
        }
    }
    var child = el.firstElementChild;

    while (child) {
        // @ts-ignore
        detachElement(child);
        child = child.nextElementSibling;
    }
}

/**
 * @description tool for visualization widgets and put widget object into console by click. just call it in your console and look
 * @returns {Promise<void>}
 */
// @ts-ignore
window.initToolkit = () => import(/* webpackChunkName: 'toolkit' */'widgets/widgetsToolkit')
    .then(widgetsToolkit => widgetsToolkit.initToolkit(widgetsMgr));

export default widgetsMgr;
