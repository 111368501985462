import { getData } from './util';

const HIDDEN_CLASS = 'm-hide';

/**
 * @description Convert string to snake-case
 * @param {string} s String in camelCase to convert
 * @returns {string} Converted to snake-case string
 * @example myCustomProperty => my-custom-property
 */
function toSnakeCase(s) {
    return s.replace(/(?:^|\.?)([A-Z])/g, (x, y) => '-' + y.toLowerCase()).replace(/^_/, '');
}

/**
 * @class RefElement
 * @classdesc jQuery like wrapper for simple access to DOM. It will be added into `Widget.items` array of first parent widget
 * @category widgets
 * @subcategory toolbox
 * @example
 * // to use just add data-ref attribute
 * <div data-ref="myRefElement"></div>
 *
 * // to get it in any widget method
 * this.ref('myRefElement')
 */
export class RefElement {
    /**
     * @param {HTMLElement[]} els array of elements
     */
    constructor(els) {
        /** @member {HTMLElement[]} */
        this.els = els;
    }

    /**
     * @description Get amount of elements in set
     * @returns {number} Number of elements in set
     */
    get length() {
        return this.els.length;
    }

    /**
     * @description Get or Set data attribute depends on provided/not provided value
     * @param {string} name Name of data attribute in camelCase, f.e. `testIt` to get `data-test-it`
     * @param {any} [value] to set
     * @returns {this|object|string|number|boolean|null|undefined}
     * - if value provided - returns current instance for chaining
     * - otherwise provided value of data attribute with appropriate type or undefined if attribute doesn't exist
     */
    data(name, value) {
        const attrName = 'data-' + toSnakeCase(name);
        if (typeof value === 'undefined') {
            if (this.hasAttr(attrName)) {
                const attrValue = this.attr(attrName);

                if (typeof attrValue === 'string') {
                    return getData(attrValue);
                }
            }
            return undefined;
        }
        return this.attr(attrName, value);
    }

    /**
     * @description Get or set the value into elements in set
     * @param {any} [value] If not empty set value into inputs in set
     * @returns {string|this}
     * - If value: undefined - returns joined string of values in set of inputs
     * - Otherwise returns current instance for chaining
     */
    val(value) {
        if (typeof value === 'undefined') {
            return this.els.map(el => /** @type {HTMLInputElement} */(el).value).join('');
        }
        if (typeof value === 'string') {
            this.els.forEach(el => { /** @type {HTMLInputElement} */(el).value = value; });
        }
        return this;
    }

    /**
     * @description Get validity object for first element in set
     * @returns {{state: ValidityState, msg: string}|undefined}
     * - If element instance of `HTMLInputElement|HTMLSelectElement` returns validity object
     * - Otherwise returns `undefined`
     */
    getValidity() {
        const element = this.els[0];

        if (element instanceof HTMLInputElement
            || element instanceof HTMLSelectElement
            || element instanceof HTMLTextAreaElement
        ) {
            return {
                state: element.validity,
                msg: element.validationMessage
            };
        }
        return undefined;
    }

    /**
     * @description Appends string into each element from set
     * @param {string} content String to append
     * @returns {void}
     */
    append(content) {
        this.els.forEach(el => {
            const tempEl = document.createElement('div');

            tempEl.innerHTML = content;

            const scripts = /** @type {NodeListOf<HTMLScriptElement>} */ (tempEl.querySelectorAll(
                'script[type="text/javascript"]'
            ));

            Array.from(scripts).forEach(script => {
                if (script && script.parentNode) {
                    script.parentNode.removeChild(script);
                }
            });

            Array.from(tempEl.childNodes).forEach(child => {
                el.appendChild(child);
            });

            Array.from(scripts).forEach((/** @type {HTMLScriptElement} */script) => {
                const tempScript = document.createElement('script');
                tempScript.text = script.text;

                el.appendChild(tempScript);
            });

            tempEl.innerHTML = '';
        });
    }

    /**
     * @description Prepends string into each element from set
     * @param {string} content String to prepend
     * @returns {void}
     */
    prepend(content) {
        this.els.forEach(el => {
            const tempEl = document.createElement('div');

            tempEl.innerHTML = content;

            const scripts = /** @type {NodeListOf<HTMLScriptElement>} */ (tempEl.querySelectorAll(
                'script[type="text/javascript"]'
            ));

            Array.from(scripts).forEach(script => {
                if (script && script.parentNode) {
                    script.parentNode.removeChild(script);
                }
            });

            Array.from(tempEl.childNodes).reverse().forEach(child => el.prepend(child));

            Array.from(scripts).forEach((/** @type {HTMLScriptElement} */script) => {
                const tempScript = document.createElement('script');
                tempScript.text = script.text;

                el.appendChild(tempScript);
            });

            tempEl.innerHTML = '';
        });
    }

    /**
     * @description
     * Get/Set/Remove attribute for each element of set depends on params:
     * - if value: undefined - Get attribute value
     * - if value: true - Set attribute attribute="attribute", f.e. `attr('disabled', true)` => `disabled="disabled"`
     * - if value: null|false - Remove attribute if `value`
     * - any another type - Convert value to string and set as attributeValue
     * @param {string} attributeName Name of attribute
     * @param {any} [value] to set (null or false to remove attribute)
     * @returns {string|this}
     * - If value: undefined - Returns string with joined values from attribute
     * - Otherwise returns current instance for chaining
     */
    attr(attributeName, value) {
        if (value === false || value === null) {
            this.els.forEach(el => el.removeAttribute(attributeName));
        } else if (value === true) {
            this.els.forEach(el => el.setAttribute(attributeName, attributeName));
        } else if (value !== undefined) {
            this.els.forEach(el => el.setAttribute(attributeName, value));
        } else {
            return this.els.map(el => el.getAttribute(attributeName)).join('');
        }

        return this;
    }

    /**
     * @description Check that some element in the set of elements has attribute `attributeName`
     * @param {string} attributeName name of attribute
     * @returns {boolean} `true` if has such attribute
     */
    hasAttr(attributeName) {
        return this.els.some(el => el.hasAttribute(attributeName));
    }

    /**
     * @description
     * - Get property value by `propertyName` from first element in set if `value` parameter is not provided
     * - Set property value for `propertyName` property for each element in set
     * @param {keyof HTMLInputElement} propertyName The name of the property to get or set.
     * @param {string|boolean|undefined} [value] A value to set for the property.
     * @returns {any} Returns undefined for the value of a property that has not been set
     * or property value if exists
     */
    prop(propertyName, value) {
        if (typeof value === 'undefined') {
            /** @type {HTMLInputElement} */
            const el = (this.els[0]);

            return el[propertyName];
        }
        this.els.forEach(el => { /** @type {any} */(el)[propertyName] = value; });

        return undefined;
    }

    /**
     * @description Get element of set by idx
     * @param {number} [idx] Identificator of element, first by default
     * @returns {HTMLElement|undefined} element if founded
     */
    get(idx = 0) {
        if (this.els[idx]) {
            return this.els[idx];
        }
        return undefined;
    }

    /**
     * @description Remove set of elements
     * @returns {this} current instance for chaining
     */
    empty() {
        this.els.forEach(el => { el.textContent = ''; });
        return this;
    }

    /**
     * @description Replace content of each element in set by empty string
     * @returns {this} current instance for chaining
     */
    remove() {
        this.els.forEach(el => el.parentNode && el.parentNode.removeChild(el));
        return this;
    }

    /**
     * @description Remove attribute disabled="disabled"
     * @returns {this} current instance for chaining
     */
    disable() {
        this.attr('disabled', true);
        this.addClass('m-disabled');

        return this;
    }

    /**
     * @description Add attribute disabled="disabled"
     * @returns {this} current instance for chaining
     */
    enable() {
        this.removeClass('m-disabled');
        this.attr('disabled', false);

        return this;
    }

    /**
     * @description Check if every element in set has disabled attribute
     * @returns {boolean} true if disabled
     */
    isDisabled() {
        return this.attr('disabled') === 'disabled';
    }

    /**
     * @description Set the content `text` into each element in the set
     * @param {string} text The text to place as content
     * @returns {this} current instance for chaining
     */
    setText(text) {
        this.els.forEach(el => {
            if (el.innerHTML !== text) {
                el.innerHTML = text;
            }
        });

        return this;
    }

    /**
     * @description Get the content of each element from set and join it to string
     * @returns {string} Joined text from set of elements
     */
    getText() {
        return this.els.map(el => el.innerHTML).join();
    }

    /**
     * @description Focus first element
     * @returns {void}
     */
    focus() {
        if (this.els[0]) {
            this.els[0].focus();
        }
    }

    /**
     * @description Blurs first element
     * @returns {void}
     */
     blur() {
        if (this.els[0]) {
            this.els[0].blur();
        }
    }


    /**
     * @description Hide element
     * @returns {this} current instance for chaining
     */
    hide() {
        if (!this.hasClass(HIDDEN_CLASS)) {
            this.attr('hidden', true);
            this.addClass(HIDDEN_CLASS);
        }

        return this;
    }

    /**
     * @description Show element
     * @returns {this} current instance for chaining
     */
    show() {
        this.attr('hidden', false);
        this.removeClass(HIDDEN_CLASS);

        return this;
    }

    /**
     * @description Show or hide element depending on either the presence or `initialState` parameter
     * @param {boolean} [initialState]  true - show else false hide
     * @returns {this} current instance for chaining
     */
    toggle(initialState) {
        const state = typeof initialState !== 'undefined' ? initialState : this.hasClass(HIDDEN_CLASS);

        this[state ? 'show' : 'hide']();
        return this;
    }

    /**
     * @description Add or Remove class depending on either the class's presence or the `state` parameter
     * @param {string} className name of class
     * @param {boolean} [state] true to add, false to remove class
     * @returns {this} current instance for chaining
     */
    toggleClass(className, state) {
        if (state === undefined) {
            if (this.hasClass(className)) {
                this.removeClass(className);
            } else {
                this.addClass(className);
            }
        } else if (state) {
            this.addClass(className);
        } else {
            this.removeClass(className);
        }

        return this;
    }

    /**
     * @description Add single class or multiple classes into element
     * @param {string|string[]} classNames string or strings array of class name(s)
     * @returns {this} current instance for chaining
     */
    addClass(classNames) {
        if (typeof classNames === 'string') {
            classNames = classNames.split(' ');
        }

        classNames.forEach(className => {
            this.els.forEach(el => {
                if (!this.hasClass(className)) {
                    el.classList.add(className);
                }
            });
        });

        return this;
    }

    /**
     * @description Remove single class or multiple classes for element in set
     * @param {string|string[]} classNames array or string of classnames
     * @returns {this} current instance for chaining
     */
    removeClass(classNames) {
        if (typeof classNames === 'string') {
            classNames = classNames.split(' ');
        }

        classNames.forEach(className => {
            this.els.forEach(el => {
                if (this.hasClass(className)) {
                    el.classList.remove(className);
                }
            });
        });

        return this;
    }

    /**
     * @description Determine whether each element in set have assigned the given class
     * @param {string} className The class name to search for.
     * @returns {boolean} `true` if yes
     */
    hasClass(className) {
        return this.els.every(el => el.classList.contains(className));
    }

    /**
     * @description Specifies the position of the element in the window
     * @returns {{top: number, left: number}} object with top and left position in px
     */
    offset() {
        const ret = { top: 0, left: 0 };
        if (this.els.length) {
            const docElem = document.documentElement;
            const elemBox = this.els[0].getBoundingClientRect();

            ret.top = elemBox.top + window.pageYOffset - docElem.clientTop;
            ret.left = elemBox.left + window.pageXOffset - docElem.clientLeft;
        }

        return ret;
    }
}
