// TODO: document as much as possible all methods
// CLARIFY: do we need to split some methods into other classes ? ie strings, console, object manipulations?
/**
 * @module util
 * @category widgets
 * @subcategory toolbox
 */
/* eslint-disable no-use-before-define */

export const log = window.console;

// IE11 fix
if (!log.table) {
    log.table = () => { };
}
/**
 * @param {string} message message with placeholders i.e. {0}
 * @param {...string} params values for placeholders
 * @returns {string} faormatted message
 */
export function format(message, ...params) {
    return params.reduce((msg, param, idx) => {
        const reg = new RegExp('\\{' + idx + '\\}', 'gm');
        return msg.replace(reg, param);
    }, message);
}

/**
 *
 * @param {HTMLElement} element HTML element which will have a transition
 * @param {string} transitionClass CSS class which triggers transition animation
 * @param {Function} cb Callback function upon transition ends
 */
export function transitionEnd(element, transitionClass, cb) {
    const eventFunction = () => {
        cb();
        element.removeEventListener('transitionend', eventFunction);
    };
    element.addEventListener('transitionend', eventFunction);
    element.classList.add(transitionClass);
}

/**
 * @param {Function|undefined} fn function to be called after specified time
 * @param {number} [time] time before call callback
 * @returns {Function} Timeout disposable
 */
export function timeout(fn, time = 0) {
    /**
     * @type {NodeJS.Timeout|undefined}
     */
    var timer = setTimeout(() => {
        if (fn) {
            fn();
        }
        timer = undefined;
        fn = undefined;
    }, time);

    return () => {
        if (timer) {
            clearTimeout(timer);
            timer = undefined;
            fn = undefined;
        }
    };
}

/**
 * @param {Function|undefined} fn function to be called regularly, after specified time delay
 * @param {number} [time] time regularity for callback execution
 * @returns {Function} disposable
 */
export function interval(fn, time = 0) {
    /**
     * @type {NodeJS.Timeout|undefined}
     */
    var intervalID = setInterval(() => {
        if (fn) {
            fn();
        }
    }, time);

    return () => {
        if (intervalID) {
            clearInterval(intervalID);
            intervalID = undefined;
            fn = undefined;
        }
    };
}

/**
 * @description Class represents the `setTimeout` with an ability to perform pause/resume actions
 */
export class PausableTimeout {
    /**
     * @param {Function|undefined} callback function to be called after specified time
     * @param {number} [time] time before call callback
     */
    constructor(callback, time = 0) {
        this.done = false;

        this.callback = () => {
            if (callback) {
                callback();
            }
            this.done = true;
            this.durationTimeout = undefined;
            callback = undefined;
        };
        this.remaining = time;
        this.resume();
    }

    /**
     * @description Pauses Timeout
     * @returns {InstanceType<PausableTimeout>} Instance of the PausableTimeout class
     */
    pause() {
        if (this.durationTimeout && !this.done) {
            this.clearTimeoutRef();
            this.remaining -= new Date().getTime() - (this.start ? this.start.getTime() : 0);
        }

        return this;
    }

    /**
     * @description Resumes Timeout
     * @returns {InstanceType<PausableTimeout>} Instance of the PausableTimeout class
     */
    resume() {
        if (!this.durationTimeout && !this.done) {
            this.start = new Date();
            this.durationTimeout = timeout(this.callback, this.remaining);
        }

        return this;
    }

    /**
     * @description Clear resources on demand
     */
    destroy() {
        this.clearTimeout();
    }

    /**
     * @description Clears the timeout and marks it as done.
     * After called, the timeout will not resume.
     */
    clearTimeout() {
        this.clearTimeoutRef();
        this.done = true;
    }

    /**
     * @description Clears resources
     */
    clearTimeoutRef() {
        if (this.durationTimeout) {
            this.durationTimeout();
            this.durationTimeout = undefined;
        }
    }
}

/**
 * @param {string} url initial url
 * @param {string} name name of params
 * @param {string} value value of param
 * @returns {string} url with appended param
 */
export function appendParamToURL(url, name, value) {
    // quit if the param already exists
    if (url.includes(name + '=')) {
        return url;
    }
    const [urlWithoutHash, hash] = url.split('#');

    const separator = urlWithoutHash.includes('?') ? '&' : '?';
    return urlWithoutHash + separator + name + '=' + encodeURIComponent(value) + (hash ? '#' + hash : '');
}

/**
 * @param {string} url Source Url
 * @param {string} name Parameter to remove
 * @returns {string} Url without parameter
 */
export function removeParamFromURL(url, name) {
    if (url.includes('?') && url.includes(name + '=')) {
        var hash = '';
        var [domain, paramUrl] = url.split('?');
        // if there is a hash at the end, store the hash
        if (paramUrl.includes('#')) {
            [paramUrl, hash] = paramUrl.split('#');
        }
        /**
         * @type {string[]}
         */
        var newParams = [];
        paramUrl.split('&').forEach(param => {
            // put back param to newParams array if it is not the one to be removed
            if (param.split('=')[0] !== name) {
                newParams.push(param);
            }
        });

        return domain + (newParams.length ? '?' + newParams.join('&') : '') + (hash ? '#' + hash : '');
    }
    return url;
}

/**
 * @param {string} url initial url
 * @param {{[keys: string]: string}} params  parmas as key value-object
 * @returns {string} Url with appended parameters
 */
export function appendParamsToUrl(url, params) {
    return Object.entries(params).reduce((acc, [name, value]) => {
        return appendParamToURL(acc, name, value);
    }, url);
}

/**
 *
 * @param {string|undefined} [url] Source Url
 * @returns {{[x: string]: string|number|boolean|Array<string|boolean>}} Hash map of Url parameters
 */
export function getUrlParams(url) {
    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    /**
     * @type {{[x: string]: string|number|boolean|Array<string|boolean>}}
     */
    var obj = {};

    // if query string exists
    if (queryString) {
        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];

        // split our query string into its component parts
        var qsTokens = queryString.split('&');

        qsTokens.forEach(qsToken => {
            // separate the keys and the values
            var a = qsToken.split('=');

            // set parameter name and value (use 'true' if empty)
            var paramName = a[0];
            var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

            // if the paramName ends with square brackets, e.g. colors[] or colors[2]
            if (paramName.match(/\[(\d+)?\]$/)) {
                // create key if it doesn't exist
                var key = paramName.replace(/\[(\d+)?\]/, '');
                obj[key] = obj[key] || [];

                var objValue = obj[key];

                // if it's an indexed array e.g. colors[2]
                if (paramName.match(/\[\d+\]$/)) {
                    // get the index value and add the entry at the appropriate position
                    var regexpArray = /\[(\d+)\]/.exec(paramName);

                    if (regexpArray) {
                        if (Array.isArray(objValue)) {
                            var index = +regexpArray[1];
                            objValue[index] = paramValue;
                        }
                    }
                } else if (Array.isArray(objValue)) {
                    // otherwise add the value to the end of the array
                    objValue.push(paramValue);
                }
            } else if (!obj[paramName]) {
                // if it doesn't exist, create property
                obj[paramName] = decodeURIComponent(paramValue + '');
            } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                // if property does exist and it's a string, convert it to an array
                // @ts-ignore
                obj[paramName] = [obj[paramName]];
                // @ts-ignore
                obj[paramName].push(paramValue);
            } else {
                // otherwise add the property
                // @ts-ignore
                obj[paramName].push(paramValue);
            }
        });
    }

    return obj;
}

/**
 * @type {string[]}
 */
let errors = [];
/**
 *
 * @param {string|Error} message to show
 */
export function showErrorLayout(message) {
    const errorLayout = document.querySelector('#errorLayout');
    if (errorLayout) {
        if (message instanceof Error) {
            if (message.stack) {
                errors.unshift(message.stack);
            }
            errors.unshift(message.message);
        } else {
            errors.unshift(message);
        }

        log.error(message);
        errorLayout.addEventListener('click', () => {
            errorLayout.innerHTML = ''; errors = [];
        }, { once: true });

        errorLayout.innerHTML = `<div class="danger" style="
            bottom: 0;
            right: 0;
            position: fixed;
            background-color: #ff0000c7;
            border: black;
            padding: 5px;
            z-index: 9999999;
            border-radius: 10px;
        ">
                Error: <br/>
                ${errors.join('<hr/>')}
            </div>`;
    }
}

/**
 * @description Check if event `event` is triggered outside of element `el`
 * @param {Event} event DOM event
 * @param {HTMLElement} el element to track click on
 * @returns {boolean} `true` if triggered outside of element
 */
export function isEventTriggeredOutsideElement(event, el) {
    if (event.target && event.target instanceof Element) {
        let currElement = event.target;
        while (currElement.parentElement) {
            if (currElement === el) {
                return false;
            }
            currElement = currElement.parentElement;
        }
        return true;
    }
    return false;
}

/**
 * @description Create a function to unsubscribe listener
 * @param {EventListener | undefined} listener Listener to unsubscribe
 * @param {string} eventName Event to unsubscribe
 * @returns {EventListener | undefined} Unsubscribed listener or undefined
 */
function makeExposableListener(listener, eventName) {
    if (listener) {
        document.removeEventListener(eventName, listener);
        listener = undefined;
    }
    return listener;
}

/**
 * @description Create listener for click outside of element `el` to execute callback `cb`
 * @param {import('./RefElement').RefElement} el Element to track click on
 * @param {Function} cb Callback
 * @param {boolean} [preventDefault] Optional to prevent the default event
 * @returns {Function|undefined} Disposable function for listener (for unsubscription)
 */
export function clickOutside(el, cb, preventDefault = true) {
    // need for support desktop emulation
    const eventName = 'click';
    const domEl = el.get();
    /**
     * @type {EventListener|undefined}
     */
    let listener;
    function expose() {
        listener = makeExposableListener(listener, eventName);
    }

    if (domEl) {
        listener = event => {
            if (isEventTriggeredOutsideElement(event, domEl)) {
                if (cb(event) === false) {
                    expose();
                }
                if (preventDefault === true) {
                    event.preventDefault();
                }
            }
        };
        setTimeout(() => {
            if (listener) {
                document.addEventListener(eventName, listener);
            }
        }, 0);

        return expose;
    }

    throw new Error('Missing required el');
}

/**
 * @description Compare object instances
 * @param {any} x Source object
 * @param {any} y Object for compare
 * @returns {boolean} `true` if objects are equal
 */
// eslint-disable-next-line complexity
export function objectEquals(x, y) {
    if (x === null || x === undefined || y === null || y === undefined) {
        return x === y;
    }
    // after this just checking type of one would be enough
    if (x.constructor !== y.constructor) {
        return false;
    }
    // if they are functions, they should exactly refer to same one (because of closures)
    if (x instanceof Function) {
        return x === y;
    }
    // if they are regexps, they should exactly refer to same one (it is hard to better equality check on current ES)
    if (x instanceof RegExp) {
        return x === y;
    }
    if (x === y || x.valueOf() === y.valueOf()) {
        return true;
    }
    if (Array.isArray(x) && x.length !== y.length) {
        return false;
    }

    // if they are dates, they must had equal valueOf
    if (x instanceof Date) {
        return false;
    }

    // if they are strictly equal, they both need to be object at least
    if (!(x instanceof Object)) {
        return false;
    }
    if (!(y instanceof Object)) {
        return false;
    }

    // recursive object equality check
    var p = Object.keys(x);
    return Object.keys(y).every((i) => p.indexOf(i) !== -1)
        && p.every((i) => objectEquals(x[i], y[i]));
}

/**
 * @description Generate an integer Array containing an arithmetic progression
 * @param {number} start start from
 * @param {number|null} [stop] end on
 * @param {number} [step] step
 * @returns {number[]} Array with an arithmetic progression
 */
export function range(start, stop = null, step) {
    if (stop === null) {
        stop = start || 0;
        start = 0;
    }
    if (!step) {
        step = stop < start ? -1 : 1;
    }

    var length = Math.max(Math.ceil((stop - start) / step), 0);
    var newRange = Array(length);

    for (var idx = 0; idx < length; idx += 1, start += step) {
        newRange[idx] = start;
    }

    return newRange;
}

const rbrace = /^(?:\{[\w\W]*\}|\[[\w\W]*\])$/;
/**
 * @description converts value from data attribute into js type value
 * @param {string} data value to convert
 * @returns {object|string|number|boolean|null|undefined} converted value
 */
export function getData(data) {
    if (data === 'true') {
        return true;
    }

    if (data === 'false') {
        return false;
    }

    if (data === 'null') {
        return null;
    }

    // Only convert to a number if it doesn't change the string
    if (data === +data + '') {
        return +data;
    }

    if (rbrace.test(data)) {
        return JSON.parse(data);
    }

    return data;
}

/**
 * @description Creates a function that memoizes the result of `func`.
 * If resolver `hasher` is provided, it determines the cache key for storing
 * the result based on the arguments provided to the memoized function.
 * By default, the first argument provided to the memoized function is used as the map cache key.
 * The func is invoked with the this binding of the memoized function.
 * @param {Function} func The function to have its output memoized.
 * @param {Function} [hasher] The function to resolve the cache key.
 * @returns {Function} Returns the new memoized function.
 */
export function memoize(func, hasher) {
    /**
     * @description Memoize function wrapper
     * @param {string} key Memoize key
     * @returns {any} Memoize cache value
     */
    function memoizeInner(key) {
        /**
         * @type {{[x: string]: any}}
         */
        var cache = memoizeInner.cache;
        // @ts-ignore
        var address = '' + (hasher ? hasher.apply(this, arguments) : key);
        if (typeof cache[address] === 'undefined') {
            // @ts-ignore
            cache[address] = func.apply(this, arguments);
        }
        return cache[address];
    }
    memoizeInner.cache = {};
    return memoizeInner;
}

/**
 * @description Add script tag on page
 * @param {string} source Url of script
 * @param {string} [globalObject] To check script loaded by global object
 * @param {string} [integrity] To add scripts integrity
 * @returns {Promise<any>} Promise when script loading is done or rejected
 */
const loadScriptHandler = (source, globalObject, integrity) => {
    return new Promise((resolve, reject) => {
        var script = document.createElement('script');
        var prior = document.getElementsByTagName('script')[0];

        if (!prior || !prior.parentNode) {
            throw Error('No document');
        }

        script.async = true;

        if (integrity) {
            script.integrity = integrity;
        }
        script.type = 'text/javascript';
        prior.parentNode.insertBefore(script, prior);

        script.onload = () => {
            script.onload = null;
            // @ts-ignore
            script = undefined;

            if (globalObject) {
                // @ts-ignore
                if (window[globalObject]) {
                    // @ts-ignore
                    resolve(window[globalObject]);
                } else {
                    reject();
                }
            } else {
                resolve();
            }
        };
        script.onabort = () => {
            reject();
        };
        script.onerror = () => {
            reject();
        };

        script.src = source;
    });
};

export const loadScript = memoize(loadScriptHandler);

/**
 * @description Get value in tree object `target` by path `path`
 * @param {any} target Source object
 * @param {string} path In `target` object
 * @param {any} [defaults] Will be returned instead of result if value doesn't exist
 * @returns {any} Value in `target` object by path `path`
 */
export function get(target, path, defaults) {
    const parts = (path + '').split('.');
    let part;

    while (parts.length) {
        part = parts.shift();
        if (typeof target === 'object' && target !== null && part && part in target) {
            target = target[part];
        } else if (typeof target === 'string' && part) {
            target = target[+part];
            break;
        } else {
            target = defaults;
            break;
        }
    }
    return target;
}

/**
 * @description Checks if DOM element is focusable
 * @param {object} element HTML element to check if it is focusable
 * @returns {boolean} true if focusable, false if not
 */
export function isDOMElementFocusable(element) {
    if (
        element.tabIndex > 0
        || (element.tabIndex === 0 && element.hasAttribute('tabIndex'))
        || element.hasAttribute('contenteditable')
    ) {
        return true;
    }

    if (element.hasAttribute('disabled') || element.hasAttribute('hidden')) {
        return false;
    }

    switch (element.nodeName) {
        case 'A':
            return !!element.href && element.rel !== 'ignore';
        case 'INPUT':
            return element.type !== 'hidden' && element.type !== 'file';
        case 'BUTTON':
        case 'SELECT':
        case 'TEXTAREA':
            return true;
        default:
            return false;
    }
}
