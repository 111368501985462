/**
 * @typedef {ReturnType<typeof import('widgets/forms/InputText').default>} InputText
 */
/**
 * @description Base InputBirthdayDate implementation
 * @param {InputText} InputText Base widget for extending
 * @returns {typeof InputBirthdayDate} Input Birthday Date class
 */
export default function (InputText) {
    class InputBirthdayDate extends InputText {
        init() {
            super.init();

            this.setLabel();
        }

        /**
         * @description Validate input value
         * @returns {boolean} is input valid or not
         */
        isValid() {
            var field = this.ref('field');
            var validation = this.prefs().validationConfig;
            var value = this.getValue();
            var reg = new RegExp(field.attr('pattern') || '', 'g');

            if (!value?.length) {
                this.error = validation.errors.required;
                return false;
            }

            // check regExp
            if (!value.match(reg)) {
                this.error = validation.errors.parse;
                return false;
            }

            // check min value
            var inputDate = new Date(value.replace(/-/g, '/'));
            var min = field.data('min') ? new Date(field.data('min').replace(/-/g, '/')) : null;

            // eslint-disable-next-line no-restricted-globals
            if (inputDate instanceof Date && !isNaN(inputDate) && min instanceof Date && !isNaN(min) && !(min >= inputDate)) {
                this.error = validation.errors.parse;
                return false;
            }
            return true;
        }

        onInput(...args) {
            super.onInput(...args);

            this.setLabel();
        }

        setValue(...args) {
            super.setValue(...args);

            this.setLabel();
        }

        setLabel() {
            this.has('label', el => {
                el.attr('aria-label', this.ref('field').val());
            });
        }

        ageGateEntrance(birth_day, birth_month, birth_year) {
            let age = 0;
			let today_date = new Date();
			let today_year = today_date.getFullYear();
			let today_month = today_date.getMonth();
			let today_day = today_date.getDate();
			if (!birth_day || !birth_month || !birth_year) {
				age = 0;
			} else {
				age = today_year - birth_year;

				if ( today_month < (birth_month - 1)) {
					age--;
				}
				if (((birth_month - 1) == today_month) && (today_day < birth_day)) {
					age--;
				}
			}
			return age;
		}

        onBirthdayChange(ele, event) {
            let selectedDate = new Date(event.target.value);
            let month = selectedDate.getMonth() + 1;
            let date = selectedDate.getDate();
            let year = selectedDate.getFullYear();
            let cartError = JSON.parse(document.querySelector("[data-widget='cartMgr']").dataset.valid).error;
            if (cartError) {
                document.getElementsByClassName('apple-pay-cart')[0].classList.add('inactive');
            } else {
                let regExp = "^19|20[0-9]{2}[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$";
                let userDOB = event.target.value;
                let res = userDOB.match(regExp);
                if(res && this.ageGateEntrance(date, month, year) >= 21) {
                    document.getElementById('dwfrm_shipping_shippingAddress_addressFields_birthday-error').classList.add('h-hidden');
                    document.getElementsByClassName('apple-pay-cart')[0].classList.remove('inactive');
                    document.getElementsByClassName('apple-pay-cart')[0].removeAttribute("tabIndex");
                } else {
                    document.getElementById('dwfrm_shipping_shippingAddress_addressFields_birthday-error').classList.remove('h-hidden');
                    document.getElementsByClassName('apple-pay-cart')[0].classList.add('inactive');
                    document.getElementsByClassName('apple-pay-cart')[0].setAttribute("tabIndex", "-1");
                }
            }
        }
    }

    return InputBirthdayDate;
}
