import { scrollElementTo } from 'widgets/toolbox/scroll';
import { timeout } from 'widgets/toolbox/util';

/**
 * @typedef {ReturnType<typeof import('widgets/search/SearchBox').default>} SearchBox
 */

/**
 * @description Search form implementation. That consists of form and combobox with suggestion functionality
 * @param {SearchBox} SearchBox Base widget for extending
 * @returns {typeof HarmonySearchBox} HarmonySearchBox class
 */
export default function (SearchBox) {
    class HarmonySearchBox extends SearchBox {
        /**
         * @description Preferences
         * @returns {object} Preferences object
         */
        prefs() {
            return {
                classesSuggestionsActive: 'm-active_suggestions',
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.toggleClearButton(!!this.ref('input').val());
        }

        /**
         * @description Shows suggestions popup in initial state.
         * <br>Initial state can be a slot/asset markup, rendered in backed
         * <br>and placed in `searchSuggestionsInitial` template
         */
        showInDefaultState() {
            const input = this.ref('input').get();
            const clearBtnElem = document.getElementById('clearButton');
            if (input.value) {
                clearBtnElem.removeAttribute("tabindex");
            } else {
                clearBtnElem.setAttribute("tabindex",'-1');
            }

            if (input) {
                input.focus();
            }
            this.lastSearchedTerm = '';
            this.render('searchSuggestionsInitial', {}, this.ref('listboxInner'))
                .then(() => {
                    this.selectedIndex = -1;
                    this.resultsCount = this.getRealItemsCount();
                    this.openListbox();
                });
            this.ref('dialog').removeClass(this.prefs().classesSuggestionsActive);
        }

        /**
         * @param {string} query - a query, used for fetching Combobox inner items
         * @param {number} resultsCount - obtained inner items count
         * @param {Array} products - arary of products object
         */
        afterSuggestionsUpdate(query, resultsCount, products) {
            super.afterSuggestionsUpdate(query, resultsCount);
            this.ref('dialog').addClass(this.prefs().classesSuggestionsActive);
            this.eventBus().emit('searchbox.products.init', products);
        }

        /**
         * @param {string} query - customer's search query
         * @param {object} response - backend response with suggestions
         */
        processResponse(query, response) {
            if (response && response.suggestions && response.isAvailableSuggestions) {
                if (this.ref('input').val() !== query) {
                    // if value changed during request, we do not show outdated suggestions
                    return;
                }

                if (document.activeElement !== this.ref('input').get()) {
                    this.toggleSpinner(false);
                    this.showInDefaultState();
                    return;
                }

                this.render(undefined, response, this.ref('listboxInner')).then(() => {
                    timeout(() => {
                        const listbox = this.ref('listbox').get();
                        if (listbox) {
                            scrollElementTo(listbox);
                        }
                    }, 10);
                    this.afterSuggestionsUpdate(query, response.suggestions.total, response.suggestions.product.products);
                });
            } else {
                this.showNoSuggestions();
            }

            this.toggleSpinner(false);
        }

        /**
         * Display no suggestions found state (message + default suggestions)
         */
        showNoSuggestions() {
            const input = this.ref('input').get();
            if (input) {
                input.focus();
            }
            this.lastSearchedTerm = '';
            this.render('searchNoSuggestions', {}, this.ref('listboxInner'))
                .then(() => {
                    this.selectedIndex = -1;
                    this.resultsCount = this.getRealItemsCount();
                    this.openListbox();
                });
            this.ref('dialog').removeClass(this.prefs().classesSuggestionsActive);
        }

        closeSearch() {
            this.emit('closesearch');
            // @ts-ignore
            this.closeModal();
            this.closeListbox();
            this.clearInput(false);
        }

        clearInput(...args) {
            super.clearInput(...args);

            const searchInput = this.ref('input').get();
            searchInput.defaultValue = '';
        }
    }

    return HarmonySearchBox;
}
