import { submitFormJson } from 'widgets/toolbox/ajax';

/**
 * @class
 * @description This class used for change place where Amazon Cognito will store session data.
 * By default it is localStorage in browser. Here it was changed to the backend session.
 * See for more details: https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#managing-security-tokens
 *
 * As well here was done mapping in localStorage for Amazon Cognito keys. Due to it has length over 50 symbols,
 * thus we are getting quote limit on back-end side.
 */
export default class Storage {
    /**
     * @param {string} storageUrl - where to get requests.
     */
    constructor(storageUrl) {
        this.storageUrl = storageUrl;
        this.dataMemory = {};
    }

    /**
     * @type {object}
     */
    syncPromise = {};

    /**
     * This is used to set a specific item in storage
     *
     * @param {string} key
     * @param {string} value
     * @returns {string}
     */
    setItem(key, value) {
        this.dataMemory[key] = value;
        return this.dataMemory[key];
    }

    /**
     * This is used to get a specific key from storage
     *
     * @param {string} key
     * @returns {string|undefined}
     */
    getItem(key) {
        return Object.prototype.hasOwnProperty.call(this.dataMemory, key) ? this.dataMemory[key] : undefined;
    }

    /**
     * This is used to remove an item from storage
     *
     * @param {string} key
     * @returns {boolean}
     */
    removeItem(key) {
        return delete this.dataMemory[key];
    }

    /**
     * This is used to clear the storage
     *
     * @returns {object}
     */
    clear() {
        this.dataMemory = {};
        return this.dataMemory;
    }

    /**
     * Will sync the Storage data from server session to object storage.
     * And will sync mapping to Cognito session key.
     *
     * @returns {Promise<object>}
     */
    sync() {
        if (!Storage.syncPromise) {
            Storage.syncPromise = new Promise((res, rej) => {
                submitFormJson(this.storageUrl, {}, 'GET', true)
                    .then(data => {
                        this.clear();
                        Object.assign(this.dataMemory, data.items);

                        res();
                    })
                    .catch(() => {
                        rej();
                    });
            });
        }
        return Storage.syncPromise;
    }
}
