// TO BE REVIEWED
// @ts-nocheck
import { scrollIntoView } from 'widgets/toolbox/scroll';
import { get } from 'widgets/toolbox/util';

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @description Base BasicInput implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof BasicInput} Basic Input class
 */
export default function (Widget) {
    /**
     * @category widgets
     * @subcategory forms
     * @class BasicInput
     * @augments Widget
     * @classdesc Basic Input Widget (like abstract ancestor), contains basic validation and input management logic.
     * Supposes valid unified input markup. Contains also error messaging.
     * Usually is not used directly, and relevant subclasses should be used.
     * @property {boolean} data-skip-validation - if input needs to skip validation
     * @property {string} data-classes-error-state - classes for input's error state
     * @property {string} data-classes-valid-state - classes for input's valid state
     * @property {string} data-classes-disabled - classes for disabled input
     * @property {string} data-classes-locked - classes for locked input (disabled + readonly)
     * @property {string} data-classes-wrapper-error-state - classes for input wrapper, when input in error state
     * @property {string} data-classes-wrapper-valid-state - classes for input wrapper, when input in valid state
     * @property {object} data-validation-config - validation rules and error messages for input
     */
    class BasicInput extends Widget {
        prefs() {
            return {
                skipValidation: false,
                classesErrorState: 'm-invalid',
                classesValidState: 'm-valid',
                classesDisabled: 'm-disabled',
                classesLocked: 'm-locked',
                classesWrapperErrorState: 'm-invalid',
                classesWrapperValidState: 'm-valid',
                validationConfig: {},
                ...super.prefs()
            };
        }

        init() {
            this.initValue = this.getValue();
            if (!this.id && this.ref('field').attr('name')) {
                this.id = this.ref('field').attr('name');
            }
            this.disabled = this.ref('field').attr('disabled') === 'disabled';
            this.formName = '';
        }

        /**
         * @description Get input value
         * @returns {string} - return input name
         */
        getValue() {
            // @ts-ignore
            return (this.ref('field').val());
        }

        /**
         * @description Get input name
         * @returns {string} - return input name
         */
        getName() {
            // @ts-ignore
            return this.ref('field').attr('name');
        }

        /**
         * @description Set focus to input
         * @returns {void}
         */
        focus() {
            var field = this.ref('field').get();
            if (field) {
                field.focus();
            }
        }

        /**
         * @description Set focus to input and scroll element into viewport
         * @returns {void}
         */
        setFocus() {
            const elementToScroll = this.ref('self').get();

            if (elementToScroll) {
                scrollIntoView(elementToScroll);
            }

            this.focus();
        }

        /**
         * @description Blur (unfocus) an input
         * @returns {void}
         */
        blur() {
            var field = this.ref('field').get();
            if (field) {
                field.blur();
            }
        }

        /**
         * @description Set input value
         * "checkRanges" method needs to validate min\max length after changing input value by JS
         * https://stackoverflow.com/questions/53226031/html-input-validity-not-checked-when-value-is-changed-by-javascript
         * @param {(string|number|undefined)} [newVal] - set this value to input
         * @param {(boolean|undefined)} [silently] - if set to `true` - input
         * should not be validated against a new value and no events should be fired
         * @returns {void}
         */
        setValue(newVal = '', silently = false) {
            const refField = this.ref('field');

            refField.val(String(newVal));

            this.checkRanges(refField);

            if (!silently) {
                this.update();
            }
        }

        /**
         * @description Check min/max length when value is being set and if it out of boundaries, set custom validity.
         * After setting value via code, a min/max length rule does not work until the value is changed by user.
         * @param {refElement} refField - Field ref object
         * @returns {void}
         * */
        checkRanges(refField) {
            const value = /** @type {string} */(refField.val());
            const field = refField.get();
            const rangesError = this.getRangesError(value);

            if (rangesError && (field instanceof HTMLInputElement)) {
                field.setCustomValidity(rangesError);
            }
        }

        /**
         * @description Check ranges and return appropriate error message or empty string
         * @param {string} val - Value that is being set
         * @returns {string} Ranges error message or empty string
         */
        getRangesError(val) {
            if (!val) {
                return '';
            }

            const refField = this.ref('field');
            const field = refField.get();

            if (field instanceof HTMLInputElement) {
                const validationConfig = this.prefs().validationConfig;
                const valueLength = String(val).length;

                if (field.minLength && valueLength < field.minLength) {
                    return get(validationConfig, 'errors.minLength', '');
                } else if (field.maxLength && valueLength > field.maxLength) {
                    return get(validationConfig, 'errors.maxLength', '');
                }
            }

            return '';
        }

        /**
         * @description Updates custom validity state
         * @returns {void}
         */
        updateCustomValidityState() {
            const field = this.ref('field');
            const validity = field.getValidity();

            if (validity && validity.state.customError) {
                this.checkRanges(field);
            }
        }

        /**
         * @description Validate input and trigger `change` event
         * @emits BasicInput#change
         * @returns {void}
         */
        update() {
            this.validate();
            /**
             * @description Event, indicates input value was changed
             * @event BasicInput#change
            */
            this.emit('change', this);
        }

        /**
         * @description Clears input error
         * @emits BasicInput#inputstatechanged
         * @returns {void}
         */
        clearError() {
            this.ref('field').removeClass(this.prefs().classesErrorState);
            this.ref('field').removeClass(this.prefs().classesValidState);
            this.ref('self').removeClass(this.prefs().classesWrapperErrorState);
            this.ref('self').removeClass(this.prefs().classesWrapperValidState);

            this.ref('errorFeedback').hide();

            /**
             * @description Event, indicates input state was changed
             * @event BasicInput#inputstatechanged
            */
            this.emit('inputstatechanged');
        }

        /**
         * @description Set/unset error state into input (message and classes)
         * @param {string} [error] error message - if defined, will set error staet, and valid state - otherwise
         * @emits BasicInput#inputstatechanged
         * @returns {void}
         */
        setError(error) {
            if (error) {
                this.ref('field').removeClass(this.prefs().classesValidState);
                this.ref('self').removeClass(this.prefs().classesWrapperValidState);

                this.ref('field').addClass(this.prefs().classesErrorState);
                this.ref('self').addClass(this.prefs().classesWrapperErrorState);

                this.ref('errorFeedback').setText(error).show();
            } else {
                this.ref('field').removeClass(this.prefs().classesErrorState);
                this.ref('self').removeClass(this.prefs().classesWrapperErrorState);

                this.ref('field').addClass(this.prefs().classesValidState);
                this.ref('self').addClass(this.prefs().classesWrapperValidState);

                this.ref('errorFeedback').hide();
            }

            this.emit('inputstatechanged');
        }

        /**
         * @description Indicates, that input value is (is not) valid against HTML5 native constraints (input patterns, min/max etc).
         * Also cares about case, when some field value should match another field value.
         * Sets error message in case of input is not valid. Message is taken from JSON `validationConfig` data-attribute
         * @returns {boolean} is input valid or not
         */
        isValid() {
            const field = this.ref('field');
            const validation = this.prefs().validationConfig;

            this.updateCustomValidityState();

            var { state, msg } = field.getValidity()
                || { msg: '', state: /** @type {ValidityState} */({ valid: true }) };

            if ((state.patternMismatch || state.typeMismatch)) {
                msg = validation.errors.parse || validation.errors.security;
            } else if (
                (state.rangeOverflow || state.rangeUnderflow || state.tooLong || state.tooShort)
            ) {
                if (state.rangeOverflow || state.tooLong) {
                    msg = validation.errors.maxLength;
                } else if (state.rangeUnderflow || state.tooShort) {
                    msg = validation.errors.minLength;
                }
            } else if (state.valueMissing) {
                msg = validation.errors.required;
            }

            if (state.valid && this.widgetToMatch
                && this.widgetToMatchOpts
                && this.widgetToMatch.data('getValue') !== this.getValue()
            ) {
                this.error = this.widgetToMatchOpts.msg;
                return false;
            }

            // user should be prevented from saving a non-compliant state as their wine club shipping address
            var isWineClubShippAddChecked = document.getElementById('dwfrm_address_setAsWineClubDefaultShipping');
            var isDefaultAddresChecked = document.getElementById('dwfrm_address_setAsDefault');
            // eslint-disable-next-line max-len
            if (isWineClubShippAddChecked !== null && isWineClubShippAddChecked.checked && this.config.id === 'dwfrm_address_setAsWineClubDefaultShipping') {
                return this.nonCompliantState();
            }
            if (isDefaultAddresChecked !== null && isDefaultAddresChecked.checked && this.config.id === 'dwfrm_address_setAsDefault') {
                return this.nonCompliantState();
            }
            this.error = msg;
            return state.valid;
        }

        /**
         * @description Triggers input validation process
         * @returns {boolean} input validation result
         */
        validate() {
            if (!this.shown || this.disabled || this.prefs().skipValidation) {
                return true;
            }

            const valid = this.isValid();

            if (valid) {
                this.setError();
            } else {
                this.setError(this.error);
            }

            return valid;
        }

        /**
         * @description Disables an input
         * @returns {this} `this` instance for chaining
         */
        disable() {
            this.disabled = true;
            this.ref('field').disable();
            this.ref('self').addClass(this.prefs().classesDisabled);
            return this;
        }

        /**
         * @description Enables an input
         * @returns {this} `this` instance for chaining
         */
        enable() {
            this.disabled = false;
            this.ref('field').enable();
            this.ref('self').removeClass(this.prefs().classesDisabled);
            return this;
        }

        /**
         * @description Locks an input (adds `readonly` attribute)
         * @returns {void}
         */
        lock() {
            this.locked = true;
            this.ref('field').attr('readonly', true);
            this.ref('self').addClass(this.prefs().classesLocked);
        }

        /**
         * @description Unlocks an input (removes `readonly` attribute)
         * @returns {void}
         */
        unlock() {
            this.locked = false;
            this.ref('field').attr('readonly', false);
            this.ref('self').removeClass(this.prefs().classesLocked);
        }

        /**
         * @description Checks if input is disabled
         * @returns {boolean} result
         */
        isDisabled() {
            return !!this.disabled;
        }

        /**
         * @description Saves on widget level target widget for comparison validation to use it further in `isValid` method
         * @param {BasicInput} widgetToMatch cmp
         * @param {{[x: string]: string|undefined}} options to compare
         * @returns {void}
         */
        setMatchCmp(widgetToMatch, options = {}) {
            this.widgetToMatch = widgetToMatch;
            this.widgetToMatchOpts = options;
        }

        /**
         * @description To be either included or not into the form submission to server.
         * @returns {boolean} result
         */
        skipSubmission() {
            return false;
        }

        /**
         * @description user should be prevented from saving a non-compliant state as their wine club shipping address
         * @returns {boolean} result
         */
        nonCompliantState() {
            var stateDropDown = document.getElementById('dwfrm_address_states_stateCode');
            const nonCompliantStateArr = document.getElementById('nonCompliantState');
            const nonCompliantStateErrorMsg = document.getElementsByClassName('non-CompliantState-errorMsg')[0];
            if (stateDropDown !== null && stateDropDown.value !== '' && nonCompliantStateArr !== null) {
                var selectedVal = stateDropDown.value;
                const nonCompliantState = JSON.parse(nonCompliantStateArr.value);
                const isNonCompliantState = nonCompliantState.includes(selectedVal);
                if (isNonCompliantState) {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    })
                    nonCompliantStateErrorMsg.removeAttribute('hidden');
                    return false;
                }
            }
            // eslint-disable-next-line
            if(nonCompliantStateErrorMsg!== undefined){
                nonCompliantStateErrorMsg.setAttribute('hidden', 'hidden');
            }
            return true;
        }
    }

    return BasicInput;
}
