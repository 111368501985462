const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32
});

const ARIA_EXPANDED = 'aria-expanded';
const ARIA_HIDDEN = 'aria-hidden';
const ARIA_DISABLED = 'aria-disabled';

/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */

/**
 * @description Base AccordionItem implementation
 * @param {typeof import('widgets/Widget').default} Widget base widget class
 * @returns {typeof AccordionItem} AccordionItem widget
 */
export default function (Widget) {
    /**
     * @category widgets
     * @subcategory global
     * @class AccordionItem
     * @augments Widget
     * @classdesc Represents AccordionItem with specific logic for accordion item, handling viewtype changes, keyboard navigation
     * Represents AccordionItem component with next features:
     * 1. Configurable via data attribute epanded state
     * 2. Support keyboard navigation for accessibility
     * 3. Define accessibility
     * @property {string} data-widget - Widget name `accordionItem`
     * @property {string} data-event-keydown - Event listener for `handleKeydown` method
     * @property {string} data-event-click - Event listener for `togglePanel` method
     * @property {boolean} [data-expanded=false] - Expanded flag
     * @category widgets
     * @subcategory global
     * @example
     * // use this code to display widget
     *   <section
     *      data-widget="accordionItem"
     *      data-widget-event-closeallitems="closeItems"
     *  >
     *      <h2
     *          data-ref="accordionItemBtn"
     *          data-event-click="togglePanel"
     *          data-event-keydown="handleKeydown"
     *      >
     *          <button
     *              type="button"
     *          >
     *              Account
     *          </button>
     *      </h2>
     *      <div data-ref="accordionItemPanel">
     *          <div data-ref="accordionItemPanelInner">
     *              Panel content here
     *          </div>
     *      </div>
     *  </section>
     */

    class AccordionItem extends Widget {
        /**
         * @description Returns Widget configuration object
         * @returns {object} Widget configuration object
         */
        prefs() {
            return {
                accordionItemBtn: 'accordionItemBtn',
                accordionItemPanel: 'accordionItemPanel',
                accordionItemPanelInner: 'accordionItemPanelInner',
                expanded: false,
                animateToggle: true,
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         * @returns {void}
         */
        init() {
            this.isPanelOpen = this.prefs().expanded;
            this.isToggleAllowed = false;
            this.isMultipleSections = false;
            this.defineAttributes(this.prefs().expanded);
            this.onDestroy(this.clearAttributes.bind(this));
            this.onDestroy(this.cleanPanelHeight.bind(this));

            if (!this.prefs().expanded) {
                this.closePanel();
            }
        }

        /**
         * @description Define attributes
         * @param {boolean} [isOpen] Panel open flag
         * @returns {void}
         */
        defineAttributes(isOpen) {
            const accordionItemBtn = this.ref('accordionItemBtn');
            const accordionItemPanel = this.ref('accordionItemPanel');
            const accordionItemBtnID = accordionItemBtn.attr('id');
            const accordionItemPanelID = accordionItemPanel.attr('id');

            accordionItemBtn.attr('role', 'button');
            accordionItemBtn.attr('aria-controls', accordionItemPanelID);
            accordionItemBtn.attr(ARIA_EXPANDED, 'false');
            accordionItemBtn.attr('tabindex', '0');
            accordionItemPanel.attr('role', 'region');
            accordionItemPanel.attr('aria-labelledby', accordionItemBtnID);

            if (isOpen) {
                accordionItemPanel.attr(ARIA_HIDDEN, 'false');
                accordionItemBtn.attr(ARIA_EXPANDED, 'true');
            } else {
                accordionItemPanel.attr(ARIA_HIDDEN, 'true');
                accordionItemBtn.attr(ARIA_EXPANDED, 'false');
            }
        }

        /**
         * @description Clear Attributes
         * @returns {void}
         */
        clearAttributes() {
            this.has(this.prefs().accordionItemBtn, (accordionItemBtn) => {
                accordionItemBtn.attr('role', false);
                accordionItemBtn.attr('aria-controls', false);
                accordionItemBtn.attr(ARIA_EXPANDED, false);
                accordionItemBtn.attr(ARIA_DISABLED, false);
                accordionItemBtn.attr('tabindex', false);
            });
            this.has(this.prefs().accordionItemPanel, (accordionItemPanel) => {
                accordionItemPanel.attr('role', false);
                accordionItemPanel.attr('aria-labelledby', false);
                accordionItemPanel.attr(ARIA_HIDDEN, false);
            });
        }

        /**
         * @description Set Focus
         * @returns {void}
         */
        focus() {
            this.has(this.prefs().accordionItemBtn, (accordionItemBtn) => {
                accordionItemBtn.focus();
            });
        }

         /**
         * @description On Blur
         * @returns {void}
         */
          blur() {
            this.has(this.prefs().accordionItemBtn && this.prefs().accordionItemPanel, (accordionItemBtn, accordionItemPanel) => {
                accordionItemBtn.blur();
            });
        }

        /**
         * @description On Refresh Hanpdler
         * @returns {void}
         */
        onRefresh() {
            super.onRefresh();

            this.defineAttributes(this.isPanelOpen);

            if (!this.isPanelOpen) {
                this.closePanel();
            }
        }

        /**
         * @description Open panel
         * @emits AccordionItem#closeallitems
         * @emits AccordionItem#openpanel
         * @returns {void}
         */
        openPanel() {
            if (!this.isMultipleSections) {
                /**
                 * @description Event to close all items
                 * @event AccordionItem#closeallitems
                 */
                this.emit('closeallitems');
            }

            this.has(this.prefs().accordionItemBtn, (accordionItemBtn) => {
                accordionItemBtn.attr(ARIA_EXPANDED, 'true');

                if (!this.isToggleAllowed) {
                    accordionItemBtn.attr(ARIA_DISABLED, 'true');
                }
            });
            this.has(this.prefs().accordionItemPanel,
                accordionItemPanel => this.togglePanelHeight(true, accordionItemPanel));

            this.isPanelOpen = true;
            /**
             * @description Event for opening panel
             * @event AccordionItem#openpanel
             */
            this.emit('openpanel');
        }

        /**
         * @description Close panel
         * @returns {void}
         */
        closePanel() {
            this.has(this.prefs().accordionItemBtn, (accordionItemBtn) => {
                accordionItemBtn.attr(ARIA_EXPANDED, 'false');

                if (!this.isToggleAllowed) {
                    accordionItemBtn.attr(ARIA_DISABLED, false);
                }
            });
            this.has(this.prefs().accordionItemPanel,
                accordionItemPanel => this.togglePanelHeight(false, accordionItemPanel));

            this.isPanelOpen = false;
        }

        /**
         * @description Toggle panel
         * @returns {void}
         */
        togglePanel() {
            if (!this.isPanelOpen) {
                this.openPanel();
            } else if (this.isToggleAllowed) {
                this.closePanel();
            }
        }

        /**
         * @description Toggle panel height, so it could be properly animated.
         * It is required inner element to has content height properly.
         * @param {boolean} isOpen Panel Open flag
         * @param {RefElement} panel IsOpen flag
         * @returns {void}
         */
        togglePanelHeight(isOpen, panel) {
            panel.attr(ARIA_HIDDEN, isOpen ? 'false' : 'true');

            this.has(this.prefs().accordionItemPanelInner, inner => {
                const panelElement = panel.get();
                const innerElement = inner.get();
                if (innerElement && panelElement) {
                    panelElement.style.height = `${isOpen ? innerElement.offsetHeight : 0}px`;
                }
            });
        }

        /**
         * @description Remove hardcoded panel height in case of widget destroyed
         * @returns {void}
         */
        cleanPanelHeight() {
            this.has(this.prefs().accordionItemPanel, accordionItemPanel => {
                const element = accordionItemPanel.get();

                if (element && (parseInt(element.style.height, 10) > 0)) {
                    element.style.height = 'auto';
                }
            });
        }

        /**
         * @description Update accordion item state, recalculate item height
         * TODO: Describe approach for accordion height in case of content change
         */
        updateState() {
            this.has(this.prefs().accordionItemPanel,
                accordionItemPanel => this.togglePanelHeight(Boolean(this.isPanelOpen), accordionItemPanel));
        }

        /**
         * @description Keydown Event handler
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         * @returns {void}
         */
        handleKeydown(_, event) {
            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.RETURN:
                case keyCode.SPACE:
                    this.togglePanel();
                    preventEventActions = true;

                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    return AccordionItem;
}
