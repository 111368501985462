import Swiper, { Navigation, Pagination } from 'swiper';

Swiper.use([Navigation, Pagination]);

/**
 * @description Swiper Carousel implementation
 * @param {CarouselSwiper} CarouselSwiper Base widget for extending
 * @returns {typeof ExtendedCarouselSwiper}
 */
export default function (CarouselSwiper) {
    return class ExtendedCarouselSwiper extends CarouselSwiper {
        prefs() {
            return {
                freeMode: true,
                clickablePagination: false,
                ...super.prefs()
            };
        }

        activate() {
            if (!this.cleanUpSwiper) {
                const carouselContent = this.ref('self').get();

                if (carouselContent) {
                    const options = this.getOptions();

                    this.initControls(options);
                    this.swiper = new Swiper(carouselContent, options);
                    this.cleanUpSwiper = () => {
                        if (this.swiper) {
                            this.swiper.destroy(true, true);
                            this.swiper = undefined;
                            this.cleanUpSwiper = undefined;
                        }
                    };
                    this.afterActivate();
                    this.onDestroy(this.cleanUpSwiper);
                }
            }
        }

        initControls(options) {
            if (!options) {
                return;
            }

            super.initControls(options);

            if (options.pagination) {
                options.pagination.clickable = this.prefs().clickablePagination;
            }
        }

        getOptions() {
            const { freeMode } = this.prefs();

            return {
                freeMode,
                ...super.getOptions()
            };
        }
    };
}
