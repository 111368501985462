import { toggleAvailability } from 'harmony/toolbox/domUtils';

/**
 * @description HarmonyCarousel implementation
 * @param {Carousel} Carousel Carousel for extending
 * @returns {typeof HarmonyCarousel} carousel
 */
export default function (Carousel) {
    return class HarmonyCarousel extends Carousel {
        prefs() {
            return {
                enableAutoplay: false,
                autoplayDelay: 0,
                pauseOnHover: false,
                loop: false,
                slidesQty: 0,
                ...super.prefs()
            };
        }

        initCarousel() {
            this.cacheCarouselElements();

            super.initCarousel();
            
            if (this.isAutoplayEnabled()) {

                this.addAutoPlayEventListeners();
                this.startAutoPlay();
            }

            this.carouselConfiguration = this.data('carouselConfiguration') || {};
            this.carouselConfiguration.slideBy = this.carouselConfiguration.slideBy || 1;

            this.currentActiveSlideIndex = 0;

            this.handleScroll = this.handleScroll.bind(this);
            this.observeSlidesInTrack();

            this.onDestroy(this.disconnectSlidesObserver.bind(this));
        }

        onRefresh() {
            this.cacheCarouselElements();
            this.disconnectSlidesObserver();
            this.observeSlidesInTrack();
        }

        cacheCarouselElements() {
            this.elemCarouselTrack = this.ref('elemCarouselTrack').get();
            this.slides = [...this.elemCarouselTrack.children];
        }

        observeSlidesInTrack() {
            this.observer = new IntersectionObserver(this.handleScroll, {
                root: this.elemCarouselTrack,
                rootMargin: '0px',
                threshold: 0.5
            });

            this.slides.forEach(slide => {
                this.observer.observe(slide);
            });
        }

        disconnectSlidesObserver() {
            this.observer.disconnect();
        }

        handleScroll(slides) {
            const position = this.carouselDirection === 'horizontal' ? 'left' : 'top';

            if (slides[0].intersectionRect[position] !== slides[0].rootBounds[position]) {
                return;
            }

            const firstElement = slides[0].isIntersecting
                ? slides[0].target
                : slides[0].target.nextElementSibling;
            this.currentActiveSlideIndex = this.slides.indexOf(firstElement);
        }

        isAutoplayEnabled() {
            const {
                enableAutoplay, autoplayDelay
            } = this.prefs();

            return enableAutoplay && autoplayDelay;
        }

        isLoopEnabled() {
            return this.prefs().loop || this.isAutoplayEnabled();
        }

        addAutoPlayEventListeners() {
            if (!this.prefs().pauseOnHover) {
                return;
            }

            const carousel = this.ref('self').get();

            carousel.addEventListener('mouseleave', this.startAutoPlay.bind(this));
            carousel.addEventListener('mouseenter', this.stopAutoPlay.bind(this));
        }

        createPaginationElements() {
            if (!this.elemCarouselTrack || this.isLoopEnabled()) {
                return;
            }
            // We need to use round, not ceil, since it called on scroll,
            // in case of last it would generate falls positive
            const numberOfPages = Math.round(this.elemCarouselTrack.scrollWidth / this.elemCarouselTrack.clientWidth);
            const pagination = new Array(numberOfPages).fill(0).map((_el, i) => ({ page: i, pageIndex: i + 1 }));

            this.pagination = new Promise(resolve => {
                this.render(undefined, { pagination }, this.ref('pagination')).then(() => {
                    resolve(this.ref('pagination').get());
                });
            });
        }

        scrollToSlide(index) {
            if (this.carouselDirection === 'horizontal') {
                this.scrollToPoint(0, (this.slides[index].offsetLeft));
            } else {
                this.scrollToPoint((this.slides[index].offsetTop), 0);
            }

            const image = this.getImages()[index];
            if (image) {
                const imagePosition = index + 1; // index from 0, but we need to send from 1
                const gtmData = {
                    imagePosition,
                    imageName: image.getAttribute('alt')
                };

                if (this.prefs().isPdpCarousel) {
                    this.eventBus().emit('gtm.carousel.pdp.click', gtmData);
                }

                if (this.prefs().isBlog) {
                    if (this.slides[index] && this.slides[index].querySelector('[data-selector-id="text-box"]')) {
                        gtmData.articleImageDescription =
                            this.slides[index].querySelector('[data-selector-id="text-box"]').innerText.trim();
                    }

                    this.eventBus().emit('gtm.scroll.blog.carousel', gtmData);
                }
            }

            this.scrollToSlideAccessability(index);
        }

        scrollToSlideAccessability(index) {
            const id = Date.now();

            this.slides.forEach((slide, i) => {
                // Add an id to current slide, so screen reader can read.
                // the same ID is added to controls to "aria-describedby"
                slide[i === index ? 'setAttribute' : 'removeAttribute']('id', id);

                // Enable current and next slides for accessing via TAB key
                // Disable previous slides from TAB order, so right after controls user can navigate to current slide
                toggleAvailability(slide, i >= index);
            });

            // Small delay for screen reader in order to not read previous slide
            setTimeout(() => {
                this.has('controls', controls => controls.attr('aria-describedby', id));
            }, 500);
        }

        startAutoPlay() {
            this.autoplayInterval = window.setInterval(
                this.scrollNext.bind(this),
                this.prefs().autoplayDelay
            );
        }

        stopAutoPlay() {
            window.clearInterval(this.autoplayInterval);
        }

        updateCarouselMetric() {
            const roundingDelta = this.roundingDelta || 0;

            if (this.elemCarouselTrack) {
                if (this.carouselDirection === 'horizontal') {
                    const totalScrollWidth = this.elemCarouselTrack.scrollLeft + this.elemCarouselTrack.offsetWidth;
                    this.isScrollStart = this.elemCarouselTrack.scrollLeft <= 0;
                    this.isScrollEnd = (totalScrollWidth + roundingDelta) >= this.elemCarouselTrack.scrollWidth;
                } else {
                    const totalScrollHeight = this.elemCarouselTrack.scrollTop + this.elemCarouselTrack.offsetHeight;
                    this.isScrollStart = this.elemCarouselTrack.scrollTop <= 0;
                    this.isScrollEnd = (totalScrollHeight + roundingDelta) >= this.elemCarouselTrack.scrollHeight;
                }
            }
        }

        updateCarouselState() {
            super.updateCarouselState();
        }

        scrollNext() {
            if (this.isScrollEnd && this.isLoopEnabled() ) {
                const currentSlides = [...this.ref('elemCarouselTrack').get().children];
                Array.from(currentSlides).forEach(element => {

                    const new_ele = element.cloneNode(true);
                    if (!new_ele.classList.contains('cloned-forward')) {
                        new_ele.classList.add('cloned-forward');
                        this.ref('elemCarouselTrack').get().append(new_ele);
                    }
                    
                });
                this.disconnectSlidesObserver();
                this.cacheCarouselElements();
                this.updateCarouselMetric();
                this.observeSlidesInTrack();    

            }

            const maxSlideIndex = this.slides.length - 1;
            let nextSlideIndex = this.currentActiveSlideIndex + this.carouselConfiguration.slideBy;
            switch (true) {
                case this.isLoopEnabled() && this.isScrollEnd:
                    
                    nextSlideIndex = nextSlideIndex;
                    break;
                case nextSlideIndex > maxSlideIndex:
                    nextSlideIndex = maxSlideIndex;
                    break;
                default: break;
            }

            this.eventBus().emit('gtm.carousel.button.click', {
                carouselButton: 'Next',
                carouselName: this.prefs().name || ''
            });
            
            this.scrollToSlide(nextSlideIndex);
        }

        scrollBack() {
            let prevSlideIndex = this.currentActiveSlideIndex - this.carouselConfiguration.slideBy;

            switch (true) {
                case this.isLoopEnabled() && this.isScrollStart:
                    prevSlideIndex = this.slides.length - 1;
                    break;
                case prevSlideIndex < 0:
                    prevSlideIndex = 0;
                    break;
                default: break;
            }

            this.eventBus().emit('gtm.carousel.button.click', {
                carouselButton: 'Previous',
                carouselName: this.prefs().name || ''
            });

            this.scrollToSlide(prevSlideIndex);
        }

        /**
         * @param {refElement} el source of event
         * @param {MouseEvent} event event instance in DOM
         */
        onMouseDown(el, event) {
            super.onMouseDown(el, event);

            // in case if input field has been focused and on parent element mousedown prevented - forced input focus
            if (event.target.nodeName === 'INPUT') {
                event.target.focus();
            }
        }

        handleContentClick(refElement) {
            const contentInfo = refElement.data('contentInfo');
            contentInfo.carouselItemPosition = refElement.data('contentPosition');
            contentInfo.carouselName = this.prefs().name;
            this.eventBus().emit('gtm.click.carousel.content', contentInfo);
        }

        hasNoScroll() {
            return (this.isScrollStart && this.isScrollEnd) || !this.ref('elemCarouselTrack').get().children.length;
        }
    };
}
