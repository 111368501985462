import { timeout } from 'widgets/toolbox/util';

const keyCode = Object.freeze({
    ESC: 27,
    TAB: 9,
    RETURN: 13,
    SPACE: 32,
    PAGEUP: 33,
    PAGEDOWN: 34,
    END: 35,
    HOME: 36,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40
});

const MENU_ACTIVATION_DELAY = 200;

/**
 * @typedef {ReturnType<typeof import('widgets/global/ListAccessibility').default>} ListAccessibility
 */

/**
 * @param {ListAccessibility} ListAccessibility Base widget for extending
 * @returns {typeof MenuButton} MenuButton widget
 */
export default function (ListAccessibility) {
    /**
     * @category widgets
     * @subcategory header
     * @class MenuButton
     * @augments ListAccessibility
     * @classdesc Represents MenuButton component with next features:
     * 1. Allow open/close menu
     * 2. Allow handling mouse, keyboard events to be able react to user's actions
     * 3. Allow check if submenus exist and its state(opened/closed) to set focus the correct place
     * 4. Support keyboard navigation for accessibility
     * MenuButton component should be imlemented according to https://www.w3.org/TR/wai-aria-practices/#menubutton specs
     * @example <caption>Example of MenuButton widget usage</caption>
     * <div
     *     data-widget="menuButton"
     *     data-event-mouseenter="mouseEnter"
     *     data-event-mouseleave="mouseLeave"
     *     data-event-keydown="handleKeydown"
     *     ....
     * >
     *     <a
     *         role="menuitem"
     *         aria-haspopup="true"
     *         aria-expanded="false"
     *         tabindex="0"
     *         data-ref="itemLink"
     *     >
     *         .... menu button text
     *     </a>
     *     <ul
     *         role="menu"
     *         aria-hidden="true"
     *         data-ref="submenu"
     *     >
     *         <isloop items="${lLinks}" var="link">
     *             <li
     *                 data-widget.lg.xl="menuBarItem"
     *                 role="none"
     *             >
     *                 <button
     *                     role="menuitem"
     *                     tabindex="-1"
     *                     data-ref="itemLink"
     *                 >
     *                     .... item link
     *                 </button>
     *             </li>
     *         </isloop>
     *     </ul>
     * </div>
     */
    class MenuButton extends ListAccessibility {
        prefs() {
            return {
                submenu: 'submenu',
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic.
         * @returns {void}
         */
        init() {
            super.init();
            this.defineItems();
        }

        /**
         * @description Keydown Event handler
         * @listens dom#keydown
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event Event object
         * @returns {void}
         */
        handleKeydown(_, event) {
            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.PAGEUP:
                case keyCode.HOME:
                    if (this.isSubmenuOpen) {
                        this.setFocusToFirstItem();
                    }
                    preventEventActions = true;
                    break;

                case keyCode.PAGEDOWN:
                case keyCode.END:
                    if (this.isSubmenuOpen) {
                        this.setFocusToLastItem();
                    }
                    preventEventActions = true;
                    break;

                case keyCode.UP:
                    if (!this.isSubmenuOpen) {
                        this.openMenu(event, false, true);
                    } else {
                        this.setFocusToPreviousItem();
                    }
                    preventEventActions = true;
                    break;

                case keyCode.DOWN:
                    if (!this.isSubmenuOpen) {
                        this.openMenu(event, true, false);
                    } else {
                        this.setFocusToNextItem();
                    }
                    preventEventActions = true;
                    break;

                case keyCode.TAB:
                case keyCode.ESC:
                    this.closeMenu(event, true);
                    break;

                case keyCode.RETURN:
                case keyCode.SPACE:
                    this.openMenu(event, true, false);
                    preventEventActions = true;
                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }

        /**
         * @description Has submenu
         * @returns {boolean} Return true if submenu exist
         */
        hasSubmenu() {
            return this.has(this.prefs().submenu);
        }

        /**
         * @description Open menu if exist
         * @param {boolean} [focusFirstItem] Item focus flag
         * @param {boolean} [focusLastItem] Item focus flag
         * @returns {void}
         */
        openMenu(e,focusFirstItem, focusLastItem) {
            if (this.hasSubmenu()) {
                this.ref(this.prefs().itemLink)
                    .attr('aria-expanded', 'true');
                this.ref(this.prefs().submenu)
                    .attr('aria-hidden', 'false');
                this.isSubmenuOpen = true;
                this.isSubmenuInFocus = focusFirstItem || focusLastItem;

                if (focusFirstItem) {
                    this.setFocusToFirstItem();
                }

                if (focusLastItem) {
                    this.setFocusToLastItem();
                }
            }
            let ele = e.target;
            if(ele.classList.contains('b-country_selector-locale_language') || ele.classList.contains('b-country_selector-switcher')) {
                let countrySelectorContainer = document.getElementById('language-switcher-menu');
                if(countrySelectorContainer) {
                    countrySelectorContainer.classList.remove('h-hidden');
                }

            }
        }

        /**
         * @description Close menu
         * @param {boolean} [returnFocus] Focus flag
         * @returns {void}
         */
        closeMenu(e,returnFocus) {
            if (this.hasSubmenu()) {
                this.ref(this.prefs().itemLink)
                    .attr('aria-expanded', 'false');
                this.ref(this.prefs().submenu)
                    .attr('aria-hidden', 'true');
                this.isSubmenuOpen = false;
                this.isSubmenuInFocus = false;

                if (returnFocus) {
                    this.focus();
                }
            }
            let ele = e.currentTarget;
            if(ele.classList.contains('b-country_selector')) {
                let countrySelectorContainer = document.getElementById('language-switcher-menu');
                if(countrySelectorContainer) {
                    countrySelectorContainer.classList.add('h-hidden');
                }

            }
        }

        /**
         * @description Toggle menu
         * @returns {boolean|void} boolean value indicates is menu open or close
         */
        toggleMenu(_, event) {
            return this.isSubmenuOpen ? this.closeMenu(event) : this.openMenu(event);
        }

        /**
         * @description Mouse enter event handler
         * @listens dom#mouseenter
         * @returns {void}
         */
        mouseEnter(_, event) {
            if (this.timerCleanerLeave) {
                this.timerCleanerLeave();
            }

            if (this.isSubmenuOpen) { return; }

            this.timerCleanerEnter = timeout(() => {
                this.openMenu(event);
            }, MENU_ACTIVATION_DELAY);
        }

        /**
         * @description Mouse leave event handler
         * @listens dom#mouseleave
         * @returns {void}
         */
        mouseLeave(_, event) {
            if (this.timerCleanerEnter) {
                this.timerCleanerEnter();
            }

            if (!this.isSubmenuOpen) { return; }

            this.timerCleanerLeave = timeout(() => {
                this.closeMenu(event);
            }, MENU_ACTIVATION_DELAY);
        }
    }

    return MenuButton;
}
