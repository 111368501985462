// TO BE REVIEWED
/**
 * @typedef {ReturnType<typeof import('widgets/forms/BasicInput').default>} BasicInput
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @description Base InputText implementation
 * @param {BasicInput} BasicInput Base widget for extending
 * @returns {typeof InputText} Input Text class
 */
export default function (BasicInput) {
    /**
     * @category widgets
     * @subcategory forms
     * @class InputText
     * @augments BasicInput
     * @classdesc Input type `text` implementation. Represents input `text` element together with widget-related HTML markup.
     * HTML structure assembled on backend and injected into resulted html markup by using `formElement` component
     * and dynamic forms configuration JSON.
     * @property {string} data-widget - Widget name `inputText`
     * @example <caption>InputText definition in dynamicforms.json</caption>
     * ...
     * // fields -> input -> text
     * text: {
     *     extends: 'fields.input.base',
     *     validation: {
     *         'patterns.security': 'validation.patterns.security',
     *         'errors.security': 'validation.errors.parse'
     *     },
     *     element: {
     *         minLength: 2,
     *         maxLength: 256,
     *         type: 'text'
     *     }
     * },
     * ...
     * // fields -> generic -> cardType
     * cardType: {
     *     extends: 'fields.input.text',
     *     'label.text': 'form.payment.card.type',
     *     element: {
     *         type: 'hidden',
     *         maxLength: 20,
     *         required: true
     *     }
     * },
     * ...
     * @example <caption>Insertion of InputText inside ISML templates</caption>
     * <isset name="formElement" value="${require('forms/formElement')}" scope="page"/>
     * ...
     * <form>
     *     ...
     *     <isprint value="${
     *         formElement(pdict.addressForm.address1, pdict.addressFormOptions).render()
     *     }" encoding="off"/>
     *     ...
     * </form>
     * @example <caption>Resulted HTML structure for InputText</caption>
     * <div data-widget="inputText" class="b-form_section m-required m-invalid"
     *     data-id="dwfrm_shipping_shippingAddress_addressFields_lastName" data-validation-config="... validation config">
     *     <label class="b-form_section-label" for="dwfrm_shipping_shippingAddress_addressFields_lastName">
     *         <span class="b-form_section-required" aria-hidden="true">*</span>
     *         Last Name
     *     </label>
     *     <input data-ref="field" id="dwfrm_shipping_shippingAddress_addressFields_lastName" type="text"
     *         class="b-input m-invalid" placeholder="" aria-describedby="dwfrm_shipping_shippingAddress_addressFields_lastName-error"
     *         pattern="..." name="dwfrm_shipping_shippingAddress_addressFields_lastName" required="" value="" maxlength="50"
     *         aria-required="true" minlength="2" autocomplete="family-name" data-event-blur="validate"
     *     >
     *     <div role="alert" class="b-form_section-message" data-ref="errorFeedback" id="dwfrm_shipping_shippingAddress_addressFields_lastName-error">This field is required.</div>
     * </div>
     */
    class InputText extends BasicInput {
        /**
         * @description Element on input handler
         * @listens dom#input
         * @emits InputText#input
         * @param {refElement} el source of event
         * @param {(Event|undefined)} event event instance if DOM event
         * @returns {void}
         */
        onInput(el, event) { // eslint-disable-line no-unused-vars
            /**
             * @description Event, indicates that `input` event happend
             * @event InputText#input
            */
            this.ref('field').val(this.ref('field').val().replace(
                /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,'').replace(/\s+/g, ' '));
            this.emit('input');
        }

        onMobileInput() {
            this.emit('input');

            const valueLength = this.ref('field').get()?.value?.length;
            const maskLenght = this.config?.mask?.length;

            if (this.ref('field').data('isExpirationDate') && valueLength === maskLenght) {
                this.eventBus().emit('force.focus.cvv');
            }
        }
    }

    return InputText;
}
