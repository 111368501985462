/**
 * https://developer.mozilla.org/en-US/docs/Web/API/Element/getAttributeNames#Polyfill
 *
 * Needed for MS Edge and IE
 */
if (Element.prototype.getAttributeNames === undefined) {
    Element.prototype.getAttributeNames = function getAttributeNames() {
        const attributes = this.attributes;
        const length = attributes.length;
        const result = [];
        result.length = length;
        for (var i = 0; i < length; i += 1) {
            result[i] = attributes[i].name;
        }
        return result;
    };
}
