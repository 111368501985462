/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description Base SoftKeyboardToggleMixin implementation.
 * <br>Hides software keyboard on touches outside the keyboard to avoid iOS issues.
 * <br>This class is not intended to have a separate DOM representation.
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof SoftKeyboardToggleMixin} SoftKeyboardToggleMixin class
 */
export default function (Widget) {
    /**
     * @class SoftKeyboardToggleMixin
     * @augments Widget
     * @property data-input - Target input
     */
    class SoftKeyboardToggleMixin extends Widget {
        prefs() {
            return {
                input: '',
                ...super.prefs()
            };
        }

        init() {
            super.init();

            const input = this.ref('input').get();
            if (input) {
                this.ev('focus', this.hideKeyboardOnOutsideTouch, input);
                this.ev('blur', this.hideKeyboardReset, input);
            }
        }

        hideKdb() {
            const input = this.ref('input').get();
            if (input) {
                input.blur();
            }
        }

        hideKeyboardOnOutsideTouch() {
            this.touchStartDisposables = this.ev('touchstart', this.hideKdb, window);
        }

        hideKeyboardReset() {
            const disposables = this.touchStartDisposables;

            if (typeof disposables !== 'undefined') {
                disposables.forEach(fn => fn());
            }
        }
    }

    return SoftKeyboardToggleMixin;
}
