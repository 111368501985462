/**
 * @typedef {typeof import('widgets/global/EmitBusEvent').default} EmitBusEvent
 */

/**
 * @param {Widget} EmitBusEvent Base widget for extending
 * @returns {typeof HarmonyEmitBusEvent} HarmonyEmitBusEvent widget
 */
export default function (EmitBusEvent) {
    class HarmonyEmitBusEvent extends EmitBusEvent {
        /**
         * @description Emit event
         * @returns {void}
         */
        emitBusEvent() {
            if (this.prefs().busEventType) {
                const events = this.prefs().busEventType.split(':');
                for (let i = 0; i < events.length; i++) {
                    this.eventBus().emit(events[i], this);
                }
            }
        }

        init() {
            super.init();

            if (this.prefs().load === 'emitBusEvent') {
                this.emitBusEvent();
            }
        }
    }

    return HarmonyEmitBusEvent;
}
