// TO BE REVIEWED
import { getJSONByUrl } from 'widgets/toolbox/ajax';
import { scrollElementTo } from 'widgets/toolbox/scroll';
import { timeout } from 'widgets/toolbox/util';

/**
 * @typedef {ReturnType<typeof import('widgets/forms/Combobox').default>} Combobox
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 * @typedef {ReturnType<typeof import('widgets/global/Modal').default>} Modal
 */
/**
 * @description Search form implementation. That consists of form and combobox with suggestion functionality
 * @param {Combobox} Combobox Base widget for extending
 * @returns {typeof SearchBox} SearchBox class
 */
export default function (Combobox) {
    /**
     * @class SearchBox
     * @augments Combobox
     * @classdesc Search suggestion box. Handles input, shows and interacts with suggestions box.
     * <br>Implements `SFCC search suggestions` provider functionality in `getSuggestions` method
     * <br>It also override combobox logic - when we select suggestion the default action not fill input, but navigate to
     * <br>suggestion that implemented as links (reference combobox should have listbox items).
     * <br>If user interactive with keyboard and select link form should not submitted.
     * @property {string} data-widget - Widget name `searchBox`
     * @property {string} data-url - URL to obtain serach suggestions from server based on customer's input
     * @property {boolean} data-close-on-tab - If true - `tab` keypress will close listbox
     * @property {string} data-widget-event-closesearch - An event, fired when `close` element was pressed
     * @property {string} data-event-keydown - event handler for `keydown` event
     * @property {boolean} data-close-from-outside - config, which shows, if combobox should be closed when click outside
     * @property {number} data-show-spinner-delay - config delay in ms to show spinner
     * @property {string} data-ref-first-focus-element - reference to first focus element for focus trap
     * @property {string} data-ref-last-focus-element - reference to last focus element for focus trap
     * @example
     * // use this code to display minicart widget
     * <div
     *     data-widget="searchBox"
     *     data-url="${URLUtils.url('SearchServices-GetSuggestions')}"
     *     data-close-on-tab="false"
     * >
     *     <form>
     *         ... serach input
     *     </form>
     *     ...
     *     <div data-ref="listbox" data-event-mouseenter="markHover" data-event-mouseleave="unMarkHover">
     *         <div role="none" class="b-suggestions-inner" data-ref="listboxInner"></div>
     *     </div>
     *     <script data-ref="template" type="template/mustache">
     *         <div data-ref="listboxInner">
     *             ... search suggestions
     *         </div>
     *     </script>
     * </div>
     */
    class SearchBox extends Combobox {
        prefs() {
            return {
                showSpinnerDelay: 500,
                outlineSpace: 5,
                classesClearEnabled: 'm-visible',
                classesLoadingSuggestions: 'm-loading',
                classesLoadingSuggestionsLong: 'm-loading-long',
                url: '',
                disableRendering: true,
                ...super.prefs()
            };
        }

        init() {
            super.init();
            this.eventBus().on('page.show.searchbox', 'openSearch');
        }

        /**
         * @description Shows suggestions popup in initial state.
         * <br>Initial state can be a slot/asset markup, rendered in backed
         * <br>and placed in `searchSuggestionsInitial` template
         * @param {boolean} setInputFocus - if we need to focus search input
         * @returns {void}
         */
        showInDefaultState(setInputFocus = true) {
            if (setInputFocus) {
                this.focusInput();
            }
            this.lastSearchedTerm = '';
            this.render('searchSuggestionsInitial', null, this.ref('listboxInner'))
                .then(() => {
                    this.selectedIndex = -1;
                    this.resultsCount = this.getRealItemsCount();
                    this.openListbox();
                });
        }

        /**
         * @param {string} query - requested search query
         */
        getSuggestions(query) {
            getJSONByUrl(this.prefs().url, { q: query })
                .then(response => this.processResponse(query, response))
                .catch(this.handleError.bind(this));

            this.toggleSpinner(true);
        }

        /**
         * @param {string} query - customer's search query
         * @param {object} response - backend response with suggestions
         */
        processResponse(query, response) {
            if (response && response.suggestions) {
                if (this.ref('input').val() !== query) {
                    // if value changed during request, we do not show outdated suggestions
                    return;
                }

                if (document.activeElement !== this.ref('input').get()) {
                    this.toggleSpinner(false);
                    this.showInDefaultState();
                    return;
                }

                this.render(undefined, response, this.ref('listboxInner')).then(() => {
                    timeout(() => {
                        const listbox = this.ref('listbox').get();
                        if (listbox) {
                            scrollElementTo(listbox);
                        }
                    }, 10);

                    this.afterSuggestionsUpdate(query, response.suggestions.total);
                });
            } else {
                this.showInDefaultState();
            }

            this.toggleSpinner(false);
        }

        /**
         * @param {string} query - customer's search query
         */
        handleError(query) {
            this.render('templateError', {}, this.ref('listboxInner')).then(()=> {
                this.toggleSpinner(false);
                this.afterSuggestionsUpdate(query, 0);
            });
        }

        afterCloseListbox() {
            this.ref('listboxInner').empty();
        }

        /**
         * @param {refElement} selectedItem - search suggestion item selected by customer
         */
        activateItem(selectedItem) {
            // We do not need default combobox behaviour.
            // Instead of pasting suggestion value we go by the link
            // if we have selected item in listbox
            if (selectedItem) {
                this.ref('input').val(selectedItem.attr('data-suggestion-value'));
                this.goingByLink = true;
                window.location.assign(selectedItem.attr('href').toString());
            }
        }

        handleInput() {
            const inputValue = this.ref('input');
            let inputLength = 0;
            if (inputValue.length) {
                inputLength = (inputValue.prop('value') && inputValue.prop('value').length) || 0;
            }

            if (inputLength >= this.prefs().minChars && inputLength <= this.prefs().maxChars) {
                this.updateListbox();
            } else {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                }
                this.showInDefaultState();
            }
            this.toggleClearButton(!!this.ref('input').val());
        }

        /**
         * @param {boolean} isShown - Indicated is spinner display
         */
        toggleSpinner(isShown) {
            const listbox = this.ref('listbox');
            listbox.attr('aria-busy', String(isShown));
            listbox.toggleClass(this.prefs().classesLoadingSuggestions, isShown);

            if (isShown) {
                this.longLoadingTimer = timeout(() => {
                    this.ref('listbox').addClass(this.prefs().classesLoadingSuggestionsLong);
                }, this.prefs().showSpinnerDelay);
            } else {
                if (this.longLoadingTimer) {
                    this.longLoadingTimer();
                }
                this.ref('listbox').removeClass(this.prefs().classesLoadingSuggestionsLong);
            }
        }

        /**
         * @param {refElement} selectedItem - search suggestion item selected by customer
         */
        afterItemSelected(selectedItem) {
            const item = selectedItem.get();
            if (item) {
                const listbox = this.ref('listbox').get();
                const top = (item.offsetTop - this.prefs().outlineSpace);
                if (listbox) {
                    scrollElementTo(listbox, top);
                }
            }
        }

        /**
         * @description Executes when user clicks on product details link in the search box results.
         * Usually used by analytics etc.
         * @emits "searchbox.product.link.click"
         * @param {refElement} link - product link
         */
        onProductLinkClick(link) {
            this.eventBus().emit('searchbox.product.link.click', link);
        }

        // Form functionality

        handleSubmit(_, event) {
            const inputVal = this.ref('input').val();
            if (this.goingByLink || !inputVal) {
                event.preventDefault();
            }

            if (!inputVal) {
                this.focusInput();
            } else {
                this.closeListbox();
            }
        }

        /**
         * @description Clears search input and show suggestions from default state. Optionally sets focus to search field.
         * @param {boolean} setInputFocus - if we need to focus input
         */
        clearInput(setInputFocus) {
            this.toggleSpinner(false);
            const searchInput = this.ref('input');
            searchInput.val('');
            if (setInputFocus) {
                this.focusInput();
            }
            this.toggleClearButton(false);
            this.showInDefaultState(setInputFocus);
        }

        /**
         * @param {boolean} isInputHasValue - is search input has value
         */
        toggleClearButton(isInputHasValue) {
            this.ref('clearButton').toggleClass(this.prefs().classesClearEnabled, isInputHasValue);
        }

        /**
         * @description Gets the real count of suggestions items.
         * <br>Could be used in case, when no backend suggestion count are set.
         * <br>As an example: case of rendering content assets with
         * <br>unknown suggestions set
         * @returns {number} - a number of listbox inner elements
         */
        getRealItemsCount() {
            const listboxInner = this.ref('listboxInner').get();
            return listboxInner
                ? listboxInner.querySelectorAll('[data-suggestion-value]').length
                : 0;
        }

        /**
         * @description Cancel handler
         */
        cancel() {
            this.closeSearch();
        }

        openSearch() {
            // @ts-ignore
            this.showModal({
                attributes: {
                    'data-tau': 'search_dialog'
                }
            }, this.showInDefaultState.bind(this));
        }

        focusInput() {
            // iOS could disable focus method on HTMLInputElement https://bugs.webkit.org/show_bug.cgi?id=195884
            // to not annoy the user with software keyboard. To get keyboard we should prevent that input and parent
            // get hidden with display none + focus should be set without delay.
            const input = this.ref('input').get();
            if (!input) { return; }
            input.focus();
        }

        /**
         * @description Used to notify concerning widget to close suggestions popup and do all other actions
         */
        closeSearch() {
            this.emit('closesearch');
            // @ts-ignore
            this.closeModal();
            this.closeListbox();
            this.clearInput(false);
        }

        toggleSearch(ref) {
            if (ref.attr('aria-expanded') === 'true') {
                this.closeSearch();
            } else {
                this.openSearch();
            }
        }

        hide() {
            super.hide();
            this.closeListbox();
            this.clearInput(false);
            return this;
        }
    }

    return SearchBox;
}
