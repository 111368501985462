/**
 * @typedef {InstanceType <ReturnType <typeof import('widgets/global/Modal').default>>} modal
 */

/**
 * @module dialogMgr
 * @category widgets
 * @subcategory toolbox
 * @class DialogMgr
 * @classdesc Contains all needed logic for managing dialog stack e.g. open second dialog above first one.
 * @example <caption>Usage of dialogMgr instance. In this case `dialogMgr` used in class {@link Modal} to show it's content above any other opened dialog</caption>
 * class Modal extends AccessibilityFocusTrapMixin {
 *     ...
        showModal(templateData, cb) {
            ...
            renderedPromise.then(() => {
                ...
                dialogMgr.openDialog(this);
            });
        }
 * }
 */
class DialogMgr {
    constructor() {
        this.dialogsStack = [];
    }

    /**
     * @description Open Modal. If any other opened dialog exists - move it behind newly opened modal.
     * @param {modal} dialog - Dialog object
     */
    openDialog(dialog) {
        const topDialog = this.dialogsStack.length ? this.dialogsStack[this.dialogsStack.length - 1] : null;

        if (topDialog !== dialog) {
            if (topDialog) {
                topDialog.moveBehind();
            }

            dialog.open();

            this.dialogsStack.push(dialog);
        }
    }

    /**
     * @description Close Modal. If any other opened dialog exists, moves it to top.
     */
    closeDialog() {
        const dialog = this.dialogsStack.pop();

        if (dialog) {
            dialog.close();

            if (this.dialogsStack.length) {
                const previousDialog = this.dialogsStack[this.dialogsStack.length - 1];

                previousDialog.moveToTop();
            } else {
                dialog.removeGlobalDialogClass();
            }
        }
    }
}

export const dialogMgr = new DialogMgr();
