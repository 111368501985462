import { timeout } from 'widgets/toolbox/util';

// TODO: JSDoc for all methods
/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @description Base ProductTile implementation
 * @param {typeof import('widgets/Widget').default} Widget Base widget for extending
 * @returns {typeof ProductTile} Product Tile class
 */
export default function (Widget) {
    /**
     * @class ProductTile
     * @augments Widget
     * @classdesc Product Tile component. Used in different product grids.
     * @property {string} data-widget - Widget name `productTile`
     * @property {string} data-pid - product ID
     * @property {string} data-product-name - product name
     * @property {object} data-analytics - analytics data for product tile
     * @property {string} data-text-network-error - network error text, when current ajax operation ended up with network error
     * @property {string} data-text-added-to-wishlist - a text which is used to indicate, that product has being added to wishlist
     * @property {string} data-add-to-wishlist-hide-texts - indicates, that some texts should not be displayed while adding product in a wishlist from product tile
     * @property {object} data-accessibility-alerts - contains accessibility alerts for product tile actions
     * @property {object} data-grid-item - indicates, that product tile is used inside product grid
     * @example
     * // use this code to display widget
     * <section
     *     class="b-product_tile"
     *     <isif condition="${pdict.first}">
     *         data-id="firstTile"
     *     </isif>
     *     data-pid="${product.id}"
     *     data-product-name="${product.productName}"
     *     data-widget="productTile"
     *     data-analytics="${JSON.stringify(product.gtmInfo)}"
     *     data-text-network-error="${Resource.msg('error.alert.network', 'product', null)}"
     *     data-text-added-to-wishlist="${Resource.msg('button.added.to.wishlist', 'wishlist', null)}"
     *     data-add-to-wishlist-hide-texts="true"
     *     data-accessibility-alerts='{
     *         "addedtowishlist": "${Resource.msg('alert.addedtowishlist', 'product', null)}"
     *     }'
     *     data-grid-item=true
     *     data-tau="product_tile"
     *     data-tau-product-id="${product.id}"
     * >
     *     <isinclude template="product/productTile" />
     * </section>
     */
    class ProductTile extends Widget {
        /**
         * @description Inits widget
         * @emits "product.tile.init"
         * @returns {void}
         */
        init() {
            super.init();

            timeout(() => {
                this.eventBus().emit('product.tile.init', this.ref('self'));
            }, 0);
        }

        /**
         * @description Executes when user clicks on product details link on product tile.
         * Usually used by analytics etc.
         * @emits tile.product.link.click
         * @param {refElement} link - product link
         */
        onProductLinkClick(link) {
            this.eventBus().emit('tile.product.link.click', link);
        }

        /**
         * @description On modal window show callback
         * @emits "product.tile.qv.open"
         */
        onModalShow() {
            this.eventBus().emit('product.tile.qv.open', {
                gtmInfo: this.data('analytics')
            });
        }

        showQuickView(button) {
            /**
             * @description Event dispatched, when Global Modal should be triggered
             * @event module:events#dialogshow
             */
            this.eventBus().emit('dialogshow', {
                url: button.data('url'),
                wrapperClasses: 'm-quick_view',
                ariaLabel: this.prefs().productName,
                attributes: {
                    'data-tau-unique': 'quick_view_dialog'
                }
            });

            this.onModalShow();
        }

        /**
         * @description Focus
         */
        focus() {
            this.ref('tileImageLink').focus();
        }
    }

    return ProductTile;
}
