import { timeout } from '../toolbox/util';
import { getJSONByUrl } from 'widgets/toolbox/ajax';

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 * @typedef {ReturnType<typeof import('widgets/global/Button').default>} Button
 * @typedef {ReturnType<typeof import('widgets/forms/BasicInput').default>} BasicInput
 */

/**
 * @param {Widget} Widget base widget class
 * @returns {typeof BasicForm} Basic Form class
 */
export default function (Widget) {
    /**
     * @category widgets
     * @subcategory forms
     * @class BasicForm
     * @augments Widget
     * @classdesc Represents BasicForm component with next features:
     * 1. Allow show/hide Form progress bar
     * 2. Allow get submit button name, Form fields, Form action url
     * 3. Allow submit and handle submit Form
     * 4. Allow set value to Form fields
     * 5. Allow validate Form and Form fields
     * 6. Allow updates Form html body by sending an AJAX request to server with params object
     * BasicForm widget should contain {@link Button} widgets that implement submit Form button.
     * Widget has next relationship:
     * * Handle Form submit using method {@link BasicForm#handleSubmit} by event {@link Button#event:click} from {@link Button} widget.
     * @example <caption>Example of BasicForm widget usage</caption>
     * <form
     *     action="${URLUtils.url('Order-Track')}"
     *     method="GET"
     *     data-widget="form"
     *     data-event-submit="handleSubmit"
     * >
     *      ... form contents
     *    <button
            data-widget="button"
            type="submit"
            data-widget-event-click="handleSubmit"
            data-event-click.prevent="handleClick"
          >
            ...Button name
          </button>
     * </form>
     * @property {string} data-widget - Widget name `form`
     * @property {string} data-event-submit - Event listener for form submission
     */
    class BasicForm extends Widget {
        prefs() {
            return {
                submitEmpty: true,
                emptyFormErrorMsg: '',
                submitButton: 'submitButton',
                errorMessageLabel: 'errorMessageLabel',
                SUBMITTING_TIMEOUT: 5000,
                formDefinitionUrl: '',
                ...super.prefs()
            };
        }

        /**
         * @description Set aria-busy attribute value true
         * @returns {void}
         */
        showProgressBar() {
            this.ref('self').attr('aria-busy', 'true');
        }

        /**
         * @description Set aria-busy attribute value false
         * @returns {void}
         */
        hideProgressBar() {
            this.ref('self').attr('aria-busy', 'false');
        }

        /**
         * @description Get submit button name
         * @returns {string|null|undefined} Submit button name
         */
        getSubmitButtonName() {
            return this.submitButtonName;
        }

        /**
         * @description Initialize widget logic
         * @returns {void}
         */
        init() {
            this.submitButtonName = null;
            super.init();

            /**
             * @type {HTMLFormElement|any}
             */
            const form = this.ref('self').get();

            this.formId = form ? form.dataset.widget : '';
            this.formName = form ? form.name : '';

            /**
             * Below code is needed to initiate correct comparison configuration for certain fields
             * It iterates all BasicInput childs and if will find in validation compare data - will
             * sets up needed data on a proper child BasicInput widgets
            */
            const BasicInput = /** @type {BasicInput} */(this.getConstructor('basicInput'));

            const formName = this.formName;

            this.eachChild(widget => {
                if (widget instanceof BasicInput) {
                    // set form name for gtm data
                    widget.formName = formName;
                    const widgetValidation = widget.prefs().validationConfig || {};
                    const compareFieldId = widgetValidation.compareWith || '';
                    if (compareFieldId) {
                        this.getById(compareFieldId, targetWidget => {
                            if (targetWidget.length) {
                                const compareOptions = {
                                    field: compareFieldId,
                                    msg: widgetValidation.errors.compareWith || ''
                                };
                                widget.data('setMatchCmp', targetWidget, compareOptions);
                            }
                        });
                    }
                }
            });
        }

        /**
         * @description Save submit button
         * @param {HTMLInputElement} el submit button element
         * @returns {void}
         */
        saveSubmitButton(el) {
            this.submitButtonName = el.name;
        }

        /**
         * @description Submit Form
         * @returns {void}
         */
        submit() {
            /**
             * @type {HTMLElement|undefined}
             */
            var elem = this.ref(this.prefs().submitButton).get();

            if (elem) {
                elem.click();
            }
        }

        /**
         * @description Handle submit Form
         * @emits BasicForm#submit
         * @param {refElement} _el event source element
         * @param {(Event|undefined)} event event instance if DOM event
         * @returns {void}
         */
        handleSubmit(_el, event) {
            this.clearError();
            const valid = this.isChildrenValid();

            if ((!valid || this.submitting) && (event && event instanceof Event)) {
                event.preventDefault();
                return;
            }
            this.ref(this.prefs().submitButton).disable();

            this.submitting = true;

            this.onDestroy(timeout(() => {
                this.submitting = false;
            }, this.prefs().SUBMITTING_TIMEOUT));

            this.emit('submit');
        }

        /**
         * @description Get Form fiels
         * @returns {object} Object contains form fields
         */
        getFormFields() {
            /**
             * @type {{[x: string]: string}}
             */
            var fields = {};
            const BasicInput = /** @type {BasicInput} */(this.getConstructor('basicInput'));

            this.eachChild(widget => {
                if (widget instanceof BasicInput && !(widget.skipSubmission && widget.skipSubmission())) {
                    const name = widget.getName && widget.getName();

                    if (name) {
                        fields[name.toString()] = widget.getValue();
                    }
                }
            });
            return fields;
        }

        /**
         * @description Sets related fields values, can be executed silently, without triggering `change` event
         * @param {object} formFields - Structured object with name: value pairs for input fields
         * @param {(boolean|undefined)} [silently] - if set to `true` - input should not be validated against a new value
         * @returns {void}
         */
        setFormFields(formFields, silently = false) {
            const BasicInput = /** @type {BasicInput} */(this.getConstructor('basicInput'));

            this.eachChild(widget => {
                if (widget instanceof BasicInput) {
                    const name = widget.getName && widget.getName();
                    widget.setValue(formFields[name], silently);
                }
            });
        }

        /**
         * @description Check is Form fields valid
         * @param {Function} [cb] callback called if child inputs are valid
         * @returns {boolean} - boolean value is Form input valid
         */
        isChildrenValid(cb) {
            var valid = true;
            const BasicInput = /** @type {BasicInput} */(this.getConstructor('basicInput'));

            this.eachChild(item => {
                if (item instanceof BasicInput && typeof item.validate === 'function' && !item.validate()) {
                    if (valid && item.setFocus) {
                        item.setFocus();
                    }
                    valid = false;
                }
            });

            if (valid && typeof cb === 'function') {
                cb();
            }

            if (!this.prefs().submitEmpty) {
                const fieldsValues = this.getFormFields();

                if (Object.keys(fieldsValues).every((key) => !fieldsValues[key])) {
                    valid = false;

                    this.ref(this.prefs().errorMessageLabel)
                        .setText(this.prefs().emptyFormErrorMsg);

                    this.ref(this.prefs().errorMessageLabel).show();
                }
            }
            return valid;
        }

        /**
         * @description Form validate
         * @returns {boolean} boolean value is Form input valid
         */
        validate() {
            return this.isChildrenValid();
        }

        /**
         * @description Check is Form valid based on Form fields validation
         * @returns {boolean} boolean value is Form valid
         */
        isValid() {
            var valid = true;
            const BasicInput = /** @type {BasicInput} */(this.getConstructor('basicInput'));

            this.eachChild(itemCmp => {
                if (itemCmp instanceof BasicInput && typeof itemCmp.isValid === 'function' && !itemCmp.isValid()) {
                    valid = false;
                    return false;
                }
                return true;
            });
            return valid;
        }

        /**
         * @description Interface that can be override
         * @returns {void}
         */
        setFocus() {
        }

        /**
         * @description Get Form action url
         * @returns {string} form action url
         */
        getFormUrl() {
            // @ts-ignore
            return this.ref('self').attr('action');
        }

        /**
         * @description Clear Form Error
         * @param {string} refID RefElement ID
         * @returns {void}
         */
        clearError(refID = 'errorMessageLabel') {
            this.ref(refID)
                .hide()
                .setText('');
        }

        /**
         * @description Updates form html body by sending an AJAX request to server with params object
         * <br>(possible param key is `countryCode`)
         * <br>Obtained template injected instead of old fields
         * <br>Data, entered in fields previosly will be restored
         * @param {object} params - request parameters
         * @param {string} params.countryCode - A country code to get country-specific form
         * @returns {InstanceType <Promise>} - new Promise
         */
        updateFormData(params) {
            const formDefinitionUrl = this.prefs().formDefinitionUrl;

            if (formDefinitionUrl && params) {
                return new Promise((resolve) => {
                    getJSONByUrl(formDefinitionUrl, params, true).then((response) => {
                        if (response.formDefinition) {
                            const formFields = this.getFormFields();
                            this.render('', {}, this.ref('fieldset'), response.formDefinition).then(() => {
                                this.setFormFields(formFields, true);
                                resolve();
                                setTimeout(() => this.formDataUpdated(), 0);
                                /* eslint-disable */
                                var defaultWineclubShipAdd = document.querySelector("div[data-id='dwfrm_address_setAsWineClubDefaultShipping']");
                                if (defaultWineclubShipAdd) {
                                    if (params.countryCode !== 'US') {
                                        defaultWineclubShipAdd.classList.add('h-hidden');
                                    }
                                    else {
                                        defaultWineclubShipAdd.classList.remove('h-hidden');
                                    }
                                }
                                var defaultAddress = document.querySelector("div[data-id='dwfrm_address_setAsDefault']");
                                if (defaultAddress) {
                                    if (params.countryCode !== 'US') {
                                        defaultAddress.classList.add('h-hidden');
                                    }
                                    else {
                                        defaultAddress.classList.remove('h-hidden');
                                    }
                                }
                                /* eslint-enable */
                            });
                        }
                    });
                });
            }

            return new Promise((resolve) => {
                resolve();
                this.formDataUpdated();
            });
        }

        /**
         * @description Template method called once form definitions were reloaded
         * @returns {void}
         */
        formDataUpdated() {

        }
    }

    return BasicForm;
}
