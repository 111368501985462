/**
 * @module ajax
 * @category widgets
 * @subcategory toolbox
 */

// TODO: Add descriptions or full JSDoc for functions
import { showErrorLayout, appendParamsToUrl, timeout } from './util';
import eventBus from './eventBus';
// eslint-disable-next-line max-len
export const errorFallbackMessage = 'For technical reasons, your request could not be handled properly at this time. We apologize for any inconvenience.';
const SAME_ORIGIN = 'same-origin';
let tokenName = '';
let tokenValue = '';

/**
 * @param {string} name tokenName
 * @param {string} val tokenValue
 */
export function setCurrentToken(name, val) {
    tokenName = name;
    tokenValue = val;
}

function getFetch() {
    if (window.fetch) {
        return Promise.resolve(window.fetch);
    }
    // @ts-ignore
    return import(/* webpackChunkName: 'fetch' */ 'whatwg-fetch');// use polyfill
}

/**
 *
 * @param {string} method - HTTP method
 * @param {{[keys: string]: string;}} data - request data
 * @param {boolean} skipToken - Checks if CSRF token should be skipped in the request
 * @param {string} url - request url
 * @returns {{valuedUrl: string, formData: string|undefined}} result
 */
function handleUrlOrFormData(method, data, skipToken, url) {
    /**
     * @type {string|undefined}
     */
    let formData;
    /**
     * @type {string}undefined
     */
    var valuedUrl;
    if (method === 'POST') {
        const token = tokenName && !skipToken ? { [tokenName]: tokenValue } : {};
        const dataToSend = { ...data, ...token };
        formData = Object.keys(dataToSend).map(key => key + '=' + encodeURIComponent(dataToSend[key])).join('&');

        valuedUrl = url;
    } else if (skipToken) {
        valuedUrl = appendParamsToUrl(url, data);
    } else {
        valuedUrl = appendParamsToUrl(url, { ...data, ...{ [tokenName]: tokenValue } });
    }
    // parameter to identify ajax request
    valuedUrl = appendParamsToUrl(valuedUrl, { ajax: 'true' });

    return { valuedUrl, formData };
}

/**
 * @param {Response} response Response object
 * @returns {Promise<any>} A Promise for the completion of which ever callback is executed.
 */
function handleResponse500(response) {
    return response.text().then(textResponse => {
        // checks if response contains redirectUrl, does redirect and rejects promise.
        // In other case shows '500' alert on storefront and continues error handling
        if (textResponse.includes('"error":') && textResponse.includes('"redirectUrl":')) {
            const error = JSON.parse(textResponse);
            if (error && error.redirectUrl) {
                window.location.assign(error.redirectUrl);
                return Promise.reject(new Error());
            }
        // in some cases we must have possibility don't show global alert, according to FSD
        } else if (!textResponse.includes('"skipErrorGlobalAlert"')) {
            eventBus.emit('alert.error', {
                errorCode: 500
            });
        }
        if (textResponse.includes('"csrfError": true')) {
            const error = JSON.parse(textResponse);
            if (error) {
                if (error.csrfError && error.redirectUrl) {
                    window.location.assign(error.redirectUrl);
                } else {
                    showErrorLayout(error);
                }
            }
        } else if (textResponse.includes('"errorMessage":')) {
            return Promise.reject(new Error(JSON.parse(textResponse).errorMessage));
        } else {
            var div = document.createElement('div');
            div.innerHTML = textResponse;
            const err = Array.from(div.querySelectorAll('code')).map(code => code.innerHTML).join('<br/>');
            showErrorLayout(err);
        }
        return Promise.reject(new Error());
    });
}

/**
 * @description A function, called after each success ajax call. Emits an event with given response context.
 * @param {object|string} response Response formatted object
 * @returns {object|string} Response formatted object
 */
function handleOkResponse(response) {
    /**
     * @description Event about success ajax server response
     * @event module:ajax#responseok
     */
    timeout(() => eventBus.emit('responseok', response), 0);
    return response;
}

/**
 * @param {string} url url of resource
 * @param {{[x: string]: string}} [data] form content
 * @param {'POST'|'GET'} [method] typeof request
 * @param {boolean} [skipToken] skip token for request
 * @returns {Promise<object>} Fetching result promise
 */
export const submitFormJson = (url, data = {}, method = 'POST', skipToken = false) => {
    return getFetch().then(() => {
        var { valuedUrl, formData } = handleUrlOrFormData(method, data, skipToken, url);
        /**
         * This magic is mandatory for MS Edge because fetch polyfill is returning not polyfilled Promise object
         */
        if(valuedUrl.split('?')[0] !== 'null') {
        return Promise.resolve(fetch(valuedUrl, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: SAME_ORIGIN, // no-cors, cors, *same-origin
            cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: SAME_ORIGIN, // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Accept: 'application/json',
                'x-requested-with': 'XMLHttpRequest'
            },
            redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // no-referrer, *client
            body: formData // body data type must match "Content-Type" header
        })).then((response) => {
            var contentType = response.headers.get('content-type');

            if (response.ok) {
                if (contentType && contentType.includes('application/json')) {
                    return handleOkResponse(response.json());
                }
                showErrorLayout(errorFallbackMessage);
                throw new TypeError(errorFallbackMessage);
            } else if (response.status === 404) {
                showErrorLayout(errorFallbackMessage);
                throw new TypeError(errorFallbackMessage);
            } else if (response.status === 500) {
                return handleResponse500(response);
            }

            return response.json().then(errorJson => {
                return Promise.reject(errorJson);
            });
        });
    }
    });
};

/**
 * @param {string} url URL to get data
 * @param {{[keys: string]: string;}} [params] optional params to url
 * @returns {Promise<Response>} Fetching result promise
 */
export function getContentByUrl(url, params = {}) {
    params.ajax = 'true';
    /**
     * This magic is mandatory for MS Edge because fetch polyfill is returning not polyfilled Promise object
     */
    return Promise.resolve(fetch(appendParamsToUrl(url, params), {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: SAME_ORIGIN, // no-cors, cors, *same-origin
        cache: 'default', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: SAME_ORIGIN, // include, *same-origin, omit
        headers: {
            Accept: 'text/html'
            //     //   'Content-Type': 'application/json'
            //     'Content-Type': 'application/x-www-form-urlencoded'
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer' // no-referrer, *client
        // body: formData // body data type must match "Content-Type" header
    })).then((response) => {
        var contentType = response.headers.get('content-type');

        if (response.ok) {
            if (contentType && contentType.includes('text/html')) {
                return handleOkResponse(response.text());
            }

            if (response.status !== 204) {
                showErrorLayout(errorFallbackMessage);
                throw new TypeError(errorFallbackMessage);
            }
        } else if (response.status === 404) {
            showErrorLayout(errorFallbackMessage);
            throw new TypeError(errorFallbackMessage);
        } else if (response.status === 500) {
            return handleResponse500(response);
        }

        return response.json().then(errorJson => {
            return Promise.reject(errorJson);
        });
    });
}

/**
 *
 * @param {string} url URL to get data
 * @param {{[keys: string]: string;}} [params] optional params to url
 * @param {boolean} [skipToken] skip token for request
 * @returns {Promise<object>} Fetching result promise
 */
export function getJSONByUrl(url, params = {}, skipToken = true) {
    return submitFormJson(url, params, 'GET', skipToken);
}

/**
 * Writes the error to the backend error logs by sending it via AJAX to the specified URL.
 *
 * @param {Object} error - The error object to be sent to the backend.
 * @returns {void}
 */
export function writeErrorInBackendErrorLogs(error) {
    var errorActionUrl = $('.m-registration').data('error-action'); // Get the data-error-action attribute value
    $.ajax({
        url: errorActionUrl,
        type: 'POST',
        data: {
          error: JSON.stringify(error)
        },
        success: function(response) {
          // Handle the success response here if needed
        },
        error: function(xhr, status, error) {
          console.log('Error sending error message:', error);
        }
    });
}
