// @ts-ignore
import { RefElement } from 'widgets/toolbox/RefElement';

/**
 * @typedef {ReturnType<typeof import('widgets/forms/BasicInput').default>} BasicInput
 * @typedef {InstanceType <typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */

/**
 * @description Base InputSelect implementation
 * @param {BasicInput} BasicInput Base widget for extending
 * @returns {typeof InputSelect} Input Select class
 */
export default function (BasicInput) {
    /**
     * @category widgets
     * @subcategory forms
     * @class InputSelect
     * @augments BasicInput
     * @classdesc Select input implementation. Represents input `password` element together with widget-related HTML markup.
     * HTML structure assembled on backend and injected into resulted html markup by using `formElement` component
     * and dynamic forms configuration JSON.
     * @property {string} data-widget - Widget name `inputSelect`
     * @example <caption>InputSelect definition in dynamicforms.json</caption>
     * ...
     * // fields -> select
     * select: {
     *     'element.type': 'select',
     *     validation: {
     *         'patterns.security': 'validation.patterns.security',
     *         'errors.security': 'validation.errors.parse'
     *     }
     * },
     * ...
     * // fields -> generic -> country
     * country: {
     *     widget: {
     *         attributes: {
     *             'data-widget-event-change': 'onCountryChange'
     *         },
     *         classes: 'm-small'
     *     },
     *     extends: 'fields.select',
     *     'label.text': 'form.address.country',
     *     element: {
     *         required: true,
     *             attributes: {
     *                 'data-event-change': 'onChange'
     *             }
     *         }
     *     },
     * }
     * ...
     * @example <caption>Insertion of InputSelect inside ISML templates</caption>
     * <isset name="formElement" value="${require('forms/formElement')}" scope="page"/>
     * ...
     * <form>
     *     ...
     *     <isprint value="${
     *         formElement(pdict.addressForm.country, pdict.addressFormOptions).render()
     *     }" encoding="off"/>
     *     ...
     * </form>
     * @example <caption>Resulted HTML structure for InputSelect</caption>
     * <div data-widget="inputSelect" data-first-default="true"
     *     data-widget-event-change="updateShippingState" class="b-form_section m-required m-small m-invalid"
     *     data-id="dwfrm_shipping_shippingAddress_addressFields_states_stateCode"
     *     data-validation-config="... validation config"
     * >
     *     <label class="b-form_section-label" for="dwfrm_shipping_shippingAddress_addressFields_states_stateCode">
     *         <span class="b-form_section-required" aria-hidden="true">*</span>
     *         State
     *     </label>
     *     <div class="b-select">
     *         <select data-ref="field" class="b-select-input m-invalid"
     *             id="dwfrm_shipping_shippingAddress_addressFields_states_stateCode"
     *             name="dwfrm_shipping_shippingAddress_addressFields_states_stateCode"
     *             required="" aria-required="true" data-event-change="onChange"
     *             aria-describedby="dwfrm_shipping_shippingAddress_addressFields_states_stateCode-error"
     *             data-event-blur="validate"
     *         >
     *             <option value="" data-id="0">Please select</option>
     *             ...
     *         </select>
     *         <svg aria-hidden="true" class="b-select-icon" width="10" height="6" focusable="false">
     *             <use href="#arrow-down-small"></use>
     *         </svg>
     *     </div>
     *     <div role="alert" class="b-form_section-message" data-ref="errorFeedback" id="dwfrm_shipping_shippingAddress_addressFields_states_stateCode-error">This field is required.</div>
     * </div>
     */
    class InputSelect extends BasicInput {
        init() {
            super.init();

            const field = /** @type {HTMLSelectElement|undefined} */(this.ref('field').get());
            // Reset value when for some reason no option preselected, even first (default)
            if (field && !field.value) {
                const option = Array.from(field.options).find(elem => elem.hasAttribute('selected'));
                let val = option && option.value;

                if (!val) {
                    val = this.getValue();
                }

                this.setValue(val, true);
            }

            if (this.config.firstDefault && field) {
                this.initValue = field.options[0].value;
            }
        }

        /**
         * @description InputSelect on change handler
         * @listens dom#change
         * @param {RefElement} el source of event
         * @param {(Event|undefined)} event event instance if DOM event
         * @returns {void}
         */
        onChange(el, event) {
            if (!this.config.dontPrevent && event && event instanceof Event) {
                event.preventDefault();
            }

            this.update();
        }

        /**
         * @description Sets new value on select from selected option
         * @param {string} newVal new value
         * @param {boolean} silently validate after change
         * @returns {void}
         */
        setValue(newVal, silently) {
            let val = newVal || this.initValue || '';

            const field = /** @type {HTMLSelectElement|undefined} */(this.ref('field').get());

            if (field) {
                const option = Array.from(field.options).find(elem => elem.value === val);

                if (!option) {
                    val = this.initValue || '';
                }
            }

            super.setValue(val, silently);
        }

        /**
         * @description Get value from selected option
         * @returns {string} selected option value
         */
        getValue() {
            const selectedOption = this.getSelectedOptions();
            if (selectedOption) {
                if (selectedOption.length) {
                    return /** @type {string} */(selectedOption.val());
                }
                const field = /** @type {HTMLSelectElement|undefined} */(this.ref('field').get());

                if (field && field.options.length) {
                    const item = field.options.item(0);
                    if (item) {
                        return item.value;
                    }
                }
            }
            return '';
        }

        /**
         * @description Get selected options for select
         * @returns {RefElement|undefined} selected options
         */
        getSelectedOptions() {
            const field = /** @type {HTMLSelectElement|undefined} */(this.ref('field').get());

            if (field && field.selectedOptions) {
                return new RefElement(Array.from(field.selectedOptions));
            }
            return undefined;
        }

        /**
         * @description Get selected option text
         * @returns {string} selected option text
         */
        getText() {
            const field = this.ref('field').get();

            if (field) {
                const selectedNode = Object.values(field.childNodes)
                    .find(node => /** @type {HTMLOptionElement} */(node).selected);

                if (selectedNode) {
                    return selectedNode.textContent || '';
                }
                return '';
            }
            return '';
        }

        /**
         * @description Locks select input (add locked classes, set read-only, disabled)
         * @returns {void}
         */
        lock() {
            if (!this.locked) {
                super.lock();
                this.ref('field').attr('disabled', true);
            }
        }

        /**
         * @description Unlocks select input (remove locked classes, remove read-only, disabled)
         * @returns {void}
         */
        unlock() {
            if (this.locked) {
                super.unlock();
                this.ref('field').attr('disabled', false);
            }
        }

        changeAttribute() {} // eslint-disable-line

        /**
         * @description Method to stop propagation in case if input events should not be interrupted.
         * For example, when there is a listener on a parent widget that is triggered by the same keydown event and it should not fire, etc.
         * @param {HTMLElement} _ - source of keydown event
         * @param {Event} event - keydown event object
         * @returns {void}
         */
        stopImmediatePropagation(_, event) {
            if (event) { event.stopImmediatePropagation(); }
        }
    }

    return InputSelect;
}
