import { getContentByUrl, getJSONByUrl } from 'widgets/toolbox/ajax';

/**
 * @typedef {ReturnType<typeof import('widgets/global/Modal').default>} Modal
 * @typedef {ReturnType<typeof import('widgets/global/EmitBusEvent').default>} EmitBusEvent
 */

/**
 * @description Base GlobalModal implementation
 * @param {Modal} Modal Base widget for extending
 * @returns {typeof GlobalModal} GlobalModal class
 */
export default function (Modal) {
    /**
     * @category widgets
     * @subcategory global
     * @class GlobalModal
     * @augments Modal
     * @classdesc Global Modal component. Allows different modals to be triggered by firing an event {@link module#dialog:dialogshow}.
     * <br>Was implemented to get rid from excessive duplicated popups markup in product lists etc.
     * <br>Also allow standalone components/link to trigger modal by using {@link EmitBusEvent} widget.
     * <br>In general could be triggered either programmatically by firing an event, or using {@link EmitBusEvent} widget in DOM markup.
     * <br>Allows: add classes to container; render modal heading; render modal action buttons; render content either via URL, or from string.
     * @property {string} data-widget - Widget name `globalModal`
     * @property {string} data-disable-rendering - property should be set to `false` to allow rendering inside popup template.
     * @example <caption>Global Modal widget markup</caption>
     * <div
     *     data-widget="globalModal"
     *     data-disable-rendering="false"
     * >
     *     <div class="b-dialog" data-ref="container" hidden></div>
     *     <script type="template/mustache" data-ref="template">
     *         <div
     *             class="b-dialog {{${'#'}wrapperClasses}}{{wrapperClasses}}{{/wrapperClasses}}"
     *             {{${'#'}ariaLabel}}
     *             aria-label="{{ariaLabel}}"
     *             {{/ariaLabel}}
     *             data-ref="container"
     *         >
     *             <div
     *                 class="b-dialog-window"
     *                 role="dialog"
     *                 data-ref="dialog"
     *                 aria-modal="true"
     *             >
     *                 <div class="b-dialog-header">
     *                     ... header rendering
     *                 </div>
     *                 <div class="b-dialog-body b-user_content">
     *                     {{& content }}
     *                 </div>
     *                 {{${'#'}showActions}}
     *                 <div class="b-dialog-footer m-actions">
     *                     ... action buttons rendering
     *                 </div>
     *                 {{/showActions}}
     *             </div>
     *         </div>
     *     </script>
     * </div>
     *
     * @example <caption>Global Modal widget triggering via DOM</caption>
     * <button
     *     class="b-promotion-details"
     *     type="button"
     *     data-widget="emitBusEvent"
     *     data-bus-event-type="dialogshow"
     *     data-event-click.prevent="emitBusEvent"
     *     data-tau="promotion_details_cta"
     *     data-modal-config='{
     *         "content": "${promotion.detailsJSONSafe}",
     *         "attributes": {
     *             "data-tau": "promotion_details_dialog"
     *         },
     *         "ariaLabel": "${Resource.msg('promotion.details.label','product',null)}"
     *     }'
     * >
     *     ${Resource.msg('promotion.details','product',null)}
     * </button>
     *
     * @example <caption>Global Modal widget triggering via code in {@link ProductTile} widget (synchronous operation)</caption>
     * ...
     * showQuickView(button) {
     *     this.eventBus().emit('dialogshow', {
     *         url: button.data('url'),
     *         wrapperClasses: 'm-quick_view',
     *         ariaLabel: this.prefs().productName,
     *         attributes: {
     *             'data-tau-unique': 'quick_view_dialog'
     *         }
     *     });
     *     this.onModalShow();
     * }
     * ...
     */
    class GlobalModal extends Modal {
        /**
         * @description Widget initialisation logic
         * @listens module:events#dialogshow
         * @returns {void}
         */
        init() {
            super.init();
            this.eventBus().on('dialogshow', 'showModal');
        }

        /**
         * @description Shows modal. Depending on needs, content for modal could be loaded via URL, or fetched from parameters.
         * Input parameter also contains setup for modal, like container classes, action buttons, heading text etc.
         * @example <caption>An object with expected parameters</caption>
         * {
         *      showActions: true,
         *      headerText: 'Header text',
         *      actions: [{
         *          text: 'Cancel',
         *          handler: 'cancel',
         *          classes: 'm-outline'
         *      },{
         *          text: 'Confirm',
         *          handler: 'cancel',
         *          lastFocusElement: true
         *      }],
         *      wrapperClasses: 'class1 class2',
         *      ariaLabel: 'test aria label',
         *      url: 'http://www.google.com',
         *      contentType: 'json'
         * }
         * In case if input parameter is an instance of {@link EmitBusEvent},
         * modal setup properties will be taken from `data-modal-config`
         * attribute of a {@link EmitBusEvent} widget.
         * @emits GlobalModal#show
         * @param {object|InstanceType<EmitBusEvent>} templateData Input object for modal popup. See example above.
         * @returns {void}
         */
        showModal(templateData) {
            let modalData = templateData;

            // @ts-ignore
            const EmitBusEventClass = /** @type {EmitBusEventClass} */(this.getConstructor('emitBusEvent'));
            if (templateData instanceof EmitBusEventClass) {
                modalData = {
                    url: templateData.data('url')
                };
                const modalConfig = templateData.data('modalConfig');
                if (modalConfig && Object.keys(modalConfig).length) {
                    modalData = Object.assign(modalData, modalConfig);
                }
            }

            if (modalData.content) {
                super.showModal(modalData);
                /**
                 * @description Event dispatched, when Global Modal was shown
                 * @event GlobalModal#show
                 */
                this.emit('show');
                return;
            }

            if (!modalData.url) {
                return;
            }

            if (modalData.contentType === 'json') {
                getJSONByUrl(modalData.url, undefined, true).then(res => {
                    if (Object.keys(res).length) {
                        modalData = Object.assign(modalData, res);
                        super.showModal(modalData);
                        this.emit('show');
                    }
                });
            } else {
                getContentByUrl(modalData.url).then(res => {
                    modalData = Object.assign(modalData, { content: res });
                    super.showModal(modalData);
                    this.emit('show');
                });
            }
        }
    }

    return GlobalModal;
}
