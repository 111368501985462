export default function (AjaxForm) {
    return class VerificationCodeForm extends AjaxForm {
        init() {
            super.init();

            this.defineItems();

            this.state = {};

            /**
             * -- cognito.hook.resendconfirmemail.catch --
             * When cognito resent thrown an exception, for example limit of request was reached.
             */
            this.eventBus().on('cognito.hook.resendconfirmemail.catch', 'onError');

            /**
             * -- cognito.hook.confirmsignup.catch --
             * When any exception was thrown by cognito "verify account"
             * For example: Invalid verification code was provided.
             */
            this.eventBus().on('cognito.hook.confirmsignup.catch', 'onError');

            /**
             * -- harmonyloginform.signin.confirmation.exception --
             * When cognito sign-in thrown an exception that customer not confirmed.
             */
            this.eventBus().on('harmonyloginform.signin.confirmation.exception', 'showLoginState');

            /**
             * -- registrationform.submitted --
             * When registration form was submitted.
             */
            this.eventBus().on('registrationform.submitted', 'showRegistrationState');

            /**
             * -- harmonytab.change --
             * When tab view was change.
             */
            this.eventBus().on('harmonytab.change', 'hide');

            /**
             * -- confirmation.create.account --
             * When customer creates account via order confirmation page.
             */
            this.eventBus().on('confirmation.create.account', 'showConfirmationCreateAccountState');

            /**
             * -- cognito.hook.signin.silent--
             * When we need to hide verification code form widget
             */
            this.eventBus().on('cognito.hook.signin.silent', 'onSilentSignIn');
        }

        prefs() {
            return {
                ...super.prefs(),
                submitButton: 'verifyButton'
            };
        }

        /**
         * @description Initializes default widget instance state and view state.
         * @param {object} data
         * @param {string} data.email - customer email which needs to be verified and will be used for sign-in.
         * @param {string} data.password - customer password for sign-in it after verification.
         * @returns {void}
         */
        initState(data) {
            this.state = {};

            // init state with the given customer
            this.state.username = data.email;
            this.state.password = data.password;

            // This flag used from order confirmation page to
            // not login customer and not redirect to any page.
            this.state.silent = !!data.silent;

            // clear component error messages
            this.clearError();

            // clear from previous input
            this.getById('dwfrm_login_verificationCode', (input) => {
                input.setValue('', true);
                input.clearError();
            });

            // hide all block which are hidden by default
            this.ref('backButton').hide();
            this.ref('registrationInfo').hide();
        }

        /**
         * @description Emits when customer creates account at order confirmation page.
         * @param {object} data
         * @param {string} data.email - customer email which needs to be verified and will be used for sign-in.
         * @param {string} data.password - customer password for sign-in it after verification.
         * @returns {void}
         */
        showConfirmationCreateAccountState(data) {
            this.initState(data);
            this.ref('confirmationCreateAccountInfo').show();
            this.show();
        }

        /**
         * @description Emits when verification code was triggered from login state.
         * For confirm customer account if it has NOT CONFIRMED status.
         * @param {object} data
         * @param {string} data.email - customer email which needs to be verified and will be used for sign-in.
         * @param {string} data.password - customer password for sign-in it after verification.
         * @returns {void}
         */
        showLoginState(data) {
            this.initState(data);
            this.ref(this.prefs().errorMessageLabel).setText(data.message).show();
            this.eventBus().emit('verificationcodeform.show', 'login');
            this.show();
        }

        /**
         * @param data
         */
        showRegistrationState(data) {
            this.initState(data);
            this.ref('registrationInfo').show();
            this.eventBus().emit('verificationcodeform.show', 'register');
            this.show();
        }

        /**
         * @description Emites when "Verify account" CTA was triggered.
         * @returns {void}
         */
        handleSubmit() {
            const fields = this.getFormFields();

            if (!this.isChildrenValid() || this.submitting) {
                return;
            }

            this.beforeSubmission();
            this.eventBus().emit('cognito.hook.confirmsignup', {
                code: fields.dwfrm_login_verificationCode,
                username: this.state.username,
                password: this.state.password,
                silent: this.state.silent
            });
        }

        /**
         * @description Hide verification form and show login form back.
         * @returns {void}
         */
        backToLogin() {
            this.hide();
            this.eventBus().emit('verificationcodeform.backtologin');
        }

        /**
         * @description Resend button was clicked.
         * @returns {void}
         */
        handleResend() {
            var accessibilityAlert = this.prefs().accessibilityAlerts.confirmationCodeSent;

            if (this.submitting) {
                return;
            }

            this.eventBus().emit('cognito.hook.resendconfirmemail', {
                username: this.state.username
            }, accessibilityAlert);
        }

        /**
         * @description Action which needs to be done before submit handleSubmit function.
         * @returns {void}
         */
        beforeSubmission() {
            this.getById(this.prefs().submitButton, submitButton => submitButton.busy());
            this.clearError();
            this.submitting = true;
            this.eventBus().emit('loader.start', new Promise((resolve) => {
                this.resolve = resolve;
            }));
        }

        /**
         * @description Handles an error, which happens during request (for ex. 500 response)
         * @param {object} error
         */
        onError(error) {
            super.onError(error);
            this.ref('backButton').show();
            this.afterSubmission();
        }

        /**
         * @description when form needs to be just hidden without any other actions.
         */
        onSilentSignIn() {
            this.hide();
            this.afterSubmission();
        }

        /**
         * @description when submitting has been done.
         */
        afterSubmission() {
            super.afterSubmission();
            this.resolveLoader();
        }

        /**
         * @description resolve loader promise for hide overlay.
         * @returns {void}
         */
        resolveLoader() {
            if (typeof this.resolve === 'function') {
                this.resolve();
            }
        }

        show() {
            super.show();
            this.setFocusToFirstItem();
        }
    };
}
