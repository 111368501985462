import { setCookie, getCookie } from 'widgets/toolbox/cookie';

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description Dismiss block implementation. Value is stored in cookies.
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof CookiesDismissBlock} Cookie Dismiss Block class
 */
export default function (Widget) {
    /**
     * @class CookiesDismissBlock
     * @augments Widget
     * @classdesc Promo header widget, which could contain dynamic markup. Could be dismissed.
     * @property {string} data-widget - Widget name `CookiesDismissBlock`
     * @property {string} data-key - Key for document.cookie to store header banner display status.
     * If value exists - header will not be shown.
     * @example
     * // use this code to display widget
     * <div
     *     data-widget="CookiesDismissBlock"
     *     data-key="hide_header_banner"
     * >
     *     ... widget markup
     *     <button data-event-click.once.prevent="dismiss">close</button>
     * </div>
     */
    class CookiesDismissBlock extends Widget {
        /**
         * @description Preferences
         * @returns {object} Preferences object
         */
        prefs() {
            return {
                classesEnabled: 'm-visible',
                key: 'hide_header_top_promoblock',
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         */
        init() {
            super.init();

            // get cookie value
            const CookiesDismissBlockStatus = getCookie(this.prefs().key);
            // check cookie value and set banner class
            if (!CookiesDismissBlockStatus || CookiesDismissBlockStatus !== '1') {
                this.ref('self').addClass(this.prefs().classesEnabled);
                this.eventBus().emit('cookiedismissblock.updated');
            }
        }

        /**
         * Dismiss block
         */
        dismiss() {
            // remove visibility class from element
            this.ref('self').removeClass(this.prefs().classesEnabled);
            // set cookie
            setCookie(this.prefs().key, '1', 0);
            // emit event for other widgets
            this.eventBus().emit('cookiedismissblock.updated');
        }
    }

    return CookiesDismissBlock;
}
