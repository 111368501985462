import { submitFormJson } from 'widgets/toolbox/ajax';

/**
 * @typedef {ReturnType<typeof import('widgets/forms/ajax/LoginForm').default>} LoginForm
 */

/**
 * @description Base LoginForm implementation
 * @param {LoginForm} LoginForm Base widget for extending
 * @returns {LoginForm} Harmony Login Form class
 */

export default function (LoginForm) {
    /**
     * @class LoginForm
     * @augments LoginForm
     * @property {string} data-widget - Widget name `harmonyLoginForm`
     * @property {string} data-event-submit - Event listener for form submission
    */
    class HarmonyLoginForm extends LoginForm {
        prefs() {
            return {
                ...super.prefs(),
                errorMessageLabel: 'errorMessageLabel',
                submitButton: 'submitLoginButton',
                scope: 'login'
            };
        }

        init() {
            super.init();

            this.defineItems(this);
            if ($('#event-enquiry-form').length) {
                this.setEventEnquiryForm();
            }
            /**
             * -- cognito.hook.signin.then --
             * When cognito sign-in process has been successfully done.
             */
            this.eventBus().on('cognito.hook.signin.then', 'signInSubmitted');

            /**
             * -- cognito.hook.signin.catch --
             * When cognito sign-in process has been failed.
             */
            this.eventBus().on('cognito.hook.signin.catch', 'signInCatch');

            /**
             * -- verificationcodeform.backtologin --
             * When "Back to login" (on login form) was triggered.
             */
            this.eventBus().on('verificationcodeform.backtologin', 'show');

            /**
             * -- harmonytab.change --
             * When Login/Registration tabs has been changed.
             */
            this.eventBus().on('harmonytab.change', 'initViewState');

            /**
             * When subsciprion status was synced with Cognito.
             */
            this.eventBus().on('sync.newsletter.subscription.then', 'onSyncedSubscription');
        }

        /**
         * @param panel
         * @description Emits when tab was changed to prepare default state view.
         * @returns {void}
         */
        initViewState(panel) {
            if (panel === 'login') {
                this.clearError();
                this.show();
            }
        }

        /**
         * Handle when social login buttons was clicked.
         * Emitted from form in ISML button attribute.
         *
         * @param {object} target
         */
        socialLogin(target) {
            var socialNetwork = target.config.socialNetwork;
            var isWCSignUp = '';
            var isCheckout = !!this.prefs().cartUrl;

            if (document.getElementById('WcSignupData')) {
                isWCSignUp = document.getElementById('WcSignupData').getAttribute('data-wc');
            }

            if (this.submitting) {
                return;
            }

            this.beforeSubmission();

            if (!socialNetwork) {
                return;
            }

            this.eventBus().emit('cognito.hook.sociallogin', { socialNetwork, isCheckout, isWCSignUp});
        }

        /**
         * @description Called when form was submitted for emit Amazon Cognito
         * sign in call.
         *
         * @returns {boolean} false - to transfer control to the Cognito component.
         */
        handleSubmit() {
            const fields = this.getFormFields();

            if (!this.isChildrenValid() || this.submitting) {
                return false;
            }
            this.beforeSubmission();
            this.eventBus().emit('cognito.hook.signin', {
                username: ( this.prefs().disableCaseSensitive ) ? fields.dwfrm_login_email.toLowerCase() : fields.dwfrm_login_email,
                password: fields.dwfrm_login_password,
                scope: this.prefs().scope
            });

            return false;
        }

        handleEventEnquirySubmit() {
            const fields = this.getFormFields();
            var underAgeStatus = document.getElementById('dwfrm_eventEnquiry_underAge');
            if(!underAgeStatus.checked){
                document.getElementById('dwfrm_eventEnquiry_underAgeExplanation').removeAttribute('required');
            } else {
                document.getElementById('dwfrm_eventEnquiry_underAgeExplanation').setAttribute('required',true);
            }
            if (!this.isChildrenValid()) {
                return false;
            }
            if(!underAgeStatus.checked){
                fields['dwfrm_eventEnquiry_underAgeExplanation'] = '';
            }
            var alternateDate = document.getElementById('alternate-date');
            if(fields['dwfrm_eventEnquiry_alternateDate'] === fields['dwfrm_eventEnquiry_preferredDate']){
                alternateDate.children[0].children[1].textContent = 'Alternate Date cannot be the same as the Preferred Date';
                alternateDate.children[0].children[1].setAttribute('style', 'display:block !important');
                window.scrollTo(0,800);
                return false;
            } else {
                alternateDate.setAttribute('style', 'display:none');
            }

            this.beforeSubmission();
            this.showProgressBar();
            const promise = submitFormJson(
                document.getElementById('event-form').action,
                fields,
                'POST'
            ).then((response) => {
                document.getElementById('event-enquiry-form').setAttribute('style', 'display:none');
                document.getElementById('event-enquiry-form-sent').style.display = 'block';
                const customerData = {
                    ...this.getFormFields(),
                    ...this.config.customerOrderData
                };
                const isSubscribed = customerData.dwfrm_eventEnquiry_newsletterUpdate === 'true';

                if (response.success) {
                    document.getElementById('successMessage').textContent = response.successMessage;
                    window.scrollTo(0,0);
                    if (isSubscribed) {
                        this.eventBus().emit('gtm.subscribe.success');
                    }
                    var gtmData = {
                        "event" : "form-submit",
                        "formName" : "event-enquiry-form",
                        "formAction" :"Submit",
                        "formStatus" : "Success",
                        "fieldEngagement" : Object.keys(fields).join(' > ')
                    };

                    dataLayer.push(gtmData);
                } else {
                    document.getElementById('successMessage').textContent = response.errorMessage;
                    var gtmData = {
                        event: "error",
                        errorMessage: response.errorMessage
                    };
                    dataLayer.push(gtmData);
                }


            }).finally(() => {
                this.afterSubmission();
            });

        }

        setEventEnquiryForm() {
            var currentDate = new Date();
            var month;
            if(currentDate.getMonth()<10){
                month = '0'+parseInt(currentDate.getMonth()+1);
            } else {
                month = parseInt(currentDate.getMonth()+1);
            }
            var dateInCalenderFormat = currentDate.getFullYear()+'-'+ month +'-'+currentDate.getDate();
            document.getElementById('dwfrm_eventEnquiry_preferredDate').setAttribute('min',dateInCalenderFormat);
            document.getElementById('dwfrm_eventEnquiry_alternateDate').setAttribute('min',dateInCalenderFormat);
            var underAgeStatus = document.getElementById('dwfrm_eventEnquiry_underAge');
            var underAgeExplanation = document.getElementById('underAgeExplanation');
            underAgeExplanation.style.display = 'none';
            underAgeStatus.addEventListener('change',function(){
                if(underAgeStatus.checked) {
                    underAgeExplanation.style.display = 'block';
                } else {
                    underAgeExplanation.style.display = 'none';
                }
            })
        }

        /**
         * @description This method will be called after cognito.hook.-sign-in finished.
         * "Cognito" component will call hook cognito.hook.sign-in.finished.
         *
         * @param {object} data - object with data from Amazon Cognito.
         * @param {string} data.userSub - external id on Amazon Cognito side.
         *
         * @returns {void}
        */
        signInSubmitted(data) {
            submitFormJson(
                this.getFormUrl(),
                {
                    // attributes from cognito like: firstname, lastname, email, sub, phone
                    ...data.attributes,

                    // pass "remember me" key/value
                    ...this.getFormFields()
                },
                'POST'
            )
                .then((response) => {
                    // TODO:HARMONY:BE Complete check for social login on Phase2
                    // Email, Google or Facebook should be for loginMethod
                    this.eventBus().emit('gtm.user.login', {
                        loginMethod: 'Email',
                        commerceKey: data.attributes.sub,
                        sfPersonAccountId: response.sfPersonAccountId
                    });
                    this.onSubmitted(response);
                })
                .catch(this.onError.bind(this));
        }

        /**
         * @param response
         * @description when form has been submitted.
         */
        onSubmitted(response) {
            this.eventBus().emit('cognito.hook.syncsubscription', response);
        }

        /**
         * @param response
         * @description when subscription has been synced with cognito.
         */
        onSyncedSubscription(response) {
            super.onSubmitted(response);
            this.afterSubmission();
        }

        /**
         * @description Handle sign in errors.
         *
         * @param {object} error
         * @param {boolean} error.confirmException - value which indicates that it was
         * confirm exception. In other cases it is an error with auth, and we need just
         * show an error. In case it is confirmation error (customer while login got an error
         * that email not confirmed).
         */
        signInCatch(error) {
            const fields = this.getFormFields();

            this.afterSubmission();

            if (error.confirmException) {
                this.hide();
                this.eventBus().emit('harmonyloginform.signin.confirmation.exception', {
                    ...error,
                    email: fields.dwfrm_login_email,
                    password: fields.dwfrm_login_password
                });
                $('.wc-login-form').removeClass('h-hidden');
                $('.mr-register-form').addClass('h-hidden');
                $('.mr-login-form #dwfrm_login_verificationCode').attr("aria-label","Code");
                this.eventBus().emit('cognito.hook.resendconfirmemail', {
                    username: fields.dwfrm_login_email
                });
                return;
            }

            this.onError(error);
        }

        /**
         * @description when submitting has been done.
         */
        afterSubmission() {
            super.afterSubmission();
            this.resolveLoader();
        }

        /**
         * @description Action which needs to be done before submit handleSubmit function.
         */
        beforeSubmission() {
            this.getById(this.prefs().submitButton, submitButton => submitButton.busy());
            this.clearError();
            this.submitting = true;
            this.eventBus().emit('loader.start', new Promise((resolve) => {
                this.resolve = resolve;
            }));
        }

        /**
         * @description resolve loader promise for hide overlay.
         * @returns {void}
         */
        resolveLoader() {
            if (typeof this.resolve === 'function') {
                this.resolve();
            }
        }

        /**
         * @description Handles an error, which happens during request (for ex. 500 response)
         * @param {Error} e - error, which happend during request
         * @returns {void}
         */
        onError(e) {
            super.onError(e);
            this.afterSubmission();
        }

        /**
         * @description show widget and make focus on a proper field.
         * @returns {void}
         */
        show() {
            super.show();
            this.setFocusToCurrentItem();
        }
    }

    return HarmonyLoginForm;
}
