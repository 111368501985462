import { PausableTimeout } from 'widgets/toolbox/util';

/**
 * @description HarmonyGlobalAlerts
 * @param {typeof import('widgets/global/HarmonyGlobalAlerts').default} HarmonyGlobalAlerts widget.
 * Is used for displaying global alerts
 * @returns {typeof HarmonyGlobalAlerts} HarmonyGlobalAlerts class
 */

export default function (GlobalAlerts) {
    class HarmonyGlobalAlerts extends GlobalAlerts {
        prefs() {
            return {
                errorClass: 'm-error',
                ...super.prefs()
            };
        }

        init() {
            super.init();
        }

        /**
         * @description Show Global Alert by starting pausable timeout
         * @param {{accessibilityAlert: string}} data - Message to be shown and pronounced
         */
        showAlert(data) {
            if (data && data.accessibilityAlert) {
                if (this.shown) {
                    this.hideAlert();
                }

                // handle error alerts
                if (data && data.errorClass) {
                    this.ref('item').addClass(this.prefs().errorClass);
                    this.eventBus().emit('gtm.error.occurred', data);
                } else {
                    this.ref('item').removeClass(this.prefs().errorClass);
                }

                this.ref('item')
                    .setText(data.accessibilityAlert)
                    .show();
                this.shown = true;

                this.pausableTimeout = new PausableTimeout(() => {
                    this.hideAlert();
                }, this.prefs().timeout);
            }
        }
    }
    return HarmonyGlobalAlerts;
}
