// TO BE REVIEWED
// TODO: should be implemented as https://www.w3.org/TR/wai-aria-practices/#spinbutton
import { timeout } from 'widgets/toolbox/util';

/**
 * @typedef {ReturnType<typeof import('widgets/forms/BasicInput').default>} BasicInput
 */

/**
 * @description Base InputNumber implementation
 * @param {BasicInput} BasicInput Base widget for extending
 * @returns {typeof InputNumber} Input Number class
 */
export default function (BasicInput) {
    /**
     * @category widgets
     * @subcategory forms
     * @class InputNumber
     * @augments BasicInput
     * @classdesc Widget used as a quantity selector for product with +/- buttons functionality.
     * - Uses `minValue` and `maxValue` properties to restrict allowed input range.
     * - Handles keyboard input, allows step +/- configuration.
     * @property {string} data-widget - Widget name `inputNumber`
     * @example <caption>Example HTML structure for InputNumber</caption>
     * <div class="quantity-form"
     *     data-widget="inputNumber"
     *     data-widget-event-change="updateQty"
     *     data-uuid="${lineItem.UUID}"
     *     data-pid="${lineItem.id}"
     *     data-min-value="${lineItem.quantityOptions.minOrderQuantity + ''}"
     *     data-max-value="${lineItem.quantityOptions.maxOrderQuantity + ''}"
     *     data-action="${pdict.actionUrls.updateQuantityUrl}"
     *     data-skip-validation="true"
     *     data-pre-select-qty="${lineItem.quantity + ''}"
     *     data-name="${lineItem.productName}"
     *     data-analytics="${JSON.stringify(lineItem.gtmInfo)}"
     *     data-remove-action="${pdict.actionUrls.removeProductLineItemUrl}"
     *     data-inform-attempt-decrease-min-val="true"
     *     data-widget-event-attemptdecreaseminval="removeProduct"
     *>
     *    <label class="line-item-pricing-info quantity-label" for="quantity-${lineItem.UUID}">
     *        ${Resource.msg('field.selectquantity','cart',null)}
     *    </label>
     *    <div class="quantity-form-inner">
     *        <button
     *            data-ref="lessBtn"
     *            data-event-click="decrement"
     *            class="quantity-form-inner__less"
     *        >
     *            <span class="is-sr-only">${Resource.msg('label.decrease.quantity','cart',null)}</span>-
     *        </button>
     *
     *        <input class="form-control quantity custom-select"
     *            data-ref="field"
     *            type="number"
     *            pattern="^\d?\d$"
     *            data-event-change="changeValue"
     *            id="quantity-${lineItem.UUID}"
     *            name="quantity-${lineItem.UUID}"
     *            value="${lineItem.quantity + ''}"
     *            min="${lineItem.quantityOptions.minOrderQuantity + ''}"
     *            max="${lineItem.quantityOptions.maxOrderQuantity + ''}"
     *            step="${lineItem.qtyStep + ''}"
     *            data-value="${lineItem.quantity + ''}"
     *        />
     *
     *        <button
     *            data-ref="moreBtn"
     *            data-event-click="increment"
     *            class="quantity-form-inner__more"
     *        >
     *            <span class="is-sr-only">${Resource.msg('label.increase.quantity','cart',null)}</span>+
     *        </button>
     *     </div>
     *     <div class="invalid-feedback is-hidden" data-ref="errorFeedback" hidden="hidden"></div>
     * </div>
     */
    class InputNumber extends BasicInput {
        prefs() {
            return {
                maxValue: 99,
                minValue: 0,
                allowEmpty: false,
                informAttemptDecreaseMinVal: false,
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.maxLengthAttr = parseInt(this.ref('field').attr('maxlength').toString(), 10);
            this.step = parseFloat(this.ref('field').attr('step').toString()) || 1;

            this.ref('field').attr('maxlength', this.prefs().maxValue.toString().length.toFixed());

            this.initValue = this.getValue();
        }

        /**
         * @description Handler for `keydown` event on element
         * @param {HTMLInputElement} el - element, which triggers an event
         * @param {KeyboardEvent} incomingEvent - event object
         * @listens dom#keydown
         * @returns {void}
         */
        handleKeyDown(el, incomingEvent) {
            let event = incomingEvent;
            if (!event && window.event) {
                // @ts-ignore
                event = window.event;
            }
            const keyCode = event.keyCode || event.which;

            // special handling for old devices
            if (this.maxLengthAttr && (el.value.toString().length + 1) >= this.maxLengthAttr) {
                this.onDestroy(timeout(() => {
                    this.setValue(this.getValue(), true);
                }, 0));
            } else if (keyCode === 13) {
                // Enter pressed
                this.changeValue();
                event.preventDefault();
            }
        }

        /**
         * @description Tries to change value of input. In case if new value is behind specific number input constrains - returns previous or default value.
         * @emits InputNumber#attemptdecreaseminval
         * @listens dom#change
         * @returns {void}
         */
        changeValue() {
            if (Number(this.getValue()) < this.prefs().minValue && this.prefs().informAttemptDecreaseMinVal) {
                this.onDestroy(timeout(() => {
                    /**
                     * @description Event, indicates that attempt to set number input value less than specified in constraints
                     * @event InputNumber#attemptdecreaseminval
                     */
                    this.emit('attemptdecreaseminval');
                    this.setValue(String(this.prevVal || this.initValue), true);
                }, 100));
            } else {
                this.setValue(this.getValue());
                this.validate();
            }
        }

        /**
         * @description Decrements value in number input. In case if new value is behind specific number input constrains - emits `attemptdecreaseminval` and skips value.
         * @emits InputNumber#attemptdecreaseminval
         * @returns {void}
         */
        decrement() {
            if (!this.isDisabled()) {
                const newVal = parseFloat(this.getValue()) - (this.step || 0);

                if (newVal >= this.prefs().minValue) {
                    this.setValue(newVal.toString());
                } else if (this.prefs().informAttemptDecreaseMinVal) {
                    this.emit('attemptdecreaseminval');
                }
            }
        }

        /**
         * @description Increments value in number input. In case if new value is behind specific number input constrains - emits `quantityNotAllowed` and skips value.
         * @emits InputNumber#quantityNotAllowed
         * @returns {void}
         */
        increment() {
            if (!this.isDisabled()) {
                const newVal = parseFloat(this.getValue()) + (this.step || 0);

                if (newVal <= this.prefs().maxValue) {
                    this.setValue(newVal.toString());
                } else {
                    /**
                     * @description Event, indicates that attempt to set number input value not valid as per constraints.
                     * @event InputNumber#quantityNotAllowed
                     */
                    this.emit('quantityNotAllowed');
                }
            }
        }

        /**
         * @description Sets value for number input. Checks it on constraints first.
         * Manages input buttons less/more.
         * @param {string} val - set this value to input
         * @param {boolean} silent - if set to `true` - input should not be validated against a new value
         * @returns {void}
         */
        setValue(val, silent = false) {
            if (val === '' && this.prefs().allowEmpty) {
                super.setValue('', silent);
            } else {
                const floatValue = typeof val !== 'number' ? parseFloat(val) : val;

                if (floatValue >= this.prefs().minValue && floatValue <= this.prefs().maxValue) {
                    super.setValue(floatValue.toString(), silent);
                    this.prevVal = floatValue;
                } else if (floatValue > this.prefs().maxValue) {
                    super.setValue(String(this.prefs().maxValue), silent);
                } else if (floatValue < this.prefs().minValue || Number.isNaN(floatValue)) {
                    super.setValue(String(this.prevVal || this.prefs().minValue), silent);
                } else {
                    super.setValue('', silent);
                }
            }

            this.ref('moreBtn').enable();
            this.ref('lessBtn').enable();

            const value = parseFloat(this.getValue());
            if (this.prefs().minValue > value - (this.step || 0)) {
                if (!this.prefs().informAttemptDecreaseMinVal) {
                    this.ref('lessBtn').disable();
                }
            } else if (this.prefs().maxValue < value + (this.step || 0)) {
                this.ref('moreBtn').disable();
            }
        }

        /**
         * @description Executes after widget was refreshed
         * @returns {void}
         */
        onRefresh() {
            this.setValue(this.ref('field').attr('value').toString(), true);
            this.ref('field').attr('maxlength', this.prefs().maxValue.toString().length.toFixed());
        }

        /**
         * @description Implements number input specific logic to indicate if field value is valid.
         * @returns {boolean} - input validation result
         */
        isValid() {
            const isSuperValid = super.isValid();

            if (isSuperValid) {
                const val = parseFloat(this.getValue());

                if (val < this.prefs().minValue || val > this.prefs().maxValue) {
                    const validation = this.prefs().validationConfig;

                    this.error = validation.errors.range || validation.errors.parse;
                    return false;
                }
            }
            return isSuperValid;
        }

        /**
         * @description Initiates field validation process. Manages validation error messages on input.
         * @returns {boolean} - input validation result
         */
        validate() {
            if (this.disabled) {
                return true;
            }
            const isValid = this.isValid();
            if (isValid) {
                this.clearError();
            } else {
                this.setError(this.error);
            }
            return isValid;
        }
    }

    return InputNumber;
}
