/**
 * @typedef {ReturnType<typeof import('widgets/global/Modal').default>} Modal
 */

/**
 * @description Harmony modal implementation
 * @param {typeof import('widgets/global/Modal').default} MOdal Base widget for extending
 * @returns {typeof HarmonyModal}  HarmonyModal class
 */

export default function (Modal) {
    /**
     * @class HarmonyModal
     * @augments Modal
     */
    class HarmonyModal extends Modal {
        addGlobalDialogClass() {
            this.disableScroll();
        }

        closeModal() {
            super.closeModal();

            this.enableScroll();
        }

        disableScroll() {
            this.body = document.body;
            this.scrollTop = window.scrollY;

            if (!this.body.classList.contains(this.prefs().classesGlobalDialog)) {
                this.body.classList.add(this.prefs().classesGlobalDialog);
            }

            this.body.dataset.scrollTop = this.scrollTop;
            this.body.style.top = `-${this.scrollTop}px`;
            document.documentElement.scrollTop = 0;
        }

        enableScroll() {
            if (this.body) {
                this.body.classList.remove(this.prefs().classesGlobalDialog);
                this.body.style.removeProperty('top');
                this.body.removeAttribute('data-scroll-top');
                window.scrollTo({
                    top: this.scrollTop,
                    behavior: 'instant'
                });
                this.scrollTop = 0;
            }
        }
    }

    return HarmonyModal;
}
