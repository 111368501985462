/**
 * @typedef {typeof import('widgets/global/MenuPanel').default} MenuPanel
 */

/**
 * @description Base MenuPanel extension
 * @param {ReturnType<typeof import('widgets/global/MenuPanel').default>} MenuPanel Base widget for extending
 * @returns {typeof HarmonyMenuPanel} HarmonyMenuPanel class
 */
export default function (MenuPanel) {
    /**
     * @class HarmonyMenuPanel
     * @augments MenuPanel
     * @classdesc HarmonyMenuPanel widget, that contains menu for touch/mobile devices.
     * Extends Base MenuPanel. For more details see global/MenuPanel widget.
     */
    class HarmonyMenuPanel extends MenuPanel {
        /**
         * @description Initialize widget logic
         */
        init() {
            super.init();

            // cache header element to manipulate with classes
            this.header = document.getElementsByClassName('b-header')[0];

            // cache html element
            this.html = this.ref('html');

            this.eventBus().on('product.added.to.wishlist', 'fillWishlistIcon');
            this.eventBus().on('wishlist.empty', 'cleanWishlistIcon');
        }

        /**
         * @description Preferences
         * @returns {object} Preferences object
         */
        prefs() {
            return {
                classesHeaderHamburgerOpen: 'm-hamburger_opened',
                classesMenuItemActive: 'm-active',
                ...super.prefs()
            };
        }

        /**
         * @description Open Menu Panel
         */
        openPanel() {
            // set additional classes
            this.header.classList.add(this.prefs().classesHeaderHamburgerOpen);
            this.html.addClass(this.prefs().classesHasDialog);

            this.setFocusToFirstItem();

            this.backFocusElement = /** @type {HTMLElement} */(document.activeElement);

            // set state
            this.isOpened = true;

            this.eventBus().emit('header.set.active.state');
        }

        /**
         * @description Close Menu Panel
         */
        closePanel() {
            if (this.isOpened) {
                // remove classes
                this.header.classList.remove(this.prefs().classesHeaderHamburgerOpen);
                this.html.removeClass(this.prefs().classesHasDialog);

                // emit events for other widgets
                this.eventBus().emit('nav.panel.close');
                this.eventBus().emit('nav.panel.toggle.focus');

                if (this.backFocusElement) {
                    this.backFocusElement.focus();
                    this.backFocusElement = null;
                }

                // set state
                this.isOpened = false;

                this.eventBus().emit('header.remove.active.state');
            }
        }

        fillWishlistIcon() {
            this.has('wishlistIcon', /** @param {RefElement} item */ (item) => {
                const activeClass = item.data('activeClass');
                item.addClass(activeClass);
            });
        }

        cleanWishlistIcon() {
            this.has('wishlistIcon', /** @param {RefElement} item */ (item) => {
                const activeClass = item.data('activeClass');
                item.removeClass(activeClass);
            });
        }
    }

    return HarmonyMenuPanel;
}
