import { clickOutside } from 'widgets/toolbox/util';
import { timeout } from 'widgets/toolbox/util';

const ESCAPE_CODE = 27;

/**
 * @description SideBar implementation
 * @param {ReturnType<typeof import('widgets/global/AccessibilityFocusTrapMixin').default>} AccessibilityFocusTrapMixin mixin
 * @returns {typeof SideBar} - SideBar instance
 */
export default function (AccessibilityFocusTrapMixin) {
    /**
     * @class SideBar
     * @classdesc Use it to show side bar.
     * @property {string} data-ref - identifier to the specific bar, used by emitter to show proper sidebar
     * @example
     * // use this code to display widget
     * <div
     *     data-widget="emitBusEvent"
     *     data-event-click.prevent="emitBusEvent"
     *     data-ref-name="myAccountSideBar"
     *     data-bus-event-type="sidebar.open"
     * >
     *     ...Toggler
     * </div>
     *
     * <div
     *     data-widget="sideBar"
     *     data-ref="myAccountSideBar"
     * >
     *     <button data-event-click.prevent="close">Close</button>
     *     Side bar content
     * </div>
     */
    class SideBar extends AccessibilityFocusTrapMixin {
        constructor(...arg) {
            super(...arg);
            this.isOpened = false;
        }

        prefs() {
            return {
                hidden: true,
                classesOpen: 'm-opened',
                classesBodyOpen: 'm-panel_opened',
                ...super.prefs(),
                refTrapsContainer: 'container'
            };
        }

        init() {
            this.isOpened = this.ref('self').hasClass(this.prefs().classesOpen);

            this.eventBus().on('sidebar.open', 'open');
            this.eventBus().on('sidebar.close', 'close');
        }

        /**
         * @param {typeof import('widgets/global/EmitBusEvent').default} target
         */
        open(target) {
            const refName = target.config.refName;
            if (this.has(refName) && !this.isOpened) {
                this.show();
                this.ref('self').addClass(this.prefs().classesOpen);
                document.body.classList.add(this.prefs().classesBodyOpen);
                this.isOpened = true;

                this.clearFocusTimer = timeout(() => this.focusFirstElement(), this.prefs().focusTimeout);

                // close panel on esc click
                this.escHandler = this.ev('keyup', (_, event) => {
                    if (event.keyCode === ESCAPE_CODE) {
                        this.close();
                    }
                }, window).pop();

                // close pane on outside click
                this.outSideClickListener = clickOutside(this.ref('container'), this.close.bind(this));
                if (this.outSideClickListener) {
                    this.onDestroy(this.outSideClickListener);
                }
            }
        }

        /**
         * @description Clean Up Listeners
         * @returns {void}
         */
        cleanUpListeners() {
            if (this.escHandler) {
                this.escHandler();
                this.escHandler = null;
            }
        }

        close(context) {
            if (
                (typeof context === 'string' && !this.has(context))
                || !this.isOpened
            ) {
                return;
            }

            if (this.clearFocusTimer) {
                this.clearFocusTimer();
            }

            this.ref('self').removeClass(this.prefs().classesOpen);
            document.body.classList.remove(this.prefs().classesBodyOpen);
            this.isOpened = false;
            // remove outside click listener
            if (this.outSideClickListener) {
                this.outSideClickListener();
                this.outSideClickListener = undefined;
            }

            // Move focus back
            if (this.backFocusElement) {
                this.backFocusElement.focus();
                this.backFocusElement = null;
            }

            this.cleanUpListeners();
        }
    }

    return SideBar;
}
