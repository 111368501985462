import { isLargeViewAndUp } from 'widgets/toolbox/viewtype';
/**
 * @typedef {typeof import('widgets/global/AccordionItem').default} AccordionItem
 */

/**
 * @description Base AccordionItem extension
 * @param {ReturnType<typeof import('widgets/global/AccordionItem').default>} AccordionItem Base widget for extending
 * @returns {typeof HarmonyAccordionItem} HarmonyAccordionItem class
 */
export default function (AccordionItem) {
    /**
     * @class HarmonyAccordionItem
     * @augments AccordionItem
     * @classdesc HarmonyAccordionItem widget, that adds extra functionality(classes toggle, etc).
     * Extends Base AccordionItem. For more details see global/MenuPanel widget.
     */
    class HarmonyAccordionItem extends AccordionItem {
        /**
         * @description Returns Widget configuration object
         * @returns {object} Widget configuration object
         */
        prefs() {
            return {
                classesOpen: 'm-opened',
                ...super.prefs()
            };
        }

        init() {
            super.init();
            var itemInnerElement = this.ref('accordionItemPanelInner').get();
            this.initialHeight = itemInnerElement.offsetHeight;
            const horizontalPanel = $('.b-refinements_accordion-horizontal_item').attr('data-horizontal');
            if (isLargeViewAndUp()) {
                if (!horizontalPanel) {
                    this.ref('accordionItemPanel').get().style.height = this.initialHeight + 'px';
                }
            }
        }

        /**
         * @description Define attributes
         * @param {boolean} [isOpen] Panel open flag
         * @returns {void}
         */
        defineAttributes(isOpen) {
            super.defineAttributes(isOpen);

            const accordionItemBtn = this.ref('accordionItemBtn');
            const accordionItemPanel = this.ref('accordionItemPanel');
            const accordionItemPanelInner = this.ref('accordionItemPanelInner');
            if($("#payment-details-CREDIT_CARD").hasClass('b-payment_option-content')){
                accordionItemPanel.attr('role', 'form');
            }
            if (isOpen) {
                accordionItemBtn.addClass(this.prefs().classesOpen);
                accordionItemPanel.addClass(this.prefs().classesOpen);
                accordionItemPanelInner.addClass(this.prefs().classesOpen);
            }
        }

        /**
         * @description Open panel
         * @returns {void}
         */
        openPanel() {
            super.openPanel();

            this.has(this.prefs().accordionItemBtn, (accordionItemBtn) => {
                accordionItemBtn.addClass(this.prefs().classesOpen);
            });

            this.has(this.prefs().accordionItemPanel, (accordionItemPanel) => {
                accordionItemPanel.addClass(this.prefs().classesOpen);
            });

            this.has(this.prefs().accordionItemPanelInner, (accordionItemPanelInner) => {
                accordionItemPanelInner.addClass(this.prefs().classesOpen);
            });
        }

        /**
         * @description Restores panel height, when user clicks on 'View more' and then close, open panel
         * @param {object} [accordionItemPanel] One of the panels for which we need to restore height
         * @returns {void}
         */

        resetHeight(viewMore, height) {
            this.ref('accordionItemPanel').get().style.height = height + 'px';
        }

        togglePanel(ele, event) {
            let horizontalPanel = $('.b-refinements_accordion-horizontal_item').attr('data-horizontal');
            if(horizontalPanel) {
                var elementClassList = event.target && event.target.classList;
                var filtersClassList = ['b-refinement_list-checkbox', 'b-refinement_checkbox',
                                        'b-refinement_navigation-link', 'f-input_radio',
                                        'b-refinement_list-label' ]
                if(elementClassList ? (filtersClassList.filter(value => elementClassList.contains(value)).length === 0) : true) {
                    super.togglePanel();
                }
            } else {
                super.togglePanel();
            }
            if (this.isPanelOpen) {
                this.getById('viewMore', (viewMoreButton) => {
                    viewMoreButton.reinitViewMore();
                });
                if(!horizontalPanel) {
                    this.ref('accordionItemPanel').get().style.height = this.initialHeight + 'px';
                }
            }
            this.eventBus().emit('accordion.item.toggled', this);
        }

        /**
         * @description Close panel
         * @returns {void}
         */
        closePanel() {
            super.closePanel();

            this.has(this.prefs().accordionItemBtn, (accordionItemBtn) => {
                accordionItemBtn.removeClass(this.prefs().classesOpen);
            });

            this.has(this.prefs().accordionItemPanel, (accordionItemPanel) => {
                accordionItemPanel.removeClass(this.prefs().classesOpen);
            });

            this.has(this.prefs().accordionItemPanelInner, (accordionItemPanelInner) => {
                accordionItemPanelInner.removeClass(this.prefs().classesOpen);
            });
        }

        closeAccordianOnBlur(ele, event) {
            var elementClassList = event.relatedTarget && event.relatedTarget.classList;
            var filtersClassList = ['b-refinement_list-checkbox', 'b-refinement_checkbox',
                                    'b-refinement_navigation-link', 'f-input_radio',
                                    'b-refinement_list-label']
            const shouldHidePanel = elementClassList ?
                (filtersClassList.filter(value => elementClassList.contains(value)).length === 0) : true;
            if(shouldHidePanel) {
                super.blur();
                this.closePanel();
            }
        }
    }

    return HarmonyAccordionItem;
}
