import { submitFormJson } from 'widgets/toolbox/ajax';

/**
 * @typedef {ReturnType<typeof import('widgets/forms/AjaxForm').default>} AjaxForm
 */

/**
 * @description Base HarmonyPasswordResetForm implementation
 * @param {AjaxForm} AjaxForm Base widget for extending
 * @returns {typeof AjaxForm} Password reset form
 */
export default function (AjaxForm) {
    /**
     * @class HarmonyPasswordResetForm
     * @augments AjaxForm
     */

    class HarmonyPasswordResetForm extends AjaxForm {
        init() {
            super.init();
            this.eventBus().on('cognito.hook.forgotpassword.then', 'forgotPasswordThen');
            this.eventBus().on('cognito.hook.forgotpassword.catch', 'onError');
            this.eventBus().on('cognito.hook.forgotpassword.confirmationcodesubmit.then', 'onConfirmSubmitted');
            this.eventBus().on('cognito.hook.forgotpassword.confirmationcodesubmit.catch', 'onError');
            this.eventBus().on('cognito.hook.signin.then', 'onSignInSubmitted');
        }

        /**
         * Modal window on login page, forgot password CTA.
         *
         * @returns {boolean}
         */
        handleSubmit() {
            if (!this.isChildrenValid()) {
                return false;
            }

            const fields = this.getFormFields();

            this.eventBus().emit('cognito.hook.forgotpassword', {
                username: ( this.prefs().disableCaseSensitive ) ? fields.dwfrm_profile_resetPassword_email.toLowerCase() : fields.dwfrm_profile_resetPassword_email,
                scope: this.prefs().scope
            });

            return false;
        }

        /**
         * Success forgot password response from Amazon Cognito.
         * For modal window we need to submit form.
         *
         * For "Set New Password" page it is "Resend" CTA was called and we
         * do not need submit any form. Do nothing.
         *
         * @param {object} data
         * @param {string} data.scope - who has submitted a hook.
         */
        forgotPasswordThen(data) {
            if (data.scope && data.scope === 'modalwindow') {
                this.ref('resetPassword').hide();
                this.ref('sentCode').show();
                this.focusFirst('sentCode');
            }
        }

        superHandleSubmit() {
            super.handleSubmit();
        }

        /**
         * Handle reset password form. It is "Set New Password" page.
         * Customer need pass userName, code (verification code from email), newPassword
         *
         * Cognito API will be called to finish password change submit.
         *
         * @returns {boolean}
         */
        handlePassword() {
            if (!this.isChildrenValid()) {
                return false;
            }

            const fields = this.getFormFields();

            this.eventBus().emit('cognito.hook.forgotpassword.confirmationcodesubmit', {
                userName: ( this.prefs().disableCaseSensitive ) ? fields.dwfrm_profile_resetPassword_email.toLowerCase() : fields.dwfrm_profile_resetPassword_email,
                code: fields.dwfrm_profile_login_newpasswords_verificationCode,
                newPassword: fields.dwfrm_profile_login_newpasswords_newpassword
            });

            return false;
        }

        /**
         * When change password was submitted we need to sign-in customer.
         * This function will redirect customer to the login page.
         */
        onConfirmSubmitted() {
            const container = this.ref('container');
            this.has('confirmation', function (item) {
                item.show();
                container.remove();
            });
        }

        /**
         * After customer was signed-in at Cognito. We need to sign-in it
         * on SFCC side.
         *
         * @param {object} data response data from amazon cognito api call.
         * @param {string} data.scope scope who was submitted sign-in flow. those
         * component must response for it as we may have few components on a page
         * which subscribed to the emitter.
         */
        onSignInSubmitted(data) {
            if (data.scope === 'resetpassword') {
                const promise = submitFormJson(
                    this.prefs().login,
                    data.attributes,
                    'POST'
                )
                    .then(this.onSubmitted.bind(this))
                    .catch(this.onError.bind(this))
                    .finally(this.afterSubmission.bind(this));

                this.eventBus().emit('loader.start', promise);
            }
        }

        /**
         * Resend verification code to the customer email.
         * It is "Resend" button on a form.
         */
        handleResend() {
            const fields = this.getFormFields();

            this.eventBus().emit('cognito.hook.forgotpassword', {
                username: fields.dwfrm_profile_resetPassword_email
            });
        }
    }

    return HarmonyPasswordResetForm;
}
