import { timeout } from 'widgets/toolbox/util';
import { isDesktopView } from 'widgets/toolbox/viewtype';

const ARIA_EXPANDED = 'aria-expanded';
const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32,
    TAB: 9,
    ESC: 27,
    DOWN: 40,
    UP: 38,
    RIGHT: 39,
    PAGEUP: 33,
    PAGEDOWN: 34,
    END: 35,
    HOME: 36
});

/**
 * @typedef {InstanceType <typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 * @typedef {ReturnType<typeof import('widgets/global/SwipeToClose').default>} SwipeToClose
 * @typedef {ReturnType<typeof import('widgets/header/MenuPanelToggle').default>} MenuPanelToggle
 * @typedef {ReturnType<typeof import('widgets/header/HamburgerMenuItem').default>} HamburgerMenuItem
 */

/**
 * @param {SwipeToClose} SwipeToClose Base widget for extending
 * @returns {typeof MenuPanel} MenuPanel widget
 */
export default function (SwipeToClose) {
    /**
     * @category widgets
     * @subcategory header
     * @class MenuPanel
     * @augments SwipeToClose
     * @classdesc Represents MenuPanel component with next features:
     * 1. Support keyboard navigation for accessibility
     * 2. Allow handling mouse, keyboard events
     * 3. Allow switch panel (next/previous)
     * 4. Allow open/close panel
     * 5. Allow close panel on Backdrop click
     * 6. Allow focus manipulation
     * 7. Control hamburger menu subpanels, copy and pasting submenu content into this panels
     *
     * MenuPanel widget should inside {@link HamburgerMenuItem} like menu item.
     * Widget has next relationship:
     * * Open panel using method {@link MenuPanel#openPanel} by event {@link MenuPanelToggle#event:navPanelOpen} from {@link MenuPanelToggle} widget.
     * * Open next panel using method {@link MenuPanel#nextPanel} by event {@link HamburgerMenuItem#event:navPanelNextLevel} from {@link HamburgerMenuItem} widget.
     * * Open next panel using method {@link MenuPanel#previousPanel} by event {@link HamburgerMenuItem#event:navPanelPreviousLevel} from {@link HamburgerMenuItem} widget.
     *
     * @example <caption>Example of MenuPanel widget usage</caption>
     * <div
     *    id="main-navigation"
     *    class="b-menu_panel"
     *    aria-labelledby="main-navigation-toggle"
     *    data-widget="menuPanel"
     *    data-event-click="closeOnBackdrop"
     *    data-event-keydown.sm.md="handleKeydown"
     * >
     *     <div class="b-menu_panel-head">
     *         <button data-event-click.prevent="closePanel">close</button>
     *     </div>
     *     ... widget content
     * </div>
     * @property {string} data-widget - Widget name `menuPanel`
     * @property {string} data-event-click - Event listener handler for click events
     * @property {string} data-event-keydown - Event listener handler for keydown events
     * @property {number} data-panel-items-count - count of items
     * @property {number} data-panel-count - count of panels
     * @property {string} data-panel-container - reference id of child element, value of its data-ref attribute
     * @property {string} data-classes-backdrop-active - class added/removed on 'self' element on openPanel/closePanel
     * @property {string} data-classes-dialog-open - class added/removed on ref element 'dialog' on openPanel/closePanel
     * @property {string} data-classes-has-dialog - class added/removed on 'html' element on openPanel/closePanel
     * @property {string} data-classes-active-level - prefix for class that is added/removed from panelContainer when navigating between panels
     * @property {string} data-panel-items-prefix - prefix of ID of panel items
     * @property {string} data-main-manu - id of menu element
     * @property {string} data-panel - prefix of ID of panels
     * @property {string} data-item-switch-timeout - timeout (in ms) between panels focus changes
     */
    class MenuPanel extends SwipeToClose {
        /**
         * @description calls super constructor, adds initial states etc.
         *
         * @param {HTMLElement} el DOM element
         * @param {{[x: string]: object|string|number|boolean|null|undefined}} config widget config
         */
        constructor(el, config = {}) {
            super(el, config);
            this.parentMenuItems = [];
            this.panelItems = [];
            this.currentLevel = 1;
        }

        prefs() {
            return {
                classesBackdropActive: 'm-active',
                classesDialogOpen: 'm-opened',
                classesDialogClosed: 'm-closed',
                classesHasDialog: 'm-has_dialog',
                classesActiveLevel: 'm-active_level_',
                panelItemsCount: 0,
                panelCount: 3,
                panelItemsPrefix: 'panelItem-',
                mainMenu: 'main-menu',
                mainMenuFooter: 'menu-footer',
                panelTitle: 'panelTitleLevel',
                panel: 'panel-level-',
                itemSwitchTimeout: 500,
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         * @listens MenuPanelToggle#navPanelOpen
         * @listens HamburgerMenuItem#navPanelNextLevel
         * @listens HamburgerMenuItem#navPanelPreviousLevel
         * @returns {void}
         */
        init() {
            super.init();
            this.definePanelItems();
            this.checkAriaAttributes();
            this.eventBus().on('viewtype.change', 'checkAriaAttributes');
            this.eventBus().on('viewtype.change', 'closePanel');
            this.eventBus().on('nav.panel.open', 'openPanel');
            this.eventBus().on('nav.panel.next.level', 'nextPanel');
            this.eventBus().on('nav.panel.previous.level', 'previousPanel');
            this.applyMenuRole(true);

            this.onDestroy(() => {
                this.clearState();
                this.applyMenuRole(false);
            });
        }

        /**
         * @description Clear menu panel state
         * @returns {void}
         */
        clearState() {
            this.ref('html').removeClass(this.prefs().classesHasDialog);
            this.ref('self').removeClass(this.prefs().classesBackdropActive);
            this.ref('dialog').removeClass(this.prefs().classesDialogOpen);
            this.ref('dialog').removeClass(this.prefs().classesDialogClosed);
        }

        /**
         * @description Toggle menu roles
         * @param {boolean} isMenu Menu type
         * @returns {void}
         */
        applyMenuRole(isMenu) {
            this.eachChild(child => {
                if (child.id && child.id === 'main-menu') {
                    child.ref('self').attr('role', 'menubar');
                }
            });
        }

        /**
         * @description Define Panel Items
         * @returns {void}
         */
        definePanelItems() {
            this.panelItems = this.getPanelItems();
            this.currentPanelItem = this.panelItems[0];
            this.firstPanelItem = this.panelItems[0];
            this.lastPanelItem = this.panelItems[this.panelItems.length - 1];
        }

        /**
         * @description Check Aria attributes and updated it for custom menu items depend on viewport.
         * <br> Custom Menu has sub item on Large and XLarge viewports but doesn't have on Small and Medium, as per FSD.
         * To correctly support accessibility requirements aria attributes dynamically updated.
         * @returns {void}
         */
        checkAriaAttributes() {
            this.panelItems
                .map(menuItem => menuItem.ref('itemLink'))
                .filter(itemLink => itemLink.data('isCustomMenuHtml'))
                .forEach(itemLink => {
                    if (isDesktopView()) {
                        itemLink.attr(ARIA_EXPANDED, 'true');
                        itemLink.attr('aria-haspopup', 'true');
                    } else {
                        itemLink.attr(ARIA_EXPANDED, 'false');
                        itemLink.attr('aria-haspopup', 'false');
                    }
                });
        }

        /**
         * @description Open next panel level
         * @param {object} data data
         * @returns {void}
         */
        nextPanel(data) {
            data.parentMenuItem.attr(ARIA_EXPANDED, 'true');
            data.parentMenuItem.attr('aria-owns', 'submenu-level-' + (this.currentLevel + 1));
            this.parentMenuItems.push(data.parentMenuItem);

            this.handlePanel(this.currentLevel + 1, true, data.parentMenuItem.getText(), data.htmlMarkup);
        }

        /**
         * @description Open previos panel level
         * @returns {void}
         */
        previousPanel() {
            if (this.parentMenuItems.length) {
                const last = this.parentMenuItems.pop();
                last.attr('aria-owns', null);
                last.attr(ARIA_EXPANDED, 'false');
            }

            this.handlePanel(this.currentLevel - 1, true);
        }

        /**
         * @description Handle panel level
         * @param {number} level Level
         * @param {boolean} focusPanelItem Focus panel item flag
         * @param {string} [panelName] Panel Name
         * @param {string} [htmlMarkup] HTML Markup
         * @returns {void}
         */
        handlePanel(level, focusPanelItem, panelName, htmlMarkup) {
            if (panelName) {
                this.has(this.prefs().panelTitle + level, (panelTitle) => {
                    panelTitle.setText(panelName);
                });
            }

            if (htmlMarkup) {
                this.getById(this.prefs().panel + level, (panel) => {
                    panel.ref('self').attr('aria-busy', 'true');
                    panel.setSubmenuHTML(htmlMarkup);
                });
            }

            if (level === 1) {
                this.ref('self').attr('aria-busy', 'true');
            }

            this.has(this.prefs().panelContainer, (panelContainer) => {
                panelContainer.removeClass(this.prefs().classesActiveLevel + this.currentLevel);
                panelContainer.addClass(this.prefs().classesActiveLevel + level);
            });

            if (focusPanelItem) {
                this.setFocusToPanelItem(level, level > this.currentLevel);
            }

            this.currentLevel = level;
        }

        /**
         * @description Set focus to panel item
         * @param {number} level Level
         * @param {boolean} updateItems Update item flag
         * @returns {void}
         */
        setFocusToPanelItem(level, updateItems) {
            this.onDestroy(timeout(() => {
                if (level === 1) {
                    this.setFocusToItem(this.currentPanelItem);
                    this.ref('self').attr('aria-busy', 'false');
                } else {
                    this.getById(this.prefs().panel + level, (panel) => {
                        panel.markSubmenuOpened();

                        if (updateItems) {
                            panel.defineItems();
                        }

                        panel.setFocusToCurrentItem();
                        panel.ref('self').attr('aria-busy', 'false');
                    });
                }
            }, this.prefs().itemSwitchTimeout));
        }

        /**
         * @description Set focus to first item
         * @returns {void}
         */
        setFocusToFirstItem() {
            this.ref('self').attr('aria-busy', 'true');

            this.onDestroy(timeout(() => {
                this.setFocusToItem(this.firstPanelItem);
                this.ref('self').attr('aria-busy', 'false');
            }, this.prefs().itemSwitchTimeout));
        }

        /**
         * @description Set focus to last item
         * @returns {void}
         */
        setFocusToLastItem() {
            this.ref('self').attr('aria-busy', 'true');

            this.onDestroy(timeout(() => {
                this.setFocusToItem(this.lastPanelItem);
                this.ref('self').attr('aria-busy', 'false');
            }, this.prefs().itemSwitchTimeout));
        }

        /**
         * @description Set focus to next item
         * @returns {void}
         */
        setFocusToNextItem() {
            if (this.panelItems === undefined) { return; }

            if (this.panelItems.length) {
                const newItem = this.currentPanelItem === this.lastPanelItem
                    ? this.firstPanelItem
                    : this.panelItems[this.getItemIndex(this.currentPanelItem) + 1];

                this.setFocusToItem(newItem);
            }
        }

        /**
         * @description Set focus to previos item
         * @returns {void}
         */
        setFocusToPreviosItem() {
            if (this.panelItems === undefined) { return; }

            if (this.panelItems.length) {
                const newItem = this.currentPanelItem === this.firstPanelItem
                    ? this.lastPanelItem
                    : this.panelItems[this.getItemIndex(this.currentPanelItem) - 1];

                this.setFocusToItem(newItem);
            }
        }

        /**
         * @description Set focus to item
         * @param {object} panelItem Panel item
         * @returns {void}
         */
        setFocusToItem(panelItem) {
            panelItem.focus();
            this.currentPanelItem = panelItem;
        }

        /**
         * @description Get item index
         * @param {object} panelItem panel item
         * @returns {number} Item index
         */
        getItemIndex(panelItem) {
            return this.panelItems ? this.panelItems.indexOf(panelItem) : -1;
        }

        /**
         * @description Get Panel Items
         * @returns {Array} Panel items
         */
        getPanelItems() {
            const panelItems = [];

            this.getById(this.prefs().mainMenu, (item) => {
                panelItems.push(...item.items);
            });

            this.getById(this.prefs().mainMenuFooter, (item) => {
                panelItems.push(...item.items);
            });

            for (let i = 0; i < this.prefs().panelItemsCount; i++) {
                this.getById(this.prefs().panelItemsPrefix + i, (item) => {
                    panelItems.push(item);
                });
            }

            return panelItems;
        }

        /**
         * @description Open Menu Panel
         * @param {object} data data
         * @returns {void}
         */
        openPanel(data) {
            this.ref('html').addClass(this.prefs().classesHasDialog);
            this.ref('self').addClass(this.prefs().classesBackdropActive);
            this.ref('dialog')
                .addClass(this.prefs().classesDialogOpen)
                .removeClass(this.prefs().classesDialogClosed);

            this.ref('self').attr('data-tau', 'menu_panel');

            this.definePanelItems();
            if (data.focusToLastItem) {
                this.setFocusToLastItem();
            } else {
                this.setFocusToFirstItem();
            }
            this.isOpened = true;
        }

        /**
         * @description Close Menu Panel
         * @emits MenuPanel#navPanelClose
         * @emits MenuPanel#navPanelToggleFocus
         * @returns {void}
         */
        closePanel() {
            if (this.parentMenuItems.length) {
                this.parentMenuItems = [];
            }

            if (this.isOpened) {
                this.ref('html').removeClass(this.prefs().classesHasDialog);
                this.ref('self').removeClass(this.prefs().classesBackdropActive);
                this.ref('dialog')
                    .removeClass(this.prefs().classesDialogOpen)
                    .addClass(this.prefs().classesDialogClosed);
                this.ref('self').attr('data-tau', false);
                /**
                 * @description Event to close panel
                 * @event MenuPanel#navPanelClose
                 */
                this.eventBus().emit('nav.panel.close');
                /**
                 * @description Event to toggle focus
                 * @event MenuPanel#navPanelToggleFocus
                 */
                this.eventBus().emit('nav.panel.toggle.focus');
                this.handlePanel(1, false);
                this.onDestroy(timeout(() => {
                    for (let i = 1; i <= this.prefs().panelCount; i++) {
                        this.getById(this.prefs().panel + i, (panel) => {
                            panel.clearSubmenuHTML();
                        });
                    }
                }, this.prefs().itemSwitchTimeout));
                this.isOpened = false;
            }
        }

        /**
         * @description Close On Backdrop handler
         * @listens dom#click
         * @param {RefElement} ref - interacted element
         * @param {Event} event - event object
         * @returns {void}
         */
        closeOnBackdrop(ref, event) {
            if (event.target === this.ref('self').get()) {
                this.closePanel();
            }
        }

        /**
         * @description Handle Enter key event
         * @listens dom#keydown
         * @param {Event} event Event Object
         * @returns {void}
         */
        handleEnterKey(event) {
            const domNode = /** @type {HTMLAnchorElement} */(event.target);
            if (domNode && domNode.href) {
                window.location.assign(domNode.href);
            }
        }

        /**
         * @description Keydown Event handler
         * @listens dom#keydown
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         * @returns {void}
         */
        handleKeydown(_, event) {
            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.PAGEUP:
                case keyCode.HOME:
                    this.setFocusToFirstItem();
                    preventEventActions = true;

                    break;

                case keyCode.PAGEDOWN:
                case keyCode.END:
                    this.setFocusToLastItem();
                    preventEventActions = true;

                    break;

                case keyCode.TAB:
                    this.closePanel();

                    break;

                case keyCode.ESC:
                    this.closePanel();
                    preventEventActions = true;
                    break;

                case keyCode.RETURN:
                case keyCode.SPACE:
                    this.handleEnterKey(event);
                    preventEventActions = true;
                    break;

                case keyCode.DOWN:
                    this.setFocusToNextItem();
                    preventEventActions = true;

                    break;

                case keyCode.UP:
                    this.setFocusToPreviosItem();
                    preventEventActions = true;

                    break;

                case keyCode.RIGHT:
                    this.currentPanelItem.openMenu();
                    preventEventActions = true;

                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    return MenuPanel;
}
