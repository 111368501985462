import { dialogMgr } from 'widgets/toolbox/dialogMgr';

const ESCAPE_CODE = 27;

/**
 * @typedef {ReturnType<typeof import('widgets/global/EmitBusEvent').default>} EmitBusEvent
 */

/**
 * @description Modal implementation
 * @param {ReturnType<typeof import('widgets/global/AccessibilityFocusTrapMixin').default>} AccessibilityFocusTrapMixin mixin
 * @returns {typeof Modal} Modal class
 */
export default function (AccessibilityFocusTrapMixin) {
    /**
     * @category widgets
     * @subcategory global
     * @class Modal
     * @augments AccessibilityFocusTrapMixin
     * @classdesc Generic modal popups implementation.<br/>
     * - It renders a modal popup with grayed background. Can be closed by clicking outside modal.
     * - Allows to manage multi-modal depth structure (move one modal behind of another) (indirectly, bu using {@link dialogMgr}).
     * - If Modal need to be inited only on some viewport can be used modificators (sm,md,lg,xl). For example data-widget.sm.md="modal"</br>
     * Note that in this case need also add same modificator to sub events in html elements. This can be easily done via "dialogViewtypes" variable in ISML template (see example below).<br/>
     * Don't forget clear this variable after dialog closing tag.
     * @property {string} data-widget - Widget name `modal`
     * @property {boolean} data-disable-rendering - Disable rendering flag
     * @property {string} data-classes-extra - Extra classes
     * @property {string} data-classes-global-dialog - a set of space separated addiptional modal classes
     * @property {string} data-classes-show - classes added to shown modal
     * @property {string} data-classes-top-dialog - top modal class in modals hierarhy depth
     * @property {string} data-classes-active - active modal classes
     * @property {string} data-ref-container - modal reference container
     * @property {string} data-ref-dialog - dialog reference container
     * @property {string} data-classes-extra - extra classes for dialog
     * @property {boolean} data-click-out-side - `true` if needed to close modal by clicking outside it
     * @property {boolean} data-close-by-escape - `true` if needed to close modal by using `Esc` button
     * @example <caption>Example of typical Modal widget</caption>
     * <div data-widget="modal">
     *     // dialogViewtypes need only in case when modal should be inited on some viewports
     *     <isset name="dialogViewtypes" value="sm.md" scope="page" />
     *
     *     <div data-ref="container" data-event-click.self="closeModal"></div>
     *     <script type="template/mustache" data-ref="template">
     *     <div class="b-dialog-header">
     *   <div
     *       class="b-dialog m-quick_view"
     *       id="editProductModal"
     *       data-ref="container"
     *       data-event-click.self="closeModal"
     *   >
     *       <div
     *           class="b-dialog-window"
     *           role="dialog"
     *           data-ref="dialog"
     *           aria-modal="true"
     *           aria-labelledby="editProductModalTitle"
     *       >
     *           <div class="b-dialog-header">
     *               <button
     *                   class="b-dialog-close"
     *                   title="${Resource.msg('common.close','common',null)}"
     *                   aria-label="${Resource.msg('common.close','common',null)}"
     *                   type="button"
     *                   data-dismiss="modal"
     *                   data-ref="closeEditPopup"
     *                   data-event-click.prevent="cancel"
     *                   data-tau="edit_product_dialog_close"
     *               >
     *                   <isinclude template="/common/icons/standalone/close" />
     *               </button>
     *           </div>
     *           {{${'#'}body}}
     *               <div class="b-dialog-body">
     *                   {{&body}}
     *               </div>
     *           {{/body}}
     *           {{${'#'}footer}}
     *               <div class="b-dialog-footer">
     *                   {{&footer}}
     *               </div>
     *           {{/footer}}
     *       </div>
     *   </div>
     *     </script>
     * </div>
     */
    class Modal extends AccessibilityFocusTrapMixin {
        prefs() {
            return {
                classesGlobalDialog: 'm-has_dialog',
                classesShow: 'm-opened',
                classesTopDialog: 'm-top_dialog',
                classesActive: 'm-active',
                refContainer: 'container',
                refDialog: 'dialog',
                classesExtra: '',
                clickOutSide: true,
                disableRendering: false,
                closeByEscape: true,
                ...super.prefs()
            };
        }

        init() {
            super.init();
            this.onDestroy(() => {
                const refDialog = this.ref(this.prefs().refDialog);
                refDialog.attr('role', false);
                refDialog.attr('aria-modal', false);
            });
        }

        /**
         * @description Show Modal and puts it to the top of opened modals hierarhy.
         * @param {object|undefined} [templateData] data to be rendered in template
         * @param {Function|undefined} [cb] optional callback
         * @returns {void}
         */
        showModal(templateData, cb) {
            const container = this.ref(this.prefs().refContainer);
            const classes = [this.prefs().classesShow];

            if (this.prefs().classesExtra) {
                classes.push(this.prefs().classesExtra);
            }

            this.backFocusElement = /** @type {HTMLElement} */(document.activeElement);

            let renderedPromise = Promise.resolve();

            if (templateData && !this.prefs().disableRendering) {
                renderedPromise = this.render(undefined, templateData, container);
            }

            renderedPromise.then(() => {
                this.onBeforeShowModal(templateData);
                dialogMgr.openDialog(this);
                container.addClass(classes.join(' '));
                this.show();

                if (cb && typeof cb === 'function') {
                    cb();
                } else {
                    this.afterShowModal();
                }
            });
        }

        /**
         * @description Open a dialog. This method is executed explicitly or implicitly from `showModal` method.
         * @returns {void}
         */
        open() {
            const dialog = this.ref(this.prefs().refDialog);
            dialog.attr('role', 'dialog');
            dialog.attr('aria-modal', 'true');
            this.addGlobalDialogClass();
            this.addListeners();
            dialog.addClass(this.prefs().classesActive);
            dialog.addClass(this.prefs().classesTopDialog);
        }

        /**
         * @description Move Behind current modal in opened modals stack.
         * @returns {void}
         */
        moveBehind() {
            this.cleanUpListeners();
            this.ref(this.prefs().refDialog).removeClass(this.prefs().classesTopDialog);
        }

        /**
         * @description Move To Top current modal in opened modals stack.
         * @returns {void}
         */
        moveToTop() {
            this.addListeners();
            this.ref(this.prefs().refDialog).addClass(this.prefs().classesTopDialog);
        }

        /**
         * @description Close modal.
         * @returns {void}
         */
        close() {
            this.cleanUpListeners();
            this.ref(this.prefs().refDialog).removeClass([this.prefs().classesTopDialog, this.prefs().classesActive].join(' '));
        }

        /**
         * @description Add Global Dialog Class
         * @returns {void}
         */
        addGlobalDialogClass() {
            const html = this.ref('html');

            if (!html.hasClass(this.prefs().classesGlobalDialog)) {
                html.addClass(this.prefs().classesGlobalDialog);
            }
        }

        /**
         * @description Remove Global Dialog Class
         * @returns {void}
         */
        removeGlobalDialogClass() {
            this.ref('html').removeClass(this.prefs().classesGlobalDialog);
        }

        /**
         * @description Close Modal
         * @returns {void}
         */
        closeModal() {
            const classes = [this.prefs().classesShow];

            if (this.prefs().classesExtra) {
                classes.push(this.prefs().classesExtra);
            }

            this.ref(this.prefs().refContainer).removeClass(classes.join(' '));

            dialogMgr.closeDialog();

            if (this.backFocusElement) {
                this.backFocusElement.focus();
                this.backFocusElement = null;
            }

            if (!this.prefs().disableRendering) {
                this.ref(this.prefs().refContainer).empty();
            }

            this.onAfterCloseModal();
        }

        /**
         * @description Clean Up Listeners
         * @returns {void}
         */
        cleanUpListeners() {
            if (this.escHandler) {
                this.escHandler();
                this.escHandler = null;
            }

            if (this.clickOutsideHandler) {
                this.clickOutsideHandler();
                this.clickOutsideHandler = null;
            }
        }

        /**
         * @description Lifecycle hook `onAfterCloseModal` executes after closing modal window.
         * Used to:
         * - remove modal DOM element attributes as per modal setup
         * @returns {void}
         */
        onAfterCloseModal() {
            if (this.attributes) {
                Object.keys(this.attributes).forEach((key) => {
                    this.ref('container').attr(key, false);
                });
            }
        }

        /**
         * @description Lifecycle hook `onBeforeShowModal` executes before opening modal window.
         * Used to:
         * - add modal DOM element attributes as per modal setup
         * @param {object|InstanceType<EmitBusEvent>} [modalData] Input object for modal popup.
         * @returns {void}
         */
        onBeforeShowModal(modalData = {}) {
            if (modalData.attributes) {
                this.attributes = modalData.attributes;

                Object.keys(modalData.attributes).forEach((key) => {
                    this.ref('container').attr(key, modalData.attributes[key]);
                });
            }
        }

        /**
         * @description Cancel Handler
         * @emits Modal#cancel
         * @returns {void}
         */
        cancel() {
            this.closeModal();
            /**
             * @description Event dispatched, when modal was closed
             * @event Modal#cancel
             */
            this.emit('cancel');
        }

        /**
         * @description Add Click Outside / Close by ESC Listener
         * @returns {void}
         */
        addListeners() {
            if (this.prefs().clickOutSide) {
                this.clickOutsideHandler = this.ev('click', (_, event) => {
                    if (event.target === this.ref(this.prefs().refContainer).get()) {
                        this.cancel();
                    }
                }, this.ref(this.prefs().refContainer).get()).pop();
            }

            if (this.prefs().closeByEscape) {
                this.escHandler = this.ev('keyup', (_, event) => {
                    const kdbEvent = /** @type {KeyboardEvent} */(event);
                    if (kdbEvent.keyCode === ESCAPE_CODE) {
                        this.cancel();
                    }
                }, window).pop();
            }
        }

        /**
         * @description Shows modal in a DOM
         * @returns {this} this obj - current instance for chaining
         */
        show() {
            super.show();
            this.ref(this.prefs().refContainer).show();
            return this;
        }

        /**
         * @description Hide modal in DOM
         * @returns {this} this obj - current instance for chaining
         */
        hide() {
            this.ref(this.prefs().refContainer).hide();
            return this;
        }
    }

    return Modal;
}
