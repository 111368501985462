/**
 * @typedef {ReturnType<typeof import('widgets/forms/AjaxForm').default>} AjaxForm
 */

/**
 * @description Base ChangePasswordForm implementation
 * @param {AjaxForm} AjaxForm Base widget for extending
 * @returns {typeof ChangePasswordForm} Change Password Form class
 */
export default function (AjaxForm) {
    /**
     * @class ChangePasswordForm
     * @augments AjaxForm
     * @property {string} data-widget - Widget name `changePasswordForm`
     * @property {string} data-event-submit - Event listener for form submission
     */
    class ChangePasswordForm extends AjaxForm {
        init() {
            super.init();

            this.eventBus().on('cognito.hook.changepassword.then', 'onChangePasswordSubmitted');
            this.eventBus().on('cognito.hook.changepassword.catch', 'onError');
        }

        /**
         * Overrides default handle submit form to call Cognito API with change password.
         *
         * @returns {boolean}
         */
        handleSubmit() {
            var fields = this.getFormFields();

            if (!this.isChildrenValid() || this.submitting) {
                return false;
            }

            this.submitting = true;

            this.eventBus().emit('cognito.hook.changepassword', {
                oldPassword: fields.dwfrm_changePassword_currentpassword,
                newPassword: fields.dwfrm_changePassword_newpassword
            });

            return false;
        }

        /**
         * Used to reload page when password was changed.
         */
        onChangePasswordSubmitted() {
            const accessibilityAlert = this.prefs().accessibilityAlerts.passwordChanged;

            this.afterSubmission();
            this.eventBus().emit('alert.show', { accessibilityAlert });

            window.location.assign(this.prefs().action);
        }
    }

    return ChangePasswordForm;
}
