/**
 * @description HarmonyFocusHighlighter implementation
 * @param {FocusHighlighter} FocusHighlighter FocusHighlighter for extending
 * @returns {typeof FocusHighlighter}
 */

export default function (FocusHighlighter) {
    return class HarmonyFocusHighlighter extends FocusHighlighter {
        /**
         * @description Moves `highlighter` (a bordere box) in place of focused element
         * @param {HTMLElement} focusedElement - element, which gets focus
         */
        moveTo(focusedElement) {
            if (!(focusedElement instanceof HTMLElement)) { return; }

            const highlighterNode = this.ref('highlighter').get();
            if (!highlighterNode) { return; }

            const scrollTop = +this.ref('self').get().dataset.scrollTop || 0;
            const targetRect = focusedElement.getBoundingClientRect();
            const targetTop = targetRect.top + window.scrollY + scrollTop;
            const targetLeft = targetRect.left + window.scrollX;
            const targetWidth = focusedElement.offsetWidth;
            const targetHeight = focusedElement.offsetHeight;

            if (
                focusedElement === this.lastFocusedElement
                && this.lastFocusedElementCoords === '' + targetTop + targetLeft + targetWidth + targetHeight
            ) {
                // If we come from coords recheck do not reapply changes
                return;
            }

            const highlighterStyle = highlighterNode.style;
            highlighterStyle.top = `${targetTop - 5}px`;
            highlighterStyle.left = `${targetLeft - 5}px`;
            highlighterStyle.width = `${targetWidth + 2}px`;
            highlighterStyle.height = `${targetHeight + 2}px`;

            this.lastFocusedElementCoords = '' + targetTop + targetLeft + targetWidth + targetHeight;
            this.lastFocusedElement = focusedElement;
        }

        handleClick(ref, event) {
            super.handleClick(ref, event);

            this.getById('harmonyHeader', headerWidget => {
                headerWidget.getById('harmonyMenuPanel', (harmonyMenuPanelWidget) => {
                    if (!event.target.closest('[data-id="harmonyMenuPanel"]')
                        && harmonyMenuPanelWidget.isOpened
                        && event.target.id !== 'main-navigation-toggle') {
                        harmonyMenuPanelWidget.closePanel();
                    }
                });
            });
        }
    };
}
