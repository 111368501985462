/**
 * @typedef {ReturnType<typeof import('widgets/forms/AjaxForm').default>} AjaxForm
 */

/**
 * @param {AjaxForm} AjaxForm Base widget for extending
 * @returns {typeof LoginForm} LoginForm widget
 */
export default function (AjaxForm) {
    /**
     * @category widgets
     * @subcategory forms
     * @class LoginForm
     * @augments AjaxForm
     * @classdesc Represents LoginForm component with next features:
     * 1. Show password reset modal on click
     * @property {string} data-widget - Widget name `loginForm`
     * @example <caption>Example of LoginForm widget usage</caption>
     *  <form
     *      action="${pdict.actionUrl}"
     *      class="b-form m-login"
     *      method="POST"
     *      name="login-form"
     *      data-widget="${pdict.isCheckoutLogin ? 'checkoutLoginForm': 'loginForm'}"
     *      data-event-submit.prevent="handleSubmit"
     *      <isif condition="${pdict.isCheckoutLogin}">data-cart-url="${URLUtils.https('Cart-Show')}"</isif>
     *      novalidate
     *  >
     *          Form content...
     *      <div class="b-reset_password">
     *          <div
     *              data-widget="passwordResetModal"
     *              data-id="passwordResetModal"
     *          >
     *              <div data-ref="container" hidden="hidden"></div>
     *              <script type="template/mustache" data-ref="template">
     *                  <div class="b-dialog m-reset_password" data-ref="container">
     *                      <isinclude template="account/password/requestPasswordResetModal"/>
     *                  </div>
     *              </script>
     *          </div>

     *          <isprint value="${
     *              formElement(pdict.loginForm.password).render()
     *          }" encoding="off"/>

     *          <button
     *              class="b-reset_password-btn"
     *              id="password-reset"
     *              data-event-click="passwordReset"
     *              data-tau="login_password_reset"
     *              type="button"
     *          >
     *              ${Resource.msg('form.login.forgot.link', 'forms', null)}
     *          </button>
     *      </div>
     *  </form>
     */
    class LoginForm extends AjaxForm {
        /**
         * @description Show password reset modal
         * @listens dom#click
         * @returns {void}
         */
        passwordReset() {
            this.getById('ajaxFormResponseContainer', ajaxFormResponseContainer => ajaxFormResponseContainer.showModal({}));
        }
    }

    return LoginForm;
}
