/**
 * @typedef {ReturnType<typeof import('widgets/global/Button').default>} Button
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @description Base ButtonWithIcon implementation
 * @param {Button} Button Base widget for extending
 * @returns {typeof ButtonWithIcon} ButtonWithIcon class
 */
export default function (Button) {
    /**
     * @class ButtonWithIcon
     * @augments Button
     * @classdesc ButtonWithIcon widget, which could be used together with icon
     * @property {string} data-widget - Widget name `processButton`
     * @property {string} data-widget-event-click - Event listener to call Parent's widget method
     * @property {string} data-event-click - Event listener method `handleClick` for click event on widget
     * @example
     * // use this code to display widget
     * <button type="submit" class="btn btn-block btn-primary"
     *     data-widget="buttonWithIcon"
     * >
     *     <!-- svg icon below -->
     *     <isinclude template="/common/svg/wishlist" />
     *
     *     <span class="container">
     *         ${Resource.msg('button.text.loginform', 'login', null)}
     *     </span>
     * </button>
     */
    class ButtonWithIcon extends Button {
        /**
         * @description Get Text
         * @returns {string} Button Text
         */
        getText() {
            return this.ref('container').getText();
        }

        /**
         * @description Set Text
         * @param {(string|undefined)} val - Value to set as a text of the Button
         * @returns {refElement} Reference element
         */
        setText(val = '') {
            return this.ref('container').setText(val);
        }
    }

    return ButtonWithIcon;
}
