/**
 * @typedef {ReturnType<typeof import('widgets/forms/InputRadio').default>} InputRadio
 */

const DATA_FIELD = '[data-ref="field"]';
const KEY_ENTER = 13;
const KEY_SPACE = 32;

/**
 * @description Harmony InputRadio implementation
 * @param {InputRadio} InputRadio Base widget for extending
 * @returns {typeof StatesInputRadio} Harmony Basic Input class
 */
export default function (InputRadio) {
    /**
     * @class StatesInputRadio
     * @augments InputRadio
     */
    class StatesInputRadio extends InputRadio {
        handleLabelKeydown(_, event) {
            const { keyCode, currentTarget } = event;
            if (keyCode === KEY_SPACE || keyCode === KEY_ENTER) {
                event.preventDefault();
                currentTarget.click();
            }
        }

        /**
         * @description Method to select radio input
         * @param {string} value - value to be set to radio button
         * @returns {void}
         */
        setValue(value) {
            const el = (this.ref('self').get());

            if (el) {
                Array.from(el.querySelectorAll(DATA_FIELD))
                    .forEach(elField => {
                        const tmpEl = /** @type {HTMLInputElement} */(elField);
                        const isChecked = tmpEl.value === value;
                        const label = tmpEl.labels[0] || tmpEl;
                        const parentEle = tmpEl.closest('.b-shipping_states-item');

                        if (isChecked) {
                            tmpEl.checked = true;
                            tmpEl.setAttribute('checked', 'checked');
                            el.setAttribute('aria-label', `Selected: ${label.textContent.trim()}.`);
                        } else {
                            tmpEl.checked = false;
                            tmpEl.removeAttribute('checked');
                        }

                        parentEle.setAttribute('aria-checked', isChecked);
                    });
            }
        }
    }

    return StatesInputRadio;
}
