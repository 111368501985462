// TODO: Need to update breakpoint for 375 / 768 and test/refactor viewtype.js
// TODO: resources shimming are loaded after bundle is parsed that results to undefined window.view.breakpoint.*
// TODO: add description to methods
// CLARIFY: isTypeView rename to isViewTypeMatch/isViewMatch ?
/**
 * @module viewtype
 * @category widgets
 * @subcategory toolbox
 */

import eventBus from './eventBus';
import { debounce } from './debounce';
import viewTypeConfig from 'widgets/viewTypeConfig';
import breakpoints from 'config/breakpoints';

const smallID = 'small';
const mediumID = 'medium';
const largeID = 'large';
const extraLargeID = 'extraLarge';

/**
 * @typedef {typeof smallID|typeof mediumID| typeof largeID|typeof extraLargeID} viewtype
 */
const breakMd = breakpoints.md;
const breakLg = breakpoints.lg;
const breakXl = breakpoints.xl;

const smallView = `screen and (max-width: ${breakMd - 1}px)`;
const mediumView = `screen and (min-width: ${breakMd}px) and (max-width: ${breakLg - 1}px)`;
const largeView = `screen and (min-width: ${breakLg}px) and (max-width: ${breakXl - 1}px)`;
const exLargeView = `screen and (min-width: ${breakXl - 1}px)`;
let queriesMap = [
    [smallID, smallView],
    [mediumID, mediumView],
    [largeID, largeView],
    [extraLargeID, exLargeView]
];

let currentWindowWidth = window.innerWidth;
let currentWindowHeight = window.innerHeight;

/**
 * @description get current viewtype
 * @returns {viewtype} current viewtype
 */
function getCurrentViewType() {
    const matchQuery = queriesMap.find(([, query]) => window.matchMedia(query).matches);
    // @ts-ignore
    return (matchQuery && matchQuery[0]) || smallID;
}

const onWindowChange = () => {
    if (currentWindowWidth !== window.innerWidth || currentWindowHeight !== window.innerHeight) {
        currentWindowWidth = window.innerWidth;
        currentWindowHeight = window.innerHeight;
        eventBus.emit('viewtype.windowChange', {
            currentWindowWidth: currentWindowWidth,
            currentWindowHeight: currentWindowHeight
        });
    }
};

/**
 * @type {viewtype} current viewtype
 */
let currentViewType = smallID;

export const SMALL = smallID;
export const MEDIUM = mediumID;
export const LARGE = largeID;
export const EXTRA_LARGE = extraLargeID;
export const getViewType = () => currentViewType;

export const isTouchDevice = () => ('ontouchstart' in window)
    // @ts-ignore
    || !!(window.DocumentTouch && document instanceof window.DocumentTouch);

/**
 * @description check viewtype is current by string
 * @param {typeof currentViewType} type name to check
 * @returns {boolean} return true if current type is same as current viewtype
 */
function isTypeView(type) {
    return getViewType() === type;
}
export const isSmallView = () => isTypeView(smallID);

export const isMediumView = () => isTypeView(mediumID);
export const isMediumViewAndUp = () => !isSmallView(); // CLARIFY: isMediumAndMoreView/isMediumViewAndMore
export const isMediumViewAndDown = () => isSmallView() || isMediumView(); // CLARIFY: isMediumAndLessView/isMediumViewAndLess

export const isExtraLargeView = () => isTypeView(extraLargeID);

export const isLargeView = () => isTypeView(largeID);
export const isLargeViewAndUp = () => isLargeView() || isExtraLargeView();
export const isLargeViewAndDown = () => !isExtraLargeView();

export const isDesktopView = isLargeViewAndUp;

const modifiersMapping = {
    sm: isSmallView,
    md: isMediumView,
    lg: isLargeView,
    xl: isExtraLargeView
};

/**
 * @type {Array<keyof modifiersMapping>}
 */
export const ALL_VIEW_TYPES = ['sm', 'md', 'lg', 'xl'];

export const getActiveViewtypeName = () => ALL_VIEW_TYPES.find(m => modifiersMapping[m]()) || 'xl';

/**
 * @description match viewport by device name
 * @param {typeof smallID|typeof mediumID| typeof largeID|typeof extraLargeID} newDevice device type
 */
function matchViewport(newDevice) {
    const previousDevice = currentViewType;

    currentViewType = newDevice;
    if (previousDevice !== currentViewType) {
        eventBus.emit('viewtype.change', currentViewType);
    }
}

/**
 * @description To init viewtypes, you shouldn't use methods of modules before this executing
 * @param {object} [config] object
 * @param {boolean} config.useWindowListeners use listeners or MediaQueryListener
 * @param {string[][]} [config.queriesMap] size of mobile in pixels
 * @returns {void}
 */
export function init(config = { useWindowListeners: false }) {
    const currentConfig = { ...config, ...viewTypeConfig };

    if (currentConfig.queriesMap) {
        queriesMap = currentConfig.queriesMap;
    }

    if (currentConfig.useWindowListeners) {
        const windowResize = debounce(() => {
            onWindowChange();
            matchViewport(getCurrentViewType());
        }, 50);

        window.addEventListener('resize', windowResize, { passive: true });
        window.addEventListener('orientationchange', windowResize, { passive: true });
    } else {
        const applyCurrentDeviceType = debounce(() => matchViewport(getCurrentViewType()), 50);

        queriesMap.forEach(([, query]) => window.matchMedia(query).addListener(applyCurrentDeviceType));
    }
    currentViewType = getCurrentViewType();

    eventBus.emit('viewtype.change', currentViewType);
}
