import { Amplify, Hub } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { setCookie } from 'widgets/toolbox/cookie';

import { submitFormJson } from 'widgets/toolbox/ajax';
import Storage from 'harmony/aws/CustomStorage';
import { formatDate, formatPhoneNumber } from 'harmony/toolbox/format';

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description Amazon Cognito implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof Cognito} Cognito class
 */

export default function (Widget) {
    class Cognito extends Widget {
        init() {
            super.init();

            this.configure();
            this.hooks();
            this.isAuth();
        }

        get constants() {
            return {
                HOOK_SIGN_IN_THEN: 'cognito.hook.signin.then',
                HOOK_SIGN_IN_CATCH: 'cognito.hook.signin.catch',
                HOOK_UPDATE_PROFILE_ERROR: 'cognito.hook.updateprofiledetails.error'
            };
        }

        /**
         * Amazon Cognito initialization.
         *
         * https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource
         *
         * Config for initialization places in cognito.isml and values of it comes from BM.
         * Expected object for ...this.prefs().config the next one:
         * {
         *    "region": <string>,
         *    "userPoolId": <string>,
         *    "userPoolWebClientId": <string>,
         *    "oauth": {
         *        domain: 'your_cognito_domain',
         *        scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
         *        redirectSignIn: 'http://localhost:3000/',
         *        redirectSignOut: 'http://localhost:3000/',
         *        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
         *     }
         * }
         */
        configure() {
            try {
                Amplify.configure({
                    Auth: {
                        mandatorySignIn: true,
                        ...this.prefs().config,
                        storage: new Storage(this.prefs().storageUrl)
                    }
                });
            } catch (e) {
                console.error(e);
            }

            /**
             * Social login Hub. After provider will return customer to the site
             * (url will have params with token and state) this Hub will be initialized
             * and listen auth trigger.
             *
             * Description of the API https://docs.amplify.aws/lib/auth/social/q/platform/js#setup-frontend
             */
            Hub.listen('auth', async (data) => {
                if (!this.prefs().isOauth) { return; }

                const failureUrl = this.prefs().failureProvider
                    .concat('&provider=')
                    .concat(window.sessionStorage.getItem('triggeredProvider'));

                window.sessionStorage.removeItem('triggeredProvider');

                switch (data?.payload?.event) {
                    case 'signIn':
                        Auth.currentAuthenticatedUser()
                            .then(userData => {
                                submitFormJson(window.location.href, userData.attributes, 'POST', true).then(res => {
                                    window.location.assign(res.redirectUrl);
                                });
                            })
                            .catch(() => {
                                window.location.assign(failureUrl);
                            });
                        break;
                    case 'cognitoHostedUI':
                        Auth.currentAuthenticatedUser()
                            .then(userData => {
                                this.updateSessionStorage(userData).then(res => {
                                    submitFormJson(res.redirectUrl, { formData: userData.attributes }, 'POST', true);
                                });
                            })
                            .catch(() => {
                                window.location.assign(failureUrl);
                            });
                        break;
                    case 'signIn_failure':
                        window.location.assign(failureUrl);
                        break;
                    default:
                        break;
                }
            });
        }

        hooks() {
            /**
             * Used for start Cognito signup process.
             *
             * Object which expects as parameter.
             * {
             *     username: fields.username,
             *     password: fields.password,
             *     attributes: {
             *         email: fields.email,
             *         name: fields.firstName,
             *         family_name: fields.lastName,
             *         phone_number: fields.phoneNumber,
             *         birthdate: fields.birthDate
             *     }
             * }
             */
            this.eventBus().on('cognito.hook.signup', 'signup');

            /**
             * Used for start Cognito signin process.
             *
             * Object which expects as parameter.
             * {
             *     username,
             *     password
             * }
             */
            this.eventBus().on('cognito.hook.signin', 'signin');

            /**
             * Used for start Cognito forgot password process.
             * This method will emit email to the customer with verification code.
             *
             * Object which expects as parameter.
             * {
             *     username
             * }
             *
             * username -- it is email address of the customer.
             */
            this.eventBus().on('cognito.hook.forgotpassword', 'forgotpassword');

            /**
             * Used for submit customer forgot password process,
             * with verification code which was sent on email.
             *
             * Object which expects as parameter.
             * {
             *     username,
             *     code,
             *     new_password
             * }
             */
            this.eventBus().on('cognito.hook.forgotpassword.confirmationcodesubmit', 'confirmationCodeSubmit');

            /**
             * Used to change password to the specific customer on Cognito side.
             *
             * Object which expects as parameter.
             * {
             *     oldPassword,
             *     newPassword
             * }
             */
            this.eventBus().on('cognito.hook.changepassword', 'changePassword');

            /**
             * Used to emit email with new verification code.
             *
             * Object which expects as parameter.
             * {
             *     username
             * }
             */
            this.eventBus().on('cognito.hook.resendconfirmemail', 'reSendConfirmationEmail');

            /**
             * Used to confirm sign up process with verification code which was sent to the email address.
             *
             * Object which expects as parameter.
             * {
             *     username,
             *     code,
             *     password
             * }
             */
            this.eventBus().on('cognito.hook.confirmsignup', 'confirmSignUp');

            /**
             * Used to call social login process. Will redirect customer on specific provider.
             * Cognito in this case behave like a proxy between SFCC and provider.
             *
             * Provider - any social network: facebook, google.
             */
            this.eventBus().on('cognito.hook.sociallogin', 'socialLogin');

            /**
             * @description when update profile was triggered (on my account dashboard).
             */
            this.eventBus().on('cognito.hook.updateprofiledetails', 'updateProfileDetails');

            /**
             * @description when subscription flag needs to be synced on cognito.
             */
            this.eventBus().on('cognito.hook.syncsubscription', 'syncNewsletterSubscription');
        }

        /**
         * @param {object} data -- facebook or google and isCheckout tag.
         * @description Will make a redirect to the specific provider.
         * API https://docs.amplify.aws/lib/auth/social/q/platform/js#setup-frontend
         */
        socialLogin(data) {
            let providerLabel = '';

            if (data.isCheckout) {
                setCookie('socialSignOn_cookie', 'checkout', 365, true)
            } else if (data.isWCSignUp) {
                var isWCSignUp = '"'+ data.isWCSignUp + '"';
                setCookie('socialSignOn_cookie', isWCSignUp , 365, true)
            } else {
                setCookie('socialSignOn_cookie', '' , 365, true)
            }

            if (data.socialNetwork === 'facebook') {
                providerLabel = 'Facebook';
                Auth.federatedSignIn({ provider: providerLabel });
            }

            if (data.socialNetwork === 'google') {
                providerLabel = 'Google';
                Auth.federatedSignIn({ provider: providerLabel });
            }

            window.sessionStorage.setItem('triggeredProvider', providerLabel);
        }

        /**
         * @description Used for sync backend and cognito session states. If on backend session lost
         * we need to logout on cognito side and if we logout (lost) session on cognito
         * we need to logout on SFCC side.
         */
        isAuth() {
            if (this.prefs().isOauth || this.prefs().isExternalAuth) {
                return;
            }

            // Trying to get auth customer from Cognito.
            Auth.currentAuthenticatedUser({
                bypassCache: false
            })
                .catch(() => {
                    // Logout customer on our end if the session was expired on AWS
                    // side and we still in logged in state on our end.
                    if (this.prefs().isAuth) {
                        window.location.assign(this.prefs().logoutUrl);
                    }
                });
        }

        /**
         * @description This function makes a change password request to the Amazon Cognito.
         *
         * https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#change-password
         *
         * @param {object} fields - object with new and old password.
         * @param {string} fields.oldPassword - an old password
         * @param {string} fields.newPassword - new password
         */
        changePassword(fields) {
            // Getting the current authenticated user object.
            Auth.currentAuthenticatedUser()
                .then(user => {
                    // If customer exists we can change a password.
                    return Auth.changePassword(user, fields.oldPassword, fields.newPassword);
                })
                .then(data => {
                    // Password was changed emit an event to go further.
                    this.eventBus().emit('cognito.hook.changepassword.then', data);
                })
                .catch(error => {
                    this.eventBus().emit('cognito.hook.changepassword.catch', this.getError(error));
                });
        }

        /**
         * @description This function make a registration request to the Amazon.
         *
         * https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js#sign-up
         *
         * @param {object} fields
         * @param {string} fields.username - customer username
         * @param {string} fields.password - customer password
         * @param {string} fields.email - customer email (it will be login name)
         * @param {string} fields.firstName - customer first name
         * @param {string} fields.lastName - customer last name
         * @param {string} fields.birthdate - the birthday customer date
         * @param {string} fields.phoneNumber - customer phone number in E.164 number convention
         */
        signup(fields) {
            let attributesObject = {
                email: fields.email,
                name: fields.firstName,
                family_name: fields.lastName,
                phone_number: fields.phoneNumber ? formatPhoneNumber(fields.phoneNumber) : '',
                birthdate: fields.birthDate,
                'custom:newsSubscription': fields.newsletterSubscription,
            };
            if(fields.state) {
                attributesObject['name'] = fields.firstName + ' ' + fields.lastName;
                attributesObject['given_name'] = fields.firstName;
                attributesObject['birthdate'] = formatDate(fields.birthDate);
                attributesObject['custom:state'] = fields.state;
            }
            Auth.signUp({
                username: fields.username,
                password: fields.password,
                attributes: attributesObject
            }).then(data => {
                this.eventBus().emit('cognito.hook.signup.then', data);
            }).catch(error => {
                this.eventBus().emit('cognito.hook.signup.catch', this.getError(error, 'signup'));
            });
        }

        /**
         * @description Sign in customer on Cognito by username and password.
         * In case of challenge (customer was created in Cognito console) complete needs to be called,
         * for now it completes with the same password.
         *
         * Description of the API https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js#sign-in
         *
         * @param {object} fields object with username and password data
         * @param {string} fields.username - customer user name
         * @param {string} fields.password - customer password
         */
        signin(fields) {
            Auth.signIn(fields.username, fields.password)
                .then(data => {
                    this.updateSessionStorage(data).then(() => {
                        if (data?.challengeName === 'NEW_PASSWORD_REQUIRED') {
                            Auth.completeNewPassword(data, fields.password)
                                .then(user => {
                                    this.eventBus().emit(this.constants.HOOK_SIGN_IN_THEN, {
                                        ...user,
                                        ...fields
                                    });
                                })
                                .catch(error => {
                                    this.eventBus().emit(this.constants.HOOK_SIGN_IN_CATCH, this.getError(error));
                                });
                        } else {
                            this.eventBus().emit(this.constants.HOOK_SIGN_IN_THEN, {
                                ...data,
                                ...fields
                            });
                        }
                    });
                })
                .catch(error => {
                    this.eventBus().emit(this.constants.HOOK_SIGN_IN_CATCH, this.getError(error, 'signin'));
                });
        }

        /**
         * @description Forgot password request to the Cognito, called from Login page -> forgot password popup.
         *
         * Description of the API https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#forgot-password
         *
         * @param {object} fields - object with username
         * @param {string} fields.username - customer user name.
         */
        forgotpassword(fields) {
            Auth.forgotPassword(fields.username)
                .then(data => {
                    this.eventBus().emit('cognito.hook.forgotpassword.then', {
                        ...data,
                        ...fields
                    });
                })
                .catch(error => {
                    this.eventBus().emit('cognito.hook.forgotpassword.catch', {
                        ...this.getError(error),
                        ...fields
                    });
                });
        }

        /**
         * @description This confirmationcodesubmit function used for submit password with code which was
         * sent by Cognito to the customer email address.
         *
         * Description of the API https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#forgot-password
         *
         * @param {object} fields - object with code and new password
         * @param {string} fields.code - code from Amazon, like a token.
         * @param {string} fields.newPassword - customer new password string.
         * @param {string} fields.userName - customer new password string.
         */
        confirmationCodeSubmit(fields) {
            Auth.forgotPasswordSubmit(fields.userName, fields.code, fields.newPassword)
                .then(() => {
                    this.eventBus().emit('cognito.hook.forgotpassword.confirmationcodesubmit.then');
                })
                .catch(error => {
                    this
                        .eventBus()
                        .emit('cognito.hook.forgotpassword.confirmationcodesubmit.catch', this.getError(error));
                });
        }

        /**
         * @description Used for resend confirmation code to the customer.
         *
         * Description of the API https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js#re-send-confirmation-code
         * @param {object} fields - object with code and username.
         * @param accessibilityAlert
         * @param {string} fields.username - customer new password string.
         */
        reSendConfirmationEmail(fields, accessibilityAlert) {
            Auth.resendSignUp(fields.username)
                .then(data => {
                    this.eventBus().emit('cognito.hook.resendconfirmemail.then', {
                        data,
                        ...fields
                    });

                    this.eventBus().emit('alert.show', {
                        accessibilityAlert
                    });
                })
                .catch(error => {
                    this.eventBus().emit('cognito.hook.resendconfirmemail.catch', {
                        ...this.getError(error),
                        ...fields
                    });
                });
        }

        /**
         * @description Confirm customer verification with code and username.
         *
         * Description of the API https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js#confirm-sign-up
         *
         * @param {object} fields - object with code and username.
         * @param {string} fields.code - confirmation code from email which was sent by Amazon Cognito.
         * @param {string} fields.username - customer username string.
         * @param {string} fields.password - customer password string.
         */
        confirmSignUp(fields) {
            Auth.confirmSignUp(fields.username, fields.code)
                .then(() => {
                    // Used in case we don't need to signin customer immediately
                    if (!!fields.silent) { // eslint-disable-line no-extra-boolean-cast
                        this.eventBus().emit('cognito.hook.signin.silent');
                        return;
                    }
                    Auth.signIn(fields.username, fields.password)
                        .then(data => {
                            this.updateSessionStorage(data).then(() => {
                                this.eventBus().emit(this.constants.HOOK_SIGN_IN_THEN, {
                                    ...data,
                                    ...fields
                                });
                            });
                        })
                        .catch(error => {
                            this.eventBus().emit(this.constants.HOOK_SIGN_IN_CATCH, {
                                ...this.getError(error),
                                ...fields
                            });
                        });
                })
                .catch(error => {
                    this.eventBus().emit('cognito.hook.confirmsignup.catch', {
                        ...this.getError(error),
                        ...fields
                    });
                });
        }

        /**
         * @description update customer profile data on cognito side
         * and emit action for update it on SFCC side.
         * @param {object} fields
         * @param {string} fields.username - customer email
         * @param {string} fields.currentPassword - customer current password
         * @param {string} fields.firstName - customer first name
         * @param {string} fields.lastName - customer last name
         * @param {string} fields.phoneNumber - customer phone number in E.164 number convention format
         */
        updateProfileDetails(fields) {
            // If it is external customer we don't need to check password.
            if (this.prefs().isExternalAuth || document.getElementById('captureDobAndPhone')?.getAttribute('data-sso-account')) {
                this.updateUserProfile(fields);
                return;
            }

            // Check provided customer username and password.
            Auth.signIn(fields.username, fields.currentPassword)
                .then((data) => {
                    this.updateSessionStorage(data).then(() => {
                        this.updateUserProfile(fields);
                    });
                })
                .catch((error) => {
                    this.eventBus().emit(this.constants.HOOK_UPDATE_PROFILE_ERROR, this.getError(error));
                });
        }

        /**
         * Internally used function which updates customer profile on cognito side.
         *
         * @param {object} fields - an object with data described below
         * @param {string} fields.username - customer email
         * @param {string} fields.currentPassword - customer current password
         * @param {string} fields.firstName - customer first name
         * @param {string} fields.lastName - customer last name
         * @param {string} fields.phoneNumber - customer phone number in E.164 number convention format
         */
        updateUserProfile(fields) {
            // Get current auth user.
            Auth.currentAuthenticatedUser()
                .then((user) => {
                    // Update user attributes
                    let fieldsObject = {
                        name: fields.firstName,
                        family_name: fields.lastName,
                        phone_number: formatPhoneNumber(fields.phoneNumber),
                        birthdate: fields.birthDate,
                        'custom:newsSubscription': fields.newsletterSubscription
                    };
                    if(fields.state) {
                        fieldsObject['name'] = fields.firstName + ' ' + fields.lastName;
                        fieldsObject['given_name'] = fields.firstName;
                        fieldsObject['birthdate'] = formatDate(fields.birthDate);
                        fieldsObject['custom:state'] = fields.state;
                    }
                    Auth.updateUserAttributes(user, fieldsObject)
                        .then((data) => {
                            this.eventBus().emit('cognito.hook.updateprofiledetails.then', data);
                        })
                        .catch((error) => {
                            this.eventBus().emit(this.constants.HOOK_UPDATE_PROFILE_ERROR, this.getError(error));
                        });
                })
                .catch((error) => {
                    this.eventBus().emit(this.constants.HOOK_UPDATE_PROFILE_ERROR, this.getError(error));
                });
        }

        /**
         * @description store cognito local storage to the backend session storage.
         * @param data - data to be stored.
         * @returns {Promise<object>}
         */
        updateSessionStorage(data) {
            return submitFormJson(
                this.config.storageUrl,
                { storage: JSON.stringify(data?.storage?.dataMemory) },
                'POST',
                true
            );
        }

        /**
         * @description sync service newsletter subscription with cognito.
         * @param {object} response
         * @param {string} svcStatus - status which was passed to the newsletter subscription service.
         * @returns {Promise<T | void>}
         */
        syncNewsletterSubscription(response) {
            Auth.currentAuthenticatedUser()
                .then((user) => {
                    const userNewsletterSubscription = String(user.attributes['custom:newsSubscription']);
                    const svcNewsletterSubscription = String(response.newsletterSubscription);

                    if (userNewsletterSubscription !== svcNewsletterSubscription) {
                        Auth.updateUserAttributes(user, {
                            'custom:newsSubscription': svcNewsletterSubscription
                        });
                    }
                })
                .catch(error => {
                    console.warn(error);
                }).finally(() => {
                    this.eventBus().emit('sync.newsletter.subscription.then', response);
                });
        }

        /**
         * @description Get error message object according to the error mapping with Amazon Cognito.
         *
         * @param {object} error - data from Amazon Cognito which code will be mapped to the message on SFCC side.
         * @param {string} error.code - error code from Amazon Cognito.
         * @param {string} type - deternime hwo called error function to get proper default error message.
         *
         * @returns {object} object with error message data.
         */
        getError(error, type = 'default') {
            var errors = this.prefs().errors;
            var confirmException = error.code === 'UserNotConfirmedException';
            var message = '';

            // Identify default error message
            switch (type) {
                case 'signin':
                    message = errors.SignInException;
                    break;
                case 'signup':
                    message = errors.SignUpException;
                    break;
                default:
                    message = errors.InternalError;
                    break;
            }

            // If an error has some specific code try to find another error message
            // otherwise will be default which was determined above
            if (error.code in errors) {
                message = errors[error.code];
            } else if (confirmException) {
                message = errors.UnconfirmedCustomerAccount;
            }

            return {
                message: message,
                confirmException: confirmException
            };
        }
    }

    return Cognito;
}
