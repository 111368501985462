import { timeout } from 'widgets/toolbox/util';
import { clickOutside } from 'widgets/toolbox/util';

/**
 * @typedef {ReturnType<typeof import('widgets/global/ListAccessibility').default>} ListAccessibility
 * @typedef {InstanceType<ReturnType<typeof import('widgets/header/MegaMenu').default>>} MegaMenu
 */

const keyCode = Object.freeze({
    ESC: 27,
    PAGEUP: 33,
    PAGEDOWN: 34,
    END: 35,
    HOME: 36,
    UP: 38,
    DOWN: 40
});

const ITEM_SWITCH_TIMEOUT = 200;

/**
 * @param {ListAccessibility} ListAccessibility Base widget for extending
 * @returns {typeof MenuBarItem} MenuBarItem widget
 */
export default function (ListAccessibility) {
    /**
     * @category widgets
     * @subcategory header
     * @class MenuBarItem
     * @augments ListAccessibility
     * @classdesc Represents MenuBarItem component with next features:
     * 2. MenuBarItem Widget can contain another MenuBarItem like submenu.
     * 3. Allow open/close menu
     * 4. Allow handling mouse, keyboard events
     * 5. Allow check if submenus exist and its state(opened/closed)
     *
     * MenuBarItem widget should be a part of {@link MegaMenu} widget that implements the menu.
     * MenuBarItem widget should be imlemented according to https://www.w3.org/TR/wai-aria-practices/#menu specs
     * @example <caption>Example of menuBarItem widget usage</caption>
     * <li role="none"
     *    class="b-menu_bar-item"
     *    data-widget="menuBarItem"
     *    data-event-mouseenter="mouseEnter"
     *    data-event-mouseleave="mouseLeave"
     *    data-event-keydown="handleKeydown"
     *>
     *    <a
     *        role="menuitem"
     *        class="b-menu_bar-link m-has-submenu"
     *        aria-label="${category.name}"
     *        aria-haspopup="true"
     *        aria-expanded="false"
     *        itemprop="url name"
     *        tabindex="${status.first ? 0 : -1}"
     *        href="<isprint value="${category.url}" encoding="htmldoublequote"/>"
     *        data-ref="itemLink"
     *        data-event-blur="handleClose"
     *    >
     *        ${category.name}
     *    </a>
     *    <isset name="menuItem" value="${category}" scope="page" />
     *    <div
     *        role="menu"
     *        class="b-menu_bar-flyout"
     *        aria-label="${category.name}"
     *        aria-hidden="true"
     *        data-ref="submenu"
     *    >
     *        <div role="none" class="b-menu_bar-flyout_inner">
     *            <isinclude template="components/header/menuItem" />
     *
     *            <button
     *                role="menuitem"
     *                class="b-menu_bar-flyout_close"
     *                aria-label="${Resource.msg('common.close', 'common', null)}"
     *                title="${Resource.msg('common.close', 'common', null)}"
     *                data-event-click="handleClose"
     *                type="button"
     *                tabindex="-1"
     *            >
    *                <isinclude template="/common/svg/close" />
     *            </button>
     *        </div>
     *    </div>
     *</li>
     * @property {string} data-widget - Widget name `MenuBarItem`
     * @property {string} data-event-mouseenter - Event listener for `mouseEnter` method
     * @property {string} data-event-mouseleave - Event listener for `mouseLeave` method
     * @property {string} data-event-keydown - Event listener for `handleKeydown` method
     * @property {string} data-event-click - Event listener for `handleClose` method
     */

    class MenuBarItem extends ListAccessibility {
        prefs() {
            return {
                submenu: 'submenu',
                menuMoreLink: 'menuMoreLink',
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic.
         * @returns {void}
         */
        init() {
            super.init();

            // Async init to not block other widget init
            timeout(() => {
                this.focusableItems = this.getMenuItems();
                this.firstItem = this.focusableItems[0];
                this.lastItem = this.focusableItems[this.focusableItems.length - 1];
                this.currentItem = this.focusableItems[0];
            }, 0);

            this.isSubmenuOpen = false;
            this.isSubmenuInFocus = false;
        }

        /**
         * @description Returns menu item array
         * @returns {Array<HTMLElement>} MenuItems array
         */
        getMenuItems() {
            const menuItems = [];

            this.eachChild(child => {
                if (!(child instanceof MenuBarItem)) {
                    return;
                }

                child.has(this.prefs().itemLink, (itemLink) => {
                    menuItems.push(itemLink.get());
                });
                menuItems.push(...child.getMenuItems());
                child.has(this.prefs().menuMoreLink, (menuMoreLink) => {
                    menuItems.push(menuMoreLink.get());
                });
            });

            return menuItems;
        }

        /**
         * @description Returns true if widget has submenu
         * @returns {boolean} Return true if submenu exist
         */
        hasSubmenu() {
            return this.has(this.prefs().submenu);
        }

        /**
         * @description Returns true if submenu open
         * @returns {boolean} Return true if submenu opened
         */
        hasOpenedSubmenu() {
            return !!this.isSubmenuOpen;
        }

        /**
         * @description Returns true if submenu in focus
         * @returns {boolean} Return true if submenu focused
         */
        hasFocusedSubmenu() {
            return !!this.isSubmenuInFocus;
        }

        /**
         * @description Remove Tab Index from element
         * @returns {void}
         */
        removeTabIndex() {
            this.ref(this.prefs().itemLink).attr('tabindex', '-1');
        }

        /**
         * @description Add Tab Index to element
         * @returns {void}
         */
        addTabIndex() {
            this.ref(this.prefs().itemLink).attr('tabindex', '0');
        }

        /**
         * @description Open menu if exist and focus first/last element
         * @emits MenuBarItem#megaMenuCloseItems
         * @param {boolean} [focusFirstItem] Item focus flag
         * @param {boolean} [focusLastItem] Item focus flag
         * @returns {void}
         */
        openMenu(focusFirstItem, focusLastItem) {
            if (this.hasSubmenu()) {
                this.ref(this.prefs().itemLink)
                    .attr('aria-expanded', 'true');
                this.ref(this.prefs().submenu)
                    .attr('aria-hidden', 'false');
                this.ref(this.prefs().submenu)
                    .attr('data-tau', 'menu_flyout');
                this.isSubmenuOpen = true;
                this.isSubmenuInFocus = focusFirstItem || focusLastItem;

                if (focusFirstItem) {
                    this.setFocusToFirstItem();
                }

                if (focusLastItem) {
                    this.setFocusToLastItem();
                }

                this.outSideListener = clickOutside(this.ref('self'), () => {
                    /**
                      * @description Event to close all items
                      * @event MenuBarItem#megaMenuCloseItems
                     */
                    this.emit('mega.menu.close.items');
                }, false);

                if (this.outSideListener) {
                    this.onDestroy(this.outSideListener);
                }
            }
        }

        /**
         * @description Close menu
         * @param {boolean} [returnFocus] Focus flag
         * @returns {void}
         */
        closeMenu(returnFocus) {
            if (this.hasSubmenu()) {
                this.ref(this.prefs().itemLink)
                    .attr('aria-expanded', 'false');
                this.ref(this.prefs().submenu)
                    .attr('aria-hidden', 'true');
                this.ref(this.prefs().submenu)
                    .attr('data-tau', false);
                this.isSubmenuOpen = false;
                this.isSubmenuInFocus = false;

                if (returnFocus) {
                    this.focus();
                }

                if (this.outSideListener) {
                    this.outSideListener();
                    this.outSideListener = null;
                }
            }
        }

        /**
         * @description Mouse enter event handler
         * @listens dom#mouseenter
         * @emits MenuBarItem#megaMenuCloseItems
         * @returns {void}
         */
        mouseEnter() {
            if (this.timerCleanerLeave) {
                this.timerCleanerLeave();
            }

            this.timerCleanerEnter = timeout(() => {
                /**
                     * @description Event to close all items
                     * @event MenuBarItem#megaMenuCloseItems
                 */
                this.eventBus().emit('mega.menu.close.items');
                this.openMenu();
            }, ITEM_SWITCH_TIMEOUT);
        }

        /**
         * @description Mouse leave event handler
         * @listens dom#mouseleave
         * @returns {void}
         */
        mouseLeave() {
            if (this.timerCleanerEnter) {
                this.timerCleanerEnter();
            }

            this.timerCleanerLeave = timeout(() => {
                this.closeMenu();
            }, ITEM_SWITCH_TIMEOUT);
        }

        /**
         * @description Close Event handler
         * @listens dom#click
         * @param {HTMLElement} _ Source of keydown event
         * @param {Event} event Event object
         * @returns {void}
         */
        handleClose(_, event) {
            event.preventDefault();
            this.closeMenu();
        }

        /**
         * @description Click Event handler
         * @listens dom#click
         * @param {HTMLElement} _ Source of keydown event
         * @param {Event} event Event object
         * @returns {void}
         */
        handleClick(_, event) {
            if (this.hasSubmenu() && !this.hasOpenedSubmenu()) {
                event.preventDefault();
                this.openMenu();
            }
        }

        /**
         * @description Keydown Event handler
         * @listens dom#keydown
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         * @returns {void}
         */
        handleKeydown(_, event) {
            let preventEventActions = false;

            if (!this.hasFocusedSubmenu()) {
                return;
            }

            switch (event.keyCode) {
                case keyCode.ESC:
                    this.closeMenu(true);
                    preventEventActions = true;

                    break;

                case keyCode.PAGEUP:
                case keyCode.HOME:
                    this.setFocusToFirstItem();
                    preventEventActions = true;

                    break;

                case keyCode.PAGEDOWN:
                case keyCode.END:
                    this.setFocusToLastItem();
                    preventEventActions = true;

                    break;

                case keyCode.UP:
                    this.setFocusToPreviousItem();
                    preventEventActions = true;

                    break;

                case keyCode.DOWN:
                    this.setFocusToNextItem();
                    preventEventActions = true;

                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    return MenuBarItem;
}
