import { timeout } from 'widgets/toolbox/util';

const TRIGGER_SHOW_IN_DEFAULT_STATE = 100;

/**
 * @typedef {ReturnType<typeof import('widgets/global/Modal').default>} Modal
 * @typedef {ReturnType<typeof import('widgets/search/SearchBox').default>} SearchBox
 */

/**
 * @description Search box mobile toggle
 * @param {Modal} Modal Base widget for extending
 * @returns {typeof SearchBoxToggle} SearchBox Toggle class
 */
export default function (Modal) {
    /**
     * @class SearchBoxToggle
     * @augments Modal
     */
    class SearchBoxToggle extends Modal {
        init() {
            super.init();
            const SearchBox = /** @type {SearchBox} */(this.getConstructor('searchBox'));
            this.eachChild(childCmp => {
                if (childCmp instanceof SearchBox) {
                    this.searchBox = childCmp;
                }
            });
            this.eventBus().on('page.show.searchbox', 'openSearch');
        }

        /**
         * @description Cancel handler
         */
        cancel() {
            this.closeSearch();
        }

        openSearch() {
            this.showModal({
                attributes: {
                    'data-tau': 'search_dialog'
                }
            });

            timeout(() => {
                if (this.searchBox) {
                    this.searchBox.showInDefaultState();
                }
            }, TRIGGER_SHOW_IN_DEFAULT_STATE);
        }

        closeSearch() {
            this.closeModal();

            if (this.searchBox) {
                this.searchBox.closeListbox();
                this.searchBox.clearInput(false);
            }
        }

        toggleSearch(ref) {
            if (ref.attr('aria-expanded') === 'true') {
                this.closeSearch();
            } else {
                this.openSearch();
            }
        }

        hide() {
            super.hide();

            if (this.searchBox) {
                this.searchBox.closeListbox();
                this.searchBox.clearInput(false);
            }
        }
    }

    return SearchBoxToggle;
}
