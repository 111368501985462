import { isSmallView } from 'widgets/toolbox/viewtype';
/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description Base MixMiniCart implementation
 * @param {Widget} Widget Modal widget for extending
 * @returns {typeof MixMiniCart} MixMiniCart class
 */
export default function (Minicart) {
    /**
     * @class MixMiniCart
     * @augments Widget
     * @classdesc MixMiniCart widget, that contains all needed logic to display/update MixMiniCart component and extends MixMiniCart widget.
     * @property {string} data-widget - Widget name `MixMiniCart`
     * URL to be used for updating MixMiniCart widget (for ex. in case of removing products from MixMiniCart)
     * @property {string} data-ref Known reference to be available in widget as a `RefElement` object.
     * Known elements for MixMiniCart are: `minicartContent`, `template`
     * @property {object} data-accessibility-alerts - Accessibility alerts per MixMiniCart action
     * Possible values are: `quantitychanged`, `productremoved`
     * @example
     * // use this code to display widget
     * <div
     *     class="b-minicart_popup"
     *     data-widget="mixMiniCart"
     *     data-action-url="${URLUtils.url('Cart-MiniCartShow')}"
     * >
     *     <div
     *         data-ref="minicartContent"
     *         data-count="${pdict.quantityTotal}"
     *     >
     *         .... minicart content
     *     </div>
     * </div>
     */
    class MixMiniCart extends Minicart {
        /**
         * This method was overriden with parent widget.
         *
         * @description Called when needs to re-render minicart (for ex. when cart / product in a cart was updated)
         * @param {object} cart cartModel
         * @returns {Promise} Promise
         */
        reRenderMinicartWrapper(cart) {
            const isShownQtyTotal = parseInt(cart.numItems, 10);
            const quantityTotal = cart.numItems > this.prefs().maxItemsValue
                ? this.prefs().maxItemsStr
                : cart.numItems;

            return this.render('template', {
                quantityTotal: quantityTotal,
                isShownQtyTotal: isShownQtyTotal >= 0,
                isOpen: cart.isOpen
            }, this.ref(
                this.prefs().refMinicartContent
            )).then(() => {
                // do not show minicart if disabled or mobile (small view port)
                if (cart.showMinicart) {
                    this.showMinicart();
                }
            });
        }
    }

    return MixMiniCart;
}
