import { timeout, isDOMElementFocusable } from 'widgets/toolbox/util';

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof AccessibilityFocusMixin} AccessibilityFocusMixin class
 */
export default function (Widget) {
    /**
     * @category widgets
     * @subcategory global
     * @class AccessibilityFocusMixin
     * @augments Widget
     * @classdesc Base implementation for inheritance, to be used to handle focus-related actions like getting set of focusable inputs etc.
     * Also could be used to set focus on:
     * - concrete element in widget's markup by ref element
     * - first input in widget's markup
     * - first focusable element in widget's markup
     * This class is not intended to have a separate DOM widget. It should be used as a mixin for any other target widgets.
     */
    class AccessibilityFocusMixin extends Widget {
        /**
         * @description Get keyboard-focusable elements
         * @param {string} [refName] ref name to get focusable elements in, or nothing to get from `self`
         * @param {string[]} [types] array of elements selectors to search for, or nothing for default set
         * @returns {HTMLElement[]} array containing focusable elements, or empty array if no elements found
         */
        getFocusableElements(refName = 'self', types = []) {
            if (!types.length) {
                types = ['a', 'button', 'input', 'textarea', 'select', 'details'];
            }
            types.push('[tabindex]:not([tabindex="-1"])');
            const selector = types.join(',');
            let result = [];
            this.has(refName, refElement => {
                const htmlEl = refElement.get();
                if (htmlEl) {
                    result = Array.from(htmlEl.querySelectorAll(selector))
                        .filter(el => isDOMElementFocusable(el));
                }
            });
            return result;
        }

        /**
         * @description Get keyboard-focusable input elements
         * @param {string} [refName] ref name to get focusable elements in, or nothing to get from `self`
         * @returns {HTMLElement[]} array containing focusable input elements, or empty array if no elements found
         */
        getFocusableInputElements(refName = 'self') {
            return this.getFocusableElements(refName, ['input', 'textarea', 'select']);
        }

        /**
         * @description Focus first element found in ref with min timeout
         * @param {string} [containerRefName] container ref name to get focusable elements in, or nothing to get from `self`
         * @param {string[]} [types] optional, array of elements selectors to search for, or nothing for default set
         * @returns {void}
         */
        focusFirst(containerRefName = 'self', types = []) {
            const focusableElements = this.getFocusableElements(containerRefName, types);
            if (focusableElements.length) {
                timeout(() => focusableElements[0].focus(), 100);
            }
        }

        /**
         * @description Method to focus on first focusable input, accepts refName as param to search input in
         * @param {string} [containerRefName] container ref name to get focusable elements in, or nothing to get from `self`
         * @returns {void}
         */
        focusFirstInput(containerRefName = 'self') {
            const focusableInputElements = this.getFocusableInputElements(containerRefName);
            if (focusableInputElements.length) {
                timeout(() => focusableInputElements[0].focus(), 100);
            }
        }

        /**
         * @description Method to focus on a concrete focusable element, accepts refName as param to search element
         * @param {string} [elementRefName] element ref name to check if it focusable, end set focus into it
         * @returns {void}
         */
        focusElement(elementRefName) {
            if (elementRefName) {
                this.has(elementRefName, (element) => {
                    const domNode = element.get();
                    if (domNode && isDOMElementFocusable(domNode)) {
                        timeout(() => domNode.focus(), 100);
                    }
                });
            }
        }
    }

    return AccessibilityFocusMixin;
}
