import { submitFormJson } from 'widgets/toolbox/ajax';
import { getCookie, setCookie } from 'widgets/toolbox/cookie';
/**
 * @typedef {typeof import('widgets/global/ConsentTracking').default} ConsentTracking
 */

/**
 * @description Cookie acceptance component (with SFRA supported backend)
 * @param {ReturnType<typeof import('widgets/global/ConsentTracking').default>} ConsentTracking Base consent Tracking widget for extending
 * @returns {typeof HarmonyConsentTracking} Consent Tracking class
 */

export default function (ConsentTracking) {
    /**
     * @class HarmonyConsentTracking
     * @augments ConsentTracking
     * @classdesc HarmonyConsentTracking Widget - used to expose Consent dialog, where customer can either accept
     * <br>or reject Consent matter. Relevant methods will be triggered pressing related buttons
     * @property {string} data-widget - Widget name `consentTracking`
     * @property {string} data-content-url - Dynamically loaded Consent's content
     * @property {string} data-reject-url - Customer chooses `reject` - request will be sent to Reject URL
     * @property {string} data-accept-url - Customer chooses `accept` - request will be sent to Accept URL
     * @example
     * // use this code to display minicart widget
     * <div
     *     data-widget="consentTracking"
     *     data-content-url="${URLUtils.url('ConsentTracking-GetContent', 'logo', 'global-header-vertical-logo', 'content', 'tracking_hint', 'footer', 'age-gate-footer')}"
     *     data-reject-url="${URLUtils.url('ConsentTracking-SetSession', 'consent', 'false')}"
     *     data-accept-url="${URLUtils.url('ConsentTracking-SetSession', 'consent', 'true')}"
     *     data-tracking-selected="${pdict.tracking_consent != null}"
     *     data-consent-api="${pdict.caOnline}"
     * >
     *     <div data-ref="container"></div>
     *
     *     <script type="template/mustache" data-ref="template">
     *         <div
     *             id="consent-dialog"
     *             role="alertdialog"
     *             aria-modal="false"
     *             aria-live="assertive"
     *             aria-atomic="true"
     *             aria-labelledby="consent-dialog-label"
     *             aria-describedby="consent-dialog-description"
     *             data-ref="container"
     *             class="b-notification_panel"
     *         >
     *             {{{content}}}
     *         </div>
     *     </script>
     * </div>
     */
    class HarmonyConsentTracking extends ConsentTracking {
        /**
         * @description Sends GTM event, when 'age gate' was shown
         * @returns {void}
        */
        init() {
            let enableCookieOptIn = $('#enableCookieOptIn').val();
            let isAcceptCookie = getCookie('dw_accept_cookie');

            if (!enableCookieOptIn) {
                if (this.prefs().forceAcceptTracking) {
                    this.accept();
                    return;
                }
            } else {
                if (isAcceptCookie === '') {
                    this.eventBus().emit('gtm.age.gate.shown', this.prefs().customerId);
                }
                if (this.prefs().forceAcceptTracking) { 
                    this.accept();
                }
            }
            super.init();
        }

        acceptConsent() {
            const acceptUrl = this.prefs().acceptUrl;
            submitFormJson(acceptUrl, undefined, 'GET').finally(() => {
                setCookie('dw_consent_cookie', '1', 365, true);
                $('.age-gate-overlay').addClass('h-hidden');
            });
        }

        rejectConsent() {
            const rejectUrl = this.prefs().rejectUrl;
            submitFormJson(rejectUrl, undefined, 'GET').finally(() => {
                $('.age-gate-overlay').addClass('h-hidden');
            });
        }

        enterAgeGate() {
            setCookie('dw_accept_cookie', '1', 365, true);
            this.togglePanel(false);
        }

        /**
         * @description Sends GTM event, when 'age gate' was applied
         * @returns {void}
        */
        accept() {
            super.accept();
            if ($('#harmonytracking').attr('role') === 'main') {
                $('#harmonytracking').removeAttr('role');
            }
            this.eventBus().emit('gtm.age.gate.enter', this.prefs().customerId);
        }

        /**
         * @description Validates legal drinking age and Sends GTM event, when 'age gate' was applied
         * @returns {void}
        */
        submit() {
            var enableCookieOptIn = this.prefs().enableCookieOptIn;
            if($('.age-gate-enter-form .b-form-message.h-hidden').length !== 3) {
                return;
            }
            let month = $('#month').val();
            let date = $('#day').val();
            let year = $('#year').val();
            if(!month) {
                $('.b-form-message.month').removeClass('h-hidden');
                $('#month').focus();
                return;
            }
            if(!date) {
                $('.b-form-message.day').removeClass('h-hidden');
                $('#day').focus();
                return;
            }
            if(!year) {
                $('.b-form-message.year').removeClass('h-hidden');
                $('#year').focus();
                return;
            }
            if(this.ageGateEntrance(date, month, year) >= 21) {
                if (enableCookieOptIn === true) {
                    this.enterAgeGate();
                } else {
                    super.accept();
                }
                if ($('#harmonytracking').attr('role') === 'main') {
                    $('#harmonytracking').removeAttr('role');
                }
                this.eventBus().emit('gtm.age.gate.enter', this.prefs().customerId);
            } else {
                $('.age-gate-enter-form').addClass('h-hidden');
                $('.too-young').removeClass('h-hidden');
            }
        }

        ageGateEntrance(birth_day, birth_month, birth_year) {
            let age = 0;
			let today_date = new Date();
			let today_year = today_date.getFullYear();
			let today_month = today_date.getMonth();
			let today_day = today_date.getDate();
			if (!birth_day || !birth_month || !birth_year) {
				age = 0;
			} else {
				age = today_year - birth_year;

				if ( today_month < (birth_month - 1)) {
					age--;
				}
				if (((birth_month - 1) == today_month) && (today_day < birth_day)) {
					age--;
				}
			}
			return age;
		}

        /**
         * @description validates age gate form input elements
         * @param {HTMLElement} ele age gate form input element
         * @param {KeyboardEvent} event age gate form input element event
         * @returns {void}
         */
        validate(ele, event) {
            const inputValue = event.target && parseInt(event.target.value);
            var res = /^\d+$/.test(event.target.value);
            switch(event.target && event.target.name) {
                case "month":
                    if(inputValue < 1 || inputValue > 12 || !(/^\d+$/.test(event.target.value))) {
                        $('.b-form-message.month').removeClass('h-hidden');
                    } else {
                        $('.b-form-message.month').addClass('h-hidden');
                    }
                    break;
                case "day":
                    if(inputValue < 1 || inputValue > 31 || !(/^\d+$/.test(event.target.value)) || ($('#month').val() && ($('#month').val() === "2"
                    || $('#month').val() === "02") && inputValue > 29)
                    || (["4","6","9","11","04","06","09"].includes($('#month').val()) && inputValue > 30)) {
                        $('.b-form-message.day').removeClass('h-hidden');
                    } else {
                        $('.b-form-message.day').addClass('h-hidden');
                    }
                    break;
                case "year":
                    if(inputValue < 1000 || inputValue > 9999 || !(/^\d+$/.test(event.target.value))) {
                        $('.b-form-message.year').removeClass('h-hidden');
                    } else {
                        $('.b-form-message.year').addClass('h-hidden');
                    }
                    break;
            }
            let month = $('#month').val();
            let date = $('#day').val();
            if(month && date && parseInt(date) > 0 && parseInt(date) <= 31 && ((month === "2"
            || month === "02") ? parseInt(date) < 30 : true)
            && (["4","6","9","11","04","06","09"].includes(month) ? parseInt(date) < 31 : true)) {
                $('.b-form-message.day').addClass('h-hidden');
            } else if(month && date) {
                $('.b-form-message.day').removeClass('h-hidden');
            }
        }

        /**
         * @description Reject Consent handler
         * @listens dom#click
         * @returns {void}
         */
         async reject() {
             try {
                await super.reject();
             }
             catch (err) {
                 console.log(err);
             }
            this.eventBus().emit('gtm.age.gate.notrack');
            // reload page to disable gtm
            location.reload(true);
        }

        prefs() {
            return {
                classesPanelOpen: 'm-age_gate-open',
                ...super.prefs()
            };
        }

        loadContent() {}

        /**
         * @description Toggle Panel
         * @param {boolean} isVisible - `true` if Consent's dialog should be shown, `false` - otherwise
         */
        togglePanel(isVisible) {
            this.ref('html').toggleClass(this.prefs().classesPanelOpen, isVisible);

            if (!isVisible) {
                this.eventBus().emit('gtm.age.gate.close');
            }
        }
    }

    return HarmonyConsentTracking;
}
