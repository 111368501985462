/**
 * @description HarmonyEmailSubscribe implementation
 * @param {ReturnType<typeof import('widgets/forms/ajax/EmailSubscribe').default>} EmailSubscribe Base widget for extending
 * @returns {typeof HarmonyEmailSubscribe} HarmonyEmailSubscribe class
 */

export default function (EmailSubscribe) {
    class HarmonyEmailSubscribe extends EmailSubscribe {
        showAgreeToPrivacy() {
        }

        /**
         * @param {any} data Server JSON response once form submitted
         */
        onSubmitted(data) {
            const gtmData = this.prepareGtmData(data);

            this.eventBus().emit('gtm.form.submit', gtmData);

            if (data.success) {
                this.eventBus().emit('monetate.track.newsletter.signup');
            }

            super.onSubmitted(data);
        }
    }

    return HarmonyEmailSubscribe;
}
