/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import { log } from './toolbox/util';
import { RefElement } from 'widgets/toolbox/RefElement';
import EventBusWrapper from 'widgets/toolbox/EventBusWrapper';
import widgetsMgr from './widgetsMgr';

const templateProp = '@@@_template';
const noop = () => { };

/**
 * @description save template during webpack HMR
 * @param {RefElement} renderTo rendering element
 * @param {string} template template string
 */
function saveTemplateForHotReload(renderTo, template) {
    if (!PRODUCTION) { // save template in element for hot reload
        const tmpEl = renderTo.get();
        if (tmpEl) {
            tmpEl[templateProp] = template;
        }
    }
}

/**
 * @description Find modified element
 * @param {ChildNode} nodeOrig element
 * @param {number[]} routeOrig path to element
 * @returns {ChildNode|false} modified element or false if not found
 */
function getFromRoute(nodeOrig, routeOrig) {
    let node = nodeOrig;
    const route = routeOrig.slice();
    while (route.length > 0) {
        if (node && node.childNodes) {
            const c = route.splice(0, 1)[0];
            node = node.childNodes[c];
        } else {
            return false;
        }
    }
    return node;
}

/**
 * @typedef Diff
 * @property {string} name
 * @property {string} action
 * @property {string} value
 * @property {string} oldValue
 * @property {string} newValue
 * @property {number[]} route
 */

/**
 * @typedef Info
 * @property {HTMLElement} node
 * @property {Diff} diff
 */

/**
 * @description Callback assigned on diff-dom post hook after applying changes
 * @param {string} action diff-dom action happens with DOM node
 * @param {HTMLElement} node changed element
 * @param {Info} info diff-dom changes object
 * @returns {() => void} callback
 */
function getDelayedCallback(action, node, info) {
    return () => {
        if (action === 'modifyAttribute') {
            widgetsMgr.removeAttribute(node, info.diff);
            widgetsMgr.addAttribute(node, info.diff);
        } else if (action === 'removeAttribute') {
            widgetsMgr.removeAttribute(node, info.diff);
        } else if (action === 'addAttribute') {
            widgetsMgr.addAttribute(node, info.diff);
        } else {
            throw new Error(`Unknown action "${action}"`);
        }
    };
}

/** Core component to extend for each widget */
/**
 * @category widgets
 * @subcategory framework
 */
class Widget {
    /**
     * @description Creates self RefElement.js wrapper, add initial states, configuration.
     * @param {HTMLElement} el DOM element
     * @param {{[x: string]: object|string|number|boolean|null|undefined}} config widget config
     */
    constructor(el, config = {}) {
        /**
         * @description RefElements related to current widget
         * @type {{[key : string] : RefElement} | undefined}
         */
        this.refs = Object.create(null);
        if (this.refs) { // for type check
            this.refs.self = new RefElement([el]);
        }

        /**
         * @description config from data attributes
         * @type {{[x: string]: object|string|number|boolean|null|undefined}}
         */
        this.config = config;

        /**
         * @description functions which executing during destructuring of widget
         * @type {Array<Function>|undefined}
         */
        this.disposables = undefined;
        /**
         * @description function assigned by WidgetsMgr after constructor call to
         * provide ability for parent widget listen child widget event
         * fired by method `this.emit()`
         * @type {(eventName: string, ...args: any) => void}
         */
        this.parentHandler = noop;

        this.getConstructor = id => id;

        /**
         * @description children widgets
         * @type {Widget[]|undefined}
         */
        this.items = [];

        if (this.ref('self').attr('id')) {
            this.id = this.ref('self').attr('id');
        }
        if (!this.id && this.config.id) {
            this.id = this.config.id;
        }

        /**
         * @type {boolean} state explain widget is shown
         */
        this.shown = !this.config.hidden && !this.ref('self').hasAttr('hidden');

        if (typeof this.config.passEvents === 'string') {
            if (!PRODUCTION) {
                log.warn('Usage of "data-pass-events" has been deprecated. Please take a look "data-forward-to-parent"');
            }
            this.config.passEvents.split(':').forEach(pair => {
                const [methodName, emitEvent] = pair.split('-');
                const self = this;
                // @ts-ignore
                this[methodName] = (...args) => {
                    self.parentHandler(emitEvent, ...args);
                };
            });
        }

        this.isRefreshingWidget = false;
    }

    get length() {
        return 1;
    }

    /**
     * @description call class method provided in argument `name` with arguments `(classContext, value, ...args)` or
     * call `RefElement.data(name, value)` for wrapper widget DOM node to get or set data attributes
     * @param {string} name of class method or data-attribute
     * @param {any} [value] if class has method `name` - will be used as argument, otherwise will be used as `value` to set into data attribute `name`
     * @param {any} [args] if class has method `name` - will be used as argument, otherwise would not be used
     * @returns {any}
     * - execution result of the method specified as `name` argument
     * - if class has no method `name` - get or set data attribute `name` depending on provided or no `value` argument
     */
    data(name, value, ...args) {
        /**
         * @type {Function}
         */
        // @ts-ignore
        const classMethod = this[name];
        if (typeof classMethod === 'function') {
            return classMethod.call(this, value, ...args);
        }
        return this.ref('self').data(name, value);
    }

    /**
     * @description call class method provided in argument `name` with arguments `...args` if method exists
     *
     * @param {string} name of class method
     * @param {any} [args] if class has method `name` - will be used as arguments
     * @returns {any} result of call (any) or undefined, if method not found
     */
    callIfExists(name, ...args) {
        const classMethod = this[name];
        if (typeof classMethod === 'function') {
            return classMethod.call(this, ...args);
        }
    }

    /**
     * @description Emit widget event that will be listened by parrent handler with context of current widget
     * @param {string} eventName name of event
     * @param  {...any} args argument to pass
     * @returns {void}
     */
    emit(eventName, ...args) {
        this.parentHandler(eventName, this, ...args);
    }

    /**
     * @description Emit widget event that will be listened by parrent handler without context of current widget
     * @param {string} eventName name of event
     * @param  {...any} args argument to pass
     * @returns {void}
     */
    emitWithoutContext(eventName, ...args) {
        this.parentHandler(eventName, ...args);
    }

    /**
     * @description In case if you need to emit/subscribe global event you may get an event bus with this method.
     * @description Get widget's EventBusWrapper instance
     * @returns {EventBusWrapper} Instance of EventBusWrapper
     */
    eventBus() {
        if (!this._eventBus) {
            this._eventBus = new EventBusWrapper(this);
            this.onDestroy(() => {
                this._eventBus = undefined;
            });
        }
        return this._eventBus;
    }

    /**
     * @description Merge data-attribute properties to default widget properties (defined in widget javascript file, or extended from parent widget)
     * and returns widget configuration map
     * @returns {{[key: string]: any}} config widget config
     */
    prefs() {
        return {
            /** is component hidden */
            hidden: false,
            /** class of component during loading */
            classesLoading: 'm-widget-loading',
            /** class of component once component loaded and inited */
            /** id of component */
            id: '',
            // configs form data attributes
            ...this.config
        };
    }

    /**
     * @description This method executed in the end of [Widgets Application Lifecycle,]{@link tutorial-WidgetsApplicationLifecycle.html}
     *  in order to add business logic before initialization is finished.
     */
    init() {
        this.ref('self').removeClass(this.prefs().classesLoading);
    }

    /**
     * @description Get child refElement by key from prefs() or id
     * @param {string} name Id of RefElement or preference key that contains id
     * @returns {RefElement} found RefElement instance or empty RefElement if doesn't exist
     * @protected
     */
    ref(name) {
        const prefsName = this.prefs();
        const prefsValue = prefsName[name];

        let ref;

        if (prefsValue) {
            ref = this.refs && this.refs[prefsValue];

            if (ref) {
                return ref;
            }
        }

        ref = this.refs && this.refs[name];

        if (ref) {
            return ref;
        }
        if (!PRODUCTION) {
            log.warn(`Reference "${name}" is not found in widget "${this.constructor.name}" `, this);
        }
        return new RefElement([]);
    }

    /**
     * @description search `refElement` inside of widget by `name`
     * - if `cb` exist - run `cb` with found `refElement` as argument
     * - otherwise return existing state
     *
     * @param {string} name Id of widget/refElement or preference that contain id of widget/refElement
     * @param {(arg: RefElement) => void} [cb] callback will be executed if element found
     * @returns {boolean} true if found `refElement`
     */
    has(name, cb) {
        const ref = this.refs && this.refs[name];

        if (ref) {
            if (cb) {
                cb(ref);
            }

            return true;
        }
        return false;
    }

    /**
     * @description Destroys widgets. Only for internal usage
     *
     * @protected
     */
    destroy() {
        if (this.disposables) {
            this.disposables.forEach(disposable => disposable());
            this.disposables = undefined;
        }

        if (this.items && this.items.length) {
            this.items.forEach(item => {
                if (item && typeof item.destroy === 'function') {
                    item.destroy();
                }
            });
        }

        this.items = undefined;
        this.refs = undefined;
    }

    /**
     * @description Attach an event handler function for one or more events to the selected elements.
     * @param {string} eventName ex: 'click', 'change'
     * @param {(this: this, element: HTMLElement, event: Event) => any} cb callback
     * @param {string|EventTarget} selector CSS selector
     * @param {boolean} passive is handler passive?
     * @returns {(() => void)[]} dispose functions for each elemenet event handler
     *
     * @protected
     */
    ev(eventName, cb, selector = '', passive = true) {
        /**
         * @type EventTarget[]
         */
        var elements = [];
        var self = this;

        if (selector instanceof Element || selector === window) {
            elements = [selector];
        } else if (typeof selector === 'string' && this.refs && this.refs.self) {
            const el = this.refs.self.get();
            if (el) {
                elements = Array.from(el.querySelectorAll(selector));
            }
        } else if (this.refs && this.refs.self) {
            const el = this.refs.self.get();
            if (el) {
                elements = [el];
            }
        }

        return elements.map(element => {
            // @ts-ignore
            let fn = function fn(...args) {
                // @ts-ignore
                return cb.apply(self, [this, ...args]);
            };

            element.addEventListener(eventName, fn, passive ? { passive: true } : { passive: false });
            const dispose = () => {
                if (fn) {
                    element.removeEventListener(eventName, fn);
                    // @ts-ignore
                    fn = undefined;
                }
            };
            this.onDestroy(dispose);
            dispose.eventName = eventName;
            return dispose;
        });
    }

    /**
     * @description Assign function to be executed during widget destructuring
     * @param {Function} fn function to be executed during destroy
     * @returns {Function} called function
     */
    onDestroy(fn) {
        if (!this.disposables) {
            this.disposables = [];
        }
        this.disposables.push(fn);
        return fn;
    }

    /**
     * @description executed when widget is re-rendered
     */
    onRefresh() {
        // executed when widget is re-rendered
        this.shown = !this.config.hidden && !this.ref('self').hasAttr('hidden');

        if (this.ref('self').attr('id')) {
            this.id = this.ref('self').attr('id');
        } else if (this.ref('self').data('id')) {
            this.id = this.ref('self').data('id');
        }
    }

    /**
     * @description Search for child component instance and execute callback with this instance as argument
     * @template T
     * @param {string} id of component
     * @param {(args0: any) => T} cb callback with widget
     * @returns {T|undefined} callback result if element found, otherwise undefined
     */
    getById(id, cb) {
        if (id && this.items && this.items.length) {
            for (var c = 0; c < this.items.length; c += 1) {
                const item = this.items[c];

                if (item && item.id === id) {
                    return cb.call(this, item);
                }
            }
        }

        if (!PRODUCTION) {
            log.warn(`Widget with id "${id}" is not found in children of "${this.constructor.name}" `, this);
        }
    }

    /**
     * Travels over nearest/next level child components
     *
     * @template T
     * @param {(args0: any) => T} fn callback
     * @returns {T[]} arrays of callback results
     */
    eachChild(fn) {
        if (this.items && this.items.length) {
            return this.items.map(item => {
                return fn(item);
            });
        }
        return [];
    }

    /**
     * @description Hide widget
     * @returns {this} current instance for chaining
     */
    hide() {
        if (this.shown) {
            this.ref('self').hide();
            this.shown = false;
        }

        return this;
    }

    /**
     * @description Show widget
     * @returns {this} current instance for chaining
     */
    show() {
        if (!this.shown) {
            this.ref('self').show();
            this.shown = true;
        }

        return this;
    }

    /**
     * @description Show or hide widget element
     * @param {boolean} [display]  Use true to show the element or false to hide it.
     * @returns {this} current instance for chaining
     */
    toggle(display) {
        const state = typeof display !== 'undefined' ? display : !this.shown;

        this[state ? 'show' : 'hide']();
        return this;
    }

    /**
     * @description Returns whether the widget is hidden
     * @returns {boolean} Hidden flag
     */
    isHidden() {
        return !this.shown;
    }

    /**
     * @description Returns whether the widget is shown
     * @returns {boolean} Shown flag
     */
    isShown() {
        return this.shown;
    }

    /**
     * @description This method provides ability to dynamically render HTML for widgets.
     * @param {string} templateRefId id of template
     * @param {object} data data to render
     * @param {RefElement} [renderTo] render into element
     * @param {string} [strToRender] pre-rendered template
     * @returns {Promise<any>} resolved if rendered or rejected if no found template promise
     *
     * @protected
     */
    render(templateRefId = 'template', data = {}, renderTo = this.ref('self'), strToRender = '') {
        return import(/* webpackChunkName: 'dynamic-render' */'mustache').then((Mustache) => {
            // eslint-disable-next-line complexity
            if (!this.cachedTemplates) {
                /**
                 * @description Container to cache templates for rendering
                 * @type {{[x: string]: string|undefined}}
                */
                this.cachedTemplates = {};
            }

            let template = this.cachedTemplates && this.cachedTemplates[templateRefId];

            if (!strToRender && !template) {
                const templateElement = this.ref(templateRefId).get();

                if (templateElement) {
                    template = templateElement.textContent || templateElement.innerHTML;
                    Mustache.parse(template);
                    this.cachedTemplates[templateRefId] = template;

                    saveTemplateForHotReload(renderTo, template);
                } else {
                    // eslint-disable-next-line no-lonely-if
                    if (!PRODUCTION) {
                        const tmpEl = renderTo.get();
                        if (tmpEl && tmpEl[templateProp]) {
                            template = tmpEl[templateProp];
                        } else {
                            log.error(`Unable find template ${templateRefId}`, this);
                            return Promise.reject(new Error(`Unable find template ${templateRefId}`));
                        }
                        log.error(`Unable find template ${templateRefId}`, this);
                        return Promise.reject(new Error(`Unable find template ${templateRefId}`));
                    }
                }
            }

            if (data) {
                data.lower = function () {
                    return function (text, render) {
                        return render(text).toLowerCase();
                    };
                };
            }

            const renderedStr = strToRender || Mustache.render(template || '', data);
            const el = renderTo.get();

            if (el && el.parentNode) {
                // use new document to avoid loading images when diffing
                const newHTMLDocument = document.implementation.createHTMLDocument('diffDOM');
                const diffNode = /** @type {HTMLElement} */(newHTMLDocument.createElement('div'));

                diffNode.innerHTML = renderedStr;

                return this.applyDiff(el, diffNode);
            } else {
                log.error(`Missing el to render ${templateRefId}`, this);
            }

            return Promise.resolve();
        });
    }

    /**
     * @description Find diff between `el` and `diffNode` and apply diff by `diff-dom`
     * @param {HTMLElement} el Element before change
     * @param {HTMLElement} diffNode Changed element to find diff
     * @returns {Promise} when diff founded
     */
    applyDiff(el, diffNode) {
        return import(/* webpackChunkName: 'dynamic-render' */ 'diff-dom/src/index')
            .then(/** @type {Function[]} */ ({ DiffDOM }) => {
                const delayedAttrModification = [];
                const dd = new DiffDOM({
                    // disableGroupRelocation: true,
                    filterOuterDiff(/** @type {any} */t1) {
                    // @ts-ignore
                        if (t1.attributes && t1.attributes['data-skip-render']) {
                        // will not diff childNodes
                            t1.innerDone = true;
                        }
                    },
                    /**
                     * @param {Info} info changes from diff-dom
                     */
                    postDiffApply(info) {
                        const { action, name } = info.diff;
                        if (
                            ['removeAttribute', 'addAttribute', 'modifyAttribute'].includes(action)
                        && typeof name === 'string'
                        && name.startsWith('data-') // handle only data attr changes
                        && info.node instanceof HTMLElement
                        ) {
                            const node = getFromRoute(el, info.diff.route);

                            if (node && node instanceof HTMLElement) {
                                const delayedCallback = getDelayedCallback(action, node, info);
                                // data-initialized should be executed at last point
                                delayedAttrModification[name === 'data-initialized' ? 'push' : 'unshift'](delayedCallback);
                            }
                        }
                        if (
                            (action === 'addAttribute' || action === 'removeAttribute')
                            && info.node.nodeName === 'INPUT'
                            && name === 'checked'
                        ) {
                            const node = /** @type {HTMLInputElement} */(info.node);
                            if (node.type === 'checkbox' || node.type === 'radio') {
                                node.checked = (action === 'addAttribute');
                            }
                        }
                    }
                });
                // Normalize DOM tree before applying diff to prevent infinite loop
                // Infinite loop appear in case when few text nodes became one by one
                el.normalize();

                const diff = dd.diff(el, diffNode.firstElementChild);

                if (diff && diff.length) {
                    // console.log(diff);
                    dd.apply(el, diff);
                }
                // report attr modification once app changes are applied
                delayedAttrModification.forEach(action => action());

                if (diff && diff.length) {
                    this.eventBus().emit('rendering.applied');
                }
            });
    }
}

export default Widget;

/**
 * @typedef IEvent
 * @property {string} [events.childID]
 * @property {Function} [events.childClass]
 * @property {string} events.eventName
 * @property {Function} events.fn
 * @property {Function} [cb]
 */
