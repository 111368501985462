const keyCode = Object.freeze({
    ESC: 27,
    PAGEUP: 33,
    PAGEDOWN: 34,
    END: 35,
    HOME: 36,
    UP: 38,
    DOWN: 40,
    RIGHT: 39,
    LEFT: 37
});

/**
 * @typedef {ReturnType<typeof import('widgets/global/ListAccessibility').default>} ListAccessibility
 * @typedef {ReturnType<typeof import('widgets/header/MenuBarItem').default>} MenuBarItem
 */

/**
 * @param {ListAccessibility} ListAccessibility Base widget for extending
 * @returns {typeof HamburgerMenuItem} HamburgerMenuItem widget
 */
export default function (ListAccessibility) {
    /**
     * @category widgets
     * @subcategory header
     * @class HamburgerMenuItem
     * @augments ListAccessibility
     * @classdesc Represents MenuPanel component with next features:
     * 1. Represents Menu Item with specific logic for submenus, handling viewtype changes, keyboard navigation, mouse click.
     * 2. Allow open/close menu
     * 3. Allow set/clear Submenu HTML
     * Submenu implemented as empty container where pasted html of selected category taken from original markup that was implemented for MegaMenu,
     * in order to not dublicate nav structure and reuse it as much as possible and to implement reliable slide animation
     * {@link HamburgerMenuItem} may contain another {@link HamburgerMenuItem}. {@link MenuBarItem} Widget can contain another {@link MenuBarItem} like submenu.
     * @example <caption>Example of HamburgerMenuItem widget usage</caption>
     * <li role="none"
     *    class="b-menu_bar-item"
     *    data-widget="hamburgerMenuItem"
     *    data-prevent-show-submenu="false"
     *    data-event-keydown="handleKeydown"
     *>
     *    <a
     *        role="menuitem"
     *        class="b-menu_bar-link m-has-submenu"
     *        aria-label="${category.name}"
     *        aria-haspopup="true"
     *        aria-expanded="false"
     *        itemprop="url name"
     *        tabindex="${status.first ? 0 : -1}"
     *        href="<isprint value="${category.url}" encoding="htmldoublequote"/>"
     *        data-ref="itemLink"
     *    >
     *        ${category.name}
     *    </a>
     *    <isset name="menuItem" value="${category}" scope="page" />
     *    <div
     *        role="menu"
     *        class="b-menu_bar-flyout"
     *        aria-label="${category.name}"
     *        aria-hidden="true"
     *        data-ref="submenu"
     *    >
     *        <div role="none" class="b-menu_bar-flyout_inner">
     *            <isinclude template="components/header/menuItem" />
     *
     *            <button
     *                role="menuitem"
     *                class="b-menu_bar-flyout_close"
     *                aria-label="${Resource.msg('common.close', 'common', null)}"
     *                title="${Resource.msg('common.close', 'common', null)}"
     *                type="button"
     *                tabindex="-1"
     *            >
    *                <isinclude template="/common/svg/close" />
     *            </button>
     *        </div>
     *    </div>
     *</li>
     * @property {string} data-widget - Widget name `hamburgerMenuItem`
     * @property {string} data-event-keydown - Event listener for `handleKeydown` method
     * @property {boolean} [data-prevent-show-submenu=false] - prevent showing submenu
     */

    class HamburgerMenuItem extends ListAccessibility {
        /**
         * @description calls super constructor, adds initial states etc.
         *
         * @param {HTMLElement} el DOM element
         * @param {{[x: string]: object|string|number|boolean|null|undefined}} config widget config
         */
        constructor(el, config = {}) {
            super(el, config);
            this.isSubmenuOpen = false;
        }

        prefs() {
            return {
                preventShowSubmenu: false,
                submenu: 'submenu',
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         * @returns {void}
         */
        init() {
            this.defineItems();
            this.ref('self').attr('role', 'presentation');
            this.has(this.prefs().itemLink, () => {
                this.ref(this.prefs().itemLink).attr('role', 'menuitem');
            });

            this.onDestroy(() => {
                this.ref('self').attr('role', null);
                this.has(this.prefs().itemLink, () => {
                    this.ref(this.prefs().itemLink).attr('role', null);
                });
            });
        }

        /**
         * @description Mark Submenu Opened
         * @returns {void}
         */
        markSubmenuOpened() {
            this.isSubmenuOpen = true;
        }

        /**
         * @description Returns true if have submenu
         * @returns {boolean} Return true if submenu exist
         */
        hasSubmenu() {
            return this.has(this.prefs().submenu) && !this.prefs().preventShowSubmenu;
        }

        /**
         * @description Returns true if submenu open
         * @returns {boolean} Return true if submenu opened
         */
        hasOpenedSubmenu() {
            return !!this.isSubmenuOpen;
        }

        /**
         * @description Open next menu level if exist
         * @emits HamburgerMenuItem#navPanelNextLevel
         * @returns {void}
         */
        openMenu() {
            if (this.hasSubmenu()) {
                let submenuHTML;
                let itemLinkRefEl;

                this.has(this.prefs().itemLink, (itemLink) => {
                    itemLinkRefEl = itemLink;
                });
                this.has(this.prefs().submenu, (submenu) => {
                    const submenuElement = submenu.get();

                    if (submenuElement) {
                        submenuHTML = submenuElement.innerHTML;
                    }
                });
                /**
                 * @description Event to open nav panel next level
                 * @event HamburgerMenuItem#navPanelNextLevel
                 */
                this.eventBus().emit('nav.panel.next.level', {
                    htmlMarkup: submenuHTML,
                    parentMenuItem: itemLinkRefEl
                });
            }
        }

        /**
         * @description Clear submenu HTML
         * @returns {void}
         */
        clearSubmenuHTML() {
            this.has(this.prefs().submenu, (submenu) => {
                const submenuElement = submenu.get();

                if (submenuElement) {
                    submenuElement.innerHTML = '';
                }
            });
        }

        /**
         * @description Set Submenu HTML
         * @param {string} submenuHTML Submenu HTML
         * @returns {void}
         */
        setSubmenuHTML(submenuHTML) {
            this.has(this.prefs().submenu, (submenu) => {
                const submenuElement = submenu.get();

                if (submenuElement) {
                    submenuElement.innerHTML = submenuHTML;
                }
            });
        }

        /**
         * @description Click Event handler
         * @listens dom#click
         * @param {HTMLElement} _ Source of keydown event
         * @param {Event} event Event object
         * @returns {void}
         */
        handleClick(_, event) {
            if (this.hasSubmenu()) {
                event.preventDefault();
                this.openMenu();
            }
        }

        /**
         * @description Keydown Event handler
         * @listens dom#keydown
         * @emits HamburgerMenuItem#navPanelPreviousLevel
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         * @returns {void}
         */
        handleKeydown(_, event) {
            let preventEventActions = false;

            if (!this.hasOpenedSubmenu()) {
                return;
            }

            switch (event.keyCode) {
                case keyCode.LEFT:
                    /**
                     * @description Event to nav panel previous level
                     * @event HamburgerMenuItem#navPanelPreviousLevel
                     */
                    this.eventBus().emit('nav.panel.previous.level');
                    preventEventActions = true;

                    break;

                case keyCode.RIGHT:
                    this.currentItem.openMenu();
                    preventEventActions = true;

                    break;

                case keyCode.PAGEUP:
                case keyCode.HOME:
                    this.setFocusToFirstItem();
                    preventEventActions = true;

                    break;

                case keyCode.PAGEDOWN:
                case keyCode.END:
                    this.setFocusToLastItem();
                    preventEventActions = true;

                    break;

                case keyCode.UP:
                    this.setFocusToPreviousItem();
                    preventEventActions = true;

                    break;

                case keyCode.DOWN:
                    this.setFocusToNextItem();
                    preventEventActions = true;

                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    return HamburgerMenuItem;
}
