/**
 * @typedef {ReturnType<typeof import('./InputText').default>} InputText
 */
/**
 * @description Base InputTel implementation
 * @param {InputText} InputText Base widget for extending
 * @returns {typeof InputTel} Input Tel class
 */
export default function (InputText) {
    /**
     * @category widgets
     * @subcategory forms
     * @class InputTel
     * @augments InputText
     * @classdesc Input type Tel implementation. Represents input `tel` element together with widget-related HTML markup.
     * HTML structure assembled on backend and injected into resulted html markup by using `formElement` component
     * and dynamic forms configuration JSON.
     * @property {string} data-widget - Widget name `inputTel`
     * @example <caption>InputSelect definition in dynamicforms.json</caption>
     * ...
     * // fields -> input -> tel
     *       tel: {
     *           extends: 'fields.input.base',
     *           validation: {
     *               'errors.parse': 'validation.errors.phone'
     *           },
     *           'label.text': 'form.address.phone',
     *           'caption.show': true,
     *           element: {
     *               type: 'tel',
     *               minLength: 5,
     *               maxLength: 20,
     *               attributes: {
     *                   autocomplete: 'tel',
     *                   'data-event-input': 'onInput',
     *                   'data-event-keypress': 'onKeypress',
     *                   'data-event-change': 'onChange',
     *                   'data-event-paste.prevent': 'onPaste'
     *               }
     *           },
     *           localization: {
     *               us: {
     *                   widget: {
     *                       attributes: {
     *                           'data-mask': '9-999-999-9999'
     *                       }
     *                   },
     *                   validation: {
     *                       'patterns.parse': 'validation.patterns.parse.phone.us'
     *                   },
     *                   caption: {
     *                       text: 'form.address.phone.caption.us'
     *                   }
     *               },
     *               ca: {
     *                  widget: {
     *                      attributes: {
     *                           'data-mask': '9-999-999-9999'
     *                       }
     *                   },
     *                   validation: {
     *                       'patterns.parse': 'validation.patterns.parse.phone.ca'
     *                   },
     *                   caption: {
     *                       text: 'form.address.phone.caption.ca'
     *                   }
     *               },
     *               gb: {
     *                   widget: {
     *                       attributes: {
     *                           'data-mask': '+99 (9)99 9999 9999'
     *                       }
     *                   },
     *                   validation: {
     *                       'patterns.parse': 'validation.patterns.parse.phone.gb'
     *                   },
     *                   caption: {
     *                       text: 'form.address.phone.caption.gb'
     *                   }
     *               }
     *           }
     *       },
     * ...
     * // fields -> generic -> phone
     * phone: {
     *     extends: 'fields.input.tel',
     *     element: {
     *         required: true
     *     }
     * },
     * ...
     * @example <caption>Insertion of InputSelect inside ISML templates</caption>
     * <isset name="formElement" value="${require('forms/formElement')}" scope="page"/>
     * ...
     * <form>
     *     ...
     *     <isprint value="${
     *         formElement(pdict.addressForm.phone, pdict.addressFormOptions).render()
     *     }" encoding="off"/>
     *     ...
     * </form>
     * @example <caption>Resulted HTML structure for InputPhone</caption>
     *   <div data-widget="inputTel" data-mask="9-999-999-9999"
     *       class="b-form_section m-required m-invalid"
     *       data-id="dwfrm_shipping_shippingAddress_addressFields_phone"
     *       data-validation-config="... validation config"
     *   >
     *       <label class="b-form_section-label" for="dwfrm_shipping_shippingAddress_addressFields_phone">
     *           <span class="b-form_section-required" aria-hidden="true">
     *               *
     *           </span>
     *           Phone Number
     *       </label>
     *   <input data-ref="field" id="dwfrm_shipping_shippingAddress_addressFields_phone"
     *       type="tel" class="b-input m-invalid" placeholder="" aria-describedby="dwfrm_shipping_shippingAddress_addressFields_phone-error"
     *       pattern="^(\d-\d{3}-\d{3}-\d{4})$" name="dwfrm_shipping_shippingAddress_addressFields_phone" required="" value="" maxlength="20"
     *       aria-required="true" minlength="5" autocomplete="tel" data-event-input="onInput" data-event-keypress="onKeypress"
     *       data-event-change="onChange" data-event-paste.prevent="onPaste" data-event-blur="validate"
     *   >
     *   <div role="alert" class="b-form_section-message" data-ref="errorFeedback" id="dwfrm_shipping_shippingAddress_addressFields_phone-error">This field is required.</div>
     *       <span id="dwfrm_shipping_shippingAddress_addressFields_phone-caption" class="b-form_section-caption">
     *           Example: 1-888-123-4567
     *       </span>
     *   </div>
     */
    class InputTel extends InputText {

    }

    return InputTel;
}
