/**
 * @module debounce
 * @category widgets
 * @subcategory toolbox
 */

// CLARIFY: Do we need default behaviour when both invokeLeading & invokeTrailing are not passed or they are false ?
/* eslint-disable jsdoc/check-tag-names */
/**
 * Returns a function, that, as long as it continues to be invoked, will not be triggered.
 * The function will be called after it stops being called for N milliseconds.
 * If `invokeLeading` is passed, trigger the function on the leading edge, instead of the trailing.
 * If `invokeTrailing` is passed, trigger the function on the trailing edge additionally.
 *
 * @template T
 * @param {(args0: T) => void} callbackFunction - callback
 * @param {number} delay - timeout duration
 * @param {boolean} [invokeLeading] - executing the debounce without waiting
 * @param {boolean} [invokeTrailing]
 * execute trailing function after timeout: could be used in combination with 'invokeLeading' parameter
 * to execute both functions - leading and trailing ones
 * @returns {(args0: T) => void} - Debounce function
 */
export function debounce(callbackFunction, delay, invokeLeading = false, invokeTrailing = false) {
    /**
     * @type {NodeJS.Timeout|null}
     */
    let timeout;
    const executeTrailing = !invokeLeading || invokeTrailing;

    return function debounceInner(...args) {
        // @ts-ignore
        const context = this;

        // Leading function execution
        if (invokeLeading && !timeout) {
            callbackFunction.apply(context, args);
        }

        // Set timeout for trailing function execution
        if (executeTrailing) {
            if (timeout) {
                clearTimeout(timeout);
            }

            timeout = setTimeout(() => {
                timeout = null;
                callbackFunction.apply(context, args);
            }, delay);
        }
    };
}

// Creates and returns a new, throttled version of the passed function, that, when invoked repeatedly,
// will only be triggered at most once
// during a given window of time. Normally, the throttled function will run
// as much as it can, without ever going more than once per `wait` duration;
// but if you'd like to disable the execution on the leading edge, pass
// `{leading: false}`. To disable execution on the trailing edge, ditto.
/**
 * @description Creates and returns a new, throttled version of the passed function, that,
 *  when invoked repeatedly, will only actually call the original function at most once per every
 * `wait` milliseconds. Useful for rate-limiting events that occur faster than you can keep up with.
 * By default, `throttle` will execute the function as soon as you call it for the first time, and,
 * if you call it again any number of times during the `wait` period,as soon as that period is over.
 * If you'd like to disable the leading-edge call, pass `{leading: false}`, and if you'd like to disable
 * the execution on the trailing-edge, pass `{trailing: false}`.
 * @param {Function} func Function to execute
 * @param {number} wait Execution period
 * @param {{leading: boolean, trailing: boolean}} [options] Configurations
 * @returns {Function} Throttled version of passed function
 */
export function throttle(func, wait, options = { leading: false, trailing: false }) {
    /** @type {NodeJS.Timeout|null} */
    var timeout;
    /** @type {any} */
    var context;
    /** @type {any} */
    var args;
    /** @type {any} */
    var result;

    var previous = 0;

    var later = function () {
        previous = options.leading === false ? 0 : Date.now();
        timeout = null;
        // @ts-ignore
        result = func.apply(context, args);
        if (!timeout) {
            context = null;
            args = null;
        }
    };

    return function () {
        var now = Date.now();
        if (!previous && options.leading === false) { previous = now; }
        var remaining = wait - (now - previous);
        // @ts-ignore
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) {
                context = null;
                args = null;
            }
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };
}
