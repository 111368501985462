const FOCUSABLE_ELEMENTS = ['a', 'button', 'input', 'textarea', 'select', 'details', '[tabindex]:not([tabindex="-1"])'];

/**
 *
 * @description Get keyboard-focusable elements
 * @param {HTMLElement} ctx context where focusable elements needs to be queried
 * @returns {HTMLElement[]} array containing focusable elements, or empty array if no elements found
 */
export function getFocusableElements(ctx) {
    return ctx.querySelectorAll(FOCUSABLE_ELEMENTS.join(', '));
}

/**
 *
 * @description Remove elements inside context from tabIndex order - remove ability to focus element by TAB
 * @param {HTMLElement} ctx context where focusable elements needs to be queried
 * @param {boolean} [isAvailable=true] state that defines whether elements inside context needs to be disabled for TAB focus
 */
export function toggleAvailability(ctx, isAvailable = true) {
    if ((!isAvailable && ctx.isAvailable === false)
        || (isAvailable && ctx.isAvailable)
    ) { return; }

    const focusableElements = getFocusableElements(ctx);

    focusableElements.forEach(el => {
        if (!el.dataset.initialTabIndex) {
            el.dataset.initialTabIndex = el.tabIndex;
        }

        el.tabIndex = isAvailable ? el.dataset.initialTabIndex : -1;
    });

    ctx.isAvailable = isAvailable;
}

/**
 * Executes inline scripts
 *
 * @param {HTMLElement} ctx context where inline scripts needs to be executed
 */
export function executeInlineScripts(ctx) {
    const scripts = (ctx.querySelectorAll(
        'script:not(src):not([type*="template"]):not([type*="json"])'
    ));

    Array.from(scripts).forEach(script => {
        const tempScript = document.createElement('script');

        tempScript.text = script.text;
        ctx.appendChild(tempScript);
        script.parentNode.removeChild(script);
    });
}
