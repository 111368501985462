const PROMISE_STATUSES = {
    PENDING: 'pending',
    FULLFILLED: 'fullfilled',
    REJECTED: 'rejected'
};

/**
 * @description Harmony Loader implementation
 * @param {typeof import('widgets/Widget').default} Widget Base widget for extending
 * @returns {typeof Loader}  Loader class
 */
export default function (Widget) {
    /**
     * @class Loader
     * @augments Widget
     * @classdesc Loader helper
     */
    class Loader extends Widget {
        /**
         * @description Preferences
         * @returns {object} Preferences object
         */
        prefs() {
            return {
                ...super.prefs(),
                classesHidden: 'h-hidden',
                classesLoaderOpen: 'm-panel_opened',
                loaderDelayTimeout: 1000, // Delay before loader appears (in ms)
                loaderMinShowTimeout: 1600, // Minimum visibility duration when loader is shown (in ms)
                loaderMaxShowTimeout: 15000 // Time to wait for response before showing an error (in ms)
            };
        }

        /**
         * @description Initialize widget logic
         */
        init() {
            // init events
            this.eventBus().on('loader.start', 'start');
        }

        /**
         * @description Start loader based on promise state
         * @param {Promise<any>} promise
         */
        start(promise) {
            const startTime = performance.now();
            const { loaderDelayTimeout, loaderMinShowTimeout, loaderMaxShowTimeout } = this.prefs();

            const loaderDelayTimerID = this.setLoaderDelayTimeout(promise, loaderDelayTimeout);
            this.setLoaderMinShowTimeout(promise, loaderMinShowTimeout);

            const loaderMaxShowTimerID = this.setLoaderMaxShowTimeout(promise, loaderMaxShowTimeout);

            promise.finally(() => {
                clearTimeout(loaderDelayTimerID);
                clearTimeout(loaderMaxShowTimerID);
                if (performance.now() - startTime >= loaderMinShowTimeout) {
                    this.close();
                }
            });
        }

        /**
         * @description Display loader
         */
        open() {
            this.ref('self').removeClass(this.prefs().classesHidden);
            document.body.classList.add(this.prefs().classesLoaderOpen);
        }

        /**
         * @description Hide loader
         */
        close() {
            this.ref('self').addClass(this.prefs().classesHidden);
            document.body.classList.remove(this.prefs().classesLoaderOpen);
        }

        /**
         * @description Set loader delay timeout
         * @param {Promise<any>} promise
         */
        setLoaderDelayTimeout(promise, loaderDelayTimeout) {
            return setTimeout(() => {
                this.promiseStatus(promise).then(status => {
                    if (status === PROMISE_STATUSES.PENDING) {
                        this.open();
                    }
                });
            }, loaderDelayTimeout);
        }

        /**
         * @description Set loader min show timeout
         * @param {Promise<any>} promise
         */
        setLoaderMinShowTimeout(promise, loaderMinShowTimeout) {
            return setTimeout(() => {
                this.promiseStatus(promise).then(status => {
                    if (status !== PROMISE_STATUSES.PENDING) {
                        this.close();
                    }
                });
            }, loaderMinShowTimeout);
        }

        /**
         * @description Set loader max show timeout
         * @param {Promise<any>} promise
         */
        setLoaderMaxShowTimeout(promise, loaderMaxShowTimeout) {
            return setTimeout(() => {
                this.promiseStatus(promise).then(status => {
                    if (status === PROMISE_STATUSES.PENDING) {
                        this.close();
                         //once bundle perfomance issue is fixed, commented code should be uncommented.
                        // const accessibilityAlert = this.prefs().accessibilityAlerts.timeoutError;
                        // this.eventBus().emit('alert.show', {
                        //     accessibilityAlert,
                        //     errorClass: true
                        // });
                    }
                });
            }, loaderMaxShowTimeout);
        }

        /**
         * @description Get promise status
         * @param {Promise<any>} promise
         */
        promiseStatus(promise) {
            const obj = {};

            return Promise.race([promise, obj])
                .then(
                    v => (v === obj) ? PROMISE_STATUSES.PENDING : PROMISE_STATUSES.FULLFILLED,
                    () => PROMISE_STATUSES.REJECTED
                );
        }
    }

    return Loader;
}
