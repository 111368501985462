import { clickOutside } from 'widgets/toolbox/util';
import { getStateID } from 'harmony/toolbox/shipToUtils';

const ESCAPE_CODE = 27;

/**
 * @typedef {ReturnType<typeof import('harmony/global/SideBar').default>} SideBar
 *
 */

/**
 * @description ShiptTo SideBar extention
 * @param {SideBar} SideBar Base widget for extending
 * @returns {typeof ShipToSideBar} - ShipToSideBar instance
 */
export default function (SideBar) {
    class ShipToSideBar extends SideBar {
        prefs() {
            return {
                ...super.prefs(),
                classesModalOpen: 'm-dialog_active'
            };
        }

        /**
         * Side Bar open handler
         *
         * @param {*} target - widget instance
         */
        open(target) {
            // trigger minicart closing
            this.eventBus().emit('auto.close.minicart', 0);

            // get currently selected state
            const selectedState = getStateID(target.stateID);

            // get side bar panel referer
            const refName = target.config.refName;
            const isHeaderAlignmentLeft = target.config.isHeaderAlignmentLeft;
            const isStickyHeader = document.getElementsByClassName('b-header')[0].classList.contains('m-stuck');
            if (this.has(refName) && !this.isOpened) {
                // Scroll top only for Desktop widget
                const isScrollTop = ((refName === 'shipToSideBar-header')
                        || (isHeaderAlignmentLeft && isStickyHeader && refName === 'shipToSideBar-stickyHeader'));
                if (isScrollTop) {
                    // Save scroll top position in order to set it back after SideBar close
                    this.scrollTop = window.scrollY;
                    window.scrollTo(0, 0);
                }
                // Save focused element in order to focus it after SideBar close
                this.backFocusElement = /** @type {HTMLElement} */(document.activeElement);
                // emit event to set radio input value
                this.eventBus().emit('shipttostate.setstate', selectedState);
                // open side bar
                this.ref('self').addClass(this.prefs().classesOpen);
                document.body.classList.add(this.prefs().classesBodyOpen);
                this.isOpened = true;

                this.accessabilityOpen();

                // close panel on esc click
                this.escHandler = this.ev('keyup', (_, event) => {
                    if (event.keyCode === ESCAPE_CODE) {
                        this.close();
                    }
                }, window).pop();

                // close pane on outside click
                this.outSideClickListener = clickOutside(this.ref('container'), this.close.bind(this));
                if (this.outSideClickListener) {
                    this.onDestroy(this.outSideClickListener);
                }

                this.eventBus().emit('header.set.active.state');
            }

            this.ref('self').attr('role', 'alert');
        }

        accessabilityOpen() {
            if (!this.has(this.prefs().firstTrapRef) || !this.has(this.prefs().lastTrapRef)) {
                this.addFocusTraps();
            }

            // Focus on first element
            this.afterShowModal();
        }

        close(...args) {
            if (this.isOpened) {
                this.eventBus().emit('header.remove.active.state');
            }

            super.close(...args);

            // Restore previous scroll position
            if (this.scrollTop) {
                window.scrollTo(0, this.scrollTop);
                this.scrollTop = null;
            }

            this.ref('self').attr('role');
        }

        /**
         * modal dialog
         */
        displayModal() {
            // hide side bar
            this.ref('self').removeClass(this.prefs().classesOpen);
            // add class for modal display
            this.ref('self').addClass(this.prefs().classesModalOpen);
        }

        closeModal() {
            this.ref('self').removeClass(this.prefs().classesModalOpen);
        }
    }

    return ShipToSideBar;
}
