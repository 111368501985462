// TODO: Add class description and description for all methods
/**
 * @module scroll
 * @category widgets
 * @subcategory toolbox
 */

import { debounce } from 'widgets/toolbox/debounce';
import {
    SMALL,
    MEDIUM,
    LARGE,
    EXTRA_LARGE,
    getViewType
} from 'widgets/toolbox/viewtype';

const stickyHeaderHeightMap = {
    [SMALL]: 64,
    [MEDIUM]: 64,
    [LARGE]: 80,
    [EXTRA_LARGE]: 80
};

/**
 * @param {HTMLElement|undefined} element - HTML Element
 */
export function scrollIntoView(element) {
    if (!element) {
        return;
    }

    if ('scrollBehavior' in document.documentElement.style) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    } else {
        element.scrollIntoView(false);
    }
}

/**
 * @param {HTMLElement} element - HTML Element
 * @param {number} [top] - top coordinate
 * @param {number} [left] - left coordinate
 */
export function scrollElementTo(element, top = 0, left = 0) {
    if (element && typeof element.scrollTo === 'function') {
        element.scrollTo({ top: top, left: left, behavior: 'smooth' });
    }
}

/**
 * @param {HTMLElement|undefined} element - HTML Element
 * @param {boolean} [countHeaderHeight] - add to top header height
 */
export function scrollWindowTo(element, countHeaderHeight = false) {
    if (!element) {
        return;
    }

    const headerHeight = stickyHeaderHeightMap[getViewType()];
    const top = element.getBoundingClientRect().top + window.pageYOffset;
    const left = 0;
    if (countHeaderHeight) {
        window.scrollTo({ top: top - headerHeight, left: left, behavior: 'smooth' });
    } else {
        window.scrollTo({ top: top, left: left, behavior: 'smooth' });
    }
}

/**
 * Scrolling to top.
 */
export function scrollToTop() {
    window.scrollTo(0, 0);
}

const DEBOUNCE_INTERVAL = 20;
/**
 * @type {((arg0: {currentPosition: number, lastScrollPosition: number, diff: number}) => void)[]}
 */
const scrollHandlers = [];

export function getScrollPosition() {
    if (document.documentElement.scrollTop !== undefined) {
        return document.documentElement.scrollTop;
    }
    if (document.scrollingElement && document.scrollingElement.scrollTop !== undefined) {
        return document.scrollingElement.scrollTop;
    }
    return -1;
}
let lastScrollPosition = 0;

const debounceScroll = debounce(() => {
    const currentPosition = getScrollPosition();

    scrollHandlers.forEach(handler => handler({
        currentPosition,
        lastScrollPosition,
        diff: currentPosition - lastScrollPosition
    }));
    lastScrollPosition = currentPosition;
}, DEBOUNCE_INTERVAL, true, true);

/**
 *
 * @param {(arg0: {currentPosition: number, lastScrollPosition: number, diff: number}) => void} callback - on scroll callback
 * @returns {Function} result
 */
export function onScroll(callback) {
    if (!scrollHandlers.length) {
        window.addEventListener('scroll', debounceScroll, { passive: true });
    }
    scrollHandlers.push(callback);

    return () => {
        scrollHandlers.splice(scrollHandlers.indexOf(callback), 1);

        if (!scrollHandlers.length) {
            window.removeEventListener('scroll', debounceScroll);
        }
    };
}
