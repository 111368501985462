/**
 * @typedef {ReturnType<typeof import('widgets/global/HamburgerMenuItem').default>} HamburgerMenuItem
 */

const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32
});

/**
 * @description Base CountrySelectorMenuItem implementation
 * @param {HamburgerMenuItem} HamburgerMenuItem Base widget for extending
 * @returns {typeof CountrySelectorMenuItem} BackToTop class
 */
export default function (HamburgerMenuItem) {
    /**
     * @category widgets
     * @subcategory header
     * @class CountrySelectorMenuItem
     * @augments HamburgerMenuItem
     * @classdesc CountrySelectorMenuItem component. Used to switch locales on small viewports. Mixes in 2 widgets:
     * - {@link HamburgerMenuItem} - to be able to behave correctly as a hamburger menu item on small viewports
     * - {@link CountrySelector} - to be able to handle Locale Switcher functionality
     * <br>Was created due to extend {@link HamburgerMenuItem} `click` and `keydown` and use {@link CountrySelector} mixin to switch locale/country
     * @property {string} data-widget - Widget name `countrySelectorMenuItem`
     * @property {object} data-locale-config - Locale item config object
     * @example
     * // use this code to display widget
     * <div
     *     data-widget.sm.md="countrySelectorMenuItem"
     *     data-event-click.sm.md="handleClick"
     *     data-event-keydown.sm.md="handleKeydown"
     *     data-locale-config="${JSON.stringify(localeLink)}"
     *     data-url-locale="${URLUtils.url('Page-SetLocale')}"
     *     data-url-site="${URLUtils.url('Page-SetSite')}"
     *     role="none"
     *     class="b-menu_item"
     * >
     *     <button
     *         role="menuitem"
     *         b-menu_item-link
     *         tabindex="-1"
     *         data-ref="itemLink"
     *         class="b-menu_item-link"
     *         aria-label="${Resource.msgf('countrySelector.link.wai', 'global', null, localeLink.displayName)}"
     *         lang="${localeLink.language}"
     *     >
     *         ....
     *     </button>
     * </div>
     */
    class CountrySelectorMenuItem extends HamburgerMenuItem {
        /**
         * @description Click Event handler
         * @listens dom#click
         * @returns {void}
         */
        handleClick() {
            // class CountrySelector, method changeLocale
            this.callIfExists('changeLocale', this.ref('self').data('localeConfig'));
        }

        /**
         * @description Keydown Event handler
         * @listens dom#keydown
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         * @returns {void}
         */
        handleKeydown(_, event) {
            super.handleKeydown(_, event);

            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.SPACE:
                case keyCode.RETURN:
                    // class CountrySelector, method changeLocale
                    this.callIfExists('changeLocale', this.ref('self').data('localeConfig'));
                    preventEventActions = true;
                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    return CountrySelectorMenuItem;
}
