/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof SwipeToClose} SwipeToClose widget
 */
export default function (Widget) {
    /**
     * @category widgets
     * @subcategory global
     * @class SwipeToClose
     * @augments Widget
     * @classdesc Represents SwipeToClose component that implement logic for closing panel by swipe gesture.
     * It has next features:
     * 1. Allow set panel close direction (left/right)
     * 2. Handle touch events for smooth panel closing
     *
     * @example <caption>Example of SwipeToClose widget usage with left drag direction</caption>
     * <div
     *    id="main-navigation"
     *    class="b-menu_panel"
     *    aria-labelledby="main-navigation-toggle"
     *    data-widget="swipeToClose"
     *    data-panel-container="panelContainer"
     *    data-drag-direction-x="left"
     * >
     *      <div
     *          data-ref="dialog" class="b-menu_panel-inner"
     *          data-event-touchstart.sm.md="handleTouchStart"
     *      >
     *          <div data-ref="panelContainer" class="b-menu_subpanel m-active_level_1">
     *              ... widget content
     *          </div>
     *      </div>
     * </div>
     * @property {string} data-widget - Widget name `swipeToClose`
     * @property {string} data-menu - reference id of child element (inner container), value of its data-ref attribute
     * @property {string} data-panel-container - reference id of child element (panels wrapper), value of its data-ref attribute
     * @property {string} data-drag-direction-x - whether closing swipe should be performed right to left (value 'left') or left to right (value 'right').
     */
    class SwipeToClose extends Widget {
        prefs() {
            return {
                menu: 'dialog',
                panelContainer: 'panelContainer',
                dragDirectionX: 'left',
                ...super.prefs()
            };
        }

        /**
         * @description Widget logic initialization
         * @returns {void}
         */
        init() {
            super.init();
            this.dragDirectionX = this.prefs().dragDirectionX === 'left' ? 1 : -1;
        }

        /**
         * @description TouchStart Event handler
         * @listens dom#touchstart
         * @param {HTMLElement} _ Source of keydown event
         * @param {TouchEvent} evt  Event object
         * @returns {void}
         */
        handleTouchStart(_, evt) {
            // @ts-ignore
            this.touchMoveDisposable = this.ev('touchmove', this.handleTouchMove, this.ref(this.prefs().menu).get());
            this.touchEndDisposable = this.ev('touchend', this.handleTouchEnd, this.ref(this.prefs().menu).get());
            this.touchCancelDisposable = this.ev('touchcancel', this.handleTouchCancel, this.ref(this.prefs().menu).get());
            this.startTime = new Date().getTime();
            const coordX = evt.touches[0].pageX || 0;
            const coordY = evt.touches[0].pageY || 0;
            this.isMoving = false;
            this.startX = coordX;
            this.startY = coordY;
            this.currentX = coordX;
            this.currentY = coordY;
            this.touchStart(this.startX, this.startY);
        }

        /**
         * @description TouchMove Event handler
         * @listens dom#touchmove
         * @param {HTMLElement} _ Source of keydown event
         * @param {TouchEvent} evt  Event object
         * @returns {void}
         */
        handleTouchMove(_, evt) {
            this.isMoving = true;
            this.currentX = evt.touches[0].pageX;
            this.currentY = evt.touches[0].pageY;
            // @ts-ignore
            const translateX = this.currentX - this.startX;
            // @ts-ignore
            const translateY = this.currentY - this.startY;

            this.touchMove(evt, this.currentX, this.currentY, translateX, translateY);
        }

        /**
         * @description TouchEnd Event handler
         * @listens dom#touchend
         * @param {HTMLElement} _ Source of keydown event
         * @param {Event} evt  Event object
         * @returns {void}
         */
        // eslint-disable-next-line no-unused-vars
        handleTouchEnd(_, evt) {
            // @ts-ignore
            const translateX = this.dragDirectionX * (this.currentX - this.startX);
            // @ts-ignore
            const translateY = this.currentY - this.startY;
            // @ts-ignore
            const timeTaken = (new Date().getTime() - this.startTime);

            // @ts-ignore
            this.touchEnd(this.currentX, this.currentY, translateX, translateY, timeTaken);
        }

        /**
         * @description TouchCancel Event handler
         * @listens dom#touchcancel
         * @param {HTMLElement} _ Source of keydown event
         * @param {Event} evt  Event object
         * @returns {void}
         */
        // eslint-disable-next-line no-unused-vars
        handleTouchCancel(_, evt) {
            this.disposableListeners();
        }

        /**
         * @description Preparing panel to move
         * @param {number} startX x coordinate of where the finger is placed in the DOM
         * @param {number} startY  y coordinate of where the finger is placed in the DOM
         * @returns {void}
         */
        touchStart(startX, startY) {
            const menu = this.ref(this.prefs().menu);
            this.isOpen = menu !== null;
            this.toggleTransition(menu, true);
            this.toggleTransition(this.ref(this.prefs().panelContainer), true);
            const menuNode = menu.get();
            this.menuWidth = menuNode ? menuNode.offsetWidth : 0;
            this.lastX = startX;
            this.lastY = startY;
            if (this.isOpen) {
                this.moveX = 0;
            } else {
                this.moveX = -this.menuWidth;
            }
            this.dragDirection = '';
        }

        /**
         * @description Update UI
         * @returns {void}
         */
        updateUi() {
            if (this.isMoving) {
                const element = this.ref(this.prefs().menu).get();
                if (element) { element.style.transform = 'translateX(' + this.moveX + 'px)'; }
                window.requestAnimationFrame(this.updateUi.bind(this));
            }
        }

        /**
         * @description Toggle menu
         * @param {number} translateX x coordinate of where the finger is placed in the viewport
         * @returns {void}
         */
        toggleMenu(translateX) {
            const menu = this.ref(this.prefs().menu);
            const menuNode = menu.get();
            if (menuNode) { menuNode.style.transform = ''; }
            this.toggleTransition(menu, false);
            if (translateX < 0 || !this.isOpen) {
                this.closePanel();
                this.isOpen = false;
            } else {
                this.openPanel(false);
                this.isOpen = true;
            }
        }

        /**
         * @description Open Menu Panel.Interface can be override
         * @param {object} data data
         * @returns {void}
         */
        // eslint-disable-next-line no-unused-vars
        openPanel(data) {
            throw new Error('Method not implemented.');
        }

        /**
         * @description Close Menu Panel.Interface can be override
         * @returns {void}
         */
        closePanel() {
            throw new Error('Method not implemented.');
        }

        /**
         * @description Toggle transition class
         * @param {RefElement} element DOM element
         * @param {boolean} isTransitionOn transition flag
         * @returns {void}
         */
        toggleTransition(element, isTransitionOn) {
            if (isTransitionOn) {
                element.addClass('m-no_transition');
            } else {
                element.removeClass('m-no_transition');
            }
        }

        /**
         * @description calculate opacity
         * @returns {number} New opacity value
         */
        calculateOpacity() {
            // @ts-ignore
            const percentageBeforeDif = (Math.abs(this.moveX) * 100) / this.menuWidth;
            const percentage = 100 - percentageBeforeDif;
            return (percentage / 100);
        }

        /**
         * @description calculate opacity
         * @param {RefElement} element Ref element
         * @param {any} opacity New opacity value
         * @returns {void}
         */
        setNewOpacity(element, opacity) {
            const htmlElement = element.get();
            if (!htmlElement) {
                return;
            }
            htmlElement.style.setProperty('--backdrop-opacity', opacity);
        }

        /**
         * @description Disposable listeners
         * @returns {void}
         */
        disposableListeners() {
            if (this.touchMoveDisposable) {
                this.touchMoveDisposable.forEach(disposable => disposable());
                delete this.touchMoveDisposable;
            }
            if (this.touchEndDisposable) {
                this.touchEndDisposable.forEach(disposable => disposable());
                delete this.touchEndDisposable;
            }
            if (this.touchCancelDisposable) {
                this.touchCancelDisposable.forEach(disposable => disposable());
                delete this.touchCancelDisposable;
            }
        }

        /**
         * @description calculate new postion.Set drag direction
         * @param {Event} evt Event object
         * @param {number} currentX current X position
         * @param {number} currentY current Y position
         * @param {number} translateX new position X value
         * @param {number} translateY new position Y value
         * @returns {void}
         */
        touchMove(evt, currentX, currentY, translateX, translateY) {
            if (!this.dragDirection) {
                if (Math.abs(translateX) >= Math.abs(translateY)) {
                    this.dragDirection = 'horizontal';
                } else {
                    this.dragDirection = 'vertical';
                }
                window.requestAnimationFrame(this.updateUi.bind(this));
            }
            if (this.dragDirection === 'vertical') {
                this.lastX = currentX;
                this.lastY = currentY;
            } else {
                // @ts-ignore
                const movedPath = this.dragDirectionX * (this.moveX + (currentX - this.lastX));
                // @ts-ignore
                if (movedPath < 0 && movedPath > -this.menuWidth) {
                    // @ts-ignore
                    this.moveX += currentX - this.lastX;
                }
                this.lastX = currentX;
                this.lastY = currentY;
                this.setNewOpacity(this.ref('html'), this.calculateOpacity());
            }
        }

        /**
         * @description Open/Close panel based on position. Remove transition classes. Remove event listeners.
         * @param {number} currentX current X position
         * @param {number} currentY current Y position
         * @param {number} translateX new position X value
         * @param {number} translateY new position Y value
         * @param {number} timeTaken timediff between touch start and touch end
         * @returns {void}
         */
        touchEnd(currentX, currentY, translateX, translateY, timeTaken) {
            const velocity = 0.3;
            if (this.isMoving && this.isOpen) {
                this.isMoving = false;
                // @ts-ignore
                if ((translateX < (-this.menuWidth) / 2) || (Math.abs(translateX) / timeTaken > velocity)) {
                    this.toggleMenu(translateX);
                } else {
                    this.toggleMenu(0);
                }
            }
            this.toggleTransition(this.ref(this.prefs().menu), false);
            this.toggleTransition(this.ref(this.prefs().panelContainer), false);
            this.setNewOpacity(this.ref('html'), '');
            this.disposableListeners();
        }
    }

    return SwipeToClose;
}
