import { clickOutside } from 'widgets/toolbox/util';
const ariaExpanded = 'aria-expanded';
/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof DropDown} DropDown widget
 */

export default function (Widget) {
    /**
     * @category widgets
     * @subcategory global
     * @class DropDown
     * @augments Widget
     * @classdesc Widget to serve different drop down blocks
     * DropDown should be imlemented according to https://www.w3.org/TR/wai-aria-practices/#menu specs
     * Represents DropDown component with next features:
     * 1. Allow open/close or toggle DropDown
     * 2. Allow get all DropDown's options or select option
     * @property {string} data-widget - Widget name `dropDown`
     * @property {string} data-event-mouseenter - Event listener for opening dropdown
     * @property {string} data-event-mouseleave - Event listener for closing dropdown
     * @property {string} data-event-focus - Event listener for opening dropdown
     * @example <caption>Example of DropDown widget usage</caption>
     * <div
     *     data-widget="dropDown"
     *     data-event-mouseenter="openByMouseOver"
     *     data-event-mouseleave="close"
     *     data-event-focus="openByMouseOver"
     *     class="header-account" tabindex="0"
     *     aria-label="${Resource.msg('myaccount.link.title', 'accessibility', null)}"
     * >
     *     <button data-ref="dropDownButton" class="o-link--block header-account__user u-p2"
     *         data-event-click.prevent="toggleDropDown"
     *         aria-haspopup="true" aria-expanded="false" tabindex="-1"
     *     >${pdict.monogram}</button>
     *
     *     <div data-ref="dropDownBox" class="header-account-dropdown is-hidden" aria-hidden="true">
     *         <div class="header-account__title">
     *             <span>${Resource.msgf('label.header.salutation','account', null, pdict.name)}</span>
     *         </div>
     *         <iscontentasset aid="account-component-dropdown" />
     *     </div>
     * </div>
     */
    class DropDown extends Widget {
        prefs() {
            return {
                classesOpen: 'is--open',
                classesOptionSelected: 'selected',
                closeOnOutsideClick: true,
                outsideClickPreventDefault: true,
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         * @returns {void}
         */
        init() {
            super.init();

            this.isOpened = false;
            // For ignore duplicate method calls
            this.toIgnoreDuplicateEvent = false;

            this.ref('dropDownBox').hide();
            this.ref('dropDownButton').attr(ariaExpanded, 'false');
        }

        /**
         * @description Change DropDown state on widget rerender
         * @returns {void}
         */
        onRefresh() {
            super.onRefresh();

            this.isOpened = false;
        }

        /**
         * @description Open DropDown
         * @emits DropDown#open
         * @listens dom#click
         * @returns {boolean} Boolean value that indicates DropDown opened
         */
        open() {
            if (this.isOpen()) {
                return false;
            }

            this.ref('self').addClass(this.prefs().classesOpen);
            this.ref('dropDownBox').show();
            this.ref('dropDownButton').attr(ariaExpanded, 'true');

            this.isOpened = true;

            if (this.prefs().closeOnOutsideClick) {
                if (this.outSideListener) {
                    this.outSideListener();
                } else {
                    this.outSideListener = clickOutside(this.ref('self'), () => {
                        this.close();
                    }, this.prefs().outsideClickPreventDefault);

                    if (this.outSideListener) {
                        this.onDestroy(this.outSideListener);
                    }
                }
            }
            /**
             * @description Event to open DropDown
             * @event DropDown#open
             */
            this.emit('open');
            return true;
        }

        /**
         * @description Close DropDown
         * @emits DropDown#close
         * @listens dom#click
         * @returns {boolean} Boolean value that indicates DropDown closed
         */
        close() {
            if (this.outSideListener) {
                this.outSideListener();
                this.outSideListener = null;
            }

            if (!this.isOpen()) {
                return false;
            }

            this.ref('self').removeClass(this.prefs().classesOpen);
            this.ref('dropDownBox').hide();
            this.ref('dropDownButton').attr(ariaExpanded, 'false');

            this.isOpened = false;
            /**
             * @description Event to close DropDown
             * @event DropDown#close
             */
            this.emit('close');
            return true;
        }

        /**
         * @description Toggle DropDown
         * @returns {boolean} value that indicates is DropDown toggled
         */
        toggleDropDown() {
            if (this.toIgnoreDuplicateEvent) {
                this.toIgnoreDuplicateEvent = false;
                return false;
            }

            if (this.isOpen()) {
                this.close();
            } else {
                this.open();
            }
            return true;
        }

        /**
         * @description Check DropDown is opened
         * @returns {boolean|undefined} Boolean value that indicates DropDown opened
         */
        isOpen() {
            return this.isOpened;
        }

        /**
         * @description Open DropDown by mouseover event
         * @listens dom#mouseover
         * @returns {void}
         */
        openByMouseOver() {
            this.toIgnoreDuplicateEvent = true;
            this.open();
        }

        /**
         * @description Get DropDown options
         * @returns {Array} DropDown options
         */
        getOptions() {
            const options = [];

            // @ts-ignore
            Object.keys(this.refs).forEach(k => {
                if (k.indexOf('option-') !== -1) {
                    // @ts-ignore
                    options.push(this.refs[k]);
                }
            });

            return options;
        }

        /**
         * @description Select option from DropDown options
         * @param {any} optionInitial initial option
         * @returns {boolean} Boolean value that indicates is DropDown's option was selected or not
         */
        selectOption(optionInitial) {
            let option = optionInitial;
            if (typeof option === 'number') {
                if (!this.ref('option-' + option)) {
                    return false;
                }

                option = this.ref('option-' + option);
            }

            this.getOptions().forEach(o => {
                o.attr('data-selected', 'false')
                    .removeClass(this.prefs().classesOptionSelected);
            });

            option
                .attr('data-selected', 'true')
                .addClass(this.prefs().classesOptionSelected);

            this.ref('dropDownButton')
                .setText(option.attr('data-name'));

            this.close();
            return true;
        }

        /**
         * @description Handles click
         * @emits DropDown#click
         * @returns {void}
         */
        handleClick() {
            /**
             * @description Event to DropDown click
             * @event DropDown#click
             */
            this.emit('click');
        }
    }

    return DropDown;
}
