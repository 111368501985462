import { getContentByUrl, submitFormJson } from '../toolbox/ajax';
import { setCookie } from '../toolbox/cookie';
/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof ConsentTracking} ConsentTracking widget
 */

export default function (Widget) {
    /**
     * @category widgets
     * @subcategory global
     * @class ConsentTracking
     * @augments Widget
     * @classdesc ConsentTracking Widget - used to expose Consent dialog, where customer can either accept
     * <br>or reject Consent matter. Relevant methods will be triggered pressing related buttons
     * @property {string} data-widget - Widget name `consentTracking`
     * @property {string} data-content-url - Dynamically loaded Consent's content
     * @property {string} data-reject-url - Customer chooses `reject` - request will be sent to Reject URL
     * @property {string} data-accept-url - Customer chooses `accept` - request will be sent to Accept URL
     * @example <caption>Example of ConsentTracking widget usage</caption>
     * <div
     *     data-widget="consentTracking"
     *     data-content-url="${URLUtils.url('ConsentTracking-GetContent', 'cid', 'tracking_hint')}"
     *     data-reject-url="${URLUtils.url('ConsentTracking-SetSession', 'consent', 'false')}"
     *     data-accept-url="${URLUtils.url('ConsentTracking-SetSession', 'consent', 'true')}"
     *     data-tracking-selected="${pdict.tracking_consent != null}"
     *     data-consent-api="${pdict.caOnline}"
     * >
     *     <section data-ref="container"></section>
     *     <script type="template/mustache" data-ref="template">
     *         <div class="b-notification_panel-inner">
     *             <div class="b-notification_panel-content">{{{assetContent}}}</div>
     *             <button data-event-click="accept">${Resource.msg('tracking.accept', 'footer', '')}</button>
     *             <button data-event-click="reject">${Resource.msg('tracking.decline', 'footer', '')}</button>
     *         </div>
     *     </script>
     * </div>
     */
    class ConsentTracking extends Widget {
        prefs() {
            return {
                contentUrl: '',
                acceptUrl: '',
                rejectUrl: '',
                classesEnabled: 'm-visible',
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         * @returns {void}
         */
        init() {
            this.loadContent();
        }

        /**
         * @description Load content
         * @returns {void}
         */
        loadContent() {
            getContentByUrl(this.prefs().contentUrl)
                .then(content => {
                    this.render('template', { assetContent: content }, this.ref('container'))
                        .then(() => this.togglePanel(true));
                });
        }

        /**
         * @description Toggle Panel
         * @param {boolean} isVisible - `true` if Consent's dialog should be shown, `false` - otherwise
         * @returns {void}
         */
        togglePanel(isVisible) {
            this.ref('container').toggleClass(this.prefs().classesEnabled, isVisible);
        }

        /**
         * @description Accept Consent handler
         * @listens dom#click
         * @returns {void}
         */
        accept() {
            submitFormJson(this.prefs().acceptUrl, undefined, 'GET').finally(() => {
                this.togglePanel(false);
                setCookie('dw_accept_cookie', '1', 365, true);
                setCookie('dw_consent_cookie', '1', 365, true);
            });
        }

        /**
         * @description Reject Consent handler
         * @listens dom#click
         * @returns {void}
         */
        reject() {
            submitFormJson(this.prefs().rejectUrl, undefined, 'GET').finally(() => {
                this.togglePanel(false);
                setCookie('dw_accept_cookie', '1', 365, true);
            });
        }
    }

    return ConsentTracking;
}
