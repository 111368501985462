/**
 * @typedef {ReturnType<typeof import('widgets/forms/InputPassword').default>} InputPassword
 */

/**
 * @description Base HarmonyInputPassword implementation
 * @param {BasicInput} InputPassword Base widget for extending
 * @returns {typeof HarmonyInputPassword} Harmony Input Password class
 */
export default function (InputPassword) {
    return class HarmonyInputPassword extends InputPassword {
        prefs() {
            return {
                ...super.prefs(),
                specialCharsList: '~`!@#$%^&*()_+-=[]{}|;:\'"<>,.',
                allowedLettersRegExp: '[a-zA-Z]'
            };
        }

        init() {
            super.init();
            this.eventBus().on('update.cvv.validation', 'updateCvvValidation');
        }

        /**
         * @description Checks if entered data is simple character (not a number or special character)
         * @param {string} val - entered password
         * @returns {boolean} check result
         */
        isSimpleChar(val) {
            return !this.isNumber(val) && !this.prefs().specialCharsList.includes(val) && this.isAllowedLetter(val);
        }

        /**
         * @description check if entered value has special letter. In implementation scope: latin letters. Could be
         * extented by prefs.allowedLettersRegExp
         * @param {string} val
         * @returns {boolean} check result
         */
        isAllowedLetter(val) {
            if (!this.prefs().allowedLettersRegExp) { return true; }
            return new RegExp(this.prefs().allowedLettersRegExp, 'g').test(val);
        }

        /**
         * Updates validation rules of CVV field based on credit card type
         * @param {string|undefined} cardType
         */
        updateCvvValidation(cardType) {
            const inputElement = this.ref('field').get();

            //
            // default values will be used if cardType is not defined
            //

            let { minLength, maxLength } = this.config.validationConfig;

            // set default values from config to avoid an errors like: The maxLength provided (3) is less than the minimum bound (4)
            // in case when visa replaced with amex

            inputElement.minLength = minLength;
            inputElement.maxLength = maxLength;

            if (cardType) {
                const length = (cardType === 'Visa' || cardType === 'Master' || cardType === 'Discover')
                    ? 3
                    : 4;

                minLength = length;
                maxLength = length;
            }

            //
            // update length attributes
            //

            inputElement.minLength = minLength;
            inputElement.maxLength = maxLength;

            //
            // if element has some entered value
            // need to validate it using new length params
            //

            if (this.getValue()) {
                this.validate();
            }
        }
    };
}
