/**
 * @description Harmony Overlay implementation
 * @param {typeof import('widgets/Widget').default} Widget Base widget for extending
 * @returns {typeof Overlay}  Overlay class
 */
export default function (Widget) {
    /**
     * @class Overlay
     * @augments Widget
     * @classdesc Overlay helper
     */
    class Overlay extends Widget {
        /**
         * @description Preferences
         * @returns {object} Preferences object
         */
        prefs() {
            return {
                ...super.prefs(),
                classesHidden: 'h-hidden',
                classesOverlayOpen: 'm-panel_opened',
                closeOnClick: true
            };
        }

        /**
         * @description Initialize widget logic
         */
        init() {
            // init events
            this.eventBus().on('show.overlay', 'open');
            this.eventBus().on('hide.overlay', 'close');

            this.closeOnClick = this.prefs().closeOnClick;
        }

        /**
         * @description Display overlay
         * @param {object} options with additional data
         * @property {boolean} closeOnClick close overlay on click
         */
        open(options) {
            if (options && typeof options.closeOnClick === 'boolean') {
                this.closeOnClick = options.closeOnClick;
            }
            this.ref('self').removeClass(this.prefs().classesHidden);
            document.body.classList.add(this.prefs().classesOverlayOpen);
        }

        /**
         * @description Hide overlay
         */
        close() {
            this.ref('self').addClass(this.prefs().classesHidden);
            document.body.classList.remove(this.prefs().classesOverlayOpen);
        }

        /**
         * @description handle click on overlay
         */
        handleClick() {
            // do noting if configured to ignore clicks
            if (!this.closeOnClick) {
                return;
            }
            // close open side bars
            this.eventBus().emit('sidebar.close');
            // hide overlay
            this.close();
        }
    }

    return Overlay;
}
