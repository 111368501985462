import { isMediumViewAndDown } from 'widgets/toolbox/viewtype';

/**
 * @description HarmonyBackToTop implementation
 * @param {BackToTop} BackToTop BackToTop for extending
 * @returns {typeof BackToTop}
 */

export default function (BackToTop) {
    return class HarmonyBackToTop extends BackToTop {
        prefs() {
            return {
                ...super.prefs(),
                pageSize: 1
            };
        }

        doInit() {
            if (isMediumViewAndDown()) {
                this.removeScrollListener();
                this.show();
            } else {
                this.attachScrollListener();
                this.toggleBackToTopButton();
            }
        }

        toggle(...args) {
            super.toggle(...args);
            this.ref('self').attr('hidden', false);
        }
    };
}
