import { getViewType, SMALL, MEDIUM } from 'widgets/toolbox/viewtype';

const STATE_ID_SELECTOR = '[data-selector-id="state-id"]';
const HEADER_SELECTOR = '[data-selector-id="harmonyHeader"]';

/**
 * Return ID of current state
 * @return {string}
 */
export function getStateID(stateID) {
    if (!stateID && document.querySelectorAll(STATE_ID_SELECTOR).length) {
        stateID = document.querySelectorAll(STATE_ID_SELECTOR)[0].dataset.refSelectedState;
    }

    return stateID;
}

/**
 * Check state restriction
 * @return {boolean}
 */
export function isStateRestricted(stateID, restrictedStates = []) {
    return restrictedStates.indexOf(stateID) > -1;
}

/**
 * Check header's type
 * @return {string} type of header
 */
export function getHeaderType() {
    var headerType = '';
    var viewType = getViewType();
    var $header = document.querySelectorAll(HEADER_SELECTOR);
    var isHeaderAlignmentLeft = $header.length && $header[0].dataset.isHeaderAlignmentLeft === 'true';

    if (viewType === SMALL || viewType === MEDIUM) {
        headerType = 'mobileHeader';
    } else if (isHeaderAlignmentLeft) {
        headerType = 'stickyHeader';
    } else {
        headerType = 'header';
    }

    return headerType;
}
