// TO BE REVIEWED
/**
 * @typedef {ReturnType<typeof import('widgets/forms/BasicInput').default>} BasicInput
 */
/**
 * @param {BasicInput} BasicInput Base widget for extending
 * @returns {typeof InputHidden} Input Hidden class
 */
export default function (BasicInput) {
    /**
     * @category widgets
     * @subcategory forms
     * @class InputHidden
     * @augments BasicInput
     * @classdesc Widget used to handle hidden inputs. Represents input `hidden` element together with widget-related HTML markup.
     * HTML structure assembled on backend and injected into resulted html markup by using `formElement` component
     * and dynamic forms configuration JSON.
     * Form validation will skip hidden inputs from validation perspective and will treat it as valid.
     * @property {string} data-widget - Widget name `inputHidden`
     * @example <caption>InputHidden definition in dynamicforms.json</caption>
     * ...
     * // fields -> generic -> hidden
     * hidden: {
     *     extends: 'fields.input.base',
     *     element: {
     *         type: 'hidden'
     *     }
     * },
     * ...
     * @example <caption>Insertion of InputHidden inside ISML templates</caption>
     * <isset name="formElement" value="${require('forms/formElement')}" scope="page"/>
     * ...
     * <form>
     *     ...
     *     <isprint value="${
     *         formElement(pdict.addressForm.hiddenFieldExample).render()
     *     }" encoding="off"/>
     *     ...
     * </form>
     * @example <caption>Resulted HTML structure for InputHidden</caption>
     * <div data-widget="inputHidden" class="b-form_section m-required m-invalid" data-id="dwfrm_login_hiddenFieldExample">
     *     <input data-ref="field" id="dwfrm_login_hiddenFieldExample" type="hidden" name="dwfrm_login_hiddenFieldExample" value="">
     * </div>
     */
    class InputHidden extends BasicInput {
        prefs() {
            return {
                ...super.prefs(),
                skipValidation: true
            };
        }
    }

    return InputHidden;
}
