/**
 * @typedef {ReturnType<typeof import('widgets/forms/AjaxForm').default>} AjaxForm
 */

/**
 * @description Base RegisterForm implementation
 * @param {AjaxForm} AjaxForm Base widget for extending
 * @returns {typeof RegisterForm} Email Subscribe Form class
 */
export default function (AjaxForm) {
    /**
     * @class RegisterForm
     * @augments AjaxForm
     * @classdesc Serves email subscription form in footer, sends AJAX request to server.
     * @property {string} data-widget - Widget name `registerForm`
     * @property {string} data-event-submit - Event listener for form submission
     */
    class RegisterForm extends AjaxForm {
        prefs() {
            return {
                ...super.prefs(),
                submitButton: 'submitRegisterButton',
                scope: 'registration'
            };
        }

        init() {
            super.init();

            /**
             * -- cognito.hook.signup.then --
             * When cognito sign-up process has been successfully done.
             */
            this.eventBus().on('cognito.hook.signup.then', 'signUpSubmitted');

            /**
             * -- cognito.hook.signup.catch --
             * When cognito sign-up process has been failed.
             */
            this.eventBus().on('cognito.hook.signup.catch', 'onError');

            /**
             * -- harmonytab.change --
             * When Login/Registration tabs has been changed.
             */
            this.eventBus().on('harmonytab.change', 'initViewState');
        }

        /**
         * @param panel
         * @description Emits when tab was changed to prepare default state view.
         * @returns {void}
         */
        initViewState(panel) {
            if (panel === 'register') {
                this.clearError();
                this.show();
            }
        }

        /**
         * @description Called when form was submitted for emit Amazon Cognito
         * sign up call.
         *
         * @returns {boolean} false - to transfer control to the Cognito component.
         */
        handleSubmit() {
            if (!this.isChildrenValid() || this.submitting) {
                return false;
            }

            this.beforeSubmission();

            const fields = this.getFormFields();
            const isSubscribed = fields.dwfrm_profile_customer_newsletterSubscription === 'true';
            let fieldsObject = {
                username: ( this.prefs().disableCaseSensitive ) ? fields.dwfrm_profile_customer_email.toLowerCase() : fields.dwfrm_profile_customer_email,
                password: fields.dwfrm_profile_login_password,
                email: ( this.prefs().disableCaseSensitive ) ? fields.dwfrm_profile_customer_email.toLowerCase() : fields.dwfrm_profile_customer_email,
                firstName: fields.dwfrm_profile_customer_firstname,
                lastName: fields.dwfrm_profile_customer_lastname,
                birthDate: fields.dwfrm_profile_customer_birthday,
                newsletterSubscription: fields.dwfrm_profile_customer_newsletterSubscription || 'false',
                phoneNumber: fields.dwfrm_profile_customer_phone,
            };
            if(fields.dwfrm_profile_customer_state) {
                fieldsObject['state'] = fields.dwfrm_profile_customer_state;
            }
            this.eventBus().emit('cognito.hook.signup', fieldsObject);

            if (isSubscribed) {
                this.eventBus().emit('gtm.subscribe.success');
            }

            return false;
        }

        /**
         * @description Used to submit form after customer was created on Cognito side.
         *
         * @param {object} data
         * @param {string} data.userSub - external id which used for create customer
         * on SFCC side. This is customer identifier.
         * @returns {void}
         */
        signUpSubmitted(data) {
            this.submitting = false;
            this.getById('dwfrm_profile_externalID', input => {
                input.setValue(data.userSub, true);
            });
            super.handleSubmit();
        }

        /**
         * Calls parent onSubmitted with emitter which will be used in
         * verification form widget to show it.
         *
         * @param {object} data - Data which was returned by parent handleSubmit
         * @returns {void}
         */
        onSubmitted(data) {
            super.onSubmitted(data);
            this.afterSubmission();
            this.hide();
            this.eventBus().emit('registrationform.submitted', data);
            this.eventBus().emit('monetate.track.account.create');

            if (data.newsletterSubscription.checked) {
                this.eventBus().emit('monetate.track.newsletter.signup');
            }
        }

        /**
         * @description Show an error only in case if it was emitted to registration form scope.
         *
         * @param {object} error
         * @param {string} error.scope - scope for which was emitted current function.
         * @returns {void}
         */
        onError(error) {
            if (error.scope && error.scope !== 'registration') {
                return;
            }
            this.afterSubmission();
            super.onError(error);
        }

        /**
         * @description Action which needs to be done before submit handleSubmit function.
         * @returns {void}
         */
        beforeSubmission() {
            this.getById(this.prefs().submitButton, submitButton => submitButton.busy());
            this.clearError();
            this.submitting = true;
            this.eventBus().emit('loader.start', new Promise((resolve) => {
                this.resolve = resolve;
            }));
        }

        /**
         * @description when submitting has been done.
         */
        afterSubmission() {
            super.afterSubmission();
            this.resolveLoader();
        }

        /**
         * @description resolve loader promise for hide overlay.
         * @returns {void}
         */
        resolveLoader() {
            if (typeof this.resolve === 'function') {
                this.resolve();
            }
        }

        /**
         * Prepare data to be passed to gtm
         *
         * @param {any} data Response from form submission
         * @returns {object} data form gtm event
         */
        prepareGtmData(data) {
            /**
             * @description gtm Object
             * @type {any}
            */
            const gtmData = {
                formStatus: 'Success',
                formId: this.id || this.formId,
                formName: this.formName
            };

            if (data) {
                // store customer info if available
                if (data.sfPersonAccountId) {
                    gtmData.sfPersonAccountId = data.sfPersonAccountId;
                }
                if (data.externalId) {
                    gtmData.commerceKey = data.externalId;
                }

                if (data.success === false) {
                    gtmData.formStatus = 'Error: Failed Form Submission';

                    if (data.fields
                        && data.fields.length
                    ) {
                        gtmData.formStatus = 'Error: Form Validation';

                        /**
                         * @description Array of error messages
                         * @type {string[]}
                        */
                        const errorMessages = [];

                        Object.keys(data.fields).forEach(fieldKey => {
                            errorMessages.push(data.fields[fieldKey]);
                        });
                        this.errorMessage = errorMessages.join('|');

                        gtmData.errorMessage = this.errorMessage;
                    }
                }
            }

            return gtmData;
        }
    }

    return RegisterForm;
}
