window.$ = require('jquery');

$(document).ready(function() {
    var $footerSocial = $('.b-footer-social');
    if ($footerSocial.length > 0) {
        var baseUrl = $footerSocial.data('attr');
        if (baseUrl) {
            var socialClasses = {
                'm-facebook': 'facebook',
                'm-instagram': 'instagram',
                'm-youtube': 'youtube',
                'm-pinterest': 'pinterest',
                'm-twitter': 'twitter'
            };

            var styleElement = document.createElement('style');
            styleElement.type = 'text/css';
            var cssText = '';

            $('.b-footer_social-link').each(function() {
                var $this = $(this);
                var socialType = Object.keys(socialClasses).find(function(cls) {
                    return $this.hasClass(cls);
                });

                if (socialType && socialClasses[socialType]) {
                    var fullUrl = baseUrl + '#' + socialClasses[socialType];

                    // Generate CSS for the ::before pseudo-element
                    cssText += `
                        .b-footer_social-link.${socialType}::before {
                            mask: url(${fullUrl});
                            -webkit-mask: url(${fullUrl});
                        }
                    `;
                }
            });

            if (cssText) {
                styleElement.appendChild(document.createTextNode(cssText));
                document.head.appendChild(styleElement);
            }
        }
    }
});