/**
 * @description Harmony sticky header implementation
 * @param {typeof import('widgets/global/StickyHeader').default} StickyHeader Base widget for extending
 * @returns {typeof HarmonyStickyHeader}  HarmonyStickyHeader class
 */
export default function (StickyHeader) {
    /**
     * @class HarmonyStickyHeader
     * @augments StickyHeader
     * @classdesc sticky header helper
     */
    class HarmonyStickyHeader extends StickyHeader {
        /**
         * @description Preferences
         * @returns {object} Preferences object
         */
        prefs() {
            return {
                ...super.prefs(),
                classesHeaderStuck: 'm-stuck',
                classesShow: 'm-header_stuck'
            };
        }

        /**
         * @description Initialize widget logic
         */
        init() {
            // store header element
            this.header = document.querySelector('.b-header');
            this.headerBackground = document.querySelector('.b-header-background');
            this.isHeaderSticky = false;

            super.init();

            // update header height on header promo banner close
            this.eventBus().on('cookiedismissblock.updated', 'setHeaderHeight');
            this.eventBus().on('gtm.age.gate.close', 'setHeaderHeight');
            // set initial height of sticky heder
            this.setHeaderHeight();
            // listen for transition end events
            this.header.addEventListener('transitionend', () => {
                this.setHeaderHeight();
            });
        }

        /**
         * @description Current header's height getter
         * @returns {integer} current headers height
         */
        get currentHeaderHeight() {
            return this.headerBackground.offsetHeight;
        }

        /**
         * @description Update --header-height || --header-sticky-height CSS variables value
         */
        setHeaderHeight() {
            let customCSSPropertyName;

            if (this.isHeaderSticky) {
                customCSSPropertyName = '--header-sticky-height';
            } else {
                customCSSPropertyName = '--header-height';
            }

            document.documentElement.style.setProperty(customCSSPropertyName, (this.currentHeaderHeight || 0) + 'px');
        }

        /**
         * @description Toggle sticky state
         * @param {IntersectionObserverEntry} entry Intersection Observer Entry
         */
        toggleStickyState(entry) {
            // call base widget function
            super.toggleStickyState(entry);
            // set additional class for header element
            this.isHeaderSticky = entry.intersectionRatio < 1;
            this.header.classList.toggle(this.prefs().classesHeaderStuck, this.isHeaderSticky);
            this.setHeaderHeight();
        }
    }

    return HarmonyStickyHeader;
}
