import { getJSONByUrl } from '../toolbox/ajax';

const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32
});

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 */

/**
 * @description Country selector switcher
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof CountrySelector} Country Selector class
 */

export default function (Widget) {
    /**
     * @category widgets
     * @subcategory header
     * @class CountrySelector
     * @augments Widget
     * @classdesc Represents `CountrySelector` implementation:
     * - Acts as a usability [component {@link MenuButton}](https://www.w3.org/TR/wai-aria-practices/#menubutton) on lg/xl viewports and as {@link HamburgerMenuItem} on sm/md viewports
     * For sm/md viewports {@link CountrySelector} class will be mixed into special {@link HamburgerMenuItem}-like class {@link CountrySelectorMenuItem}
     * - Allows customer to choose target localized version of the site for both, locales and external sites. Also changes currency in accordance to selected locale/country.
     * @property {string} data-widget - Widget name `countrySelector`
     * @property {string} data-url-locale - Set chosen localization info URL for current site locale
     * @property {string} data-url-site - Set chosen site info URL for target site locale
     * @example <caption>Example of CountrySelector for lg/xl viewport</caption>
     * <div
     *     data-widget="countrySelector"
     *     data-url-locale="${URLUtils.url('Page-SetLocale')}"
     *     data-url-site="${URLUtils.url('Page-SetSite')}"
     * >
     *     <div
     *         class="b-country_selector"
     *         data-widget.lg.xl="menuButton"
     *         data-event-keydown.lg.xl="handleKeydown"
     *         data-event-click.lg.xl="toggleMenu"
     *         data-forward-to-parent="switchLocale"
     *     >
     *         <button
     *             onclick=""
     *             class="b-country_selector-switcher"
     *             ...
     *         >
     *             <span class="b-country_selector-locale">
     *                 ... selected locale
     *             </span>
     *         </button>
     *
     *         <ul
     *             role="menu"
     *             ...
     *         >
     *             <isloop items="${pdict.localeModel.locale.localeLinks}" var="localeLink">
     *                 <li
     *                     data-widget.lg.xl="menuBarItem"
     *                     data-forward-to-parent="switchLocale"
     *                     ...
     *                 >
     *                     <button
     *                         data-locale-config="${JSON.stringify(localeLink)}"
     *                         data-event-click.prevent="switchLocale"
     *                         data-event-keydown="switchLocale"
     *                         ...
     *                     >
     *                         ...
     *                         <span class="b-country_selector-locale_language">${localeLink.displayName}</span>
     *                     </button>
     *                 </li>
     *             </isloop>
     *         </ul>
     *     </div>
     * </div>
     * @example <caption>Example of CountrySelector for md/sm viewport</caption>
     * ...
     * <isloop items="${pdict.localeModel.locale.localeLinks}" var="localeLink">
     *     <div role="none" class="b-menu_bar-flyout_column">
     *         <div
     *             data-widget.sm.md="countrySelectorMenuItem"
     *             data-event-click.sm.md="handleClick"
     *             data-event-keydown.sm.md="handleKeydown"
     *             data-locale-config="${JSON.stringify(localeLink)}"
     *             data-url-locale="${URLUtils.url('Page-SetLocale')}"
     *             data-url-site="${URLUtils.url('Page-SetSite')}"
     *             ...
     *         >
     *             <button ...>
     *                 ... locale data
     *             </button>
     *         </div>
     *     </div>
     * </isloop>
     */
    class CountrySelector extends Widget {
        prefs() {
            return {
                urlLocale: '',
                urlSite: '',
                ...super.prefs()
            };
        }

        /**
         * @description Method to switch locale depending on event. Is used for switching locale either by mouse click or by pressing `enter` or `space` on button from keyboard
         * @listens dom#keydown
         * @listens dom#click
         * @param {refElement} button An element, which was chosen by customer and contains all info about selected localization in data attributes
         * @param {Event} event Source event, happened on button element
         * @returns {void}
         */
        switchLocale(button, event) {
            if (!(event instanceof KeyboardEvent)) {
                this.changeLocale(button.data('localeConfig'));
                return;
            }

            let preventEventActions = false;
            switch (event.keyCode) {
                case keyCode.RETURN:
                case keyCode.SPACE:
                    preventEventActions = true;
                    this.changeLocale(button.data('localeConfig'));
                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }

        /**
         * @description Change locale. Sends change locale request,
         * obtains updated URL and follows it to display target locale/site
         * @param {object} localeConfig - Target Locale configuration object
         * @param {string} localeConfig.localeID - Target Locale ID
         * @param {string} localeConfig.currencyCode - Target Locale currency code
         * @param {string} localeConfig.siteID - Target Locale site ID
         * @param {boolean} localeConfig.isOtherSite - If Target Locale not belongs to current site
         */
        changeLocale(localeConfig) {
            const queryString = (this.ref('html').data('querystring') || '').replace(/&amp;/g, '&');
            const data = {
                code: localeConfig.localeID,
                CurrencyCode: localeConfig.currencyCode,
                queryString: queryString || '',
                action: this.ref('html').data('action'),
                siteID: localeConfig.siteID
            };

            const url = localeConfig.isOtherSite ? this.prefs().urlSite : this.prefs().urlLocale;
            getJSONByUrl(url, data).then(
                response => {
                    if (response && response.redirectUrl) {
                        const brandcode = 'BrandSDK-brandJSON_';
                        let brandcodeItem = brandcode.concat($('#ejgContactUs').data('brandcode'));
                        sessionStorage.removeItem(brandcodeItem);
                        window.location.assign(response.redirectUrl);
                    }
                }
            );
        }
    }

    return CountrySelector;
}
