import { submitFormJson } from 'widgets/toolbox/ajax';

/**
 * @description Base SecureCheckoutButton implementation
 * @param {ReturnType<typeof import('widgets/global/Button').default>} Button widget for extending
 * @returns {typeof SecureCheckoutButton} SecureCheckoutButton class
 *
 */

export default function (Button) {
    class SecureCheckoutButton extends Button {
        /**
         * @description Sends GTM events, when secure checkout button clicked
         * @returns {void}
        */

        handleSubmit() {
            const promise = submitFormJson(this.config.basketValidationUrl, {}).then((response) => {
                if (response.error) {
                    if (this.config.context === 'minicart') {
                        this.eventBus().emit('gtm.cart.updated', response);
                        this.eventBus().emit('minicart.show');
                    } else {
                        this.eventBus().emit('secure.checkout.clicked', response.cartModel);
                        this.eventBus().emit('cart.items.refresh');
                    }
                } else {
                    window.location.href = this.config.checkoutUrl;
                }
            });
            this.eventBus().emit('loader.start', promise);
            this.eventBus().emit('gtm.button.click');
        }
    }

    return SecureCheckoutButton;
}
