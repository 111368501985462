export default function (Modal) {
    class BrowsingOnlyModal extends Modal {
        accept() {
            this.eventBus().emit('shiptostate.submit');
        }

        showModal(...args) {
            super.showModal(...args);

            this.eventBus().emit('header.set.active.state');
        }

        closeModal(...args) {
            super.closeModal(...args);

            this.eventBus().emit('header.remove.active.state');
        }
    }
    return BrowsingOnlyModal;
}
