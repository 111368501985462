// TODO: Add full description for methods
import EventBus from 'widgets/toolbox/eventBus';

/**
 * @class EventBusWrapper
 * @category widgets
 * @subcategory toolbox
 */
class EventBusWrapper {
    /**
     * @param {import('widgets/Widget').default} widgetInstance usually this of widget
     */
    constructor(widgetInstance) {
        this.widgetInstance = widgetInstance;
    }

    /**
     * @param {string} eventName name of event
     * @param {string} methodName name of method to call if/when event happen
     */
    on(eventName, methodName) {
        /**
         * @type {(...args: any[]) => void}
         */
        let fn = (...args) => {
            this.widgetInstance[methodName].call(this.widgetInstance, ...args);
        };
        EventBus.addListener(eventName, fn);
        this.widgetInstance.onDestroy(() => {
            if (fn) {
                EventBus.removeListener(eventName, fn);
                // @ts-ignore
                fn = null;
            }
        });
    }

    /**
     * @param {string} eventName name of event
     * @param {string} methodName name of method to call if/when event happen
     */
    once(eventName, methodName) {
        let onceFn = (...args) => {
            EventBus.removeListener(eventName, onceFn);
            this.widgetInstance[methodName].call(this.widgetInstance, ...args);
            // @ts-ignore
            onceFn = null;
        };
        EventBus.addListener(eventName, onceFn);
        this.widgetInstance.onDestroy(() => {
            if (onceFn) {
                EventBus.removeListener(eventName, onceFn);
                // @ts-ignore
                onceFn = null;
            }
        });
    }

    emit(...args) {
        // @ts-ignore
        EventBus.emit(...args);
    }
}

export default EventBusWrapper;
