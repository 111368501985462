/**
 * @description Handle ViewMore button behaviour and show hidden refinement after click on it.
 * @param {typeof import('widgets/Widget').default} Widget Base widget for extending
 */

export default function (Widget) {
    return class ReserveBarCart extends Widget {
        prefs() {
            return {
                ...super.prefs() //extend preferences from parent widget class
            };
        }
        init() {
            setTimeout(() => {
                this.eventBus().emit('rbCart.init', this);
            }, 0);
            
        }
        handleCartClick() {
            this.eventBus().emit('reserveBarCart.toggle', this);
        }
    };
}
