import { getContentByUrl } from 'widgets/toolbox/ajax';

export default function (EmitBusEvent) {
    class ShipToTitle extends EmitBusEvent {
        init() {
            super.init();
            this.eventBus().on('shipto.title.redraw', 'redraw');
        }

        /**
         * Makes an ajax call to get new view for itself.
         */
        redraw() {
            // exit if 'data-ref-url' is empty
            if (!this.config.refUrl) {
                return;
            }

            getContentByUrl(this.config.refUrl, {}).then((content) => {
                if (this.refs && this.refs.innerTitle) {
                    this.render('', {}, this.ref('innerTitle'), content);
                }
                this.eventBus().emit('sidebar.close');
            });
        }
    }

    return ShipToTitle;
}
