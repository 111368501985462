import { submitFormJson, getJSONByUrl } from 'widgets/toolbox/ajax';

/**
 * @description Extended HarmonyAddToWishlistMixin implementation.
 * @param {AddToWishlistMixin} AddToWishlistMixin Base widget for extending
 * @returns {typeof HarmonyAddToWishlistMixin} Harmony Add to Wishlist Mixin class
 */
export default function (AddToWishlistMixin) {
    class HarmonyAddToWishlistMixin extends AddToWishlistMixin {
        init() {
            super.init();
            this.pid = this.prefs().masterPid || this.prefs().pid;

            // set add to wishlist state on load
            this.getById(this.prefs().addToWishlistBtn, (/** @type {button} */ button) => {
                this.addedToWishList = button.prefs().addedToWithlist;
            });

            this.eventBus().on('update.wishlist.state', 'updateWishlistState');
        }

        prefs() {
            return {
                textRemovedFromWishlist: '',
                activeClasses: 'm-added',
                ...super.prefs()
            };
        }

        /**
         * @description Show message. The function was overloaded. It is empty, because the message isn't used.
         * @param {string} msg Message
         * @param {boolean} error Error flag
         */
        showWishlistMessage() { }

        /**
         * @description Hide message. The function was overloaded. It is empty, because the message isn't used.
         * @returns {void}
         */
        hideWishlistMessage() { }

        /**
         * @param {object} button - button
         * @description Add to Wishlist
         * @returns {void}
         */
        addToWishlist(button) {
            const addTWishlistBtnPrefs = button.prefs();

            button.busy();

            const promise = submitFormJson(addTWishlistBtnPrefs.addToWishlistUrl, {
                pid: this.pid || '',
                qty: this.selectedQuantity || ''
            }).then(response => {
                this.handleSuccess(response, button);
                this.eventBus().emit('product.added.to.wishlist', this.config.analytics);
            }).finally(() => {
                button.unbusy();
            }).catch(() => {
                this.showWishlistMessage(this.prefs().textNetworkError, true);
            });

            this.eventBus().emit('loader.start', promise);
        }

        /**
         * @param {object} button - button
         * @description Remove from Wishlist
         * @returns {void}
         */
        removeFromWishlist(button) {
            const addTWishlistBtnPrefs = button.prefs();

            button.busy();

            const promise = getJSONByUrl(addTWishlistBtnPrefs.removeFromWishlistUrl, {
                pid: this.pid || ''
            }).then(response => {
                this.handleRemoveSuccess(response, button);
                this.eventBus().emit('product.removed.from.wishlist', this.config.analytics);
            }).finally(() => {
                button.unbusy();
            }).catch(() => {
                this.showWishlistMessage(this.prefs().textNetworkError, true);
            });

            this.eventBus().emit('loader.start', promise);
        }

        /**
         * @description Handle success response
         * @param {object} response - Response object
         * @param {object} button - button
         * @returns {void}
         */
        handleSuccess(response, button) {
            const addToWishlistBtn = button || this.getById(
                this.prefs().addToWishlistBtn,
                (/** @type {button} */ element) => element
            );

            if (!addToWishlistBtn) {
                return;
            }

            if (response.success) {
                this.addedToWishList = true;

                addToWishlistBtn
                    .activate()
                    .press();

                if (!this.addToWishlistHideTexts) {
                    addToWishlistBtn.setText(this.prefs().textAddedToWishlist);
                }

                const accessibilityAlert = this.prefs().accessibilityAlerts.addedtowishlist;
                // TODO add event description
                this.eventBus().emit('alert.show', {
                    accessibilityAlert
                });
            }

            if (!this.addToWishlistHideTexts) {
                this.showWishlistMessage(response.msg, response.error);
            }
        }

        /**
         * @description Handle remove success response
         * @param {object} response - Response object
         * @param {object} button - button
         * @returns {void}
         */
        handleRemoveSuccess(response, button) {
            const addToWishlistBtn = button || this.getById(
                this.prefs().addToWishlistBtn,
                (/** @type {button} */ element) => element
            );

            if (!addToWishlistBtn) {
                return;
            }

            if (response.success) {
                this.addedToWishList = false;

                addToWishlistBtn
                    .deactivate()
                    .unpress();

                if (!this.addToWishlistHideTexts) {
                    addToWishlistBtn.setText(this.prefs().textRemovedFromWishlist);
                }

                const accessibilityAlert = this.prefs().accessibilityAlerts.removedfromwishlist;
                // TODO add event description
                this.eventBus().emit('alert.show', {
                    accessibilityAlert
                });

                if (response.isEmpty) {
                    this.eventBus().emit('wishlist.empty');
                }
            }

            if (!this.addToWishlistHideTexts) {
                this.showWishlistMessage(response.msg, response.error);
            }
        }

        /**
         * @description Method to revert initial state for `Add to Wishlist` button and messages
         * Usually used when re-rendering PDP/QuickView after variation change
         * @param {object} product - Product object
         * @returns {void}
         */
        renderWishList(product) {
            super.renderWishList(product);
            const masterPid = product?.master?.pid;
            if (masterPid) {
                this.pid = masterPid;
            }
        }

        /**
         * @param {object} button - button
         * @description Process Wishlist
         * @returns {void}
         */
        processWishlist(button) {
            if (this.addedToWishList) {
                this.removeFromWishlist(button);
            } else {
                this.addToWishlist(button);
            }
        }

        /**
         * @description Update wishlist state according to incloming state
         * @param {boolean} isAdded - is added to wishlist
         */
        updateWishlistState(isAdded) {
            this.getById(this.prefs().addToWishlistBtn, (/** @type {button} */ button) => {
                if (isAdded) {
                    button.activate().press();
                    this.addedToWishList = true;
                } else {
                    button.deactivate().unpress();
                    this.addedToWishList = false;
                }
            });
        }
    }

    return HarmonyAddToWishlistMixin;
}
