window.$ = require('jquery');

const parseQueryString = (str) => Object.fromEntries(new URLSearchParams(str).entries());

const handleCommunicationAction = (action, params) => {
    const actions = {
        "transactResponse": () => {
            $('#paymentIframe').remove();
            $('.gift-payment-msg').show();
        },
        "cancel": () => {
            window.location.href = "/";
        }
    };

    if (actions[action]) {
        actions[action](params);
    }
};

$(document).ready(() => {
    window.AuthorizeNetIFrame = window.AuthorizeNetIFrame || {};

    window.AuthorizeNetIFrame.onReceiveCommunication = (querystr) => {
        const params = parseQueryString(querystr);
        handleCommunicationAction(params.action, params);
    };

    $('#paymentIframe').show();
    $('#paymentForm').submit();
});