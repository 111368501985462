const keyCode = Object.freeze({
    TAB: 9,
    RETURN: 13,
    SPACE: 32,
    PAGEUP: 33,
    PAGEDOWN: 34,
    END: 35,
    HOME: 36,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40
});
/**
 * @typedef {ReturnType<typeof import('widgets/global/ListAccessibility').default>} ListAccessibility
 * @typedef {InstanceType<ReturnType<typeof import('widgets/header/MenuBarItem').default>>} menuBarItem
 */

/**
 * @param {ListAccessibility} ListAccessibility Base widget for extending
 * @returns {typeof MegaMenu} MegaMenu widget
 */
export default function (ListAccessibility) {
    /**
     * @category widgets
     * @subcategory header
     * @class MegaMenu
     * @augments ListAccessibility
     * @classdesc Menu bar widget for Top Navigation menu.
     * Represents MegaMenu component with next features:
     * 1. Support keyboard navigation for accessibility using as basis ListAccessibility mixin and implement specific for Mega Menu accessibility behaviour
     * 2. Implementing opening/closing of mega menu flyout
     *
     * MegaMenu widget should contain {@link MenuBarItem} widgets that implement one menu item.
     * For additional information you can check:
     * cartridges/app_storefront_widgets/cartridge/templates/default/components/header/menu.isml
     * MegaMenu widget (menubar in specs) should be imlemented according to https://www.w3.org/TR/wai-aria-practices/#menu specs
     * @example <caption>Example of MegaMenu usage</caption>
        <ul
            id="main-menu"
            class="b-menu_bar-inner"
            aria-label="Main categories"
            data-widget.lg.xl="megaMenu"
            data-event-keydown.lg.xl="handleKeydown"
        >
            ...Menu bar items
        </ul>
     * @property {string} data-widget - Widget name `MegaMenu`
     * @property {string} data-event-keydown - Event listener for `handleKeydown` method
     */
    class MegaMenu extends ListAccessibility {
        /**
         * @description Initialize widget logic.
         * Define current, first and last menu item.
         * Set the role of menu.
         * @returns {void}
         */
        init() {
            this.defineItems();
            this.eventBus().on('mega.menu.close.items', 'closeItems');
            this.ref('self').attr('role', 'menubar');
            this.onDestroy(() => {
                this.ref('self').attr('role', false);
            });
        }

        /**
         * @description Set focus to item
         * @param {any} menuItem menuItem Widget
         * @returns {void}
         */
        setFocusToItem(menuItem) {
            let hasOpenedMenu = false;
            const MenuBarItem = (this.getConstructor('menuBarItem'));

            this.eachChild((child) => {
                if (!(child instanceof MenuBarItem)) { return; }

                if (child.hasOpenedSubmenu()) {
                    hasOpenedMenu = true;
                }

                child.removeTabIndex();
                child.closeMenu();
            });

            menuItem.addTabIndex();
            menuItem.focus();

            if (hasOpenedMenu) {
                menuItem.openMenu();
            }

            this.currentItem = menuItem;
        }

        /**
         * @description Close all menu Items
         * @returns {void}
         */
        closeItems() {
            this.eachChild((child) => {
                if (typeof child.closeMenu === 'function') {
                    child.closeMenu();
                }
            });
        }

        /**
         * @description Handle Enter key event
         * @listens dom:keydown
         * @param {KeyboardEvent} event Event Object
         * @returns {void}
         */
        handleEnterKey(event) {
            const domNode = /** @type {HTMLAnchorElement} */ (event.target);

            if (domNode && domNode.href) {
                window.location.assign(domNode.href);
            }
        }

        /**
         * @description Keydown Event handler
         * @listens dom:keydown
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event Event object
         * @returns {void}
         */
        handleKeydown(_, event) {
            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.PAGEUP:
                case keyCode.HOME:
                    this.setFocusToFirstItem();
                    preventEventActions = true;

                    break;

                case keyCode.PAGEDOWN:
                case keyCode.END:
                    this.setFocusToLastItem();
                    preventEventActions = true;

                    break;

                case keyCode.RIGHT:
                    this.setFocusToNextItem();
                    preventEventActions = true;

                    break;

                case keyCode.LEFT:
                    this.setFocusToPreviousItem();
                    preventEventActions = true;

                    break;

                case keyCode.UP:
                    this.currentItem.openMenu(false, true);
                    preventEventActions = true;

                    break;

                case keyCode.DOWN:
                    this.currentItem.openMenu(true, false);
                    preventEventActions = true;

                    break;

                case keyCode.TAB:
                    this.closeItems();

                    break;

                case keyCode.RETURN:
                case keyCode.SPACE:
                    this.handleEnterKey(event);
                    preventEventActions = true;

                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    return MegaMenu;
}
