// This is the swiper plugin-based carousel implementation, that could be used per specific client needs.

/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import { objectEquals } from 'widgets/toolbox/util';
import {
    getViewType, LARGE, EXTRA_LARGE, MEDIUM, SMALL
} from 'widgets/toolbox/viewtype';

const allViewPorts = [SMALL, MEDIUM, LARGE, EXTRA_LARGE].join(',');
/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description Swiper Carousel implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof CarouselSwiper} Swiper Carousel class
 */
export default function (Widget) {
    /**
     * @class CarouselSwiper
     * @augments Widget
     * @classdesc CarouselSwiper widget for different carousel types, like Product images carousel or Recommendations carousel
     * <br>Uses Swiper slider https://swiperjs.com/api/ under the hood
     * @property {string} data-widget - Widget name `carouselSwiper`
     * @property {string} data-prev - Previous button text
     * @property {string} data-next - Next button text
     * @property {string} data-slides-per-view - Number of slides per view (slides visible at the same time on slider's container).
     * @property {string} data-slides-per-view-tablet - Number of slides per view for tablet devices (slides visible at the same time on slider's container).
     * @property {string} data-slides-per-view-mobile - Number of slides per view for mobile devices (slides visible at the same time on slider's container).
     * @property {string} data-loop - Enable continuous loop mode
     * @example
     * // use this code to display widget
     * <div
     *     data-widget="carouselSwiper"
     *     data-id="carousel-${slotcontent.slotID}"
     *     class="o-carousel swiper-container o-carousel--recommend carousel slide"
     *     data-interval="0"
     *     data-prev="${Resource.msg('button.previous', 'common', null)}"
     *     data-next="${Resource.msg('button.next', 'common', null)}"
     *     data-slides-per-view="${Resource.msg('carousel.recommendations.slidesPerView', 'carousel', null)}"
     *     data-slides-per-view-tablet="${Resource.msg('carousel.recommendations.slidesPerViewTablet', 'carousel', null)}"
     *     data-slides-per-view-mobile="${Resource.msg('carousel.recommendations.slidesPerViewMobile', 'carousel', null)}"
     *     data-loop="${products.length > 3 ? true : false}"
     *     data-quantity="${products.length}"
     * >
     *     <!-- previous button -->
     *     <button class="carousel-control-prev o-carousel__control-prev" data-ref="prevBtn">
     *         <span class="icon icon-arrow-to-left" aria-hidden="true"></span>
     *     </button>
     *
     *     <!-- pagination -->
     *     <ol class="carousel-indicators swiper-pagination is-hidden" data-ref="pagination" aria-hidden="true">
     *         <isloop items="${products}" var="product" status="loopStatus">
     *             <li data-target="${'#'}Carousel-${product.ID}" data-slide-to="${loopStatus.index}" class="${!loopStatus.index ? 'active' : ''}" ></li>
     *         </isloop>
     *     </ol>
     *
     *     <!-- carousel slides -->
     *     <div class="swiper-wrapper carousel-inner o-carousel__inner" role="listbox" data-ref="carouselInner" aria-label="carousel">
     *         <isloop items="${products}" var="product" status="tile">
     *             <div class="swiper-slide carousel-item o-carousel__slide active" data-tile-position="${tile.index}">
     *                 <div class="homepage-products__item">
     *                     <isinclude url="${URLUtils.url('Tile-Show', 'pid', product.ID, 'pview', 'tile', 'ratings', true, 'swatches', true, 'showQuickView', false, 'recommendation', true)}"/>
     *                 </div>
     *             </div>
     *         </isloop>
     *     </div>
     *
     *     <!-- next button -->
     *     <button class="carousel-control-next o-carousel__control-next" data-ref="nextBtn">
     *         <span class="icon icon-arrow-to-right" aria-hidden="true"></span>
     *     </button>
     * </div>
     */
    class CarouselSwiper extends Widget {
        prefs() {
            return {
                forceInitOnUpdate: false,
                slideClass: 'swiper-slide',
                slideActiveClass: 'swiper-slide-active',
                wrapperClass: 'swiper-wrapper',
                navDisabledClass: 'swiper-button-disabled',
                navHiddenClass: 'swiper-button-hidden',
                watchSlidesVisibility: 'swiper-slide-visible',
                autoplay: false,
                loop: true,
                spaceBetween: 30,
                slidesPerView: 4,
                slidesPerViewTablet: 3,
                slidesPerViewMobile: 1,
                activeOnViewType: allViewPorts,
                ...super.prefs()
            };
        }

        activateIfViewPort() {
            const viewType = getViewType();

            if (this.prefs().activeOnViewType.includes(viewType)) {
                this.activate();
            } else {
                this.deactivate();
            }
        }

        activate() {
            if (!this.cleanUpSwiper) {
                // https://idangero.us/swiper/api/
                Promise.all([
                    import(/* webpackChunkName: 'swiper' */'swiper')
                ]).then(([swiperModule]) => {
                    const Swiper = swiperModule.default;
                    const carouselContent = this.ref('self').get();
                    if (carouselContent) {
                        /**
                         * @type {import('swiper').SwiperOptions}
                         */
                        const options = this.getOptions();

                        this.initControls(options);

                        this.swiper = new Swiper(carouselContent, options);

                        this.cleanUpSwiper = () => {
                            if (this.swiper) {
                                this.swiper.destroy(true, true);
                                this.swiper = undefined;
                                this.cleanUpSwiper = undefined;
                            }
                        };

                        this.afterActivate();

                        this.onDestroy(this.cleanUpSwiper);
                    }
                });
            }
        }

        afterActivate() {}

        initControls(options) {
            this.has('prevBtn', prevBtnEl => {
                const prevBtn = prevBtnEl.get();
                if (prevBtn && options.navigation) {
                    options.navigation.prevEl = prevBtn;
                }
            });
            this.has('nextBtn', nextBtnEl => {
                const nextBtn = nextBtnEl.get();
                if (nextBtn && options.navigation) {
                    options.navigation.nextEl = nextBtn;
                }
            });
            this.has('pagination', paginationEl => {
                const pagination = paginationEl.get();
                if (pagination) {
                    options.pagination = {
                        el: pagination
                    };
                }
            });
        }

        deactivate() {
            if (this.cleanUpSwiper) {
                this.cleanUpSwiper();
                this.cleanUpSwiper = undefined;
            }
        }

        /**
         *
         * @param {any} sliders data for slide rendering
         */
        updateSlides(sliders) {
            if (!objectEquals(this.prevSlides, sliders)) {
                this.prevSlides = sliders;
                this.render(undefined, sliders, this.ref('carouselInner')).then(() =>{
                    if (this.swiper) {
                        if (this.prefs().forceInitOnUpdate) {
                            this.deactivate();
                            this.init();
                        } else {
                            this.swiper.update();
                        }
                    }
                });
            }
        }

        getOptions() {
            const prefs = this.prefs();
            return {
                slideClass: prefs.slideClass,
                slideActiveClass: prefs.slideActiveClass,
                watchSlidesVisibility: prefs.watchSlidesVisibility,
                wrapperClass: prefs.wrapperClass,
                autoplay: prefs.autoplay,
                spaceBetween: prefs.spaceBetween,
                slidesPerView: prefs.slidesPerView,
                breakpoints: {
                    1024: {
                        loop: prefs.loop,
                        slidesPerView: prefs.slidesPerViewTablet
                    },
                    767: {
                        loop: prefs.loop,
                        slidesPerView: prefs.slidesPerViewMobile
                    }
                },
                loop: prefs.loop,
                navigation: {
                    disabledClass: prefs.navDisabledClass,
                    hiddenClass: prefs.navHiddenClass
                }
            };
        }

        init() {
            if (this.prefs().activeOnViewType === allViewPorts) {
                this.activate();
            } else {
                this.activateIfViewPort();
                this.eventBus().on('viewtype.change', 'activateIfViewPort');
            }
        }
    }

    return CarouselSwiper;
}
