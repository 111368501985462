/**
 * @description Handle ViewMore button behaviour and show hidden refinement after click on it.
 * @param {typeof import('widgets/Widget').default} Widget Base widget for extending
 */

export default function (Widget) {
    return class ReserveBarMgr extends Widget {
        prefs() {
            return {
                autoCompleteResults: [],
                shoppingCart: null,
                domCart: '',
                domSearch: '',
                liquid: null,
                selectedAddress: null,
                productContainers: [],
                groupingIDS: [],
                emptyCart: '',
                ...super.prefs() //extend preferences from parent widget class
            };
        }
        init = async () => {
            this.groupingIDS = [];
            this.autoCompleteResults = this.prefs().autoCompleteResults;
            this.shoppingCart = this.prefs().shoppingCart;
            this.liquid = this.prefs().liquid;
            this.selectedAddress = this.prefs().selectedAddress;
            this.productContainers = this.prefs().productContainers;
            this.domCart = this.prefs().domCart;
            this.domSearch = this.prefs().domSearch;
            this.emptyCart = this.config.emptycart;

            this.eventBus().on('rbSearch.init', 'initDomSearch');
            this.eventBus().on('rbCart.init', 'initDomCart');
            this.eventBus().on('reserveBarCart.toggle', 'toggleReserveBarCart');
            this.eventBus().on('reserveBarAddress.update', 'getAddress');
            this.eventBus().on('reserveBarProduct.init', 'initProduct');
            this.initLiquid().then(()=> this.initCart());
            
        }
        // takes instance of cart widget
        toggleReserveBarCart(cart) {
            const cartDrawer = cart.ref('reserveBarCartDrawer').get();
            const cartBg = cart.ref('reserveBarCartBG').get();
            if (cartDrawer) {
                cartDrawer.classList.toggle('translate-x-full');
                cartDrawer.classList.toggle('translate-x-0');
            }
            if (cartBg) {
                cartBg.classList.toggle('show');
            }
        }
        initLiquid = async () => {
            this.liquid = await Liquid({
                clientId: 'e53d4d04a676395ccd1c2d5b6b94c3aa44452330e502c999892d8091',
                env: 'production'
            });
            
        }
        initCart = async () => {
            const storedCart = JSON.parse(localStorage.getItem('RbCartID'));
            if (storedCart === null || storedCart.length === 0) {
                // If no cart is found in local storage, create a new one
                const liquidCart = await this.liquid.cart({
                    cartItems: [],
                    giftOrder: false,
                });
                this.shoppingCart = liquidCart;
            } else {
                // If a cart is found in local storage, set it in the state
                const preExistingCart = await this.liquid.cart({
                    id: storedCart.id 
                });
                //handles coming back from checkout if cart ahs been emptied.
                if (preExistingCart.cartItmes?.length < 0) {
                const liquidCart = await this.liquid.cart({
                    cartItems: [],
                    giftOrder: false,
                });
                this.shoppingCart = liquidCart;
                } else {
                    this.shoppingCart = preExistingCart;
                }
                
                this.renderShoppingCart(this.shoppingCart);
            }
            const savedAddress = JSON.parse(localStorage.getItem('RbAddress'));
            if (this.selectedAddress == null && savedAddress != null && typeof (this.domSearch) != 'string'
            ) {
                try {
                    
                    this.selectedAddress = savedAddress;
                        const search = this.domSearch.ref('reserveBarSearch').get();
                        search.value = this.selectedAddress.formattedAddress;
                    const productList = await this.fetchProducts(this.selectedAddress.formattedAddress);
                    // Render the productList
                    this.renderRetailers(productList);
                } catch (error) {
                    console.log(error);
                }
            }
            this.handleUpdateCartQty(this.domCart);
        }
        initProduct(product) {
            this.groupingIDS.push(product.config.grouping);
            this.productContainers.push(product);
        }
        initDomCart(cart) {
            this.domCart = cart;
        }
        initDomSearch(search) {
            this.domSearch = search;
        }
        getAddress = async (searchWidget) => {
            const search = searchWidget.ref('reserveBarSearch').get();
            let autoCompleteResults;
            if (search.value.trim().length === 0) {
                autoCompleteResults = [];
                this.renderAutoCompleteResults();
                return;
            }
            try {
                /// Get address suggestion from liquid
                autoCompleteResults = await this.liquid.address({
                    search: search.value,
                });
                /// Render the address suggestion
                this.renderAutoCompleteResults(searchWidget, autoCompleteResults, this.config.emptycart);
            } catch (error) {
                console.log(error);
            }
        }
        renderAutoCompleteResults(searchWidget, autoCompleteResults, emptyCartMessage) {
            // Clear the previous results
            if (searchWidget == undefined) return;
            const autoCompleteList = searchWidget.ref('autoCompleteList').get();
            autoCompleteList.innerHTML = '';
            autoCompleteList.classList.remove('results');
        
            if (autoCompleteResults == undefined) return;
            // // Render the new results
            autoCompleteResults.forEach((result) => {
                const listItem = document.createElement('li');
                listItem.setAttribute('tabindex', '0');
                listItem.textContent = result.description;
                listItem.addEventListener('click', () => this.handleAddressClick(searchWidget, result, emptyCartMessage));
                listItem.addEventListener('keypress', (event) => { if (event.key === "Enter") { this.handleAddressClick(searchWidget, result, emptyCartMessage); } })
                autoCompleteList.classList.add('results');
                autoCompleteList.appendChild(listItem);
            });
        }
        handleAddressClick = async (searchWidget, result, emptyCartMessage) => {
            // Clear the previous results
            const searchInput = searchWidget.ref('reserveBarSearch').get();
            searchInput.value = result.description;
            if (searchWidget != undefined) {
                    const autoCompleteList = searchWidget.ref('autoCompleteList').get();
                    autoCompleteList.innerHTML = '';
                    autoCompleteList.classList.remove('results'); 
            }

            this.selectedAddress = '';
            //clear shopping cart
            this.clearCart().then(() => this.handleUpdateCartQty(this.domCart));
            localStorage.removeItem('RbAddress');
           
            const productCartDiv = this.domCart.ref('productCart').get();
            productCartDiv.innerHTML = emptyCartMessage;

            this.productContainers.forEach(element => {
                const retailersContainer = element.ref('reserveBarRetailers').get();
                if (retailersContainer) {
                    retailersContainer.style.display = 'block';
                }
            });

            const productList = await this.fetchProducts(result.description);
            // Render the productList
            this.renderRetailers(productList);

            try {
                // Get the full address details
                const placeDetail = await this.liquid.address({
                    placeId: result.placeId
                });
                localStorage.setItem('RbAddress', JSON.stringify(placeDetail));
                this.selectedAddress = placeDetail;
           
            } catch (error) {
                 console.log(error);
            }
        };

        /// Fetch products from liquid
        fetchProducts = async (address) => {
            try {
                return await this.liquid.product({
                    ids: this.groupingIDS ,
                    shipAddress: address,
                    top100: false,
                });
            } catch (error) {
                console.log(error);
            }
        };

        renderRetailers = (productList) => {


            this.productContainers.forEach(element => {
                const product = productList.find(product => product.id == element.config.grouping)
                const retailersContainer = element.ref('reserveBarRetailers').get();
                const productGrid = document.createElement('div');
                productGrid.classList.add('b-reserve_bar--productGrid');
                const productContainer = document.createElement('div');
                const sizeContainer = document.createElement('div');
                if (retailersContainer) {
                    retailersContainer.innerHTML = "";
                }
                if (product) {
                    if (product.variants.length > 0) {
                        const sizeSelect = document.createElement('div');
                        sizeSelect.classList.add('b-reserve_bar--sizeSelect');
                        sizeSelect.innerHTML = '<p class="t-heading_5">Select a Size</p>';
                        product.variants.forEach((variant) => {
                            if (variant) {
                                sizeSelect.innerHTML += `<button class="g-button_alt" data-sizeselect="${variant.size.replace(', bottle', '')}">${variant.size.replace(', bottle', '')}</button>`;
                                sizeSelect.addEventListener('click', (event) => {

                                  
                                    const allsizeButtons = document.querySelectorAll('[data-sizeselect]');
                                    [...allsizeButtons].forEach(button => {
                                        if (button == event.target) {
                                              event.target.classList.remove('g-button_alt');
                                                event.target.classList.add('g-button_main');
                                        } else {
                                              button.classList.add('g-button_alt');
                                            button.classList.remove('g-button_main');
                                        }
                                    });
                                    const allRetailers = retailersContainer.querySelectorAll('.productRetailer');
                                    allRetailers.forEach(retailer => {
                                        retailer.style.display = 'none';
                                    });
                                    const availabilityLabels = retailersContainer.querySelectorAll('.productAvailability');
                                          availabilityLabels.forEach(label => {
                                        label.style.display = 'none';
                                    });
                                    const selectedRetailers = retailersContainer.querySelectorAll(`[data-size="${event.target.dataset.sizeselect}"]`);
                                    selectedRetailers.forEach(selected => {
                                        selected.style.display = 'block';
                                    });
                                })
                                sizeContainer.appendChild(sizeSelect);
                                variant.availability.forEach((availability) => {
                                    const availabilityLabel = document.createElement('h3');
                                    let label = '';
                                    if (availability == 'shipped') {
                                        label= "Ship"
                                    }
                                    if (availability == 'onDemand') {
                                        label = 'Delivery Same Day or Schedule';
                                    }
                                    availabilityLabel.innerHTML = label;
                                    availabilityLabel.classList.add('productAvailability');
                                    availabilityLabel.classList.add('t-heading_5');
                                    availabilityLabel.setAttribute('data-size', variant.size.replace(', bottle', ''));
                                    availabilityLabel.style.display = 'none';
                                    productContainer.appendChild(availabilityLabel);   
                                    variant.retailers.forEach((retailer) => {
                                        if (retailer.type == availability) {
                                        const productDetails = document.createElement('div');
                                            productDetails.style.display = 'none';
                                            productDetails.classList.add('productRetailer');
                                            if (retailer.shippingMethod.deliveryExpectation == 'On backorder') {
                                                productDetails.classList.add('disabled');
                                            }
                                            productDetails.classList.add('t-paragraph_1');
                                            productDetails.setAttribute('data-size', variant.size.replace(', bottle', ''));
                                            productDetails.innerHTML = `
                                            <span>${retailer.retailer.name}</span><br />
                                            <span>${retailer.shippingMethod.deliveryExpectation}</span><br />
                                            <span><b>Price:</b> $${retailer.price}</span>
                                        `;
                                            productDetails.addEventListener('click', () => {
                                                retailer['productImages'] = variant.images;
                                                retailer['productName'] = product.name;
                                                this.handleRetailerClick(retailer, retailer.type, element);
                                            });

                                            productContainer.appendChild(productDetails);
                                            productGrid.appendChild(productContainer);
                                    }
                                });
                                });
                            }

                        });

                        retailersContainer.appendChild(sizeContainer);
                        retailersContainer.appendChild(productGrid);
                    } else {
                        retailersContainer.innerHTML = `<p class="t-paragraph_1" style="text-align:center;" data-ref="reserveBarNoAddress"><b>${element.config.unavailable}</b></p>`;
                    }
                }
            });
        };
        handleRetailerClick(selectedRetailer, availability, element) {
            /// hide the retailers container
            const retailersContainer = element.ref('reserveBarRetailers').get();
            retailersContainer.style.display = 'none';
            const selectedProductDetailsContainer = element.ref('reserveBarSelectedProductDetails').get();

            if (selectedRetailer) {
                const productDetailsContainer = document.createElement('div');
                const productQuantityContainer = document.createElement('div');
                //// Create back button
                const buttonWrap = document.createElement('span');
                const backButton = document.createElement('button');
                backButton.type = 'button';
                backButton.textContent = '<< Back';
                backButton.addEventListener('click', () => {
                    retailersContainer.style.display = 'block';
                    selectedProductDetailsContainer.innerHTML = '';
                    //handle error message text
                });
                buttonWrap.appendChild(backButton);
                //Create product name element
                const productName = document.createElement('h3');
                productName.textContent = selectedRetailer.productName;
                productName.classList.add('t-heading_4');

                /// Create availability title element
                const retailerDetails = document.createElement('p');
                retailerDetails.textContent = `Sold By ${selectedRetailer.retailer.name}`;

                /// Create delivery expectation element
                const deliveryExpectation = document.createElement('p');
                deliveryExpectation.textContent = selectedRetailer.shippingMethod.deliveryExpectation;
        
                const selectWrap = document.createElement('div');
                /// Create quantity element
                const quantityLabel = document.createElement('label');
                quantityLabel.textContent = 'Quantity:';
                quantityLabel.classList.add('f-input_text-label');
                /// Create quantity select element
                const quantitySelect = document.createElement('select');
                quantitySelect.dataset.ref = 'quantitySelect';
                quantitySelect.addEventListener('change', () => this.handleProductQtyChange(element, selectedRetailer.price, quantitySelect.value));
                quantitySelect.classList.add('f-input_select-field');
                

                /// Populate quantity select element
                //setting artifical limit to account for typos like 99999 bottles in stock
                Array.from({
                        length: Number(selectedRetailer.inStock <= 99 ? selectedRetailer.inStock : 99)
                    },
                    (_, index) => index + 1
                ).forEach((quantity) => {
                    const option = document.createElement('option');
                    option.value = quantity.toString();
                    option.textContent = quantity.toString();
                    quantitySelect.appendChild(option);
                });

                selectWrap.appendChild(quantityLabel);
                selectWrap.appendChild(quantitySelect);
                /// Create price element
                const price = document.createElement('p');
                price.innerHTML = `<b>$${parseFloat(selectedRetailer.price).toFixed(
            2
          )}</b>`;

                /// Create error message element
                const errorMessageElement = document.createElement('p');
                errorMessageElement.id = 'errorMessage';

                /// Create total price element
                const total = document.createElement('p');
                total.dataset.ref = 'totalPrice';
                total.innerHTML = `<b>Total</b>: $${(parseFloat(selectedRetailer.price) * Number(quantitySelect.value)).toFixed(2)}`;

                /// Create `add to cart` button
                const addToCartButton = document.createElement('button');
                addToCartButton.classList.add('g-button_main');
                addToCartButton.textContent = 'Add To Cart';
                addToCartButton.addEventListener('click', () => {
                    this.updateCart(selectedRetailer, element).then(()=>{                    retailersContainer.style.display = 'block';
                    selectedProductDetailsContainer.innerHTML = '';});
                });

                /// Create product unavailable element
                const productUnavailable = document.createElement('p');
                productUnavailable.textContent = 'Product is Unavailable';


                /// Add elements to the product details container
                
                productDetailsContainer.appendChild(productName);
                productDetailsContainer.appendChild(price);
                productDetailsContainer.appendChild(retailerDetails);
                productDetailsContainer.appendChild(deliveryExpectation);
             

                productQuantityContainer.appendChild(selectWrap);
                productQuantityContainer.appendChild(total);
            
                selectedProductDetailsContainer.appendChild(buttonWrap);
                selectedProductDetailsContainer.appendChild(productDetailsContainer);
                selectedProductDetailsContainer.appendChild(productQuantityContainer);
                 selectedProductDetailsContainer.appendChild(addToCartButton);

                selectedProductDetailsContainer.appendChild(errorMessageElement);
            }






        };
        handleProductQtyChange(element, price, quantity) {
            const total = element.ref('totalPrice').get();
            total.innerHTML = `<b>Total</b>: $${(parseFloat(price) * quantity).toFixed(2)}`;
        }

        updateCart = async (retailer, element) => {
            let qty;
            try {
                if (this.shoppingCart !== null) {
                    const inCart = this.shoppingCart.cartItems.find(
                        (o) => o.product.id === retailer?.variantId
                    );
                    qty = Number(element.ref('quantitySelect').get().value);
                    // if already in the cart incrament the quantity instead of resetting to 1
                    if (inCart) {
                        const index = this.shoppingCart.cartItems.findIndex(
                            (o) => o.product.id === retailer?.variantId
                        );

                        if (index !== -1) {
                            qty = this.shoppingCart.cartItems[index].quantity + qty;
                        }
                    }
                }
                const currentCart = this.shoppingCart.cartItems ? this.shoppingCart.cartItems : [];
                currentCart.push({
                            variantId: retailer?.variantId,
                            quantity: qty,
                        })
                const newCart = {
                    id: this.shoppingCart.id,
                    cartItems: currentCart
                };

                const result = await this.liquid.cart(newCart);
                this.shoppingCart = result;
                localStorage.setItem('RbCartID', JSON.stringify(this.shoppingCart));

                // Update the this.shoppingCart object in the productCart div
                this.renderShoppingCart(result).then(() => { this.handleUpdateCartQty(this.domCart); this.toggleReserveBarCart(this.domCart); });
               
            } catch (error) {
                console.log(error);
                alert('Failed to add product to cart. Please try again later.');
            }
        };
        renderShoppingCart = async (cart) => {
            const productCartDiv = this.domCart.ref('productCart').get();
         
            if (cart) {
                       productCartDiv.innerHTML = '';
          const cartContainer = document.createElement('div');
          let counter = 0;

                        /// Render the new shopping cart items
                        if (cart.cartItems != null) {
                      cart.cartItems.forEach((item) => {
            const cartItemDiv = document.createElement('div');
            cartItemDiv.classList.add('b-rb_cart--list__item');
            const cartQuantitySelect = document.createElement('select');
            cartQuantitySelect.id = `cartQuantitySelect_${++counter}`;
            cartQuantitySelect.addEventListener('change', (event) => {
                this.handleCartQtyChange(event, item);
            });

            /// Populate quantity select element
            Array.from(
              { length: Number(item.product.inStock) },
              (_, index) => index + 1
            ).forEach((quantity) => {
              const option = document.createElement('option');
              option.value = quantity;
              option.textContent = quantity;
              cartQuantitySelect.appendChild(option);
              if (quantity === item.quantity) {
                option.selected = true;
              }
            });

        
            const cartImage = document.createElement('img');
            cartImage.width = 100;
            cartImage.height = 200;
            cartImage.src = item.product.thumbUrl;
            cartImage.alt = item.product.name
            const cartItemWrap = document.createElement('div');
            const cartItemTitle = document.createElement('h3');
            cartItemTitle.innerHTML = item.productGrouping.name;
                          const cartItemVolume = document.createElement('p');
                          cartItemVolume.classList.add('volume');
            cartItemVolume.innerHTML = item.product.volume;
            const cartQtyContainer = document.createElement('p');
                          cartQtyContainer.appendChild(cartQuantitySelect);
                          cartQtyContainer.classList.add('cartQty');
            const cartItemPrice = document.createElement('p');
                          cartItemPrice.innerHTML = `<b>Price</b>: ${item.product.price}`;
                          cartQtyContainer.appendChild(cartItemPrice);
            const cartItemFulfillment = document.createElement('p');
            cartItemFulfillment.innerHTML = `Fulfilled by ${item.retailerName}`;
            // const cartItemDelivery = document.createElement('p');
            // cartItemDelivery.innerHTML = item.shortDeliveryExpectation;
            const cartItemRemove = document.createElement('button');
            cartItemRemove.setAttribute('aria-label', 'Remove Item');
            cartItemRemove.innerHTML = '<svg fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" /></svg>'

            cartItemRemove.addEventListener('click', () => {
                this.handleRemoveFromCart(item.product.id).then(() => this.handleUpdateCartQty(this.domCart));
            });
            cartItemWrap.appendChild(cartItemTitle);
            cartItemWrap.appendChild(cartItemVolume);
            cartItemWrap.appendChild(cartItemFulfillment);
            // cartItemWrap.appendChild(cartItemDelivery);
            cartItemWrap.appendChild(cartQtyContainer);

            
            cartItemDiv.appendChild(cartImage);
            cartItemDiv.appendChild(cartItemWrap);
            cartItemDiv.appendChild(cartItemRemove); 
            cartContainer.appendChild(cartItemDiv);
        });
            
        }


          /// Create checkout button
            const checkoutButton = document.createElement('button');
            checkoutButton.textContent = 'Checkout';
            checkoutButton.classList.add('g-button_main')
            checkoutButton.addEventListener('click', () => {
            this.handleCheckout();
        });

                        if (cart.cartItems.length > 0) {
                            const subTotalWrap = document.createElement('div')
                            subTotalWrap.classList.add('b-rb_cart--subtotal');
                            subTotalWrap.id = 'rbCart-subtotal';
                            const subTotalItems = document.createElement('p');
                            subTotalItems.innerHTML = `<b>Subtotal</b> (${cart.itemCount} ${cart.itemCount > 1 ? 'items' : 'item'})`;
                            const subTotal = document.createElement('p');
                            subTotal.innerHTML = `<span>$</span> ${cart.subtotal}`;
                            subTotalWrap.appendChild(subTotalItems);
                            subTotalWrap.appendChild(subTotal)
                            productCartDiv.appendChild(cartContainer);
                            productCartDiv.appendChild(subTotalWrap);
              productCartDiv.appendChild(checkoutButton);

                        } else {
                            productCartDiv.textContent = this.emptyCart;
          }
            } else {
          productCartDiv.textContent = this.emptyCart;
        }
        }
        handleRemoveFromCart = async (productId) => {
        if (!productId) return;
        const updatedCart = {
          id: this.shoppingCart.id,
          cartItems: [
            ...this.shoppingCart.cartItems,
            ...[
              {
                variantId: productId,
                quantity: 0,
              },
            ],
          ],
        };

        try {
          this.shoppingCart = await this.liquid.cart(updatedCart);
          localStorage.setItem('RbCartID', JSON.stringify(this.shoppingCart));
          this.renderShoppingCart(this.shoppingCart);
        } catch (error) {
          console.log(error);
        }
        };
        
      handleCartQtyChange = async (event, item) => {
        const qty = Number(event.target?.value);
        const updatedCart = {
          id: this.shoppingCart.id,
          cartItems: [
            ...this.shoppingCart.cartItems,
            ...[
              {
                variantId: item.product.id,
                quantity: qty,
              },
            ],
          ],
        };
        try {
            const result = await this.liquid.cart(updatedCart);
          this.shoppingCart = result;
            localStorage.setItem('RbCartID', JSON.stringify(this.shoppingCart));
            const subTotal = document.getElementById('rbCart-subtotal');
            const subTotalItems = document.createElement('p');
            subTotalItems.innerHTML = `Subtotal(${this.shoppingCart.itemCount} ${this.shoppingCart.itemCount > 1 ? 'items' : 'item'})`;
            const subTotalPrice = document.createElement('p');
            subTotalPrice.innerHTML = `<span>$</span> ${this.shoppingCart.subtotal}`;
            subTotal.innerHTML = '';
            subTotal.appendChild(subTotalItems);
            subTotal.appendChild(subTotalPrice);
        } catch (error) {
            // log error
            console.log(error);
        }
      };
        handleCheckout = async () => {
        try {
          if (!this.selectedAddress?.address1.trim()) {
            alert('Please enter a valid delivery address');
            return;
          }
          const checkoutUrl = await this.liquid.checkout({
            cartId: this.shoppingCart.id,
            address: {
              address1: this.selectedAddress.address1,
              city: this.selectedAddress.city,
              state: this.selectedAddress.state,
              zipCode: this.selectedAddress.zipCode,
            },
          });

          if (checkoutUrl.url) {
            // Redirect to checkout
            window.location = checkoutUrl.url;
        }
        } catch (error) {
          console.log(error);
        }
        };
        handleUpdateCartQty = async (domCart) => {
            const cart = await this.liquid.cart({id: this.shoppingCart.id});
            const domQty = this.domCart.ref('reserveBarCartQty').get();
            if (this.shoppingCart.itemCount > 0) {
                domQty.classList.add('show');
            } else {
                domQty.classList.remove('show');
            }
            domQty.innerHTML = this.shoppingCart.itemCount;
        }
        async clearCart() {
            const newCart = await this.liquid.cart({
            cartItems: [],
            giftOrder: false,
            });
            this.shoppingCart = newCart;
        }
    };
    
}
