import { submitFormJson } from 'widgets/toolbox/ajax';

/**
 * @typedef {ReturnType<typeof import('widgets/product/ProductDetail').default>} ProductDetail
 * @typedef {InstanceType<ReturnType<typeof import('widgets/global/Label').default>>} label
 * @typedef {InstanceType<ReturnType<typeof import('widgets/global/Button').default>>} button
 */

/**
 * @param {ProductDetail} ProductDetail Base widget for extending
 * @returns {typeof AddToWishlistMixin} Add to Wishlist Mixin class
 */
export default function (ProductDetail) {
    /**
     * @category widgets
     * @subcategory wishlist
     * @class AddToWishlistMixin
     * @augments ProductDetail
     * @classdesc Add to wishlist mixin with next features:
     * 1. Represents `Add to wishlist` button together with related functionality
     * <br>*As far as it's a mixin, separate DOM widget is not needed.*
     * 2. Allows to add products to wishlist from PDP, QuickView
     * 3. Communicates to user operation results in both cases: error and success
     *
     * Widget has next relationship:
     * * Listens click on `add button` that instance of {@link Button} to handle addition to wish list.
     * <br>Also uses component methods to change button text, activate/deactivate button
     * * Uses methods of `add message` that instance of {@link Label} to set/hide/show messsage
     * @property {string} data-pid - product id
     * @property {string} [data-text-network-error=Network Error] - Network error message
     * @property {string} [data-add-to-wishlist-msg=addToWishlistMsg] - ID of {@link Label} component that represents `add msg`
     * @property {string} [data-add-to-wishlist-btn=addToWishlist] -  ID of {@link Button} component that represents `add button`
     * @property {string} data-text-added-to-wishlist - Added to wishlist message
     * @property {boolean} [add-to-wishlist-hide-texts=false] - Added to wishlist message
     * @property {string} data-original-wishlist-button-message - original message for `add to wishlist` button
     * @property {boolean} data-show-alert-if-product-in-wishlist - Indicates, if we need to show active icon for wishlist button,
     * in case if request returns, that iteam already in wishlist
     * @property {string} [data-class-added-to-wishlist] - Added to wishlist class
     */
    class AddToWishlistMixin extends ProductDetail {
        prefs() {
            return {
                pid: '',
                textNetworkError: 'Network Error',
                addToWishlistMsg: 'addToWishlistMsg',
                addToWishlistBtn: 'addToWishlist',
                textAddedToWishlist: '',
                addToWishlistHideTexts: false,
                originalWishlistButtonMessage: '',
                showAlertIfProductInWishlist: false,
                ...super.prefs()
            };
        }

        /**
         * @description Initialize properties
         * @returns {void}
         */
        init() {
            super.init();
            this.pid = this.prefs().pid;
            this.selectedQuantity = this.prefs().selectedQuantity;
            this.addToWishlistHideTexts = this.prefs().addToWishlistHideTexts;
        }

        /**
         * @description Update Product View
         * @param {object} product - Product Object
         * @returns {void}
         */
        updateProductView(product) {
            super.updateProductView(product);
            this.renderWishList(product);
        }

        /**
         * @description Show error/success message
         * @param {string} msg - Message string
         * @param {boolean} [error=false] Error flag
         * @returns {void}
         */
        showWishlistMessage(msg, error = false) {
            const addToWishlistMsg = this.getById(
                this.prefs().addToWishlistMsg, (/** @type {label} */ labelAddToWishlistMsg) => labelAddToWishlistMsg
            );
            if (!addToWishlistMsg) {
                return;
            }

            if (error) {
                addToWishlistMsg.setError(msg).show();
                addToWishlistMsg.deactivate();
            } else {
                addToWishlistMsg.setText(msg).show();
                addToWishlistMsg.activate();
            }
        }

        /**
         * @description Hide wishlist error/success messaging. Usualy used when PDP variation updated.
         * @returns {void}
         */
        hideWishlistMessage() {
            this.getById(
                this.prefs().addToWishlistMsg,
                (/** @type {label} */ labelAddToWishlistMsg) => {
                    labelAddToWishlistMsg.setText('').hide().deactivate();
                }
            );
        }

        /**
         * @description Show error/success message
         * @param {button} addButton - Message string
         * @listens dom#click
         * @returns {void}
         */
        addToWishlist(addButton) {
            const addToWishlistUrl = addButton.ref('self').data('addToWishlistUrl');

            addButton.busy();

            this.getById(
                this.prefs().addToWishlistMsg,
                (/** @type {label} */ labelAddToWishlistMsg) => labelAddToWishlistMsg.hide()
            );

            submitFormJson(addToWishlistUrl, {
                pid: this.pid || '',
                qty: this.selectedQuantity || ''
            }).then(response => this.handleSuccess(response)).finally(() => {
                addButton.unbusy();
            }).catch(() => {
                this.showWishlistMessage(this.prefs().textNetworkError, true);
            });
        }

        /**
         * @description Handle success response
         * @param {object} response - Response object
         * @returns {void}
         */
        handleSuccess(response) {
            const addToWishlistBtn = this.getById(
                this.prefs().addToWishlistBtn,
                (/** @type {button} */ element) => element
            );

            if (!addToWishlistBtn) {
                return;
            }

            if (response.success) {
                addToWishlistBtn
                    .activate()
                    .press()
                    .disable(); // we do not have remove from WL functionality

                if (!this.addToWishlistHideTexts) {
                    addToWishlistBtn.setText(this.prefs().textAddedToWishlist);
                }

                const accessibilityAlert = this.prefs().accessibilityAlerts.addedtowishlist;
                // TODO add event description
                this.eventBus().emit('alert.show', {
                    accessibilityAlert
                });
            } else if (response.itemAlreadyExists) {
                addToWishlistBtn
                    .activate()
                    .press()
                    .disable();

                if (this.prefs().showAlertIfProductInWishlist) {
                    // TODO add event description
                    this.eventBus().emit('alert.show', {
                        accessibilityAlert: response.msg
                    });
                }
            }

            if (!this.addToWishlistHideTexts) {
                this.showWishlistMessage(response.msg, response.error);
            }
        }

        /**
         * @description Method to revert initial state for `Add to Wishlist` button and messages
         * Usually used when re-rendering PDP/QuickView after variation change
         * @param {object} product - Product object
         * @returns {void}
         */
        renderWishList(product) {
            this.pid = product.id;

            this.getById(
                this.prefs().addToWishlistBtn,
                (/** @type {button} */ buttonAddToWishlistBtn) => {
                    buttonAddToWishlistBtn.deactivate().unpress().enable().setText(this.prefs().originalWishlistButtonMessage);
                }
            );

            if (!this.addToWishlistHideTexts) {
                this.hideWishlistMessage();
            }
        }
    }

    return AddToWishlistMixin;
}
