const keyCode = Object.freeze({
    RETURN: 13,
    SPACE: 32,
    UP: 38,
    DOWN: 40
});
/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof MenuPanelToggle} MenuPanelToggle widget
 */
export default function (Widget) {
    /**
     * @category widgets
     * @subcategory header
     * @class MenuPanelToggle
     * @augments Widget
     * @classdesc Represents MenuPanelToggle component with next features:
     * 1. Widget, which triggers touch/mobile navigation menu panel to open/close
     * 2. This widget work same as menubutton https://www.w3.org/TR/wai-aria-practices/#menubutton and open Hamburger menu, that act as regular menu, but styled as panel
     * Widget has next relationship:
     * * Open panel using method {@link MenuPanelToggle#openPanel} by event {@link MenuPanelToggle#event:navPanelOpen} from {@link MenuPanelToggle} widget.
     * * Close panel using method {@link MenuPanelToggle#closePanel} by event {@link MenuPanel#event:navPanelClose} from {@link MenuPanel} widget.
     * * Toggle panel focus using method {@link MenuPanelToggle#focus} by event {@link MenuPanel#event:navPanelToggleFocus} from {@link MenuPanel} widget.
     * @example <caption>Example of MenuPanelToggle widget usage</caption>
     * <button id="main-navigation-toggle" type="button"
     *     data-widget="menuPanelToggle"
     *     data-event-click.prevent="showPanel"
     *     data-event-keydown="handleKeydown"
     *     data-widget="menuPanelToggle"
     * >
     *     ...button content
     * </button>
     * @property {string} data-widget - Widget name `menuPanelToggle`
     * @property {string} data-event-click - Event handler method for click event
     * @property {string} data-event-keydown - Event handler method for keydown event
     */
    class MenuPanelToggle extends Widget {
        prefs() {
            return {
                classesOpenedNav: 'm-active',
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic.
         * @listens MenuPanel#navPanelClose
         * @listens MenuPanelToggle#navPanelOpen
         * @listens MenuPanel#navPanelToggleFocus
         * @returns {void}
         */
        init() {
            this.eventBus().on('nav.panel.close', 'closePanel');
            this.eventBus().on('nav.panel.open', 'openPanel');
            this.eventBus().on('nav.panel.toggle.focus', 'focus');
        }

        /**
         * @description Focus 'self' element
         * @returns {void}
         */
        focus() {
            this.ref('self').focus();
        }

        /**
         * @description Show panel
         * @emits MenuPanelToggle#navPanelOpen
         * @param {boolean} [focusToLastItem] Focus to last item flag
         * @returns {void}
         */
        showPanel(focusToLastItem) {
            this.openPanel();
            /**
             * @description Event to open nav panel
             * @event MenuPanelToggle#navPanelOpen
             */
            this.eventBus().emit('nav.panel.open', {
                focusToLastItem: focusToLastItem
            });
        }

        /**
         * @description On click handler
         * @listens dom#click
         * @returns {void}
         */
        onClick() {
            this.showPanel();
        }

        /**
         * @description Marked toggle as opened
         * @returns {void}
         */
        openPanel() {
            this.ref('self')
                .addClass(this.prefs().classesOpenedNav)
                .attr('aria-expanded', 'true');

            this.isOpenDialog = true;
        }

        /**
         * @description Close panel
         * @returns {void}
         */
        closePanel() {
            this.ref('self')
                .removeClass(this.prefs().classesOpenedNav)
                .attr('aria-expanded', 'false');

            if (this.isOpenDialog) {
                this.focus();
                this.isOpenDialog = false;
            }
        }

        /**
         * @description Keydown Event handler
         * @listens dom#keydown
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         * @returns {void}
         */
        handleKeydown(_, event) {
            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.UP:
                    this.showPanel(true);
                    preventEventActions = true;

                    break;

                case keyCode.RETURN:
                case keyCode.SPACE:
                case keyCode.DOWN:
                    this.showPanel();
                    preventEventActions = true;

                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    return MenuPanelToggle;
}
