/**
 * @description Handle ViewMore button behaviour and show hidden refinement after click on it.
 * @param {typeof import('widgets/Widget').default} Widget Base widget for extending
 */

export default function (Widget) {
    return class ViewMore extends Widget {
        prefs() {
            return {
                classCollapsed: 'm-collapsed',
                classHiddenButton: 'h-hide_visually',
                ...super.prefs()
            };
        }

        showMore() {
            const { classCollapsed, classHiddenButton, visibleItems } = this.prefs();
            const self = this.ref('self').get();

            self.classList.remove(classCollapsed);
            this.emit('resetheight', self.closest('[data-ref="accordionItemPanelInner"]').offsetHeight);

            this.has('viewMoreButton', btn => {
                btn.attr('aria-expanded', 'true');
                btn.addClass(classHiddenButton);
            });

            // timeout needed for ScreenReader to announce Expanded state
            setTimeout(() => {
                const nextVisibleItem = +visibleItems + 1;
                this.items[nextVisibleItem].ref('self').get().focus();
            }, 500);
        }

        /**
         * @description Restores 'ViewMore' button, when user clicks on 'View more' and then close, open panel
         * Emits event, that restores height for panel, that contains THIS 'view more' button
         * @param {object} [viewMore] One of the 'ViewMore' buttons for restoring
         * @param {object} [accordionItemPanel] One of the panels in which 'View more' button placed.
         * We need this panel for her height restoring
         * @returns {void}
         */

        reinitViewMore() {
            if(this.prefs().classCollapsed && this.refs.self.els[0].dataset.horizontalfilter) {
                this.ref('self').get().classList.remove(this.prefs().classCollapsed);
            } else {
                this.ref('self').get().classList.add(this.prefs().classCollapsed);
            }
            this.ref('viewMoreButton').show();
        }
    };
}
