import { transitionEnd } from 'widgets/toolbox/util';
/**
 * @typedef {typeof import('widgets/global/HamburgerMenuItem').default} HamburgerMenuItem
 */

/**
 * @description Extended HamburgerMenuItem implementation
 * @param {ReturnType<typeof import('widgets/global/HamburgerMenuItem').default>} HamburgerMenuItem Base widget for extending
 * @returns {typeof HarmonyHamburgerMenuItem} Harmony HamburgerMenuItem widget
 */
export default function (HamburgerMenuItem) {
    /**
     * @class HarmonyHamburgerMenuItem
     * @augments HamburgerMenuItem
     * @classdesc Extension of Base HamburgerMenuItem widget.
     * Harmony Hamburger menu does not use Panels, but utilize general navigation items with additional elements and classes
     * For more details see description of global/HamburgerMenuItem widget.
     */
    class HarmonyHamburgerMenuItem extends HamburgerMenuItem {
        /**
         * @description Preferences
         * @returns {object} Preferences object
         */
        prefs() {
            return {
                classesActive: 'm-active',
                backButton: 'backButton',
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         */
        init() {
            this.eventBus().on('nav.panel.close', 'resetMenu');
            super.init();
        }

        /**
         * @description Open hamburger menu
         */
        openMenu() {
            if (this.hasSubmenu()) {
                this.ref(this.prefs().submenu).get().setAttribute('aria-hidden', false);
                transitionEnd(
                    this.ref('self').get(),
                    this.prefs().classesActive,
                    () => {
                        this.ref(this.prefs().backButton).get().focus();
                    }
                );
            }
        }

        /**
         * back/level up
         */
        back() {
            this.ref('self').removeClass(this.prefs().classesActive);
            this.ref(this.prefs().itemLink).get().focus();
            this.ref(this.prefs().submenu).get().setAttribute('aria-hidden', true);
        }

        /**
         * reset menu and all menu items to default (unopened) state
         */
        resetMenu() {
            this.ref('self').removeClass(this.prefs().classesActive);
        }
    }

    return HarmonyHamburgerMenuItem;
}
