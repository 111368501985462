/**
 * Formats date to mm/dd/yyyy
 * @param {string} date -- date from input field.
 */
export function formatDate(dateString) {
    return new Date(dateString).toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });
}

/**
 * Format phone number which will be applicable for Cognito.
 * @param phoneNumber
 * @return {string}
 */
export function formatPhoneNumber(phoneNumber) {
        const phone = phoneNumber?.match(/[0-9]/g);

        // Append country code, as on storefront phone won't have it.
        phone.unshift('+1');
        
        return phone.join('')
}
