/**
 * @typedef {ReturnType<typeof import('widgets/Widget').default>} Widget
 */

/**
 * @description ProductPositionMapping widget implementation
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof ProductPositionMapping} - ProductPositionMapping instance
 */
export default function (Widget) {
    class ProductPositionMapping extends Widget {
        init() {
            const positionKey = this.data('positionKey');
            var positionMap = this.data('positionMapping');

            var currentPositions = window[positionKey] || {};
            window[positionKey] = Object.assign(currentPositions, positionMap);
        }
    }

    return ProductPositionMapping;
}
