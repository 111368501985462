/**
 * @description Handle ViewMore button behaviour and show hidden refinement after click on it.
 * @param {typeof import('widgets/Widget').default} Widget Base widget for extending
 */

export default function (Widget) {
    return class ReserveBarProduct extends Widget {
        prefs() {
            return {
                ...super.prefs() //extend preferences from parent widget class
            };
        }
        init() {
            this.eventBus().emit('reserveBarProduct.init', this);
        }
    };
}
