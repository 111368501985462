/**
 * @typedef {ReturnType<typeof import('widgets/forms/BasicForm').default>} BasicForm
 */

/**
 * @param {BasicForm} BasicForm Base widget for extending
 * @returns {typeof Form} Form class
 */
export default function (BasicForm) {
    /**
     * @class Form
     * @augments BasicForm
     * @classdesc Represents Form component
     * @example <caption>Example of Form widget usage</caption>
     * <form
     *     action="${URLUtils.url('Order-Track')}"
     *     method="GET"
     *     data-widget="form"
     *     data-event-submit="handleSubmit"
     * >
     *     ... form contents
     * </form>
     * @property {string} data-widget - Widget name `form`
     * @property {string} data-event-submit - Event listener for form submission
     */
    class Form extends BasicForm {

    }

    return Form;
}
