import { submitFormJson, writeErrorInBackendErrorLogs } from 'widgets/toolbox/ajax';

/**
 * @typedef {ReturnType<typeof import('widgets/forms/AjaxForm').default>} AjaxForm
 */

/**
 * @description HarmonyAjaxForm implementation
 * @param {AjaxForm} AjaxForm Base widget for extending
 * @returns {typeof HarmonyAjaxForm} Harmony Basic Input class
 */
export default function (AjaxForm) {
    /**
     * @class HarmonyAjaxForm
     * @augments AjaxForm
     */
    class HarmonyAjaxForm extends AjaxForm {
        /**
         * @description Handles submit Form
         * @returns {void|boolean} return bolean value if Form fields doen't valid
         */
        handleSubmit() {
            if (this.isChildrenValid() && !this.submitting) {
                this.submitting = true;
                this.showProgressBar();
                this.getById(this.prefs().submitButton, submitButton => submitButton.busy());
                this.ref(this.prefs().errorMessageLabel).hide();
                this.formFeild = Object.keys(this.getFormFields()).join(' > ');
                const promise = submitFormJson(
                    this.getFormUrl(),
                    this.getFormFields(),
                    this.ref('self').attr('method') === 'GET' ? 'GET' : 'POST'
                )
                    .then(this.onSubmitted.bind(this))
                    .catch(this.onError.bind(this))
                    .finally(this.afterSubmission.bind(this));

                this.eventBus().emit('loader.start', promise);
            }

            return false;
        }

        /**
         * @description After form submit function. Saves submitted forms, reloads onbeforeunload, sends gtm.form.submit event
         * @param {any} data Response
         * @returns {void} return bolean value if Form fields doesn't valid
         */

        onSubmitted(data) {
            this.formFeild = Object.keys(this.getFormFields()).join(' > ');
            const gtmData = this.prepareGtmData(data);
            this.eventBus().emit('gtm.form.submit', gtmData);
            super.onSubmitted(data);
        }

        /**
         * @description Sends GTM error event
         * @param {Error} e - error, which happend during request
         * @returns {void}
         */

        onError(e) {
            super.onError(e);
            writeErrorInBackendErrorLogs(e);
            this.eventBus().emit('gtm.error.occurred', e);
        }

        /**
         * Prepare data to be passed to gtm
         *
         * @param {any} data Response from form submission
         * @returns {object} data form gtm event
         */
        prepareGtmData(data) {
            /**
             * @description gtm Object
             * @type {any}
            */
            const gtmData = {
                formStatus: 'Success',
                formId: this.id || this.formId,
                formName: this.formName,
                fieldEngagement: this.formFeild
            };

            if (data) {
                // store customer id if available
                if (data.externalId) {
                    gtmData.customerId = data.externalId;
                }

                if (data.success === false) {
                    gtmData.formStatus = 'Error: Failed Form Submission';

                    if (data.fields
                        && data.fields.length
                    ) {
                        gtmData.formStatus = 'Error: Form Validation';

                        /**
                         * @description Array of error messages
                         * @type {string[]}
                        */
                        const errorMessages = [];

                        Object.keys(data.fields).forEach(fieldKey => {
                            errorMessages.push(data.fields[fieldKey]);
                        });
                        this.errorMessage = errorMessages.join('|');

                        gtmData.errorMessage = this.errorMessage;
                    }
                }
            }

            return gtmData;
        }

        dummySubmit() {
            const self = this;

            // Set time out used to call this function after BrandSDK script will have validate form
            setTimeout(function () {
            // Updates error message based on French Locale
            if (window.location.href.includes('fr_CA')) {
                $('#firstName').siblings('ul').children('li')[0].innerText = "Le prénom est requis.";
                $('#lastName').siblings('ul').children('li')[0].innerText = "Le nom de famille est requis.";
                $('#dobMonth').siblings('ul').children('li')[0].innerText = "Le mois est requis.";
                $('#dobDay').siblings('ul').children('li')[0].innerText = "Le jour est requis.";
                $('#dobYear').siblings('ul').children('li')[0].innerText = "L'année est requise.";
                $('#email').siblings('ul').children('li')[0].innerText = "Le courriel est requis.";
                $('#country').siblings('ul').children('li')[0].innerText = "Le pays est requis.";
                $('#zip').siblings('ul').children('li')[0].innerText = "Le code postal est requis.";
                $('#userCommentLabel').siblings('ul').children('li')[0].innerText = "Des commentaires sont requis.";
            }
                if (self.formName === 'contactUsForm') {
                    const errorContainer = self.ref('self').get().querySelector('.parsley-errors-list.filled');
                    const valid = !errorContainer;
                    let error;

                    if (errorContainer) {
                        error = errorContainer.innerText.trim();
                    }

                    if (!valid) {
                        // report gtm data
                        self.eventBus().emit('gtm.form.error', {
                            formName: self.formName,
                            errorMessage: error
                        });
                    }
                }
            }, 100);
        }
    }

    return HarmonyAjaxForm;
}
