/**
 * @typedef {ReturnType<typeof import('widgets/forms/InputText').default>} InputText
 */

/**
 * @description Base InputTextarea implementation
 * @param {InputText} InputText Base widget for extending
 * @returns {typeof InputTextarea} Input Textarea class
 */
export default function (InputText) {
    /**
     * @class InputTextarea
     * @augments InputText
     * @classdesc Input type InputTextarea implementation. Represents input `textarea` element together with widget-related HTML markup.
     * HTML structure assembled on backend and injected into resulted html markup by using `formElement` component
     * and dynamic forms configuration JSON.
     * Has a possibility to display number of entered symbols and how many of them are left to enter.
     * @property {string} data-widget - Widget name `inputTextarea`
     * @example <caption>InputTextarea definition in dynamicforms.json</caption>
     * ...
     * // fields -> input -> textarea
     * textarea: {
     *     extends: 'fields.input.base',
     *     validation: {
     *         'errors.security': 'validation.errors.parse'
     *     },
     *     element: {
     *         minLength: 1,
     *         maxLength: 256,
     *         type: 'textarea'
     *     }
     * },
     * ...
     * // forms -> shipping -> shippingAddress
     * giftMessage: {
     *     widget: {
     *         attributes: {
     *             'data-id': 'giftMessage'
     *         },
     *         classes: 'gift-message'
     *     },
     *     extends: 'fields.input.textarea',
     *     'label.text': 'form.gift.message',
     *     'label.showOptionalMarker': true,
     *     element: {
     *         maxcounter: true,
     *         attributes: {
     *             'data-event-input': 'onInput'
     *         },
     *         placeholder: 'form.gift.placeholder'
     *     }
     * }
     * @example <caption>Insertion of InputTextarea inside ISML templates</caption>
     * <isset name="formElement" value="${require('forms/formElement')}" scope="page"/>
     * ...
     * <form>
     *     ...
     *     <isprint value="${
     *         formElement(pdict.addressForm.giftMessage).render()
     *     }" encoding="off"/>
     *     ...
     * </form>
     * @example <caption>Resulted HTML structure for InputTextarea</caption>
     * <div data-widget="inputTextarea" data-id="giftMessage"
     *     class="b-form_section gift-message m-valid" data-validation-config="... validation config"
     * >
     *     <label class="b-form_section-label" for="dwfrm_shipping_shippingAddress_giftMessage">
     *         Gift message (optional)
     *     </label>
     *     <textarea id="dwfrm_shipping_shippingAddress_giftMessage"
     *         class="b-textarea m-valid" data-ref="field" placeholder="Enter your text..."
     *         aria-describedby="dwfrm_shipping_shippingAddress_giftMessage-error" rows="5"
     *         name="dwfrm_shipping_shippingAddress_giftMessage" maxlength="256" minlength="1"
     *         data-event-input="onInput" data-event-blur="validate"
     *     ></textarea>
     *     <div class="b-form_section-counter">
     *         <span class="b-form_section-counter_value" data-ref="maxcounter">256</span>
     *         characters left
     *     </div>
     *     <div role="alert" class="b-form_section-message m-hide" data-ref="errorFeedback" id="dwfrm_shipping_shippingAddress_giftMessage-error" hidden="hidden"></div>
     * </div>
     */
    class InputTextarea extends InputText {
        /**
         * @description On `input` InputTextarea logic. Gives a possibility to check how many symbols are entered
         * and restricts further input in case if max allowed characters reached.
         * @listens dom#input
         * @returns {void}
         */
        onInput() {
            if (!this.has('maxcounter')) {
                return;
            }

            const maxLength = Number(this.ref('field').attr('maxlength'));
            const currentInputValue = this.ref('field').val();
            let inputTextLength = currentInputValue.length;
            /* Incrementing count of 2 for linebreak in Safari, as it takes up space
               of 2 characters in text area. */
            var isSafari = window.safari !== undefined;
            if (isSafari) {
                let currentInput = currentInputValue;
                currentInput = currentInput.replaceAll((/(\r\n|\n|\r)/g), '');
                let lineLength = 0;
                const newLine = String(currentInputValue).match(/(\r\n|\n|\r)/g);
                if (newLine != null) {
                    lineLength = newLine.length;
                }
                inputTextLength = currentInput.length + (lineLength * 2);
            }

            if ((typeof currentInputValue === 'string') && (inputTextLength > maxLength)) {
                /**
                 * Additional handling of input field value on paste to
                 * avoid issues caused by browser incorrect value handling
                 * https://bugs.webkit.org/show_bug.cgi?id=182725
                 */
                this.ref('field').val(currentInputValue.slice(0, maxLength));
                inputTextLength -= inputTextLength;
                this.ref('maxcounter').setText(String(inputTextLength));
            } else {
                this.ref('maxcounter').setText(String(maxLength - inputTextLength));
            }
        }

        isValid() {
            return !this.isTextValid() ? false : super.isValid();
        }

        isTextValid() {
            const field = this.ref('field').get();
            const validation = this.prefs().validationConfig;

            if (!this.shown || this.disabled || this.prefs().skipValidation) {
                return true;
            }

            if (!new RegExp(this.prefs().notAllowedCharacters, 'g').test(field?.value)) {
                this.error = validation.errors.parse || validation.errors.security;
                return false;
            }

            return true;
        }
    }

    return InputTextarea;
}
