/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} RefElement
 */
export default function (SideBar) {
    class HarmonyHeaderSideBar extends SideBar {
        init() {
            super.init();
            this.eventBus().on('product.added.to.wishlist', 'fillWishlistIcon');
            this.eventBus().on('wishlist.empty', 'cleanWishlistIcon');
        }

        prefs() {
            return super.prefs();
        }

        fillWishlistIcon() {
            this.has('wishlistIcon', /** @param {RefElement} item */ (item) => {
                const activeClass = item.data('activeClass');
                item.addClass(activeClass);
            });
        }

        cleanWishlistIcon() {
            this.has('wishlistIcon', /** @param {RefElement} item */ (item) => {
                const activeClass = item.data('activeClass');
                item.removeClass(activeClass);
            });
        }

        /**
         * @param {typeof import('widgets/global/EmitBusEvent').default} target
         */
        open(target) {
            const refName = target.config.refName;
            if (!this.has(refName)) {
                return;
            }

            super.open(target);
            this.eventBus().emit('header.set.active.state');
        }

        close(context) {
            if (
                (typeof context === 'string' && !this.has(context))
                || !this.isOpened
            ) {
                return;
            }

            super.close(context);
            this.eventBus().emit('header.remove.active.state');
        }
    }

    return HarmonyHeaderSideBar;
}
