import { appendParamToURL, removeParamFromURL } from 'widgets/toolbox/util';
import { getJSONByUrl } from 'widgets/toolbox/ajax';
import { getStateID, isStateRestricted } from 'harmony/toolbox/shipToUtils';

/**
 * @description Base HarmonyQuantityStepper implementation. It is used for the quanity's stepper, etc.
 * @param {InputNumber} InputNumber widget for extending
 * @returns {typeof HarmonyQuantityStepper} HarmonyQuantityStepper class
 */
export default function (InputNumber) {
    /**
     * @class HarmonyQuantityStepper
     * @augments InputNumber
     */
    class HarmonyQuantityStepper extends InputNumber {
        prefs() {
            return {
                accessibilityAlerts: {},
                disabledClass: 'm-disabled',
                shipToRestrictedStates: [],
                isHandledRestrictedStates: false,
                isDisabled: false,
                wineclub : false,
                ...super.prefs()
            };
        }

        get constants() {
            return {
                ALERT_SHOW: 'alert.show'
            };
        }

        init() {
            super.init();
            this.updateStepsButtons();
            this.eventBus().on('product.quantity.disable', 'toggleDisable');
            this.eventBus().on('product.qty.disabled', 'setQtyDisabled');
            this.eventBus().on('product.quantity.updated', 'updateStepsButtons');
            this.eventBus().on('addtocart.button.clicked', 'setAddToCartClicked');

            // handle shipping restrictions
            this.eventBus().on('shipto.restrictions.updated', 'handleStateRestrictions');
            this.eventBus().on('shipto.restrictions.updated.quantity', 'handleStateRestrictions');
            this.handleStateRestrictions();
        }

        addToCartProductId() {
            let pid = document.getElementById('add-to-cart-product-id');
            let productId;
            if(this.prefs().pid){
                productId = this.prefs().pid
            } else {
                 productId = pid?.getAttribute('data-pid');
            }
            return productId;
        }

        /**
         * @param {string} incomingValue - set this value to input
         * @param {boolean} silent - if set to `true` - input should not be validated against a new value
         * @param {boolean} skipAddToCartDisable - skip disabling add to cart button
         */
        setValue(incomingValue, silent = false, skipAddToCartDisable) {
            if (!incomingValue) {
                this.processSelectedValue(incomingValue, silent);

                return;
            }

            // update 'data-qty-url' attribute with incoming value
            this.updateQunatityInDataAttrURL('qtyUrl', incomingValue);

            const qtyUrl = this.ref('field').data('qtyUrl');

            // in case if 'data-qty-url' is not set just proceed with initial min max thresholds
            if (!qtyUrl) {
                this.processSelectedValue(incomingValue, silent);

                return;
            }

            // in case if current focus is not on qty input field skipAddToCartDisable should be false or not set at all
            if (!skipAddToCartDisable) {
                this.emit('togglebusy', true);
            }

            // ajax call to receive actual quntity thresholds from DB
            const promise = getJSONByUrl(
                qtyUrl
            ).then((response) => {
                if (!response?.product) {
                    let element = document.getElementById('add-to-cart-' + this.addToCartProductId());
                    element?.setAttribute('data-add-to-cart-success', 'false');
                    return;
                }
                const previousValue = this.getValue();

                this.step = response.product.qtyStep;
                this.qtyInCart = response.product.qtyInCart;
                this.config.minValue = response.product.minOrderQuantity;
                this.config.maxValue = response.product.maxOrderQuantity;
                this.availability = response.product.availability;
                this.available = response.product.available;

                this.processSelectedValue(incomingValue, silent);

                if (!this.hasError && this.getValue() !== previousValue) {
                    this.showQuantityChangedAlert(this.getValue());
                }
            }).finally(() => {
                // allow to click add to cart button after receiveing quantity responce from DB
                if (!skipAddToCartDisable) {
                    this.emit('togglebusy', false);
                }

                /**
                 * in case directly after losing focus from qty input and add to cart btn has been clicked and
                 * selected quantity applicable to be added to cart, add to cart event triggered to avoid double click
                 */
                if (this.isAddToCartClicked && !this.hasError) {
                    this.emit('triggerclick');
                }

                this.setAddToCartClicked(false);
                let element = document.getElementById('add-to-cart-' + this.addToCartProductId());
                    element?.setAttribute('data-add-to-cart-success', 'false');
            });

            this.eventBus().emit('loader.start', promise);
        }

        /**
         * @description Update button enable/disable stage based on incoming value
         */
        updateStepsButtons() {
            // if the elements were disabled on backend then isn't need any additional actions
            if (this.isQuantityBlockDisabled()) {
                return;
            }

            this.ref('moreBtn').enable();
            this.ref('lessBtn').enable();

            const value = parseInt(this.getValue(), 10);

            if (this.prefs().minValue > value - this.step && !this.prefs().informAttemptDecreaseMinVal) {
                this.ref('lessBtn').disable();
            }

            if (this.prefs().maxValue < value + this.step) {
                this.ref('moreBtn').disable();
            }
        }

        /**
         * @description Update `data-attr-url` which is used for update of the product
         * @param {string} attrName - data attr name
         * @param {string} val - qty value
         */
        updateQunatityInDataAttrURL(attrName, val) {
            let url = this.ref('field').data(attrName);

            if (!url) {
                return;
            }

            url = removeParamFromURL(url, 'quantity');
            url = appendParamToURL(url, 'quantity', val);

            this.ref('field').data(attrName, url);
        }

        /**
         * @description Return the field of RefElement
         * @returns {RefElement} - selected qty option (field)
         */
        getSelectedOptions() {
            return this.ref('field');
        }

        /**
         * @description Toggle disable attr of input and +/- buttons
         * @param {any} isDisabled - is disabled flag
         */
        toggleDisable(isDisabled) {
            if (isDisabled) {
                this.ref('self').addClass(this.prefs().disabledClass);
                this.ref('field').disable();
                this.ref('lessBtn').disable();
                this.ref('moreBtn').disable();
            } else {
                this.ref('self').removeClass(this.prefs().disabledClass);
                this.ref('field').enable();

                const value = parseInt(this.getValue(), 10);

                if (value - this.step > this.prefs().minValue) {
                    this.ref('lessBtn').enable();
                }

                if (value + this.step < this.prefs().maxValue) {
                    this.ref('moreBtn').enable();
                }
            }
        }

        /**
         * @description Check `disabled` attribute of field and increment/decrement buttons
         * @returns {boolean} - is quantity block disabled flag
         */
        isQuantityBlockDisabled() {
            return ((this.ref('moreBtn').attr('disabled') === 'disabled')
                    && (this.ref('lessBtn').attr('disabled') === 'disabled')
                    && (this.ref('field').attr('disabled') === 'disabled'));
        }

        /**
         * @description Handle `decrement` button
         */
        decrement() {
            const newVal = parseInt(this.getValue(), 10) - this.step;

            if (newVal >= this.prefs().minValue) {
                this.setValue(newVal.toString(), false, true);
                this.eventBus().emit('product.changed.quantity', 'Remove');
            } else if (this.prefs().informAttemptDecreaseMinVal) {
                this.emit('attemptdecreaseminval');
            }
        }

        /**
         * @description Handle increment button
         */
        increment() {
            const newVal = parseInt(this.getValue(), 10) + this.step;

            if (newVal <= this.prefs().maxValue) {
                this.setValue(newVal.toString(), false, true);
                this.eventBus().emit('product.changed.quantity', 'Add');
            } else {
                this.emit('attemptincreasemaxval');
            }
        }

        /**
         * @description Show Quantity Exceed alert
         */
        showQuantityExceedAlert() {
            const accessibilityAlerts = this.prefs().accessibilityAlerts;
            const isWcInculded = this.prefs().wineclub;
            let accessibilityAlert = null;
            if(this.availability.stock > this.data('max-value')){
                accessibilityAlert = !isWcInculded ? accessibilityAlerts.exceededmaxvaluewithinventory
                .replace('{0}', this.config.maxValue): '' ;
            } else {
                accessibilityAlert = !isWcInculded ? accessibilityAlerts.exceededmaxvaluewithcart
                .replace('{maxOrderQuantity}', this.config.maxValue): '' ;

            }
            this.eventBus().emit(this.constants.ALERT_SHOW, {
                accessibilityAlert,
                errorClass: true
            });
        }

        /**
         * @description Show Quantity Changed alert
         * @param {string} value - changed value
         */
        showQuantityChangedAlert(value) {
            const isWcInculded = this.prefs().wineclub;
            const accessibilityAlert = !isWcInculded ? `${this.prefs().accessibilityAlerts.quantitychanged} ${value}` : '';

            this.eventBus().emit(this.constants.ALERT_SHOW, {
                accessibilityAlert
            });
        }

        /**
         * @description Process selected value
         * @param {string} incomingValue - set this value to input
         * @param {boolean} silent - if set to `true` - input should not be validated against a new value
         */
        processSelectedValue(incomingValue, silent) {
            let value = null;

            if (incomingValue === '' && this.prefs().allowEmpty) {
                value = incomingValue;
            } else {
                const intValue = typeof incomingValue !== 'number' ? parseInt(incomingValue, 10) : incomingValue;

                if (
                    intValue >= this.prefs().minValue
                    && intValue <= this.prefs().maxValue
                ) {
                    this.prevVal = intValue;
                    this.hasError = false;
                    value = intValue.toString();
                } else if (intValue > this.prefs().maxValue) {
                    // show exceed quantity flyout
                    var _this = this;
                    let element = document.getElementById('add-to-cart-' + this.addToCartProductId());
                    const addToCartSuccess = element?.getAttribute('data-add-to-cart-success');
                    if(addToCartSuccess == 'false') {
                    setTimeout(function () {
                        _this.showQuantityExceedAlert();
                    }, 2000);
                    }
                    this.hasError = true;
                    value = String(this.prefs().maxValue);
                    this.setValue(value, silent, false);

                    return;
                } else if (intValue < this.prefs().minValue || Number.isNaN(intValue)) {
                    this.hasError = false;
                    value = String(this.prevVal || this.prefs().minValue);
                } else {
                    this.hasError = false;
                    value = '';
                }

                if (intValue < 1) {
                    incomingValue = value;
                }
            }

            if (value) {
                // set 'data-attr-url' that is used for update variation products on pdp
                this.updateQunatityInDataAttrURL('attrUrl', incomingValue || value);
                super.setValue(value, silent);

                if (this.availability) {
                    this.eventBus().emit('availability.update', {
                        pid: this.prefs().pid,
                        availability: this.availability,
                        available: this.available
                    });
                }
            }

            // disable quantity block
            if (this.isQtyDisabled) {
                this.toggleDisable(this.isQtyDisabled);
                this.setQtyDisabled(false);
            }

            // update increment/decrement buttons enabled/disabled state based on selected qty value
            this.updateStepsButtons();
        }

        /**
         * @description vlaidate input legacy
         * @returns {boolean} - validation status
         */
        validate() {
            if (this.prefs().skipValidation) {
                return true;
            }

            return super.validate();
        }

        /**
         * @description Set isAddToCartClicked attribute
         * @param {boolean} isClicked - is add to cart clicked flag
         */
        setAddToCartClicked(isClicked) {
            this.isAddToCartClicked = isClicked;
        }

        /**
         * @description Handle shipping restrictions
         * @param {string} stateID - state id
         */
        handleStateRestrictions(stateID) {
            if (this.prefs().isDisabled || !this.prefs().isHandledRestrictedStates) {
                return;
            }

            stateID = getStateID(stateID);
            if (!stateID) {
                return;
            }
            this.toggleDisable(isStateRestricted(stateID, this.prefs().shipToRestrictedStates));
        }

        /**
         * @description Set isQtyDisabled attribute
         * @param {boolean} isDisabled - is quantity disabled flag
         */
        setQtyDisabled(isDisabled) {
            this.isQtyDisabled = isDisabled;
        }
    }

    return HarmonyQuantityStepper;
}
