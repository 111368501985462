import { scrollToTop } from '../toolbox/scroll';
import { debounce } from './../toolbox/debounce';
import { isSmallView } from './../toolbox/viewtype';
import { timeout } from 'widgets/toolbox/util';

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof BackToTop} BackToTop widget
 */
export default function (Widget) {
    /**
     * @category widgets
     * @subcategory global
     * @class BackToTop
     * @augments Widget
     * @classdesc Back to top component. Allows scrolling window to top, pressing on a corresponding element
     * @property {string} data-widget - Widget name `backtotop`
     * @property {string} data-event-click - Event listener method `backToTop` for click event on widget
     * @example <caption>Example of BackToTop widget usage</caption>
     * <div
     *     class=""
     *     data-widget="backtotop"
     *     data-event-click="backToTop"
     * >
     *     .... back to top element
     * </div>
     */
    class BackToTop extends Widget {
        prefs() {
            return {
                pageSize: 1.5,
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         * @listens "viewtype.change"
         * @returns {void}
         */
        init() {
            super.init();
            // Async init to not block other widget init
            timeout(() => {
                this.doInit();
                this.eventBus().on('viewtype.change', 'doInit');
            }, 0);
        }

        /**
         * @description Attach scroll listener
         * @returns {void}
         */
        attachScrollListener() {
            this.scrollDisposable = this.ev('scroll', debounce(() => {
                this.toggleBackToTopButton();
            }, 50), window, true);
        }

        /**
         * @description Remove scroll listener
         * @returns {void}
         */
        removeScrollListener() {
            if (this.scrollDisposable) {
                this.scrollDisposable.forEach(disposable => disposable());
                this.scrollDisposable = null;
            }
        }

        /**
         * @description Initialize widget logic
         * @returns {void}
         */
        doInit() {
            if (isSmallView()) {
                this.removeScrollListener();
                this.show();
            } else {
                this.attachScrollListener();
                this.toggleBackToTopButton();
            }
        }

        /**
         * @description Toggle back to top button
         * @returns {void}
         */
        toggleBackToTopButton() {
            const scrolled = document.body.scrollTop || document.documentElement.scrollTop;
            const windowHeight = window.innerHeight;

            this.toggle(scrolled > (windowHeight * this.prefs().pageSize));
        }

        /**
         * @description Back to top
         * @listens dom#click
         * @returns {void}
         */
        backToTop() {
            scrollToTop();
        }
    }

    return BackToTop;
}
