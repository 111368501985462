export default function (HarmonyModal) {
    class cancelOrderModal extends HarmonyModal {
        init() {
            super.init();
        }

        closeModal() {
            super.closeModal();
        }

        show() {
            this.getById('cancelOrder', (/** @type {modal} */ cancelOrder) => cancelOrder
                .showModal({
                    attributes: {
                        'data-tau-unique': 'open_cancelorder_dialog'
                    }
                }));
        }

        cancelOrder() {
            const cancelReason = ($('#cancelreason').val() === '' ? 'none' : $('#cancelreason').val());
            const url = $('#confirmCancelOrderUrl').val();
            const CancelOrderDetails = { cancelReason: cancelReason };
            $('body').addClass('m-panel-open');
            $('.b-loader').removeClass('h-hidden');
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: CancelOrderDetails,
                success: function (res) {
                    var transactionId = res.transactionID;
                    var gtmData = {
                        event: 'orderHistory',
                        orderHistoryAction: 'Cancel Order',
                        canceledTransactionId: transactionId
                    };
                    var isOrderDetailPage = $('.b-order_short_summary-status').parents('.b-order_details').length;
                    dataLayer.push(gtmData);
                    if (res.success === false) {
                        $('.renderTemplate').html(res.renderedTemplate);
                        $('.b-loader').addClass('h-hidden');
                    } else if (res.guest === true || isOrderDetailPage) {
                        $('body').removeClass('m-has_dialog');
                        $('.b-loader').addClass('h-hidden');
                        $('.b-order_short_summary-status').addClass('deleted');
                        $('.b-order_short_summary-status').html('Your order is cancelled');
                        $('.cancelorder-modal').hide();
                    } else {
                        window.location.reload();
                    }
                },
                error: function(error){
                    $('body').removeClass('m-panel-open');
                    $('.b-loader').addClass('h-hidden');
                    console.log(error);
                }
            });
        }
    }

    return cancelOrderModal;
}
