/**
 * @typedef {ReturnType<typeof import('widgets/global/Button').default>} Button
 */

/**
 * @description HarmonyButton implementation
 * @param {Button} Button Base widget for extending
 * @returns {typeof HarmonyButton} HarmonyButton
 */
export default function (Button) {
    class HarmonyButton extends Button {
        /**
         * @description Sends GTM event, when button was clicked
         * @returns {void}
         */
        handleClick() {
            super.handleClick();
            this.eventBus().emit('gtm.button.click');
        }

        busy() {
            this.isBusy = true;
        }

        unbusy() {
            this.isBusy = false;
        }
    }

    return HarmonyButton;
}
