import YouTubeIframe from 'youtube-iframe';

/**
 * @description VideoPlayer implementation
 * @param {typeof import('widgets/Widget').default} Widget Base widget for extending
 * @returns {typeof VideoPlayer}
 */
export default function (Widget) {
    /**
     * @class VideoPlayer
     */
    class VideoPlayer extends Widget {
        prefs() {
            return {
                videoId: '',
                controls: true,
                autoplay: false,
                loop: false,
                videoPlayerContainer: 'videoPlayerContainer',
                ...super.prefs()
            };
        }

        init() {
            super.init();

            const {
                videoId, controls, autoplay, loop
            } = this.prefs();

            YouTubeIframe.load((YT) => {
                this.player = new YT.Player(this.ref('videoPlayerContainer').els[0], {
                    videoId: videoId,
                    playerVars: {
                        enablejsapi: 1,
                        fs: 1,
                        mute: 1,
                        disablekb: 1,
                        controls: controls ? 1 : 0,
                        autoplay: autoplay ? 1 : 0,
                        playlist: autoplay ? videoId : '',
                        loop: loop ? 1 : 0,
                        rel: 0
                    }
                });
            });
        }
    }

    return VideoPlayer;
}
