import { submitFormJson } from 'widgets/toolbox/ajax';
/**
 * @description Base ShippingSelector implementation
 * @param {Widget} Widget Modal widget for extending
 * @returns {typeof ShippingSelector} ShippingSelector class
 */
export default function (Widget) {
    class ShippingSelector extends Widget {
        selectShippingMethod() {
            var methodID = this.ref('field').val();
            const promise = submitFormJson(
                this.config.actionUrl,
                { methodID: methodID },
                'POST'
            ).then((res) => {
                // res - basketModel
                this.eventBus().emit('shipping.method.selected', res);
            });

            this.eventBus().emit('loader.start', promise);
        }
    }
    return ShippingSelector;
}
