/**
 * @module cookie
 * @category widgets
 * @subcategory toolbox
 */

/**
 * @description Uses to set cookies to the document
 *
 * @param {string} cookieName - cookie name
 * @param {string} cookieValue - cookie value
 * @param {number} expireInDays - number of days before cookie expiration. 0 - end of a session, 365 - default value
 * @param {boolean} isSecure - adds secure cookie if true
 */
export function setCookie(cookieName, cookieValue, expireInDays = 365, isSecure = false) {
    const d = new Date();
    d.setTime(d.getTime() + (expireInDays * 24 * 60 * 60 * 1000));
    const expires = expireInDays === 0 ? '' : `expires=${d.toUTCString()}`;
    document.cookie = `${cookieName}=${cookieValue};${expires};path=/${isSecure ? ';secure' : ''}`;
}

/**
 * @description Gets cookie value from document.
 * Empty string is returned by default
 *
 * @param {string} cookieName - cookie name
 * @returns {string} - cookie value
 */
export function getCookie(cookieName) {
    const name = `${cookieName}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
