import { getContentByUrl } from 'widgets/toolbox/ajax';

/**
 * @description EinsteinCarousel implementation
 * @param {Carousel} Carousel
 * @returns {typeof EinsteinCarousel}
 */
export default function (Carousel) {
    return class EinsteinCarousel extends Carousel {
        prefs() {
            return {
                recommender: '',
                recommenderType: '',
                productLoadUrl: '',
                categoryId: '',
                secondaryProductId: '',
                alternativeGroupType: '',
                alternativeGroupId: '',
                primaryProductId: '',
                template: 'commerce_assets/product/productTileWrapper',
                iterationDelay: 250,
                maxIterationsCount: 10,
                ...super.prefs()
            };
        }

        initCarousel() {
            // CQuotient library included in the end of the page
            // and sometimes not available, when EinsteinCarousle initializes.
            // Therefore, we run an interval check to handle such cases.
            const { maxIterationsCount, iterationDelay } = this.prefs();
            let iteration = 0;

            this.interval = window.setInterval(() => {
                if (++iteration === maxIterationsCount) {
                    window.clearInterval(this.interval);
                }

                this.getEinsteinUtils();
                this.loadRecommendations();
            }, iterationDelay);
        }

        getEinsteinUtils() {
            const einsteinUtils = window.CQuotient;

            if (einsteinUtils
                && (typeof einsteinUtils.getCQUserId === 'function')
                && (typeof einsteinUtils.getCQCookieId === 'function')
                && (typeof einsteinUtils.getRecs === 'function')
            ) {
                this.einsteinUtils = einsteinUtils;
                window.clearInterval(this.interval);
            }
        }

        loadRecommendations() {
            if (!this.einsteinUtils) {
                return;
            }

            const { recommenderType } = this.prefs();

            if (recommenderType === 'category') {
                this.processRecommendationsTile(this.createCategoryAnchor());
            } else if (recommenderType === 'product') {
                this.processRecommendationsTile(this.createProductAnchor());
            } else {
                this.processRecommendationsTile();
            }
        }

        createCategoryAnchor() {
            return [{
                id: this.prefs().categoryId
            }];
        }

        createProductAnchor() {
            const {
                primaryProductId, secondaryProductId, alternativeGroupType, alternativeGroupId
            } = this.prefs();

            return [{
                id: primaryProductId,
                sku: secondaryProductId,
                type: alternativeGroupType,
                alt_id: alternativeGroupId
            }];
        }

        processRecommendationsTile(anchorsArray) {
            const {
                getCQUserId, getCQCookieId, getRecs, clientId
            } = this.einsteinUtils;
            const recommender = this.prefs().recommender;
            const params = {
                userId: getCQUserId(),
                cookieId: getCQCookieId(),
                ccver: '1.01'
            };

            if (anchorsArray) {
                params.anchors = anchorsArray;
            }

            getRecs(clientId, recommender, params, this.recommendationsReceived.bind(this));
        }

        recommendationsReceived(einsteinResponse) {
            const { recommender, list } = this.prefs();
            const recommendedProducts = einsteinResponse[recommender].recs;

            if (!recommendedProducts || !recommendedProducts.length) {
                return;
            }

            const { template, productLoadUrl } = this.prefs();
            const components = recommendedProducts.map((recommendedProduct) => ({
                model: {
                    type: 'product',
                    id: recommendedProduct.id,
                    list: list
                },
                template
            }));

            getContentByUrl(productLoadUrl, {
                components: JSON.stringify(components)
            }).then((html) => {
                this.ref('elemCarouselTrack').els[0].insertAdjacentHTML('beforeend', html);
                this.ref('self').show();
                super.initCarousel();
            });
        }
    };
}
