import { submitFormJson } from 'widgets/toolbox/ajax';
import { get } from 'widgets/toolbox/util';

/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @description Asks the user to share geolocation.
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof GeolocationConsent} GeolocationConsent class
 */
export default function (Widget) {
    /**
     * @class GeolocationConsent
     * @augments Widget
     * @property {string} data-stateselectedbyuser - Has user already selected his state.
     * @property {string} data-geolocation-url - Action URL.
     */
    class GeolocationConsent extends Widget {
        init() {
            super.init();
            this.locateUser();
        }

        /**
         * Show prompt for user.
         */
        locateUser() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(() => {
                    this.sendLocation(true);
                }, () => {
                    this.sendLocation(false);
                });
            }
        }

        /**
         * handle state change
         */
        handleStateChange(response) {
            const stateID = get(response, 'stateID');
            const basketItems = get(response, 'basket.numItems');

            // trigger events if 'stateID' is not empty
            if (stateID) {
                this.eventBus().emit('shipto.title.redraw');
                this.eventBus().emit('shipto.state.changed', stateID);
                this.eventBus().emit('shipto.restrictions.updated', stateID);
            }

            // refresh basket items if it includes items
            if (basketItems) {
                this.eventBus().emit('cart.items.refresh');
            }
        }

        /**
         * Send location data.
         *
         * @param {Boolean} allowed
         */
        sendLocation(allowed) {
            const geolocationUrl = this.ref('self').data('geolocation-url');
            submitFormJson(String(geolocationUrl), { allowed: String(allowed) }, 'POST', true)
                .then((res) => {
                    this.handleStateChange(res);
                });
        }
    }

    return GeolocationConsent;
}
