const keyCode = Object.freeze({
    END: 35,
    HOME: 36,
    UP: 38,
    DOWN: 40
});

/**
 * @typedef {ReturnType<typeof import('widgets/global/ListAccessibility').default>} ListAccessibility
 * @typedef {ReturnType<typeof import('widgets/global/AccordionItem').default>} AccordionItem
 */

/**
 * @description Base Accordion implementation
 * @param {ListAccessibility} ListAccessibility Base widget for extending
 * @returns {typeof Accordion} Accordion widget
 */
export default function (ListAccessibility) {
    /**
     * @category widgets
     * @subcategory global
     * @class Accordion
     * @augments ListAccessibility
     * @classdesc Represents Accordion component with next features:
     * 1. Configurable via data attributes single or multiple selection of panels
     * 2. Allow has opened or closed first panel
     * 3. Allow or disallow closing panel on second click
     * 4. Support keyboard navigation for accessibility
     *
     * Accordion widget should contain {@link AccordionItem} widgets that implement one accordion section.
     * Widget has next relationship:
     * * Accordion widget expand first item if such defined in `data-open-first` attribute by calling method {@link AccordionItem#openPanel}
     * * Close all items using method {@link AccordionItem#closePanel} by event {@link AccordionItem#event:closeallitems} from {@link AccordionItem} widget.
     *
     * @property {string} data-widget - Widget name `accordion`
     * @property {string} data-event-keydown - Event listener for `handleKeydown` method
     * @property {boolean} [data-allow-toggle=false] - Flag that allow or dissallow toggle
     * @property {boolean} [data-open-first=false] - Flag that open first item
     * @property {string}  data-active-panel - Activate item by provided id
     * @property {boolean} [data-allow-multiple=false] - Flag that allow or dissallow multiple open items
     * @example <caption>Example of Single Selectable Accordion with one panel that is opened on init</caption>
     * <div
     *     data-widget="accordion"
     *     data-event-keydown="handleKeydown"
     *     data-allow-toggle="false"
     *     data-open-first="true"
     *     data-allow-multiple="false"
     *     class="b-footer_nav"
     * >
     *      <section
     *          data-widget="accordionItem"
     *          data-widget-event-closeallitems="closeItems"
     *      >
     *          <h2
     *              data-ref="accordionItemBtn"
     *              data-event-click="togglePanel"
     *              data-event-keydown="handleKeydown"
     *          >
     *              <button type="button">
     *                  Account
     *              </button>
     *          </h2>
     *          <div data-ref="accordionItemPanel">
     *              <div data-ref="accordionItemPanelInner">
     *                  Account Panel content here
     *              </div>
     *          </div>
     *      </section>
     * </div>
     * @example <caption>Example of Multi Selectable Accordion with two panel. First panel closed on init</caption>
     * <div
     *     data-widget="accordion"
     *     data-event-keydown="handleKeydown"
     *     data-allow-toggle="false"
     *     data-open-first="false"
     *     data-allow-multiple="true"
     *     class="b-footer_nav"
     * >
     *      <section
     *          data-widget="accordionItem"
     *          data-widget-event-closeallitems="closeItems"
     *      >
     *          <h2
     *              data-ref="accordionItemBtn"
     *              data-event-click="togglePanel"
     *              data-event-keydown="handleKeydown"
     *          >
     *              <button
     *                  type="button"
     *              >
     *                  Account
     *              </button>
     *          </h2>
     *          <div data-ref="accordionItemPanel">
     *              <div data-ref="accordionItemPanelInner">
     *                  Account Panel content here
     *              </div>
     *          </div>
     *      </section>
     *      <section
     *          data-widget="accordionItem"
     *          data-widget-event-closeallitems="closeItems"
     *      >
     *          <h2
     *              data-ref="accordionItemBtn"
     *              data-event-click="togglePanel"
     *              data-event-keydown="handleKeydown"
     *          >
     *              <button type="button">
     *                  Wishlist
     *              </button>
     *          </h2>
     *          <div data-ref="accordionItemPanel">
     *              <div data-ref="accordionItemPanelInner">
     *                  Wishlist Panel content here
     *              </div>
     *          </div>
     *      </section>
     * </div>
     */

    class Accordion extends ListAccessibility {
        prefs() {
            return {
                allowToggle: false,
                allowMultiple: false,
                activePanel: '',
                openFirst: false,
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         * @returns {void}
         */
        init() {
            super.init();
            this.defineItems();
            this.eachChild((item) => {
                item.isToggleAllowed = this.prefs().allowToggle;
                item.isMultipleSections = this.prefs().allowMultiple;
            });

            if (this.prefs().openFirst) {
                this.firstItem.openPanel();
            } else if (this.prefs().activePanel && this.items) {
                const AccordionItem = /** @type {AccordionItem} */(this.getConstructor('accordionItem'));
                const activeItem = this.items.find((item) => item.config.methodId === this.prefs().activePanel);

                if (activeItem instanceof AccordionItem) {
                    activeItem.openPanel();
                }
            }
            this.ref('self').addClass('m-inited');
        }

        /**
         * @description Close items
         * @listens AccordionItem#closeallitems
         * @returns {void}
         */
        closeItems() {
            this.eachChild((accordionItem) => {
                accordionItem.closePanel();
            });
        }

        /**
         * @description Keydown Event handler
         * @listens dom#keydown
         * @param {HTMLElement} _ Source of keydown event
         * @param {KeyboardEvent} event  Event object
         * @returns {void}
         */
        handleKeydown(_, event) {
            let preventEventActions = false;

            switch (event.keyCode) {
                case keyCode.HOME:
                    this.setFocusToFirstItem();
                    preventEventActions = true;

                    break;

                case keyCode.END:
                    this.setFocusToLastItem();
                    preventEventActions = true;

                    break;

                case keyCode.UP:
                    this.setFocusToPreviousItem();
                    preventEventActions = true;

                    break;

                case keyCode.DOWN:
                    this.setFocusToNextItem();
                    preventEventActions = true;

                    break;

                default:
                    break;
            }

            if (preventEventActions) {
                event.preventDefault();
                event.stopPropagation();
            }
        }
    }

    return Accordion;
}
