import { submitFormJson } from 'widgets/toolbox/ajax';

/**
 * @typedef {ReturnType<typeof import('widgets/forms/AjaxForm').default>} AjaxForm
 */

/**
 * @description Base Profile details form implementation
 * @param {AjaxForm} AjaxForm Base widget for extending
 * @returns {typeof ProfileDetailsForm}
 */

export default function (AjaxForm) {
    class ProfileDetailsForm extends AjaxForm {
        init() {
            super.init();

            this.eventBus().on('cognito.hook.updateprofiledetails.then', 'updateProfileDetailsSubmitted');
            this.eventBus().on('cognito.hook.updateprofiledetails.error', 'updateProfileDetailsError');
        }

        /**
         * Overridden default handleSubmit for handle Cognito API
         * call with updating profile data.
         *
         * @returns {boolean}
         */
        handleSubmit() {
            if (this.isChildrenValid() && !this.submitting) {
                this.submitting = true;
                this.getById(this.prefs().submitButton, submitButton => submitButton.busy());
                this.ref(this.prefs().errorMessageLabel).hide();

                const fields = this.getFormFields();
                const isSubscribed = fields.dwfrm_profile_customer_newsletterSubscription === 'true';
                let fieldsObject = {
                    firstName: fields.dwfrm_profile_customer_firstname,
                    lastName: fields.dwfrm_profile_customer_lastname,
                    phoneNumber: fields.dwfrm_profile_customer_phone,
                    currentPassword: fields.dwfrm_profile_login_currentpassword,
                    birthDate: fields.dwfrm_profile_customer_birthday,
                    username: fields.dwfrm_profile_customer_email,
                    newsletterSubscription: fields.dwfrm_profile_customer_newsletterSubscription || 'false'
                };
                if(fields.dwfrm_profile_customer_state) {
                    fieldsObject['state'] = fields.dwfrm_profile_customer_state;
                }
                    this.eventBus().emit('cognito.hook.updateprofiledetails', fieldsObject);

                this.eventBus().emit('loader.start', new Promise((resolve) => {
                    this.resolve = resolve;
                }));

                if (isSubscribed) {
                    this.eventBus().emit('gtm.subscribe.success');
                    this.eventBus().emit('monetate.track.newsletter.signup');
                }
            }

            return false;
        }

        /**
         * Called after cognito updated profile details.
         */
        updateProfileDetailsSubmitted() {
            submitFormJson(
                this.getFormUrl(),
                this.getFormFields(),
                this.ref('self').attr('method') === 'GET' ? 'GET' : 'POST'
            )
                .then(this.onSubmitted.bind(this))
                .catch(this.onError.bind(this));
        }

        /**
         * Called after cognito thrown exception while updating
         * profile details.
         *
         * @param {object} data
         * @param {string} data.message - occurred error message
         */
        updateProfileDetailsError(data) {
            this.onError(data);
            this.afterSubmission();
        }

        /**
         * @description when submitting has been done.
         */
        afterSubmission() {
            super.afterSubmission();
            this.resolveLoader();
        }

        /**
         * @description resolve loader promise for hide overlay.
         * @returns {void}
         */
        resolveLoader() {
            if (typeof this.resolve === 'function') {
                this.resolve();
            }
        }
    }

    return ProfileDetailsForm;
}
