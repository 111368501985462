/**
 * @description Base HarmonyAjaxFormResponseContainer implementation
 * @param {ReturnType<typeof import('widgets/forms/AjaxFormResponseContainer').default>} AjaxFormResponseContainer Base widget for extending
 * @returns {typeof HarmonyAjaxFormResponseContainer}
 */

export default function (AjaxFormResponseContainer) {
    /**
     * @class HarmonyAjaxFormResponseContainer
     * @augments Modal
     */
    class HarmonyAjaxFormResponseContainer extends AjaxFormResponseContainer {
        /**
         * @description Added possibility for redirect customer on a "Set New Password" page
         * when "Sent Code" popup appeared (requirement from FSD).
         */
        cancel() {
            this.getById('harmonyPasswordResetForm', harmonyPasswordResetForm => {
                // Submit Account-ForgotPassword form only in case if
                // "Sent Code" popup was opened.
                if (!harmonyPasswordResetForm.ref('sentCode').get().hidden) {
                    harmonyPasswordResetForm.superHandleSubmit();
                } else {
                    super.cancel();
                }
            });
        }
    }

    return HarmonyAjaxFormResponseContainer;
}
