/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof StickyHeader} StickyHeader widget
 */
export default function (Widget) {
    /**
     * @category widgets
     * @subcategory global
     * @class StickyHeader
     * @augments Widget
     * @classdesc StickyHeader helper. Should be on element before StickyHeader to observe intersection
     * Could be reimplemented with getBoundClientRect.top and scroll listener.
     * The main purpose is to define anchor for StickyHeader and toggle StickyHeader.
     * @example <caption>Example of StickyHeader widget usage</caption>
     * <div data-widget="stickyHeader"></div>
     */
    class StickyHeader extends Widget {
        prefs() {
            return {
                classesShow: 'b-header_stuck',
                dataTau: 'header_sticky',
                ...super.prefs()
            };
        }

        /**
         * @description Initialize widget logic
         * @returns {void}
         */
        init() {
            const stickyHeader = this.ref('self').get();
            if (!stickyHeader) {
                return;
            }
            this.observer = new IntersectionObserver(([entry]) => this.toggleStickyState(entry), { threshold: 1 });
            this.observer.observe(stickyHeader);
        }

        /**
         * @description Toggle sticky header state
         * @param {object} entry Anchor for apply sticky header
         * @returns {void}
         */
        toggleStickyState(entry) {
            document.body.classList.toggle(this.prefs().classesShow, entry.intersectionRatio < 1);
            if (entry.intersectionRatio < 1) {
                document.body.setAttribute('data-tau', this.prefs().dataTau);
            } else {
                document.body.removeAttribute('data-tau');
            }
        }

        /**
         * @description Destroy widget logic
         * @returns {void}
         */
        destroy() {
            const stickyHeader = this.ref('self').get();
            if (this.observer && stickyHeader) {
                this.observer.unobserve(stickyHeader);
                this.observer.disconnect();
            }
        }
    }

    return StickyHeader;
}
